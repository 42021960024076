import { embedDashboard } from "@superset-ui/embedded-sdk";
import { useEffect } from "react";

import { Axios } from "../../utils/axios";
import { parseJwt } from "../../utils/util";
import "./supersetEmbed.scss";

interface propsType {
  pageType: string;
  isDepartmentEnabled: boolean;
}

interface IEmbedData {
  id: string;
  page: string;
  type: string;
}

/**
 * SupersetEmbedComponent is used to embed superset dashboard to the react application
 * library used: https://www.npmjs.com/package/@superset-ui/embedded-sdk
 */
const SupersetEmbedComponent = (props: propsType) => {
  const { pageType, isDepartmentEnabled } = props;

  /**
   *
   * @returns Login response
   * Below function calls superset login wrapper api
   */
  const login = async () => {
    const res = await Axios.post(`v1/superset/superset-login`, {
      userId: localStorage.getItem("userId"),
      department_enable: isDepartmentEnabled,
    });
    return res;
  };

  const handleLoginForEmbed = async (
    supersetEmbedElement: HTMLElement | null
  ) => {
    const response = await login();
    if (response.status !== 200) return;

    const { embed_details, embed_token } = response.data;
    sessionStorage.setItem("supersetEmbedData", JSON.stringify(embed_details));
    sessionStorage.setItem("supersetGuestToken", embed_token);

    if (supersetEmbedElement) {
      embed(embed_token, embed_details);
    }
  };

  /**
   * getEmbedContent function
   * @param supersetEmbedElement - HTML Element having id supersetEmbed
   * Below function is get called whenever component is rendered
   * Here we get the supersetGuestToken from the session storage (if already exist)
   * if token is not exist then login to the superset (handleLoginForEmbed)
   * else if it is exist and if expiry time is less than current date then login to the superset
   * else pass guest token to the embed api
   */

  /**
   * If token is expired then login to the superset and get the guest token for embed
   * else return the exist token
   */
  const checkForGuestToken = async (token: string) => {
    const decodedJwt = parseJwt(
      sessionStorage.getItem("supersetGuestToken") || ""
    );

    if (!decodedJwt || decodedJwt?.exp * 1000 - 30 < Date.now()) {
      sessionStorage.setItem("supersetGuestToken", "");
      const res = await login();
      return res.data.embed_token;
    }
    return token;
  };

  /**
   *
   * @param token - guest token
   * @param embedData - array of objects contains embed ids
   * embed function is responsible for embedding dashboard to the ui
   */
  const embed = async (token: string, embedData: IEmbedData[]) => {
    try {
      await embedDashboard({
        id: embedData.find((obj: any) => obj.page === pageType)?.id || "",
        supersetDomain: process.env.REACT_APP_SUPERSET_DOMAIN || "/",
        mountPoint: document.getElementById("supersetEmbed") as HTMLElement,
        fetchGuestToken: () => checkForGuestToken(token),
        dashboardUiConfig: {
          hideTitle: true,
          hideChartControls: true,
          hideTab: false,
          filters: {
            visible: true,
          },
        },
      });
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    const embedContainer = document.getElementById("supersetEmbed");
    handleLoginForEmbed(embedContainer);
  }, []);

  return <div id="supersetEmbed" />;
};

export default SupersetEmbedComponent;
