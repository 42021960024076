import "antd/dist/antd.css";
import { Drawer } from "antd";
import Select from "react-select";
import { Formik } from "formik";
import * as yup from "yup";
import { AxiosConfig } from "../../../ApiConfig";
import { InputField } from "../../../components";
import ButtonReuse from "../../../components/Buttons/Button";
import { useState, useEffect } from "react";
import { getToken } from "../../../auth";
import { notification } from "antd";
import { addUser } from "../../../utils/enums";
require("./AddUser.scss");

const AddUser = (props: {
  visible?: any;
  onClose?: any;
  onCancelButton?: any;
}) => {
  const validationSchema = yup.object({
    first_name: yup.string().required("Please enter the first name"),
    last_name: yup.string().required("Please enter the last name"),
    email_id: yup
      .string()
      .email("Email Id must be Valid")
      .required("Please enter the email Id"),
    password: yup.string().min(6).required("Please enter the password"),
  });

  const onCancelHandler = () => {
    props.onCancelButton();
  };

  const initialValue: any = {
    first_name: "",
    last_name: "",
    email_id: "",
    password: "",
    associated_bu_array: [],
  };

  // selectRef: any = null;

  // const clearValue = () => {
  //   this.selectRef.select.clearValue();
  // };

  var token = getToken();

  const [associatedBu, setAssociatedBu] = useState([]);

  useEffect(() => {
    getAssociatedBu();
  }, []);

  const getAssociatedBu = async () => {
    await AxiosConfig.get("departments", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setAssociatedBu(res.data.data.departments);
    });
  };

  const filteredBu = associatedBu.filter(function (associatedBu: {
    flag: any;
  }) {
    return associatedBu.flag === true;
  });

  const associatedBuOptions = filteredBu.map(function (associatedBus: {
    name: any;
    label: any;
    id: any;
  }) {
    return {
      value: associatedBus.name,
      label: associatedBus.name,
      key: associatedBus.id,
    };
  });

  return (
    <>
      <Drawer
        title="Add User"
        width={500}
        onClose={props.onClose}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        className="add_user"
        closable={false}
        maskClosable={false}
      >
        <Formik
          // initialValues={{
          //   first_name: "",
          //   last_name: "",
          //   email_id: "",
          //   password: "",
          //   associated_bu_array: [],
          // }}
          initialValues={initialValue}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            AxiosConfig.post("users/register", values, {
              headers: { Authorization: `Bearer ${token}` },
            })
              .then((response) => {
                if (response.status == 200) {
                  const notify = () => {
                    notification.open({
                      message: response.data.status.message,
                    });
                  };
                  {
                    notify();
                  }
                  setTimeout(function () {
                    window.location.reload();
                  }, 1000);
                }
              })
              .catch(function (error) {
                // console.log(error.response.data.status.message);
                const notify = () => {
                  notification.open({
                    message: "",
                    description: error.response.data.status.message,
                    style: { color: "red" },
                  });
                };
                {
                  notify();
                }
              });
          }}
        >
          {({
            handleSubmit,
            handleChange,
            // handleReset,
            errors,
            values,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit} className="add_user_form">
              <label>{addUser.labelForFirstName} </label>

              <InputField
                placeholder="Enter the First Name"
                name="first_name"
                onChange={handleChange}
                value={values.first_name}
                className="users--field"
              ></InputField>
              <p className="display_error">{errors.first_name}</p>

              <label>{addUser.labelForLastName}</label>

              <InputField
                placeholder="Enter the Last Name"
                name="last_name"
                onChange={handleChange}
                value={values.last_name}
                className="users--field"
              ></InputField>
              <p className="display_error">{errors.last_name}</p>

              <label> {addUser.labelForEmailId}</label>

              <InputField
                placeholder="Enter the Email ID"
                name="email_id"
                onChange={handleChange}
                value={values.email_id}
                className="users--field"
              ></InputField>
              <p className="display_error">{errors.email_id}</p>

              <label>{addUser.labelForPassword} </label>
              <InputField
                placeholder="Enter the Password"
                name="password"
                onChange={handleChange}
                value={values.password}
                className="users--field"
              ></InputField>
              <p className="display_error">{errors.password}</p>

              <label>{addUser.labelForDepartments}</label>
              <Select
                isMulti
                isClearable
                // ref={(ref: any) => {
                //   this.selectRef = ref;
                // }}
                options={associatedBuOptions}
                className="AddUserBUSelect"
                placeholder="Select department(s) to provide access"
                name="associated_bu_array"
                values={values.associated_bu_array}
                onChange={(selectedValue: any) =>
                  setFieldValue(
                    "associated_bu_array",
                    selectedValue.map((row: any) => {
                      return row.key;
                    })
                  )
                }
              />
              <div className="button-container">
                <ButtonReuse
                  type="primary"
                  className="primary-btn"
                  htmlType="submit"
                  value="Add"
                ></ButtonReuse>
                <ButtonReuse
                  type="primary"
                  className="primary-btn cancel--btn"
                  value="Cancel"
                  // onClick={handleReset}
                  onClick={() => {
                    onCancelHandler();
                    resetForm();
                    // setFieldValue("associated_bu_array", []);
                    // handleReset();
                  }}
                ></ButtonReuse>
              </div>
            </form>
          )}
        </Formik>
      </Drawer>
    </>
  );
};

export default AddUser;
