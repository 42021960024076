import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import type { ColumnType } from 'antd/es/table';
import "antd/dist/antd.css";
require("./Audits.scss");
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { useHistory } from "react-router-dom";

const token = getToken();

interface AuditFormItem {
  id: number;
  review_type: string;
  totalProducts: number;
  interval:string;
  description: string;
}

const Audits: React.FC = () => {
  const [auditData, setAuditData] = useState([]);
  let history = useHistory();

  useEffect(() => {
    const getAuditReview = async () => {
      try {
        const res = await AxiosConfig.get("reviewSettings", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAuditData(res.data.data.reviewData);
      } catch (error) {
        console.error("Error fetching audit data:", error);
      }
    };

    getAuditReview();
  }, []);

  const onClickRow = (record: any) => {
    history.push("/audits");
    localStorage.setItem("auditId", record);
  };

  const columns: ColumnType<AuditFormItem>[] = [
    {
      title: 'Audit Forms',
      dataIndex: 'review_type',
      key: 'review_type',
      render: (text,record) => (
        <span>
          <Link onClick={() => {onClickRow(record["id"]);}} to={`/audits/${record.interval.toLowerCase()}/${record.id}`} 
            style={{ color: '#7788FB' }}  >
            {text}
          </Link>
        </span>
      ),
      align: 'center',
    },
    {
      title: 'Total Products',
      dataIndex: 'project_count',
      key: 'project_count',
      render: (text) => (
        <span>
          {text} projects
        </span>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  return (
    <div>
      <Table<AuditFormItem> dataSource={auditData} columns={columns} rowKey="id" bordered
       pagination={{
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30"],
      }}/>
    </div>
  );
};

export default Audits;
