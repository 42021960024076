import { useRef, useEffect, useState } from "react";
import "./assistant.css";
import Image from "../assets/images/sideNav_none.png";
import userImage from "../assets/images/verbal.png";
import axios from "axios";
import {
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar,
  Button,
  InputToolbox,
  MainContainer,
} from "@chatscope/chat-ui-kit-react";
import { SendOutlined, ClearOutlined } from "@ant-design/icons";
import { Modal } from "antd";
const Assistant = (props) => {
  const { setTableData, clearTableData } = props;

  const authToken = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjMsImlhdCI6MTY4Mzg3MjM1NywiZXhwIjoxNjg0NDc3MTU3fQ.orfZD4WB7uesMP_oROxel7wnHnnpglgRa44nhCEat44`;
  const inputRef = useRef();
  const messageListRef = useRef(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [conversationMessages, setConversationMessages] = useState([]);
  const [query, setQuery] = useState({ value: "" });
  const [disableFields, setDisableFields] = useState(false);
  const [responseTime, setResponseTime] = useState(0);
  const [feedbackInputIndex, setFeedbackInputIndex] = useState(-1);
  const [feedbackId, setFeedbackId] = useState();
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [conversationMessages]);
  const handleClear = () => {
    setConversationMessages([]);
    setResponseTime(0);
    clearTableData();
    clearSuggestedQuestions();
  };

  const handleSend = async () => {
    setDisableFields(true);
    const queryRequest = query.value;
    if (!queryRequest) {
      setDisableFields(false);
      return;
    }
    setQuery({ value: "" });
    // Update conversation messages for the user query
    const updatedMessages = [
      ...conversationMessages,
      {
        value: queryRequest,
        sender: "User",
        position: "outgoing",
      },
    ];
    setConversationMessages(updatedMessages);

    // Indicate that the AI is analyzing the query
    setIsAnalyzing(true);

    // Scroll to the bottom of the message list if it exists
    if (messageListRef.current) {
      messageListRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
    // Incrementally update the response time while analyzing
    let startTime = new Date().getTime();
    let intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - startTime;
      setResponseTime(elapsedTime);
    }, 1000);
    try {
      const reply = await postQueryToAPI(queryRequest);
      clearInterval(intervalId);

      if (reply.response) {
        const endTime = new Date().getTime();
        const newResponseTime = endTime - startTime;

        // Update conversation messages with AI response
        setConversationMessages((prevMessages) => [
          ...prevMessages,
          {
            value: reply.response.replace(/\n/g, "<br>"),
            sender: "AI",
            responseTime: (newResponseTime / 1000).toFixed(2), // Converted to seconds and fixed to 2 decimal places
            position: "incoming",
          },
        ]);
        setTableData(reply.result);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setIsAnalyzing(false);

      // Enable input fields and scroll to the bottom after the reply is received
      setDisableFields(false);
      if (messageListRef.current) {
        messageListRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
      inputRef.current.focus();
    }
  };
  const submitFeedback = async (feedbackId, feedback) => {
    try {
      const url = `https://traccar-dev-ai.spurtreetech.com/api/assistant/feedback/${feedbackId}`;
      const method = "put";
      await axios({
        method: method,
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        data: {
          response: true,
          feedback: feedback,
        },
      });
    } catch (error) {
      console.error(
        `Error ${feedbackId ? "updating" : "submitting"} feedback:`,
        error
      );
    }
  };
  const handleThumbsDownClick = async (index) => {
    setIsModalVisible(true);
    setFeedbackInputIndex(index);
    setConversationMessages((currentMessages) =>
      currentMessages.map((msg, idx) =>
        idx === index ? { ...msg, feedbackState: "negative" } : msg
      )
    );
  };
  const handleThumbsUpClick = async (index) => {
    const positiveFeedback = "This response was helpful.";
    await handleFeedbackSubmit(index, positiveFeedback);
    // Update the conversationMessages to reflect the feedback state
    setConversationMessages((currentMessages) =>
      currentMessages.map((msg, idx) =>
        idx === index ? { ...msg, feedbackState: "positive" } : msg
      )
    );
  };
  const postQueryToAPI = async (query) => {
    try {
      const newUrl = `https://traccar-dev-ai.spurtreetech.com/api/assistant/chat`;

      const responseMessage = await axios.post(
        newUrl,

        {
          type: "message",
          message: query,
        }
      );

      if (responseMessage) {
        const data = responseMessage;
        const responseAIMessage = {
          sender: "AI",
          value: {
            query: data.data.data.content,
            response: data.data.data.output,
            result: data.data.result,
            feedbackID: data.data.feedbackID,
            suggestedQuestions: data?.data?.suggestedQuestions,
            // threadId: data,
          },
        };


        const suggestedQuestionsString =
          responseAIMessage.value?.suggestedQuestions;
        const suggestedQuestionsArray = suggestedQuestionsString.split("\n");

        setSuggestedQuestions(suggestedQuestionsArray);

        const returnValue = {
          response: responseAIMessage.value.response,
          result: responseAIMessage.value.result,
          feedbackID: responseAIMessage.value.feedbackID,
          suggestedQuestions: responseAIMessage?.value?.suggestedQuestions,
        };
        setFeedbackId(responseAIMessage.value.feedbackID);
        return returnValue;
      } else {
        console.error("Error: No response message received.");
        return null;
      }
    } catch (error) {
      console.error("Error sending message:", error.message);
      return null;
    }
  };
  const handleFeedbackSubmit = async (index, feedback) => {
    const feedbackText =
      feedback ||
      (document.getElementById(`feedback-input-${index}`)
        ? document.getElementById(`feedback-input-${index}`).value
        : "");
    if (feedbackText) {
      await submitFeedback(feedbackId ?? null, feedbackText);
      setFeedbackInputIndex(-1);
      setIsModalVisible(false);
    }
  };
  const handleSuggestedQuestionClick = (question) => {
    const strippedQuestion = question.replace(/^\d+\.\s*/, ""); // Remove digits followed by a dot and a space

    // Update the query state to include the selected question
    setQuery((prevQuery) => {
      const updatedQuery = {
        value:
          prevQuery.value + (prevQuery.value ? " " : "") + strippedQuestion,
      };
      return updatedQuery;
    });

    // Send the suggested question as the user's input
    handleSend();
  };
  const clearSuggestedQuestions = () => {
    setSuggestedQuestions([]);
  };
  const handleCancelVerifyModel = () => {
    setIsModalVisible(false);
    setFeedbackInputIndex(-1);
  };
  return (
    <div className="assistant">
      <Modal
        title={<b>Submit Feedback</b>}
        visible={isModalVisible}
        onCancel={handleCancelVerifyModel}
        onOk={() => handleFeedbackSubmit(feedbackInputIndex)}
        okText="Submit"
        wrapClassName="ant-modal-wrap"
        style={{
          width: "500px",
          height: "290px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          border: "1px solid #ddd",
        }}
      >
        {feedbackInputIndex >= 0 && (
          <div style={{ padding: "20px" }}>
            <textarea
              placeholder="Enter feedback"
              id={`feedback-input-${feedbackInputIndex}`}
              style={{
                width: "100%",
                padding: "8px 12px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                fontSize: "14px",
                lineHeight: "1.5",
                resize: "vertical",
              }}
            ></textarea>
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        ></div>
      </Modal>
      <MainContainer className="main-container">
        <ChatContainer className="chat-container">
          <MessageList autoScrollToBottom={true} scrollBehavior="smooth">
            <div
              as={"Message"}
              ref={messageListRef}
              className="messageContainer"
            >
              {conversationMessages &&
                conversationMessages?.map((currMessage, index) => (
                  <Message
                    id={`message-${index}`}
                    key={"message-" + index}
                    avatarPosition={"tl"}
                    model={{
                      message: currMessage.value,
                      sender: currMessage.sender,
                      // responseTime: currMessage.responseTime,
                      direction:
                        currMessage.sender === "AI" ? "incoming" : "outgoing", // Adjust direction based on sender
                      position: "single",
                    }}
                  >
                    <Message.Header>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          src={currMessage.sender === "AI" ? Image : userImage}
                          style={{
                            marginRight: "15px",
                            height: "30px",
                            width: "30px",
                          }}
                        />
                        <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                          {currMessage.sender === "AI"
                            ? "TraccNinja"
                            : currMessage.sender}
                        </span>
                        {currMessage.sender === "AI" && (
                          <>
                            <span
                              style={{
                                marginLeft: "332px",
                                fontWeight: "bold",
                                fontSize: "13px",
                                display: "inline-block",
                                minWidth: "111px",
                                textAlign: "right",
                              }}
                            >
                              [{currMessage.responseTime} Seconds ]
                            </span>
                            <div>
                              <button
                                onClick={() => handleThumbsUpClick(index)}
                                className={
                                  currMessage.feedbackState === "positive"
                                    ? "button-positive"
                                    : ""
                                }
                                style={{
                                  marginLeft: "9px",
                                  borderRadius: "5px",
                                  border: "none",
                                }}
                              >
                                👍
                              </button>
                              <button
                                onClick={() => handleThumbsDownClick(index)}
                                className={
                                  currMessage.feedbackState === "negative"
                                    ? "button-negative"
                                    : ""
                                }
                                style={{
                                  marginLeft: "3px",
                                  borderRadius: "5px",
                                  border: "none",
                                }}
                              >
                                👎
                              </button>
                            </div>
                          </>
                        )}
                      </span>
                    </Message.Header>
                  </Message>
                ))}
              {isAnalyzing && (
                <Message
                  avatarPosition={"tl"}
                  model={{
                    message: "Analyzing...",
                    sender: "TraccNinja",
                    isProcessing: true,
                    direction: "incoming",
                    position: "single",
                  }}
                >
                  <Message.Header>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <Avatar
                        src={Image}
                        style={{
                          marginRight: "15px",
                          height: "30px",
                          width: "30px",
                        }}
                      />
                      <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                        TraccNinja
                      </span>
                      <span
                        style={{
                          marginLeft: "120px",
                          fontWeight: "bold",
                        }}
                      >
                        [{(responseTime / 1000).toFixed(2)} Seconds...]
                      </span>
                    </span>
                  </Message.Header>
                </Message>
              )}
              {!isAnalyzing &&
                Array.isArray(suggestedQuestions) &&
                suggestedQuestions.map((question, index) => {
                  return (
                    <Button
                      key={`suggested-question-${index}`}
                      onClick={() => handleSuggestedQuestionClick(question)}
                      disabled={disableFields}
                      className="suggested-questions"
                    >
                      {question}
                    </Button>
                  );
                })}
            </div>
          </MessageList>
          <InputToolbox className="InputToolbox">
            <div className="InputToolbox">
              <MessageInput
                className="msg-input"
                placeholder="Ask me anything related to Traccar"
                onSend={handleSend}
                ref={inputRef}
                autoFocus
                attachButton={false}
                sendButton={false}
                onChange={(text) => {
                  setQuery({ value: text });
                }}
                value={query.value}
                disabled={disableFields}
              ></MessageInput>
              <SendOutlined
                className="submit-button"
                disabled={disableFields}
                border
                onClick={handleSend}
              />

              <ClearOutlined
                className="clear-button"
                disabled={disableFields}
                border
                onClick={handleClear}
              />
            </div>
          </InputToolbox>
        </ChatContainer>
      </MainContainer>
    </div>
  );
};

export default Assistant;
