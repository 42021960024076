import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./tableComponent.css";
import {
  ExportOutlined,
  BarChartOutlined,
  PieChartOutlined,
  LineChartOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { Button, Row, Select } from "antd";
import ReactApexChart from "react-apexcharts";
import { Doughnut } from "react-chartjs-2";

const { Option } = Select;

const TableComponent = ({ Tabledata }) => {
  const [rowData, setRowData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [barchartData, setBarChartData] = useState(null);
  const [chartType, setChartType] = useState("");
  const [showDropdowns, setShowDropdowns] = useState(false);
  const [showLineDropdowns, setShowLineDropdowns] = useState(false);
  const [xAxisKey, setXAxisKey] = useState(null);
  const [yAxisKey, setYAxisKey] = useState(null);
  const [activeView, setActiveView] = useState("table");
  const [lineChartData, setLineChartData] = useState(null);
  const [selectedValueSub, setSelectedValueSub] = useState(null);
  const [stackedBy, setStackedBy] = useState(null);
  const [showDropdownsPie, setShowDropdownsPie] = useState(false);
  const [stackedByOptions, setStackedByOptions] = useState([]);
  const [lineSeriesType, setLineSeriesType] = useState(null);

  const [pieChartData, setPieChartData] = useState(null);
  let gridApi;
  const onGridReady = (params) => {
    gridApi = params.api;
  };
  useEffect(() => {
    if (Tabledata && Tabledata.length > 0) {
      const options = Object.keys(Tabledata[0]).filter(
        (key) => key !== xAxisKey && key !== yAxisKey
      );
      setStackedByOptions(options);
    }
  }, [Tabledata, xAxisKey, yAxisKey]);
  useEffect(() => {
    handleTableData(Tabledata);
  }, [Tabledata]);

  useEffect(() => {
    if (!stackedBy) {
      prepareBarChartDataWithoutStack();
    } else {
      prepareBarChartData();
    }
  }, [Tabledata, xAxisKey, yAxisKey, selectedValueSub, stackedBy]);

  useEffect(() => {
    prepareLineChartData();
  }, [xAxisKey, yAxisKey, selectedValueSub, Tabledata, lineSeriesType]);

  useEffect(() => {
    const canGeneratePieChart =
      xAxisKey && yAxisKey && selectedValueSub && showDropdownsPie;

    const generatePieChart = () => {
      // Assuming category (xAxisKey) selection creates your labels
      const labels = [...new Set(rowData.map((item) => item[xAxisKey]))];
      const data = labels.map((label) => {
        const filteredData = rowData.filter((item) => item[xAxisKey] === label);
        switch (selectedValueSub) {
          case "SUM":
            return filteredData.reduce(
              (acc, item) => acc + parseFloat(item[yAxisKey] || 0),
              0
            );
          case "AVERAGE":
            return (
              filteredData.reduce(
                (acc, item) => acc + parseFloat(item[yAxisKey] || 0),
                0
              ) / filteredData.length
            );
          case "COUNT":
            return filteredData.length;
          default:
            return filteredData.length;
        }
      });

      const backgroundColors = labels.map(() => getRandomColor());
      const borderColors = backgroundColors.map((color) =>
        shadeColor(color, -20)
      );

      setPieChartData({
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1,
          },
        ],
        options: {
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
            callbacks: {
              label: function (tooltipItem, chartData) {
                let dataset = chartData.datasets[tooltipItem.datasetIndex];
                let total = dataset.data.reduce(
                  (previousValue, currentValue) => previousValue + currentValue,
                  0
                );
                let currentValue = dataset.data[tooltipItem.index];
                let percentage = Math.floor((currentValue / total) * 100 + 0.5);
                return `${
                  chartData.labels[tooltipItem.index]
                }: ${currentValue} (${percentage}%)`;
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: "top",
          },
          animation: {
            animateScale: true,
            animateRotate: true,
            duration: 2000,
          },
        },
      });
    };

    // Utility function to generate random colors
    const getRandomColor = () => {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    const shadeColor = (color, percent) => {
      var R = parseInt(color.substring(1, 3), 16);
      var G = parseInt(color.substring(3, 5), 16);
      var B = parseInt(color.substring(5, 7), 16);

      R = parseInt((R * (100 + percent)) / 100);
      G = parseInt((G * (100 + percent)) / 100);
      B = parseInt((B * (100 + percent)) / 100);

      R = R < 255 ? R : 255;
      G = G < 255 ? G : 255;
      B = B < 255 ? B : 255;

      var RR =
        R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
      var GG =
        G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
      var BB =
        B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

      return "#" + RR + GG + BB;
    };

    // Only call generatePieChart if all conditions are met
    if (canGeneratePieChart) {
      generatePieChart();
    }
  }, [xAxisKey, yAxisKey, selectedValueSub, showDropdownsPie, rowData]);
  const handleValueSubChange = (value) => {
    setSelectedValueSub(value);
  };
  const handleTableData = (Tabledata) => {
    if (Tabledata && Tabledata.length > 0) {
      const apiColumns = Object.keys(Tabledata[0]).map((key) => ({
        headerName: key,
        field: key,
        sortable: true,
      }));

      const formattedData = Tabledata.map((item, index) => {
        return {
          ...item,
          id: index + 1,
        };
      });

      setColumns(apiColumns);
      setRowData(formattedData);
    } else {
      setColumns([]);
      setRowData([]);
    }
  };

  const defaultColDef = {
    sortable: true,
    editable: true,
    filter: true,
    floatingFilter: true,
    flex: 1,
  };

  const onExportClick = () => {
    if (gridApi) {
      gridApi.exportDataAsCsv();
    }
  };
  const handlePieChartButtonClick = () => {
    setActiveView("piechart");
    setShowDropdownsPie(true);
  };

  const handleBarChartButtonClick = (type) => {
    setChartType(type);
    setActiveView("barchart");
    setShowDropdowns(true);
  };

  const handleLineChartButtonClick = () => {
    setActiveView("linechart");
    setShowLineDropdowns(true);
  };

  const handleYAxisChange = (value) => {
    setYAxisKey(value);
  };
  const handleCategoryChange = (value) => {
    setXAxisKey(value);
    // Reset Y Axis Key when Category changes
    setYAxisKey("");
  };
  const prepareBarChartData = () => {
    if (
      !Tabledata ||
      Tabledata.length === 0 ||
      !xAxisKey ||
      !yAxisKey ||
      !stackedBy
    )
      return;

    const aggregatedData = {};

    Tabledata.forEach((item) => {
      const xAxisValue = item[xAxisKey];
      const yAxisValue = parseFloat(item[yAxisKey]) || 0;
      const stackValue = item[stackedBy];

      if (!aggregatedData[stackValue]) {
        aggregatedData[stackValue] = {};
      }
      if (!aggregatedData[stackValue][xAxisValue]) {
        aggregatedData[stackValue][xAxisValue] = { sum: 0, count: 0 };
      }
      aggregatedData[stackValue][xAxisValue].sum += yAxisValue;
      aggregatedData[stackValue][xAxisValue].count += 1;
    });

    const categories = Object.keys(aggregatedData)
      .reduce((acc, stackKey) => {
        const stackKeys = Object.keys(aggregatedData[stackKey]);
        stackKeys.forEach((key) => {
          if (acc.indexOf(key) === -1) {
            acc.push(key);
          }
        });
        return acc;
      }, [])
      .sort();

    const series = Object.entries(aggregatedData).map(
      ([stackKey, categoriesMap]) => {
        const data = categories.map((category) => {
          const categoryData = categoriesMap[category];
          if (!categoryData) return 0;
          switch (selectedValueSub) {
            case "SUM":
              return categoryData.sum;
            case "AVERAGE":
              return categoryData.count > 0
                ? categoryData.sum / categoryData.count
                : 0;
            case "COUNT":
            case "Count":
              return categoryData.count;
            default:
              return 0;
          }
        });

        return { name: stackKey, data };
      }
    );

    // Chart options, set as stacked
    const options = {
      chart: { type: "bar", stacked: true },
      xaxis: { categories },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value.toFixed(2);
          },
        },
      },
      plotOptions: { bar: { horizontal: false } },
    };

    setBarChartData({ series, options });
  };
  const prepareBarChartDataWithoutStack = () => {
    if (Tabledata && Tabledata.length > 0 && xAxisKey && yAxisKey) {
      if (selectedValueSub === "SUM") {
        const groupedData = Tabledata.reduce((acc, item) => {
          const xValue = item[xAxisKey];
          if (!acc[xValue]) {
            acc[xValue] = 0;
          }
          acc[xValue] += parseFloat(item[yAxisKey]); // Summing numerical values
          return acc;
        }, {});

        const seriesData = Object.keys(groupedData).map((key) => ({
          x: key,
          y: groupedData[key],
        }));
        const options = {
          chart: {
            type: "bar",
            stacked: false,
          },
          xaxis: {
            categories: seriesData.map((data) => data.x),
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return value.toFixed(2);
              },
            },
          },
        };
        const barchartData = {
          series: [
            {
              name: yAxisKey,
              data: seriesData.map((data) => data.y),
            },
          ],
          options,
        };
        setBarChartData(barchartData);
      } else if (selectedValueSub === "AVERAGE") {
        const groupedData = Tabledata.reduce((acc, item) => {
          const xValue = item[xAxisKey];
          const yValue = parseFloat(item[yAxisKey]);
          if (!acc[xValue]) {
            acc[xValue] = { sum: 0, count: 0 };
          }
          acc[xValue].sum += yValue;
          acc[xValue].count++;
          return acc;
        }, {});

        const seriesData = Object.keys(groupedData).map((key) => ({
          x: key,
          y: groupedData[key].sum / groupedData[key].count,
        }));

        const options = {
          chart: {
            type: "bar",
            stacked: false,
          },
          xaxis: {
            categories: seriesData.map((data) => data.x),
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return value.toFixed(2);
              },
            },
          },
        };

        const barchartData = {
          series: [
            {
              name: yAxisKey,
              data: seriesData.map((data) => data.y),
            },
          ],
          options,
        };

        setBarChartData(barchartData);
      } else if (selectedValueSub === "Count" || selectedValueSub === "COUNT") {
        const groupedData = Tabledata.reduce((acc, item) => {
          const xValue = item[xAxisKey];
          if (!acc[xValue]) {
            acc[xValue] = 0;
          }
          acc[xValue] += 1; // Increment count for each occurrence of x-axis value
          return acc;
        }, {});

        const seriesData = Object.keys(groupedData).map((key) => ({
          x: key,
          y: groupedData[key],
        }));

        const options = {
          chart: {
            type: "bar",
            stacked: false,
          },
          xaxis: {
            categories: seriesData.map((data) => data.x),
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return value.toFixed(2);
              },
            },
          },
        };

        const barchartData = {
          series: [
            {
              name: yAxisKey,
              data: seriesData.map((data) => data.y),
            },
          ],
          options,
        };

        setBarChartData(barchartData);
      }
    }
  };

  const prepareLineChartData = () => {
    if (Tabledata && Tabledata.length > 0 && xAxisKey && yAxisKey) {
      let groupedData = {};
      let maxYValue = Math.max(
        ...Tabledata.map((item) => parseFloat(item[yAxisKey]))
      );
      let maxXPoint = Tabledata.find(
        (item) => parseFloat(item[yAxisKey]) === maxYValue
      );

      // Aggregate the data based on selectedValueSub (SUM, AVERAGE, COUNT)
      Tabledata.forEach((item) => {
        const xValue = item[xAxisKey];
        const yValue = parseFloat(item[yAxisKey]) || 0;

        groupedData[xValue] = groupedData[xValue] || { sum: 0, count: 0 };
        groupedData[xValue].sum += yValue;
        groupedData[xValue].count += 1;
      });

      // Prepare seriesData based on selectedValueSub
      let seriesData = Object.keys(groupedData).map((key) => {
        let y;
        switch (selectedValueSub) {
          case "SUM":
            y = groupedData[key].sum;
            break;
          case "AVERAGE":
            y = groupedData[key].sum / groupedData[key].count;
            break;
          case "COUNT":
            y = groupedData[key].count;
            break;
          case "Count":
            y = groupedData[key].count;
            break;
          default:
            y = 0; // Default case
        }
        return { x: key, y };
      });
      const chartOptions = {
        chart: {
          type: lineSeriesType,
          zoom: {
            enabled: true,
            type: "xy",
          },
          toolbar: {
            show: true,
            autoSelected: "zoom",
          },
        },
        responsive: [
          {
            // At 480px or less, apply these settings
            breakpoint: 480,
            options: {
              chart: {
                height: 300,
              },
              legend: {
                position: "bottom",
              },
              xaxis: {
                labels: {
                  style: {
                    fontSize: "10px",
                  },
                },
              },
              yaxis: {
                labels: {
                  style: {
                    fontSize: "10px",
                  },
                },
              },
            },
          },
        ],
        xaxis: {
          categories: seriesData.map((data) => data.x),
          labels: {
            style: {
              colors: "#333",
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
            },
          },
          title: {
            text: xAxisKey,
            style: {
              color: "#666",
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
            },
          },
        },
        yaxis: {
          labels: {
            formatter: (value) => value.toFixed(2),
            style: {
              colors: "#333",
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
            },
          },
          title: {
            text: yAxisKey,
            style: {
              color: "#666",
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
            },
          },
        },
        tooltip: {
          x: {
            show: true,

            formatter: (value) => `Category: ${value}`,
          },
          y: {
            formatter: (value) => `${selectedValueSub}: ${value.toFixed(2)}`,
          },
          style: {
            fontSize: "14px",
            fontFamily: "Helvetica, Arial, sans-serif",
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toFixed(2);
          },
          style: {
            colors: ["#304758"],
          },
        },
        theme: {
          mode: "light",
          palette: "palette3",
          monochrome: {
            enabled: false,
            color: "#255aee",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },
        markers: {
          size: 5,
          colors: ["#FFFFFF"],
          strokeColors: "#FF4560",
          strokeWidth: 2,
        },
      };

      if (maxXPoint) {
        // Only add annotations if maxXPoint is valid
        chartOptions.annotations = {
          yaxis: [
            {
              y: maxYValue,
              borderColor: "#ff4560",
              label: {
                borderColor: "#ff4560",
                style: {
                  color: "#fff",
                  background: "#ff4560",
                },
                text: "Max Value",
              },
            },
          ],
          xaxis: [
            {
              x: maxXPoint[xAxisKey],
              borderColor: "#00E396",
              strokeDashArray: 0,
              label: {
                borderColor: "#00E396",
                style: {
                  color: "#fff",
                  background: "#00E396",
                },
                text: "Max Value Point",
              },
            },
          ],
        };
      }

      // Update state with prepared chartData
      setLineChartData({
        series: [{ name: yAxisKey, data: seriesData.map((data) => data.y) }],
        options: chartOptions,
      });
    }
  };
  const handleTableButtonClick = () => {
    setActiveView("table");
    setShowDropdowns(false);
  };
  return (
    <div
      className="table-con"
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div style={{ backgroundColor: "#F6F7FB", borderRadius: "15px", width: "min-content" }}>
        <Row>
          <TableOutlined onClick={handleTableButtonClick} className="Table" />
          <BarChartOutlined
            className="bar"
            onClick={() => handleBarChartButtonClick("bar")}
          />
          <PieChartOutlined
            className="pie"
            onClick={handlePieChartButtonClick}
          />
          <LineChartOutlined
            className="line"
            onClick={handleLineChartButtonClick}
          />
          <Button
            icon={<ExportOutlined />}
            onClick={() => onExportClick()}
            style={{
              fontSize: "15px",
              marginLeft: "592px",
              background: "#F6F7FB",
              color: "black",
              border: "none",
              marginBottom: "5px",
            }}
          >
            Export
          </Button>
        </Row>
      </div>

      <Row>
        {activeView === "barchart" &&
          showDropdowns &&
          Tabledata &&
          Tabledata.length > 0 &&
          Object.keys(Tabledata[0] || {}).length > 1 && (
            <div style={{ marginBottom: "20px", marginTop: "15px" }}>
              <Row>
                <Select
                  style={{ width: 150, marginRight: 10 }}
                  placeholder="Category"
                  onChange={handleCategoryChange}
                  value={xAxisKey}
                >
                  {Object.keys(Tabledata[0] || {})
                    .filter((key) => isNaN(Tabledata[0][key]))
                    .map((key) => (
                      <Option key={key} value={key}>
                        {key}
                      </Option>
                    ))}
                </Select>
                <Select
                  style={{ width: 150, marginRight: 10 }}
                  placeholder="Values"
                  onChange={handleYAxisChange}
                  value={yAxisKey}
                >
                  {Object.keys(Tabledata[0] || {}).map(
                    (key) =>
                      key !== xAxisKey && (
                        <Option key={key} value={key}>
                          {key}
                        </Option>
                      )
                  )}
                </Select>
                {yAxisKey && !isNaN(Tabledata[0][yAxisKey]) && (
                  <Select
                    style={{ width: 150 }}
                    placeholder="Value Sub"
                    onChange={handleValueSubChange}
                    value={selectedValueSub}
                  >
                    <Option value="SUM">SUM</Option>
                    <Option value="AVERAGE">AVERAGE</Option>
                    <Option value="Count">COUNT</Option>
                  </Select>
                )}
                {yAxisKey && isNaN(Tabledata[0][yAxisKey]) && (
                  <Select
                    style={{ width: 150 }}
                    placeholder="Value Sub"
                    onChange={handleValueSubChange}
                    value={selectedValueSub}
                  >
                    <Option value="COUNT">Count</Option>
                  </Select>
                )}
                {yAxisKey && stackedByOptions && stackedByOptions.length > 0 && (
                  <Select
                    style={{ width: 150, marginRight: 10, marginLeft: 10 }}
                    placeholder="Stacked by"
                    onChange={setStackedBy}
                    value={stackedBy}
                  >
                    {stackedByOptions.map((key) => (
                      <Option key={key} value={key}>
                        {key}
                      </Option>
                    ))}
                  </Select>
                )}
              </Row>
            </div>
          )}
      </Row>
      <Row>
        {activeView === "piechart" &&
          showDropdownsPie &&
          Tabledata &&
          Tabledata.length > 0 && (
            <div style={{ marginBottom: "20px", marginTop: "15px" }}>
              <Row>
                <Select
                  style={{ width: 150, marginRight: 10 }}
                  placeholder="Category"
                  onChange={handleCategoryChange}
                  value={xAxisKey}
                >
                  {Object.keys(Tabledata[0] || {})
                    .filter((key) => isNaN(Tabledata[0][key]))
                    .map((key) => (
                      <Option key={key} value={key}>
                        {key}
                      </Option>
                    ))}
                </Select>
                <Select
                  style={{ width: 150, marginRight: 10 }}
                  placeholder="Values"
                  onChange={handleYAxisChange}
                  value={yAxisKey}
                >
                  {Object.keys(Tabledata[0] || {}).map(
                    (key) =>
                      key != xAxisKey && (
                        <Option key={key} value={key}>
                          {key}
                        </Option>
                      )
                  )}
                </Select>
                {yAxisKey && !isNaN(Tabledata[0][yAxisKey]) && (
                  <Select
                    style={{ width: 150 }}
                    placeholder="Value Sub"
                    onChange={handleValueSubChange}
                    value={selectedValueSub}
                  >
                    <Option value="SUM">SUM</Option>
                    <Option value="AVERAGE">AVERAGE</Option>
                    <Option value="COUNT">COUNT</Option>
                  </Select>
                )}
                {yAxisKey && isNaN(Tabledata[0][yAxisKey]) && (
                  <Select
                    style={{ width: 150 }}
                    placeholder="Value Sub"
                    onChange={handleValueSubChange}
                    value={selectedValueSub}
                  >
                    <Option value="COUNT">Count</Option>
                  </Select>
                )}
              </Row>
            </div>
          )}
      </Row>
      <Row>
        {activeView == "linechart" &&
          showLineDropdowns &&
          Tabledata &&
          Tabledata.length > 0 && (
            <div style={{ marginBottom: "20px", marginTop: "15px" }}>
              <Row>
                <Select
                  style={{ width: 150, marginRight: 10 }}
                  placeholder="Category"
                  onChange={handleCategoryChange}
                  value={xAxisKey}
                >
                  {Object.keys(Tabledata[0] || {})
                    .filter((key) => isNaN(Tabledata[0][key]))
                    .map((key) => (
                      <Option key={key} value={key}>
                        {key}
                      </Option>
                    ))}
                </Select>
                <Select
                  style={{ width: 150, marginRight: 10 }}
                  placeholder="Values"
                  onChange={handleYAxisChange}
                  value={yAxisKey}
                >
                  {Object.keys(Tabledata[0] || {}).map(
                    (key) =>
                      key != xAxisKey && (
                        <Option key={key} value={key}>
                          {key}
                        </Option>
                      )
                  )}
                </Select>
                {yAxisKey && !isNaN(Tabledata[0][yAxisKey]) && (
                  <Select
                    style={{ width: 150 }}
                    placeholder="Value Sub"
                    onChange={handleValueSubChange}
                    value={selectedValueSub}
                  >
                    <Option value="SUM">SUM</Option>
                    <Option value="AVERAGE">AVERAGE</Option>
                    <Option value="COUNT">COUNT</Option>
                  </Select>
                )}
                {yAxisKey && isNaN(Tabledata[0][yAxisKey]) && (
                  <Select
                    style={{ width: 150 }}
                    placeholder="Value Sub"
                    onChange={handleValueSubChange}
                    value={selectedValueSub}
                  >
                    <Option value="COUNT">Count</Option>
                  </Select>
                )}
                <Select
                  style={{ width: 150, marginLeft: 10 }}
                  placeholder="Series Type"
                  onChange={(value) => setLineSeriesType(value)}
                  value={lineSeriesType}
                >
                  <Option value="line">Line</Option>
                  <Option value="area">Area</Option>
                </Select>
              </Row>
            </div>
          )}
      </Row>
      {activeView === "table" && (
        <div
          className="ag-theme-alpine"
          style={{
            height: "600px",
            width: "100%",
          }}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={columns}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
          />
        </div>
      )}

      {activeView === "barchart" && chartType && barchartData && (
        <div
          className="chart-container"
          style={{ width: "100%", height: "100%" }}
        >
          <ReactApexChart
            options={barchartData.options}
            series={barchartData.series}
            type={chartType}
            height={500}
          />
        </div>
      )}
      {activeView === "piechart" && pieChartData && (
        <div
          className="chart-container"
          style={{ width: "500px", height: "400px" }}
        >
          <Doughnut data={pieChartData} />
        </div>
      )}
      {activeView === "linechart" && lineChartData && lineChartData.options && (
        <div
          className="chart-container"
          style={{ width: "100%", height: "100%" }}
        >
          <ReactApexChart
            options={lineChartData.options}
            series={lineChartData.series}
            type={lineSeriesType}
            height={500}
          />
        </div>
      )}
    </div>
  );
};

export default TableComponent;
