import { Row, Col, Button, Typography, Progress } from "antd";
import AddInvoice from "./AddInvoice";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { useState, useEffect, useRef } from "react";
import {
  FileExclamationOutlined,
  EditOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import moment from "moment";
import EditInvoice from "./EditInvoice";
import MarkAsSend from "./MarkAsSend";
import RenderMarkAsSent from "./RenderMarkAsSent";
import AddModal from "./Modal";
import { checkBase, checkNull, convertNumber } from "../../../utils/util";
import { useDispatch, useSelector } from "react-redux";
import { getAllInvoice } from "../../../stores/actions/getAllInvoiceAction";
import Loader from "../../../components/Loader/Loader";
import { invoiced } from "../../../utils/enums";
require("./PurchaseOrderTable.scss");

const Invoice = (props: {
  newMonthId?: any;
  POid?: any;
  poDetails?: any;
  mastercurrencies?: any;
  currencyName?: any;
  currencySymbol?: any;
  archiveStatus?: any;
  activeKey?: string;
}) => {
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const token = getToken();
  const dateFormat = "YYYY/MM/DD";
  const { Text } = Typography;
  const [invoice, setInvoice] = useState([]);
  const [loading, isLoading] = useState(true);
  // const [sentInvoiceData, setSentInvoiceData] = useState([]);
  // const [invoiceCheck, setInvoiceCheck] = useState([]);

  const [invoiceVisibility, setInvoiceVisibility] = useState([]);
  const [value, setData] = useState<any>([]);
  const currentInvoiceAmt: any = useRef(0);
  const [markAsSendVsibleHandler, setmarkAsSendVisibleHandler] =
    useState(false);
  const [show, setShow] = useState(false);
  const [rowId, setRowId] = useState();
  const selectedInvoice = useRef({});
  const [lastIn, setLastIn] = useState(false);
  const [plannedInvoice, setPlannedInvoice] = useState("0");
  const [sentInvoice, setSentInvoice] = useState("0");
  const [netSentAmount, setNetSentAmount] = useState("0");
  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );
  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(getAllInvoice(props.POid));
  }, [dispatch]);

  const getData = () => {
    AxiosConfig.get(`invoice/${props.POid}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        let Data: any = Array(res.data.data);
        const tempArr: any = Array(res.data.data.invoiceList.length).fill(true);
        setData(Data);
        setInvoice(res.data.data.invoiceList);
        const count = res.data.data.invoiceList.filter(
          (obj: any) => obj.status == true
        ).length;
        if (count == 1) {
          setLastIn(true);
        }
        currentInvoiceAmt.current = res.data.data.totalInvoiceAmt;

        setInvoiceVisibility(tempArr ?? []);

        const plannedInvoiceValue =
          currencySymb?.currency_code !== "INR"
            ? convertNumber(res.data.data.plannedInvoice, 2)
            : checkBase(
                currencySymb?.currency_code,
                res.data.data.plannedInvoice
              );
        setPlannedInvoice(plannedInvoiceValue ?? 0);

        const sentInvoiceValue =
          currencySymb?.currency_code !== "INR"
            ? convertNumber(res?.data?.data?.sentInvoice, 2)
            : checkBase(
                currencySymb?.currency_code,
                res?.data?.data?.sentInvoice
              );
        setSentInvoice(sentInvoiceValue ?? 0);

        const netSentAmountValue =
          currencySymb?.currency_code !== "INR"
            ? convertNumber(res?.data?.data?.totalAdjustedAmt, 2)
            : checkBase(
                currencySymb?.currency_code,
                res?.data?.data?.totalAdjustedAmt
              );
        setNetSentAmount(netSentAmountValue ?? 0);
      })
      .finally(() => isLoading(false));
  };
  useEffect(() => {
    if (props.activeKey == "3") getData();
  }, [props.activeKey]);

  const visibleHandler = () => {
    setVisible(!visible);
  };
  const MSVsibleHandler = (invoice: any) => {
    setmarkAsSendVisibleHandler(!markAsSendVsibleHandler);
    selectedInvoice.current = invoice;
  };
  const editInvoiceHandler = (index: any) => {
    setVisible1(!visible1);
    let tempArr: any = invoiceVisibility;
    tempArr[index] = true;
    setInvoiceVisibility(tempArr);
  };
  const handleDelete = (id: any) => {
    setRowId(id);
    setShow(true);
  };
  let perc = 0;
  value.forEach(
    (res: { plannedInvoice: any; sentInvoice: any; saleValue: any }) => {
      perc =
        perc +
        checkNull((res.sentInvoice + res.plannedInvoice) / res.saleValue) * 100;
    }
  );
  let unaccounted = 0;
  value.forEach(
    (res: { plannedInvoice: any; sentInvoice: any; saleValue: any }) => {
      unaccounted =
        currencySymb?.currency_code != "INR"
          ? convertNumber(res?.saleValue, 2)
          : checkBase(currencySymb?.currency_code, res?.saleValue);
    }
  );
  return (
    <>
      <Row>
        <Col span={6}>
          <Row>
            <Col>
              <strong>
                <p className="totalClaimed">
                  {invoiced.textForTotalInvoiceable} {perc.toFixed(2)}%
                </p>
                <p>
                  {props.currencySymbol[props.poDetails.currency_id]}{" "}
                  {unaccounted}
                </p>
              </strong>
            </Col>
          </Row>
          <Row>
            <Col span={18}>
              <Progress
                className="RevenueProgress"
                percent={perc}
                showInfo={false}
              />
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Col>
              <label>{invoiced.labelForInvoiceSent}</label>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong className="sentInvoice">
              {props.currencySymbol[props.poDetails.currency_id]}{" "}
                {sentInvoice}
              </strong>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Col>
              <label>{invoiced.labelForPlannedInvoice}</label>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>
              {props.currencySymbol[props.poDetails.currency_id]}{" "}
                {plannedInvoice}
              </strong>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Col>
              <label>{invoiced.labelForNetSentAmount}</label>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>
                {props.currencySymbol[props.poDetails.currency_id]}{" "}
                {netSentAmount}
              </strong>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {" "}
        <Col
          span={24}
          className="AddIVButton"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {!visible && !visible1 && !props.archiveStatus && (
            <Button
              type="primary"
              onClick={visibleHandler}
              className="primary-btn add-po"
              name="AddInvoice"
            >
              {invoiced.buttonForAddInvoice}
            </Button>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          {visible && (
            <AddInvoice
              cancelHandler={visibleHandler}
              newMonthId={props.newMonthId}
              Poid={props.POid}
              mastercurrencies={props.mastercurrencies}
              poDetails={props.poDetails}
              currentInvoiceAmt={currentInvoiceAmt.current}
              currencyName={props.currencyName}
              currencySymbol={props.currencySymbol}
              getData={getData}
            />
          )}
        </Col>
      </Row>
      <div>
        {loading ? (
          <Loader />
        ) : !invoice.length ? (
          <div className="no-invoice-div">
            <FileExclamationOutlined
              style={{ fontSize: "100px", color: "#00000073" }}
            />
            <Text type="secondary" style={{ fontSize: "1.2rem" }}>
              No Invoices
            </Text>
          </div>
        ) : (
          <div className="invoice-list-div">
            {invoice.map((v: any, index: any) => {
              return (
                <>
                  {invoiceVisibility[index] && (
                    <>
                      <div key={index} className="pb-20">
                        <Row
                          gutter={16}
                          style={{ marginInlineStart: "0.2rem" }}
                        >
                          <Col md={12} xs={12}>
                            <Text style={{ color: "#5C67E5" }}>{v.title}</Text>
                          </Col>
                          {!visible && !visible1 && v.status && (
                            <Col
                              md={12}
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <CheckCircleOutlined
                                className="icon-class"
                                title="Mark as sent"
                                onClick={() => MSVsibleHandler(v)}
                              />

                              <EditOutlined
                                className="icon-class"
                                title="Edit invoice"
                                onClick={() => {
                                  setVisible1(!visible1);
                                  let tempArr: any = invoiceVisibility;
                                  tempArr[index] = false;
                                  setInvoiceVisibility(tempArr);
                                }}
                              />

                              <DeleteOutlined
                                className="delete-icon-class"
                                title="Delete invoice"
                                onClick={() => handleDelete(v.id)}
                              />
                            </Col>
                          )}
                          {!v.status && (
                            <Col
                              md={12}
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <CheckCircleFilled
                                className="filled-icon-class"
                                title="Mark as sent"
                              />
                            </Col>
                          )}
                        </Row>
                        {markAsSendVsibleHandler && (
                          <Row>
                            <Col>
                              <MarkAsSend
                                visible={markAsSendVsibleHandler}
                                onClose={MSVsibleHandler}
                                onCancelButton={MSVsibleHandler}
                                mastercurrencies={props.mastercurrencies}
                                Poid={props.POid}
                                invoice={selectedInvoice.current}
                                currencyName={props.currencyName}
                                currencySymbol={props.currencySymbol}
                                isLast={lastIn}
                                poDetails={props.poDetails}
                                getData={getData}
                              />
                            </Col>
                          </Row>
                        )}
                        <Row
                          gutter={16}
                          style={{ marginInlineStart: "0.2rem" }}
                        >
                          <Col md={8} xs={24} style={{ display: "grid" }}>
                            <label className="mt-0">
                              {invoiced.labelForInvoiceAmt}
                            </label>
                            <Text type="secondary">
                              {
                                props.currencySymbol[
                                  props.poDetails.currency_id
                                ]
                              }
                              {props.currencySymbol[
                                props.poDetails.currency_id
                              ] === "₹"
                                ? Intl.NumberFormat("en-IN").format(
                                    v.invoice_amt
                                  )
                                : Intl.NumberFormat("en-US").format(
                                    v.invoice_amt
                                  )}
                            </Text>
                            <Text type="secondary">
                              {invoiced.textForEquivalentValue}{" "}
                              {currencySymb?.currency_symbol}
                              {Intl.NumberFormat("en-IN").format(
                                v.invoice_amt_base.toFixed(2)
                              )}
                            </Text>
                          </Col>
                          <Col md={8} xs={24} style={{ display: "grid" }}>
                            <label className="mt-0">
                              {invoiced.labelForExpectedDate}
                            </label>
                            <Text type="secondary">
                              {moment(v.expected_date).format(dateFormat)}
                            </Text>
                          </Col>

                          <Col md={8} xs={24} style={{ display: "grid" }}>
                            <label className="mt-0">
                              {invoiced.labelForDesc}
                            </label>
                            <Text type="secondary">{v.description}</Text>
                          </Col>
                        </Row>
                        {!v.status && (
                          <RenderMarkAsSent
                            invoiceId={v.id}
                            currencyName={props.currencyName}
                            currencySymbol={props.currencySymbol}
                            poDetails={props.poDetails}
                            Poid={props.POid}
                            finalInvoice={v.FinalInvoice}
                          />
                        )}
                      </div>
                      <hr />
                    </>
                  )}
                  {!invoiceVisibility[index] && (
                    <EditInvoice
                      invoiceData={v}
                      Poid={props.POid}
                      cancelHandler={editInvoiceHandler}
                      newMonthId={props.newMonthId}
                      invoiceInstanceIndex={index}
                      mastercurrencies={props.mastercurrencies}
                      poDetails={props.poDetails}
                      currentInvoiceAmt={currentInvoiceAmt.current}
                      currencyName={props.currencyName}
                      getData={getData}
                    />
                  )}
                </>
              );
            })}
          </div>
        )}
      </div>
      <AddModal
        onClose={() => setShow(false)}
        show={show}
        rowData={rowId}
        getData={getData}
      ></AddModal>
    </>
  );
};
export default Invoice;
