import InputField from "../../components/FormInput/InputField";
import PasswordField from "../../components/FormInput/PasswordField";
import ButtonReuse from "../../components/Buttons/Button";
import logo from "../../assets/images/logo.svg";
import { Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { PoweredBy } from "../../viewgroups/index";
// import { Checkbox } from "antd";
import { Row, Col } from "antd";
import Layout from "antd/lib/layout/layout";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Formik } from "formik";
import * as yup from "yup";
// import { setUserSession } from "../../auth";
import { AxiosConfig } from "../../ApiConfig";
import {
  saveFirstName,
  saveIsLoggedIn,
  saveToken,
  saveUser,
} from "../../utils/localStore";
require("./Login.scss");
require("../../components/Button/Button.scss");

const Login = () => {
  const validationSchema = yup.object({
    email: yup.string().email().required(),
    password: yup.string().min(8).required(),
  });

  const [errorMsg, setErrorMsg] = useState("");
  let history = useHistory();

  useEffect(() => {
    localStorage.clear();
  }, []);
  return (
    <Fragment>
      <div className="login-wrapper">
        <Layout style={{ height: "100vh" }}>
          <Row
            justify="space-around"
            align="middle"
            style={{ height: "100vh", marginRight: "0px" }}
          >
            <Col xs={24} md={12}>
              <div className="login-container">
                <div className="logo">
                  <img src={logo} alt="logo" />
                </div>
                <h5>Login</h5>
                <Formik
                  initialValues={{ email: "", password: "" }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    AxiosConfig.post("users/login", values)
                      .then((response) => {
                        if (response.status == 200) {
                          saveUser(response.data.data.user);
                          saveFirstName(response.data.data.user);
                          saveToken(response.data.data.user);
                          localStorage.setItem(
                            "userId",
                            response.data.data.user.id
                          );
                          saveIsLoggedIn();
                          if (response.data.data.user.projects.length > 0) {
                            if (!localStorage.getItem("projects")) {
                              localStorage.setItem(
                                "projects",
                                JSON.stringify(
                                  response.data.data.user.projects
                                )
                              );
                            }
                          }
                          
                          if (response.data.data.user.departments.length > 0) {
                            if (!localStorage.getItem("associatedBus")) {
                              localStorage.setItem(
                                "associatedBus",
                                JSON.stringify(
                                  response.data.data.user.departments.filter(
                                    function (associatedBu: { flag: any }) {
                                      return associatedBu.flag === true;
                                    }
                                  )
                                )
                              );
                            }
                            if (!localStorage.getItem("fixedAssociatedBu")) {
                              localStorage.setItem(
                                "fixedAssociatedBu",
                                JSON.stringify(
                                  response.data.data.user.departments.filter(
                                    function (associatedBu: { flag: any }) {
                                      return associatedBu.flag === true;
                                    }
                                  )
                                )
                              );
                            }
                            sessionStorage.setItem("isDepartment", "1");
                            sessionStorage.setItem("Heading", "/buDashboard");
                            localStorage.setItem("key", "1");
                            history.push("/buDashboard");
                          } else {
                            history.push("/projects");
                            localStorage.setItem("key", "1");
                            sessionStorage.setItem(
                              "Heading",
                              "/projects"
                            );
                          }
                          window.location.reload();
                        }
                      })
                      .catch(function (error) {
                        console.log(error?.response?.data?.status?.message);
                        setErrorMsg(error?.response?.data?.status?.message);
                      });
                  }}
                >
                  {({ handleSubmit, handleChange, values, errors }) => (
                    <form onSubmit={handleSubmit}>
                      <InputField
                        type="text"
                        onChange={handleChange}
                        value={values.email}
                        name="email"
                        placeholder="Enter your email address"
                        className="email--input"
                      />
                      <p className="display_error">{errors.email}</p>

                      <PasswordField
                        onChange={handleChange}
                        value={values.password}
                        name="password"
                        placeholder="Enter your password"
                        className="password--input"
                        iconRender={(visible: any) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                      <p className="display_error">{errors.password}</p>
                      <p className="display_error">{errorMsg}</p>
                      <div className="text-left">
                        <ButtonReuse
                          type="primary"
                          className="primary-btn mt20"
                          htmlType="submit"
                          value="Login"
                        ></ButtonReuse>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
          <PoweredBy></PoweredBy>
        </Layout>
      </div>
    </Fragment>
  );
};
export default Login;
