import { useRef, useState, useEffect } from "react";
import "antd/dist/antd.css";
import {
  notification,
  Button,
  Table,
  Select,
  Progress,
  InputNumber,
  Row,
  Col,
} from "antd";
import { AxiosConfig } from "../../../ApiConfig";
import ButtonReuse from "../../../components/Buttons/Button";
import { EditCreditNote } from "../../../utils/enums";
import { getToken } from "../../../auth";
import uniqueId from "lodash/uniqueId";
import { ColumnsType } from "antd/es/table";
import {
  EditTwoTone,
  PlusSquareOutlined,
  MinusSquareOutlined,
} from "@ant-design/icons";
import { calculateAdjustedAmount } from "../../../utils/util";

require("./AddCustomer.scss");

interface IInvoiceType {
  id: number;
  final_amt: number;
  invoice_number: string;
  Invoice: {
    title: string;
    description: string;
  };
}

interface IPoType {
  id: number;
  name: string;
  number: string;
  potype: {
    type: string;
  };
  FinalInvoices: IInvoiceType[];
}
export interface CreditNotePO {
  key: string;
  id: number;
  name: string;
  project_code: string;
  PurchaseOrders: IPoType[];
}

const EditCreditNoteApplication = (props) => {
  const {
    creditNoteDetails,
    onCancelHandler,
    creditNotePos,
    setCreditNotePos,
    editable,
    setEditable,
    creditNoteDetail,
  } = props;

  const token = getToken();

  const [totalAdjustedAmount, setTotalAdjustedAmount] = useState<string>();
  const [adjustedAmount, setAdjustedAmount] = useState<number>();
  const [pendingAmount, setPendingAmount] = useState<string>();
  const [selectedPOs, setSelectedPOs] = useState<string[]>([]);
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [allPOs, setAllPOs] = useState<CreditNotePO[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const currentEditable = useRef(-1);

  const creditNotePosTableColumns: ColumnsType<CreditNotePO> = [
    {
      title: "Project Code",
      dataIndex: "project_code",
      key: "projectCode",
      render: (_, record, index) => {
        const selectedProject = allPOs.find(
          (project) => project.id == creditNotePos[index]?.project_id
        );

        const generateLabel = (project) => {
          return project ? `${project.project_code} (${project.name})` : "Select Project Code";
        };
        return (
          <Select
            className="select-dropdown"
            placeholder="Select Project Code"
            disabled={!editable[index]}
            value={generateLabel(selectedProject)}
            onChange={(value) => {
              handleCreditPoValChange("project_id", value, index);
            }}
          >
            {allPOs.map((po) => (
           <Select.Option key={po.id} value={po.id}>
           {`${po.project_code} (${po.name})`}
         </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "Purchase Order",
      dataIndex: ["po_id"],
      key: "purchaseOrder",
      render: (_, record, index) => {
        const selectedPo = allPOs.find(
          (projects) => projects.id === creditNotePos[index]?.project_id
        );
        const label = selectedPo
          ? selectedPo.PurchaseOrders.find(
              (po) => po.id === creditNotePos[index]?.po_id
            )?.name
          : "Select PO";

        return (
          <Select
            className="select-dropdown"
            placeholder="Select PO"
            disabled={!editable[index]}
            value={label}
            onChange={(value) => {
              handleCreditPoValChange("po_id", value, index);
            }}
            options={
              selectedPo
                ? selectedPo.PurchaseOrders.map((po) => ({
                    value: po.id,
                    label: po.name,
                  }))
                : []
            }
          />
        );
      },
    },

  {
  title: "Invoice",
  dataIndex: "invoice_id",
  key: "invoice",
  width: 400,
  render: (_, record, index) => {
    const selectedPo = allPOs.find(
      (po) => po.id === creditNotePos[index]?.project_id
    );
    const selectedPoPurchaseOrder = selectedPo
      ? selectedPo.PurchaseOrders.find(
          (po) => po.id === creditNotePos[index]?.po_id
        )
      : null;

    const selectedInvoice = selectedPoPurchaseOrder
      ? selectedPoPurchaseOrder.FinalInvoices.find(
          (invoice) => invoice.id === creditNotePos[index]?.invoice_id
        )
      : null;

    const label = selectedInvoice
      ? selectedInvoice.invoice_number
      : "Select Invoice";

    const invoiceOptions = selectedPoPurchaseOrder
      ? selectedPoPurchaseOrder.FinalInvoices.filter(
          (invoice) => invoice.id !== creditNotePos[index]?.invoice_id
        ).map((invoice) => ({
          value: invoice.id,
          label: invoice.invoice_number,
        }))
      : [];

    const isExpanded = expandedRows.includes(index.toString());

    return (
      <div className="purchase-order-row">
        <div
          className="expand-button"
          onClick={() => {
            if (isExpanded) {
              setExpandedRows((prev) =>
                prev.filter((rowIndex) => rowIndex !== index.toString())
              );
            } else {
              setExpandedRows((prev) => [...prev, index.toString()]);
            }
          }}
        >
          {isExpanded ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
        </div>
        <Select
          className="select-dropdown"
          placeholder="Select Invoice"
          disabled={!editable[index]}
          value={label}
          onChange={(value) => {
            const isIdPresent = validateInvoiceInput(value);
            if(!isIdPresent) {
              handleCreditPoValChange("invoice_id", value, index);
            } else {
              notification.error({
                message: "Error",
                description:
                  "This Invoice is already selected.",
                style: { color: "red" },
              });
            }
          }}
          options={invoiceOptions}
        />
        {isExpanded && selectedInvoice && (
          <div className="expanded-details">
            <div>
              <Row className="details-row">
                <Col>
                  <p>{EditCreditNote.labelForProjectName}</p>
                  <p className="light-font-poexpansion">
                    {selectedPo?.name}
                  </p>
                </Col>
                <Col>
                  <p>{EditCreditNote.labelForInvoiceAmount}</p>
                  <p className="light-font-poexpansion">
                    {creditNoteDetails.currency_symbol}
                    {selectedInvoice.final_amt}
                  </p>
                </Col>
                <Col>
                  <p>{EditCreditNote.labelForInvoiceDescription}</p>
                  <div className="light-font-poexpansion">
                    <div key={selectedInvoice.id}>
                      <p>{selectedInvoice.Invoice.description}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    );
  },
},
    {
      title: "Enter Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, record, index) =>
        !editable[index] ? (
          <span>
            {creditNotePos[index]?.amount !== undefined
              ? `${creditNoteDetails.currency_symbol} ${creditNotePos[index].amount}`
              : ""}
          </span>
        ) : (
          <InputNumber
            value={creditNotePos[index]?.amount}
            min={0}
            onChange={(value) => {
              handleCreditPoValChange("amount", value, index);
            }}
            precision={2}
            step={0.2}
          />
        ),
    },
    
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      render: (_, record, index) => {
        return !editable[index] ? (
          <EditTwoTone
            onClick={() => {
              setEditable((prevEditable) => [
                ...prevEditable.slice(0, index),
                true,
                ...prevEditable.slice(index + 1),
              ]);
              currentEditable.current = index;
            }}
          />
        ) : (
          <Button
            onClick={() => {
              handleDoneButton(record, index);
            }}
          >
            Done
          </Button>
        );
      },
    },
  ];

  const handleDoneButton = async (record, index) => {
    const po_id = creditNotePos[index]?.po_id;
    const amount = creditNotePos[index]?.amount;
    const project_id = creditNotePos[index]?.project_id;
    const invoice_id = creditNotePos[index]?.invoice_id;
  
    if (!po_id || !project_id || !invoice_id || amount === null || amount === "") {
      notification.error({
        message: "Error",
        description: "Please select a project code, PO, select an invoice, and provide the amount.",
        style: { color: "red" },
      });
      return;
    }
  
    const selectedPo = allPOs.find(
      (projects) => projects.id === creditNotePos[index]?.project_id
    );
  
    const selectedInvoice = selectedPo?.PurchaseOrders
      .find((po) => po.id === creditNotePos[index]?.po_id)
      ?.FinalInvoices.find(
        (invoice) => invoice.id === creditNotePos[index]?.invoice_id
      );
  
    const maxAdjustableAmount = selectedInvoice?.final_amt ?? 0;
  
    if (amount <= maxAdjustableAmount) {
      setEditable((prevEditable) => [
        ...prevEditable.slice(0, index),
        false,
        ...prevEditable.slice(index + 1),
      ]);
      currentEditable.current = -1;
    } else {
      notification.error({
        message: "Error",
        description:
          "Adjustment amount for the credit note is greater than the final Invoice amount",
        style: { color: "red" },
      });
    }
  };
  
  const validateInvoiceInput = (invoiceId) => {
    const isInvoicePresent = creditNotePos.some((pos)=> pos.invoice_id === invoiceId);
    return isInvoicePresent;
  }

  const openNotificationForPo = () => {
    notification.open({
      message: "Credit note adjusted for the Invoice successfully",
    });
  };

  const openNotificationForRequiredField = () => {
    notification.open({
      message: "Please fill in all the required fields before proceeding.",
    });
  };

  const handleCreditPoValChange = (
    fieldName: string,
    value: number | string,
    index: number
  ) => {
    const selectedPOId = value as string;

    setCreditNotePos((prevCreditNote) => [
      ...prevCreditNote.slice(0, index),
      { ...prevCreditNote[index], [fieldName]: selectedPOId },
      ...prevCreditNote.slice(index + 1),
    ]);
    setSelectedPOs((prevSelectedPOs) =>
      prevSelectedPOs.includes(selectedPOId)
        ? prevSelectedPOs
        : [...prevSelectedPOs, selectedPOId]
    );
  };

  const handleAdjustAgainstPO = () => {
    if (allPOs.length === 0) {
      notification.error({
        message: "Error",
        description: "No Pos available.",
        style: { color: "red" },
      });
      return;
    }

    const allPOsSelected = allPOs.every((po) =>
      creditNotePos.some((item) => item.po_id === po.id)
    );

    if (allPOsSelected) {
      notification.error({
        message: "Error",
        description: "All Pos are already selected.",
        style: { color: "red" },
      });
      return;
    }

    setSelectedPOs([]);

    const creditNoteval = creditNotePos.some(
      (eachPo) =>
        eachPo.amount === null || eachPo.amount === "" || !eachPo.po_id
    );
    if (creditNoteval) {
      openNotificationForRequiredField()
    } else {
      setEditable((prevEditable) => [true, ...prevEditable]);
      currentEditable.current = 0;

      const updatedCreditNotePos = [...creditNotePos];

      const newArray = [
        {
          key: uniqueId(),
          po_id: "",
          amount: "",
          project_id: "",
          invoice_id: "",
        },
        ...updatedCreditNotePos,
      ];

      setCreditNotePos(newArray);
    }
  };
  
  const handleSaveAdjustment = async () => {
    try {
      const adjustedAmountSum = adjustedAmountSumm();
      const creditAmount = creditNoteDetail.credit_amount;

      if (adjustedAmountSum <= creditAmount) {
        if (creditNotePos.length === 0) {
          notification.error({
            message: "Error",
            description: "Please select at least one PO to add.",
            style: { color: "red" },
          });
          return;
        }

        const isValidData = creditNotePos.every(
          (item) => item.po_id &&  item.project_id && item.invoice_id && item.amount !== null && item.amount !== ""
        );

        if (!isValidData) {
          notification.error({
            message: "Error",
            description:
              "Please select a project code, PO, select an invoice, and provide the amount.",
            style: { color: "red" },
          });
          return;
        }

        const payload = {
          creditnoteProject: creditNotePos.map((item) => ({
            id: item.id,
            po_id: item.po_id,
            amount: item.amount,
            project_id: item.project_id,
            invoice_id: item.invoice_id,
          })),
        };
        setIsSaving(true);
        const res = await AxiosConfig.post(
          `creditnote/po/${creditNoteDetail?.id}`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (res.status === 200) {
          openNotificationForPo();
          window.setTimeout(function () {
            location.reload();
          }, 1000);
        } else{
          setIsSaving(false);
        }
      } else {
        setIsSaving(false);
        notification.error({
          message: "Error",
          description: "Adjusted amount exceeds the credit amount.",
          style: { color: "red" },
        });
      }
    } catch (error) {
      setIsSaving(false);
      notification.error({
        message: "Error",
        description: "invalid adjustment amount",
        style: { color: "red" },
      });
    }
  };

  const getPOData = async () => {
    try {
      const res = await AxiosConfig.get(
        `creditnote/project/${props.creditNoteDetail?.customer_id}/${props.creditNoteDetails.currency_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (res.status === 200) {
        setEditable(Array(creditNotePos.length).fill(false));
        setAllPOs(res.data.data.creditNoteProject);
        const selectedPOIds = selectedPOs.map((poId) => parseInt(poId, 10));
        const filteredPOData = res.data.data.creditNotePO.filter(
          (po) => !selectedPOIds.includes(po.id)
        );
        setAllPOs(filteredPOData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPOData();
  }, []);

  const adjustedAmountSumm = () => {
    const adjustedAmountSum = creditNotePos.reduce((total, item) => {
      const amount = typeof item.amount === "number" ? item.amount : 0;
      return total + amount;
    }, 0);
    return adjustedAmountSum;
  };

  useEffect(() => {
    const { totalAdjustedAmount, adjustedAmount, pendingAmount } =
      calculateAdjustedAmount(creditNotePos, creditNoteDetails.credit_amount);

    setTotalAdjustedAmount(totalAdjustedAmount);
    setAdjustedAmount(adjustedAmount);
    setPendingAmount(pendingAmount);
  }, [creditNotePos, creditNoteDetails]);

  const renderSummaryRow = (pageData) => {
    const totalAmount = pageData.reduce(
      (total, item) => total + (item.amount || 0),
      0
    );
    const formattedTotalAmount = totalAmount.toFixed(2);
    return (
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} className="totalText">
          {EditCreditNote.labelForTotal}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} />
        <Table.Summary.Cell index={2} />
        <Table.Summary.Cell index={3} className="totalText">
          {creditNoteDetails.currency_symbol} {formattedTotalAmount}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={4} />
      </Table.Summary.Row>
    );
  };

  const renderSummary = (pageData) => {
    return <Table.Summary fixed>{renderSummaryRow(pageData)}</Table.Summary>;
  };
  return (
    <>
      <div className="container">
        <div className="toatlpercentcontainer">
          <div className="totalpercent">
            <label className="progress-label">
              {EditCreditNote.totalPercent}
            </label>
            <label>
              <span className="Fontchanges">{totalAdjustedAmount}%</span>
            </label>
          </div>
          <div className="progress-bar-container">
            <Progress
              percent={Number(totalAdjustedAmount)}
              strokeWidth={3}
              showInfo={false}
            />
          </div>
        </div>
        <div className="toatlpercentcontainer">
          <label className="progress-label">{EditCreditNote.calculation}</label>
          <div className="AdjustedPending">
            <span className="Fontchanges">
              {creditNoteDetails.currency_symbol} {adjustedAmount}&nbsp;/&nbsp;
            </span>
            {creditNoteDetails.currency_symbol} {pendingAmount}
          </div>
        </div>
        <div className="adjustPobutton">
          <Button
            type="primary"
            className="primary-btn"
            onClick={handleAdjustAgainstPO}
            disabled={
              allPOs.length > 0 &&
              allPOs.every((po) =>
                creditNotePos.some((item) => item.po_id === po.id)
              )
            }
          >
            {EditCreditNote.buttonForAdjustAgainstPO}
          </Button>
        </div>
      </div>

      <>
        <div>
          <Table
            className="CreditApplicationTable"
            columns={creditNotePosTableColumns}
            dataSource={creditNotePos}
            pagination={false}
            summary={renderSummary}
          />
        </div>
        <div>
          <div className="button-container">
            <ButtonReuse
              type="primary"
              className="primary-btn"
              htmlType="submit"
              value="Save"
              onClick={handleSaveAdjustment}
              disabled={isSaving}
            ></ButtonReuse>
            <ButtonReuse
              type="primary"
              className="primary-btn cancel--btn"
              value="Cancel"
              onClick={onCancelHandler}
            ></ButtonReuse>
          </div>
        </div>
      </>
    </>
  );
};

export { EditCreditNoteApplication };
