import React, { useState, useEffect } from "react";
import { Table, Button, Space, Row, Col, Input,Select } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { enumForAudit } from "../../../utils/enums";
import { AxiosConfig } from "../../../ApiConfig";
import { errorNotification } from "../../../utils/util";
import { getToken } from "../../../auth";
import SessionAudit from "../../../components/AuditModal/SessionAudit";
import { startOfWeek,format,getWeek,add } from "date-fns";
import { useParams } from "react-router-dom";
import moment from "moment";
import "antd/dist/antd.css";
require("./Audits.scss");


const AuditsWSR = () => {

  const { id }: any = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  //const [month, setMonth] = useState(new Date().getMonth());
  const [project_id, setProjectId] = useState();
  const [func_type, setFuncType] = useState();
  const [week_no, setWeekNo] = useState(53);
  
  const token = getToken();
  const currentWeek = getWeek(new Date(year,0));
  const todayWeek = getWeek(new Date());
  const { Option } = Select;


  
  const [nameSearch, setNameSearch] = useState("");
  const [userId, setUserId] = useState();
  const [auditWsrData, setAuditWsrData] = useState([]);  
  const [filterData, setFilterData] = useState([]);
  const [filterTotalCount, setFilterTotalCount] = useState(0);
  const [currentDisplayedWeek, setCurrentDisplayedWeek] = useState(currentWeek);
  const [currentFirstWeek, setCurrentfirstWeek] = useState(currentWeek);
  const [currentLastWeek, setCurrentlastWeek] = useState(currentWeek+4);
  const [financialYears,setFinancialYears] = useState([])
  
  function getFormattedDate(date) {
    return format(date, 'dd MMM yy');
  }

  function getWeekRangeDates(weekNumber, referenceDate = new Date(year,0)) {
    if(referenceDate.getDay() == 6){
      moment(referenceDate.setDate(referenceDate.getDate() + 2)).format('YYYY-MM-DD');
    }else if(referenceDate.getDay() == 7){
      moment(referenceDate.setDate(referenceDate.getDate() + 1)).format('YYYY-MM-DD');
    }
    const referenceStartOfWeek = startOfWeek(referenceDate,{ weekStartsOn: 1 });
    const startDate = add(referenceStartOfWeek, { days: (weekNumber - 1) * 7 });
    const endDate = add(startDate, { days: 4 }); // End date is 6 days after the start date

    return {
      startDate: getFormattedDate(startDate),
      endDate: getFormattedDate(endDate),
    };
  }
  const updateDisplayedWeek = (offset) => {
    let weekchk:any;    
    const newWeek = currentDisplayedWeek + offset;
    if(offset == -4){
      weekchk = currentFirstWeek-4;
      if (weekchk >= 1) {
        setCurrentDisplayedWeek(newWeek);
        setCurrentfirstWeek(weekchk);
        setCurrentlastWeek(weekchk+4);
      }
    }else{
      weekchk = currentLastWeek+4;
      setCurrentDisplayedWeek(newWeek);
      setCurrentfirstWeek(weekchk-4);
      setCurrentlastWeek(weekchk);     
    }    
  };

  const columns = [
    {
      title: (
        <div className="project-code-column-header">
          <div style={{ fontSize: "16px" }}>
            {enumForAudit.labelForProjectCode}
          </div>
        </div>
      ),
      dataIndex: "project_code",
      key: "project_code",
      width: "100px",
      className: "project-code-column",
    },
    {
      title: (
        <div className="project-code-column-header">
          <div style={{ fontSize: "16px" }}>
            {enumForAudit.labelForProjectName}
          </div>
        </div>
      ),
      dataIndex: "name",
      key: "name",
      width: "100px",
      className: "project-code-column",
    },
    ...Array.from({ length: 4 })
      .map((_, index) => {
        const weekNumber = currentDisplayedWeek + index;
        const { startDate, endDate } = getWeekRangeDates(weekNumber);
        return {
          title: (
            <div className="header-div">
              <div className="header-div-child">{`Week ${weekNumber}`}</div>
              <div className="header-week-range">{`${startDate} - ${endDate}`}</div>
            </div>
          ),
          dataIndex: `week${weekNumber}`,
          key: `week${weekNumber}`,
          width: "200px",
          onCell: (record) => ({
            onClick: () =>{ 
              const weekData = record.week_data.find(
                (option:any) => option.week == weekNumber && option.year == year
              );
              weekData ? 
              handleCellClick(weekData.week,record.id,weekData.year,"update") :
              handleCellClick(weekNumber,record.id,new Date().getFullYear(),"add")
            }
          }),
          render: (_, record) => {

          const weekData = record.week_data.find(
            (option:any) => option.week == weekNumber && option.year == year
          );
           if(weekData){
            const colorData = weekData.color_code!=null?weekData.color_code:"#B0B0B0";
            const textColor = weekData.color_code!=null?"#000000":"#000000";
            return weekData ? (
              <Button
                style={{
                  backgroundColor: `${colorData}`,
                  width: "100%",
                  borderRadius: "20px",
                  height: "40px",
                  color:`${textColor}`,
                }}
              >
               Total Score : {weekData ? weekData.total_score : null}
              </Button>
            ) : null;
            }
          },
        };
      })      
  ];

  useEffect(() => {
    getUser();
    getFinancialYearData()
  }, [userId]);
  useEffect(() => {
    if (auditWsrData) {
      const results: any = auditWsrData.filter(
        (item: any) => item.name.toLowerCase().includes(nameSearch.toLowerCase()) ||
          item.project_code.toLowerCase().includes(nameSearch.toLowerCase())        
      );
      setFilterData(results);
      setFilterTotalCount(results?.length);           
    }
  }, [nameSearch]);
  const getUser = async () => {
    try {
      const response = await AxiosConfig.get("users/current", {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data && response.data.data && response.data.data.user) {
        const userId = response.data.data.user.id;
        setUserId(userId);
        getWsrAuditData(userId);
      } else {
        console.error("User data not found in the API response");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  const getFinancialYearData = async () => {
    try {
      const response = await AxiosConfig.get("/financialYears", {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data && response.data.data && response.data.data.years) {
      const finYearsData = response.data.data.years.map((finyears:any) => new Date(finyears.start_date).getFullYear());    
      
      setFinancialYears(finYearsData);
      } else {
        console.error("User data not found in the API response");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const getWsrAuditData = async (userId:any) => {
    try {
      const res = await AxiosConfig.get(`audit/${userId}?group_id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const tableData = res.data.data.projects;
      setAuditWsrData(tableData);      
      setFilterData(tableData);
      setFilterTotalCount(tableData?.length);
    /*  let mappedData = auditWSR.flatMap((project:any) => {
        if(project.week_data){
          return project.week_data.map((weekData:any) => ({
            audit_id: project.id,
            project_id: project.id,
            project_name: project.name,
            project_code: project.project_code,
            year: weekData.year,
            week: weekData.week,
            start_date: weekData.start_date,
            end_date: weekData.end_date,
            total_score: weekData.total_score,
          }));
        }else if(project.monthly_data){
          return project.monthly_data.map((monthData:any) => ({
            audit_id: project.id,
            project_id: project.id,
            project_name: project.name,
            project_code: project.project_code,
            year: monthData.year,
            week: monthData.week,
            start_date: monthData.start_date,
            end_date: monthData.end_date,
            total_score: monthData.total_score,
          }));
        }
      });
      // Group by project_id
     const groupedData = mappedData.reduce((acc, item) => {
        const { project_id, ...rest } = item;
        acc[project_id] = acc[project_id] || [];
        acc[project_id].push(rest);
        return acc;
      }, {});

      // Convert the grouped data back to an array
      const resultArray:any = Object.keys(groupedData).map(project_id => ({
        project_id: parseInt(project_id, 10),
        data: groupedData[project_id],
      }));
      console.log(resultArray, " : ")*/
    } catch (error) {
      console.log("Error fetching Audit WSR data:", error);
    }
  };

  const handleCellClick = (week_no:any,project_id:any,year:any,func_type:any) => {
    setYear(year);
    setWeekNo(week_no);
    setProjectId(project_id);
    if(week_no > todayWeek){
      errorNotification("Sorry, data entry for the upcoming week is not allowed");
    }else{
      setIsModalOpen(true);
    }
    setFuncType(func_type)
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const handleOnChange = (event) => {
    setNameSearch(event.target.value);
  };

  return (
    <div>
      <Row className="audit-wsr-search">
        <Col>
          <div className="searchForm" style={{width:"380px"}}>
            <form>
              <Input.Search
                allowClear
                onChange={handleOnChange}
                placeholder="Search by project name / project code"                
              />
            </form>
          </div>
        </Col> 
        <Col>
              <div style={{paddingTop:"10px",paddingLeft:"20px"}}>
                <label>
                {enumForAudit?.labelForPojectCount} : {filterTotalCount}
                </label>
              </div>
        </Col>
        <Col className="div-padding">
        <Space className="left-right-navigation-button">
          <Button
            icon={<LeftOutlined />}
            onClick={() => updateDisplayedWeek(-4)}
            disabled={currentFirstWeek <= 1}
          />
          <Button
            icon={<RightOutlined />}
            onClick={() => updateDisplayedWeek(4)}
            disabled={currentLastWeek >= 53}
          />
        </Space>
        </Col>  
        <Col>
          <div>
            <span className="div-padding">Year : </span>
              <Select
                  value={year} 
                  className="year-dropdown"  
                  onChange={(value: any) => {
                    setYear(value);                    
                  }}              
                >
                  {financialYears.map((year: any, index: any) => (
                    <Option key={index} value={year}>
                      {year}
                    </Option>
                   ))}
                </Select>            
            </div>  
          </Col>  
      </Row>
      <Table
        className="custom-table fixed-header"
        dataSource={filterData}
        columns={columns}
        bordered
        pagination={false}        
        style={{ tableLayout: "fixed",height:"600px",overflow:"auto" }}
      />
      <div>{isModalOpen && <SessionAudit group_id={id} project_id={project_id} week_no={week_no} year={year} func_type={func_type} onClose={handleModalCancel} />}</div>
    </div>
  );
};
export default AuditsWSR;
