import { Tabs } from "antd";
import { useDispatch } from "react-redux";

import { SupersetEmbedComponent } from "../../../components";
import BuDashboardSalesDetailView from "./BuDashboardSalesDetailView";
import { getSalesTabsActions } from "../../../stores/actions/dashboardActions";

const buDashboard = () => {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();

  return (
    <Tabs className="BoldStyling"
      defaultActiveKey="1"
      onChange={(tabsKey: string) => dispatch(getSalesTabsActions(tabsKey))}
    >
      <TabPane tab="Summary" key="1">
        <SupersetEmbedComponent pageType="sales" isDepartmentEnabled={true} />
      </TabPane>

      <TabPane tab="Detail View" key="2">
        <BuDashboardSalesDetailView />
      </TabPane>
    </Tabs>
  );
};

export default buDashboard;
