import React, { ChangeEvent, useState,useEffect } from "react";
import { Modal, Select,notification, } from "antd";
import edit from "../../assets/images/edit.png";
import { InputField } from "../../components";
import { Axios } from "../../utils/axios";
import { errorNotification } from "../../utils/util";
import "./SessionAudit.scss"
import {Upload} from "antd";
import ButtonReuse from "../../components/Buttons/Button";
import TextArea from "antd/lib/input/TextArea";
import { AxiosConfig } from "../../ApiConfig";
import { getToken } from "../../auth";
import { PlusOutlined } from "@ant-design/icons";
import { AddProject } from "../../utils/enums"
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import deleteImage from "../../assets/images/delete.png";


const token = getToken();

const { Option } = Select;

interface SessionAuditProps {
  group_id:any;
  project_id:any;
  year:any;
  week_no:any;
  func_type:any;
  onClose: () => void;
}

  const SessionAudit: React.FC<SessionAuditProps> = ({ group_id,project_id,year,week_no,func_type,onClose }) => {
  const [review_type, setReviewType] = useState("");
  const [recordedDate, setRecordedDate] = useState();
  const [scoring_color, setScoringColor] = useState("Red");
  const [scoring_color_code, setScoringColorCode] = useState("#FFFFFF");
  const [minRating, setminRating] = useState(1);
  const [maxRating, setmaxRating] = useState(5);  
  const [disableMode, setDisableMode] = useState(true);
  const [statusColorData, setStatusColorData] = useState([]);

  const [questionnaires, setQuestionnaires] = useState<{ id:number,
    question_id:number,
    question: string;
    response: string;
    date_of_response:string;
    comments_enabled:any;
    response_score: string }[]>([]);
    interface Attachment {
      attachment_id: string; 
    }
    interface AttachmentData {
      attachment_id: string;
     
    }
   
    const [fileUrls, setFileUrls] = useState<string[]>([]);


const arrayBufferToBase64 = (buffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};


const fetchFileUrl = async (attachment_id: string) => {
  try {
    const response = await AxiosConfig.get(`audit/attachment/${attachment_id}`, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'arraybuffer', 
    });

    const fileData = arrayBufferToBase64(response.data);

    return `data:image/png;base64,${fileData}`;
  } catch (error) {
    console.error(`Error fetching file URL for ${attachment_id}:`, error);
    return null;
  }
};


const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
const [attachmentsData, setAttachmentsData] = useState<AttachmentData[]>([]);

  const getResponseData = async () => {
    try {
      const res = await AxiosConfig.get(`reviewSettings/${group_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const filesResponse = await AxiosConfig.get(`audit/attachments/${project_id}?week_no=${week_no}&year=${year}&group_id=${group_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      setUploadedFiles(filesResponse.data.data.files);
      setAttachmentsData(filesResponse.data.data.attachments);

      const attachmentsData = filesResponse.data.data.attachments;
      console.log(attachmentsData, "aa");
      
      
     
      const fileUrlPromises = attachmentsData.map(async (attachment: Attachment) => {
        const fileUrl = await fetchFileUrl(attachment.attachment_id);
        return fileUrl;
        
        
      });
  
      const fileUrls = await Promise.all(fileUrlPromises);

       setFileUrls(fileUrls as string[]);

      const reviewData = res.data.data.reviewData;
      let statusColorResData = reviewData[0].group_scorings || [];
      setStatusColorData(statusColorResData)
      let questionArray:any = [];
      setReviewType(reviewData[0].review_type);
      setminRating(reviewData[0].min_rating);
      setmaxRating(reviewData[0].max_rating);

      if(reviewData[0].questions && reviewData[0].questions.length){
        let quesObj = reviewData[0].questions;
        quesObj.map((questions:any) => {
        questionArray.push({ 
          question_id:questions.id,
          question:questions.question,
          comments_enabled:questions.comments_enabled})
      })
      if(func_type == "update"){
        const res = await AxiosConfig.get(`audit/response/${project_id}?group_id=${group_id}&week_no=${week_no}&year=${year}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
      
        const responseData = res.data.data.responseData;
        const statusColorResData = res.data.data.statusColor;
        // const attachmentsData = res.data.data.attachments;
        const recorded_date = res.data.data.recorded_date;
        const formatedRecDate:any = getFormatedData(recorded_date);
        setRecordedDate(formatedRecDate);
        const scoreColor = statusColorResData.rating_color || "No Status";
        const scoreColorCode = statusColorResData.color_code || "#808080"
        setScoringColor(scoreColor);
        setScoringColorCode(scoreColorCode);
        responseData.flatMap((questions:any) => {
        const questionIndex = questionArray.findIndex(question => question.question_id === questions.question_id);
       
        if (questionIndex !== -1) {
            questionArray[questionIndex]["id"]=questions.id;
            questionArray[questionIndex]["response"]=questions.response;
            questionArray[questionIndex]["date_of_response"]=questions.date_of_response;
            questionArray[questionIndex]["response_score"]=questions.response_score;
        }         
        })
      }
      }

      setQuestionnaires(questionArray);
    } catch (error) {
      console.log("Error fetching Response data:", error);
    }
  };
 
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialFormState, setInitialFormState] = useState<any>({
    supersec_pwd: "",
    status: "",
  });
  const handleOk = async () => {
    try {
      const response = await Axios.put(
        "auth/verifySuperSecretPassword",
        initialFormState
      );

      if (response) {
        setDisableMode(false);
        setIsModalVisible(false);
        setIsAttachFileVisible(true);
      } else {
        errorNotification("");
      }
    } catch (error: any) {
      errorNotification("Invalid Super secret password");
    }
  };
  const getFormatedData = (recDate) => {

    const dateObject = new Date(recDate);

    const day = String(dateObject.getDate()).padStart(2, '0');
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const year = dateObject.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }
  const handleChangeQuestionnaire = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const updatedQuestionnaires = [...questionnaires];
    updatedQuestionnaires[index] = {
      ...updatedQuestionnaires[index],
      response: e.target.value,
    };
    setQuestionnaires(updatedQuestionnaires);
  };


  const handleSave =async () => {


    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files", file.originFileObj as File);
    });
    

      try {
        if (fileList.length > 0) {
          const fileUploadResponse = await AxiosConfig.post(
            `audit/attachments/upload/${project_id}?week_no=${week_no}&year=${year}&group_id=${group_id}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (fileUploadResponse.status === 200) {
            notification.success({
              message: "Files uploaded successfully!",
            });
          } else {
            console.error("Unexpected response:", fileUploadResponse);
          }
        }
      } catch (error) {
        console.error("File upload error:", error);
      }

      try {
        if (filesToDelete.length > 0) {
          for (const attachment_id of filesToDelete) {
            await AxiosConfig.delete(`audit/attachments/${attachment_id}`, {
              headers: { Authorization: `Bearer ${token}` },
            });
          }

          
          const updatedFileUrls = fileUrls.filter((url) => !filesToDelete.includes(url));
          setFileUrls(updatedFileUrls);

          notification.success({
            message: "Files deleted successfully!",
          });
        }
      } catch (error) {
        console.error("Error deleting files:", error);
      }

      let responseArray = 
           questionnaires.map((item) => {
            if (item.id) {
              console.log("Editing existing response");
              return {               
                id: item.id,
                question_id: item.question_id,
                group_id:parseInt(group_id),
                response: item.response,
                response_score:parseInt(item.response_score)
              };
            } else {
              console.log("Adding new response");
              return {
                question_id: item.question_id,
                group_id:parseInt(group_id),
                response: item.response,
                response_score:parseInt(item.response_score),                
                week_no:week_no,
                year:year       
              };
            }
          })
      
      let requestData = {
        project_id:project_id,
        date_of_response:recordedDate,
        responseArray:responseArray ,
        formData: formData
      }
      try {
        const response = await AxiosConfig.post("audit/response", requestData, {
          headers: { Authorization: `Bearer ${token}` },
        });
    
        if (response.status === 200) {
          notification.success({
            message: "Audit response saved successfully!"
          });
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        }
      } catch (error) {
        console.error("Audit response save error:", error);
      }
    onClose();
  };


  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{AddProject.ButtonForUpload}</div>
    </div>
  );
  

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState();

  const [previewTitle, setPreviewTitle] = useState();
  
  const handleCancell = () => {
    setPreviewVisible(false);
  };
  const handlePreview = async (file: any) => {
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const [fileList, setFileList] = useState<any[]>([]);

  const handleAttachmentChange = (info: UploadChangeParam<UploadFile<any>>) => {
    const { fileList } = info;
    setFileList(fileList);
  };

  const handleCancel = () => {
    onClose();
  };
  const handleClick = () => {
    setIsModalVisible(true);
  };
  const handleCancelVerifyModel = () => {
    setIsModalVisible(false);
  };

  const handleChangeRatingQuestion = (value, index) => {    
    const questionnairesCopy = [...questionnaires];
    questionnairesCopy[index].response_score = value;
    setQuestionnaires(questionnairesCopy);
    const score = questionnaires.reduce((total, item) => item.response_score?total + parseInt(item.response_score):total+0, 0);
     if(statusColorData){
      const changedStatusColor:any = statusColorData.find((item:any) => score >= item.score_from && score <= item.score_to);
      if(changedStatusColor){
        setScoringColor(changedStatusColor.rating_color);
        setScoringColorCode(changedStatusColor.color_code);
      }
    }
  };
  const ratingOptions = Array.from({ length: maxRating - minRating + 1 }, (_, index) => (
    <Option key={index} value={`${minRating + index}`}>{minRating + index}</Option>
  ));
  const getTodayDate=()=>{
    const currentDate = new Date();

    const thisyear = currentDate.getFullYear();
    const thismonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const today = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate:any = `${thisyear}-${thismonth}-${today}`;
    setRecordedDate(formattedDate);

  }
  useEffect(() => {
    getResponseData();
    getTodayDate()
 },[])

 console.log('uploadedFiles after getResponseData:', uploadedFiles);

const [filesToDelete, setFilesToDelete] = useState<string[]>([]);
const [isAttachFileVisible, setIsAttachFileVisible] = useState(false);

const handleDeleteImage = (attachment_id: string, index: number) => {
 
  const updatedFileUrls = fileUrls.filter((url, i) => i !== index);
  const updatedAttachmentsData = attachmentsData.filter((data) => data.attachment_id !== attachment_id);

  setFileUrls(updatedFileUrls);
  setAttachmentsData(updatedAttachmentsData);

 
  setFilesToDelete((prevFilesToDelete) => [...prevFilesToDelete, attachment_id]);
};

  return (
    <>
      <Modal
        title=""
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancelVerifyModel}
        className="password-modal"
      >
        <label>{"Enter the super secret password to continue"}</label>
        <InputField
          placeholder="Enter password"
          name="supersec_pwd"
          onChange={(event: any) => {
            setInitialFormState({
              ...initialFormState,
              supersec_pwd: event.target.value,
            });
          }}
          className="input--field"
          type="password"
        ></InputField>
      </Modal>

      <Modal
        title={
          <div className="modal-header">
            <span>Session Audit 2023</span>
            <img
              src={edit}
              alt="Edit Icon"
              className="edit-icon"
              onClick={handleClick}
            />
          </div>
        }
        visible={true}
        onCancel={onClose}
        footer={[
          <div className="button-container" key="e">
                <ButtonReuse
                  key="save"
                  type="primary"
                  className="primary-btn"
                  htmlType="submit"
                  value="Save"
                  onClick={handleSave}                 
                />

                <ButtonReuse
                  key="cancel"
                  type="primary"
                  className="primary-btn cancel--btn"
                  value="Cancel"
                  onClick={handleCancel}                
                />
                </div>
        ]}
      >
        <div className="session-audit">
        <div className="section-heading">
            <label className="bold">Audit Template</label>
          </div>
          <div>{review_type}</div>

          <div className="date-picker-container">
            <label htmlFor="recorded-date" className="bold">Recorded Date</label>
            <div>{recordedDate}</div>
          </div>
        <div className="question">
          <label className="q-heading">Questionnaires</label>
        </div>
        {questionnaires.map((questionnaire, index) => (
        <div className="questionnaire" key={index}>
        <label className="questionnaire-label">{questionnaire.question}</label>
        <div className="rating-container">
            <Select
            id={`rating-${index}`}
            disabled={disableMode}
            value={questionnaire.response_score || "Rating" }
            onChange={(value) => handleChangeRatingQuestion(value, index)}
            className="rating-dropdown"
          >
            <Option value="Rating" disabled>Rating</Option>
            {ratingOptions}
          </Select>
        </div>
        {questionnaire.comments_enabled && 
        <TextArea
            className="description"
            disabled={disableMode}
            value={questionnaire.response}
            onChange={(e) => handleChangeQuestionnaire(e, index)}
        />}
    </div>
))}
 </div>
 {isAttachFileVisible && (
        <div className="attach-file-section">
            <div>
              <label className="bold">Attach File</label>
            </div>
            <Upload
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleAttachmentChange}
                beforeUpload={() => false}
              >
                {uploadButton}
              </Upload>

              </div>      
        )}

          {fileUrls.length > 0 && (
            <>
              <div>
              <label className="bold">Uploaded Files</label>
            </div>
            <div className="uploaded-files-section">
            <div className="image-gallery">
            {fileUrls.map((fileUrl, index) => (
            <div key={index} className="uploaded-file-entry">
              <div className="image-container">
                <img
                  src={fileUrl}
                  alt={`File ${index + 1}`}
                  style={{ maxWidth: '100%', maxHeight: '100%', width: '100px', height: '100px' }}
                />
              </div>
                  <div className="delete-button-container">
                  <span onClick={() => handleDeleteImage(attachmentsData[index].attachment_id, index)}>
                    <img src={deleteImage} style={{ cursor: "pointer" }} alt="Delete" />
                  </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          </>
       )}

              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancell}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
      

        <div className="final-status-section">
            <div>
              <label className="bold">Final Status</label>
            </div>
        </div>

        <div className="rating-section">
          <div className="input-with-border">
            <div className="color-box-input" style={{backgroundColor:scoring_color_code}}></div>
            <div className="rating-text-input">{scoring_color}</div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SessionAudit;
