import { useEffect, useState } from "react";
import { Table, Row, Col, Card, Popover, Spin } from "antd";
import { useSelector } from "react-redux";
import { convertNumber, formatCurrencyValue } from "../../../utils/util";
import { useHistory } from "react-router-dom";
import { efficiencyList } from "../../../utils/enums";
require("./SnapshotProject.scss");

const EfficiencyList = (props: { managerId: any }) => {
  const snapShotData = useSelector(
    (store: any) => store?.snapshot?.snapShotList?.revenueTableData
  );
  const [data, setData] = useState<any>([]);
  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );
  let managers = props.managerId;

  const history = useHistory();

  useEffect(() => {
    if (snapShotData && snapShotData.length >= 0) {
      let sData: any = [];

      snapShotData.map((row: any) => {
        sData.push({
          id: row?.id,
          Month_Year: `${row?.month.substr(0, 3)} ${row?.year}`,
          Month: row?.month,
          Year: row?.year,
          Revenue: formatCurrencyValue(
            currencySymb?.currency_code,
            row?.earned
          ),
          Project_Count: convertNumber(row?.count, 0),
          Earned_Stu: convertNumber(row?.stus_earned, 2),
          Planned_Utilization: convertNumber(row?.Monthallocated_Stu, 2),
          Actual_Utilization: convertNumber(row?.stus_utilized, 2),
          Planned_Efficiency: `${convertNumber(
            (row?.stus_earned / row?.Monthallocated_Stu) * 100,
            0
          )}%`,
          Actual_Efficiency: `${convertNumber(
            (row?.stus_earned / row?.stus_utilized) * 100,
            0
          )}%`,
        });
      });
      setData(sData);
    }
  }, [snapShotData]);

  const onClickRow = (month: any, year: any) => {
    history.push({
      pathname: "/snapshotReport",
      state: { month, year, managers },
    });
  };

  const columns = [
    {
      title: "MONTH",
      dataIndex: ["Month", "Year", "Month_Year"],
      key: "month",
      render: (text: any, record: any) => (
        <a
          style={{ color: "#5389F8" }}
          onClick={() => {
            onClickRow(record["Month"], record["Year"]);
          }}
        >
          {record["Month_Year"]}
        </a>
      ),
    },
    {
      title: "Revenue",
      dataIndex: "Revenue",
      className: "Amount",
      key: "id",
    },
    {
      title: "Project Count",
      dataIndex: "Project_Count",
      key: "Project_Count",
      className: "tableContent",
    },
    {
      title: "Earned Stu's",
      dataIndex: "Earned_Stu",
      className: "Amount",
      key: "id",
    },
    {
      title: (
        <Popover content="Earned/Planned Allocated" trigger="hover">
          {" "}
          {efficiencyList.popoverLabelForPlannedEfficiency}
        </Popover>
      ),
      dataIndex: "Planned_Efficiency",
      className: "Amount",
      key: "id",
    },
    {
      title: (
        <Popover content="Earned/Actual Utilized" trigger="hover">
          {" "}
          {efficiencyList.popoverLabelForActualEfficiency}
        </Popover>
      ),
      dataIndex: "Actual_Efficiency",
      className: "Amount",
      key: "id",
    },
  ];

  return (
    <Card className="efficiencyList">
      <Row>
        <Col span={24} className="table-container">
          {data.length === 0 ? (
            <div className="loader-container">
              <Spin size="large" />
            </div>
          ) : (
            <Table
              bordered
              columns={columns}
              className="snapshotTable"
              dataSource={data}
              rowKey={"id"}
              pagination={false}
            />
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default EfficiencyList;
