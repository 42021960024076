import "./App.scss";
import Login from "./viewgroups/Login/Login";
import { Switch, useHistory, useParams, Link } from "react-router-dom";
import buDashboard from "./pages/Dashboard/buDashboard/buDashboard";
import buDashboardDelivery from "./pages/Dashboard/buDashboard/buDashboarddDelivery";
import AllocationDetails from "./pages/Dashboard/Project/AllocationDetails/AllocationDetails";
import { PublicRoute, PrivateRoute } from "./Routes/index";
import {
  Customer,
  CustomerClassification,
  CreditNote,
  Project,
  ProjectDetails,
  Talent,
  TalentDetails,
  ChangePass,
  MonthlySlots,
  Users,
  PurchaseOrderList,
  allocation,
  TalentAllocation,
  Expenses,
  Audits,
  AuditsReview,
  AuditsWSR,
} from "./pages/index";
import { SideMenu } from "./viewgroups";
import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Select, Menu, Dropdown, notification } from "antd";
import { Logo } from "./components";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
  TeamOutlined,
  AppstoreOutlined,
  LogoutOutlined,
  ContactsOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import logo from "./assets/images/logo_dashboard.svg";
import logoSym from "./assets/images/logo_sym.svg";
import { AxiosConfig } from "./ApiConfig";
import { getToken } from "./auth";
import dayjs from "dayjs";
import AIAssistant from "../src/pages/ai-assistant/aiassistant";
import MonthlySales from "./pages/Dashboard/buDashboard/MonthlySales";
import MonthlyEarnings from "./pages/Dashboard/buDashboard/MonthlEarnings";
import Snapshot from "./pages/Dashboard/Talent/Snapshot";
import SnapshotProject from "./pages/Dashboard/ProjectDetails/SnapshotProject";
import dashboardInvoice from "./pages/Dashboard/buDashboard/DashboardInvoce";
import buDashboardInvoice from "./pages/Dashboard/buDashboard/buDashboardInvoice";
import { isAdmin, isBuHead } from "./utils/util";
import { getBaseCurrency } from "./stores/actions/getBaseCurrencyAction";
import { useDispatch, useSelector } from "react-redux";
import FINANCE from "../src/pages/Dashboard/buDashboard/finance";
import SnapshotDetails from "./pages/Dashboard/ProjectDetails/snapshotDetails";
import { clearStorage } from "../src/utils/localStore";
import { app } from "./utils/enums";
import AuditsPSAT from "./pages/Dashboard/Project/AuditPSAT";
const { Header, Sider, Content } = Layout;
// import { Link } from 'react-router-dom';

function App() {
  const dashboardIndex = useSelector((state: any) => state.dashboard);
  const logoutHandler = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("isLoggedIn");
    sessionStorage.removeItem("isDepartment");
    clearStorage();
    history.push("/login");
    window.location.reload();
    localStorage.clear();
  };
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [heading, setHeading] = useState(sessionStorage.getItem("Heading"));
  const [isRedirect, setRedirect] = useState(false);
  const [isRedirection, setRedirection] = useState(false);
  const history = useHistory();
  const firstName = localStorage.getItem("firstName");
  const projectId = localStorage.getItem("projectsId");
  const auditId = localStorage.getItem("auditId");
  const talentId = localStorage.getItem("talentid");
  const [collapsed, setCollapsed] = useState(() => {
    const storedCollapsed = localStorage.getItem("menuCollapsed");
    return storedCollapsed ? JSON.parse(storedCollapsed) : false;
  });

  const talentJson = talentId ? JSON.parse(talentId) : null;
  let token = getToken();

  useEffect(() => {
    localStorage.setItem("selectedFy", "2023-2024");
    localStorage.setItem("isFYChange", "not changed");
  }, []);

  const [showText, setShowText] = useState(false);

  useEffect(() => {
    getUser();
  }, []);

  const [showDash, setShowDash] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(false);
  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(getBaseCurrency());
  }, [dispatch]);

  const getUser = async () => {
    await AxiosConfig.get("users/current", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      const departments = res.data.data.user.departments;
      const filteredBu = departments.filter(function (associatedBu: {
        flag: any;
      }) {
        return associatedBu.flag === true;
      });
      localStorage.setItem("Departments", JSON.stringify(filteredBu));
      const checkAdmin = res.data.data.user.superAdmin;
      if (departments.length > 0) {
        setShowDash(true);
      }
      if (checkAdmin === true) {
        setSuperAdmin(true);
      }
    });
  };
  const handleTrainAiModal = async () => {
    try {
      const response = await AxiosConfig.post(
        "https://traccar-dev-ai.spurtreetech.com/api/assistant/train",
        {},
        {
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjMsImlhdCI6MTY4Mzg3MjM1NywiZXhwIjoxNjg0NDc3MTU3fQ.orfZD4WB7uesMP_oROxel7wnHnnpglgRa44nhCEat44",
          },
        }
      );
      notification.success({
        message: "Train Success",
        description: JSON.stringify(response.data),
        duration: 10,
      });
    } catch (error: any) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      notification.error({
        message: "Train Error",
        description: error.response ? error.response.data : error.message,
        duration: 10,
      });
    }
  };

  const currentYear = dayjs().year();
  const startYear = FINANCE;
  const fiscalYears: string[] = [];
  for (let i = startYear; i <= currentYear; i++) {
    const endYear = i + 1;
    const fiscalYearString = `${i}-${endYear}`;
    fiscalYears.push(fiscalYearString);
  }

  const toggleHandler = () => setShowText(true);
  const Text = () => (
    <div className="toggleIn">
      <Menu mode="inline" className="innerToggleOut">
        <div className="menu-mob-Inner">
          <h4>{app?.headingForTalent}</h4>
          <Menu.Item key="1-1">
            <Link to={"/buDashboard"}>{app?.menuSales}</Link>
          </Menu.Item>
          <Menu.Item key="1-2">
            <Link to={"/buDashboardDelivery"}>{app?.menuDelivery}</Link>
          </Menu.Item>
          <Menu.Item key="1-3">
            {app?.menuInvoice}
            <Link
              to={"/buDashboardInvoice"}
              onClick={() => localStorage.setItem("key", "1")}
            ></Link>
          </Menu.Item>
        </div>
        <div className="menu-mob-Inner">
          <h4>{app?.headingForProject}</h4>
          <Menu.Item key="2-1">
            <Link to={"/snapshotproject"}></Link>
          </Menu.Item>
          <Menu.Item
            key="2"
            //icon={<BookOutlined />}
            onClick={() => onClickItems("projects")}
          >
            {app?.menuProject}
            <Link to={"/projects"}></Link>
          </Menu.Item>
          <Menu.Item key="2-2">
            {app?.menuPurchaseOrder}
            <Link
              to={"/purchaseorders"}
              onClick={() => localStorage.setItem("key", "2-2")}
            ></Link>
          </Menu.Item>
          <Menu.Item key="2-3">
            {app?.menuForExpenses}
            <Link
              to={"/expenses"}
              onClick={() => localStorage.setItem("key", "2-3")}
            ></Link>
          </Menu.Item>
          <Menu.Item key="2-4">
            {app?.menuForAudits}
            <Link
              to={"/audits"}
              onClick={() => localStorage.setItem("key", "2-4")}
            ></Link>
          </Menu.Item>
          <Menu.Item key="2-4">
            {app?.menuForAudits}
            <Link
              to={"/audits/weekly/:id"}
              onClick={() => localStorage.setItem("key", "2-4")}
            ></Link>
          </Menu.Item>
        </div>
        <div className="menu-mob-Inner">
          <h4>{app?.headingForTalent}</h4>
          <Menu.Item key="4-1">
            <Link to={"/snapshot"}>{app?.menuSnapShot}</Link>
          </Menu.Item>
          <Menu.Item key="4-2">{app?.menuTrends}</Menu.Item>
          <Menu.Item key="4">
            {app?.menuTalentList}
            <Link
              to={"/talent"}
              onClick={() => localStorage.setItem("key", "4")}
            ></Link>
          </Menu.Item>
        </div>
        <div className="menu-mob-Inner">
          <h4>{app?.headingForAllocation}</h4>
          <Menu.Item key="8-1">
            {app?.menuProjectAllocation}
            <Link
              to={"/allocations"}
              onClick={() => localStorage.setItem("key", "8-1")}
            ></Link>
          </Menu.Item>
          <Menu.Item key="8-2">
            {app?.menuTalentAllocation}
            <Link
              to={"/TalentAllocation"}
              onClick={() => localStorage.setItem("key", "8-2")}
            ></Link>
          </Menu.Item>
        </div>
        {superAdmin && (
          <Menu.Item
            key="6"
            icon={<ContactsOutlined />}
            onClick={() => onClickItems("users")}
          >
            <Link to="/users">{app?.menuUsers}</Link>
          </Menu.Item>
        )}
      </Menu>
    </div>
  );

  const onClickItems = (e: any) => {
    if (e === "users") {
      setShowText(false);
    } else if (e === "budashboard") {
      setShowText(false);
    } else if (e === "budashboardDelivery") {
      setShowText(false);
    } else if (e === "projects") {
      setShowText(false);
    } else if (e === "customers") {
      setShowText(false);
    }
  };

  useEffect(() => {
    return history.listen((location) => {
      setHeading(location.pathname);
    });
  }, []);
  sessionStorage.setItem("Heading", location.pathname);
  useEffect(() => {
    const isUserLoggedIn = localStorage.getItem("isLoggedIn");
    if (isUserLoggedIn === "1") {
      setIsLoggedIn(true);
    }
  }, []);

  const toggle = () => {
    const newCollapsed = !collapsed;
    setCollapsed(newCollapsed);
    localStorage.setItem("menuCollapsed", JSON.stringify(newCollapsed));
  };

  useEffect(() => {
    localStorage.setItem("menuCollapsed", JSON.stringify(collapsed));
  }, [collapsed]);

  const { Option } = Select;

  function handleOnChange(value: any) {
    localStorage.setItem("selectedFy", value);
    localStorage.setItem("isFYChange", "changed");
    setRedirection(true);
  }

  function handleChange(value: any) {
    localStorage.setItem("selectedFy", value);
    localStorage.setItem("isFYChange", "changed");
    setRedirect(true);
  }
  let { id }: any = useParams();
  console.log("id", id);

  useEffect(() => {
    getUser();
  }, []);

  const menu =
    superAdmin === true ? (
      <Menu className="customDropDown">
        <Menu.Item key="0">
          <p>{app.menuMonthSlots}</p>
          <Link to={"/monthlyslots"}></Link>
        </Menu.Item>
        <Menu.Item key="1">
          <p>{app.menuAuditReview}</p>
          <Link to={"/auditreview"}></Link>
        </Menu.Item>
        <Menu.Item key="0">
          <p>{app.menuChangePass}</p>
          <Link to={"/password"}></Link>
        </Menu.Item>
        <Menu.Item key="2" onClick={handleTrainAiModal}>
          {app.menuTrainAIModal}
        </Menu.Item>
        <Menu.Item key="7" icon={<LogoutOutlined />} onClick={logoutHandler}>
          {app.menuLogout}
        </Menu.Item>
      </Menu>
    ) : (
      <Menu className="customDropDown">
        <Menu.Item key="0">
          <p>{app.menuChangePass}</p>
          <Link to={"/password"}></Link>
        </Menu.Item>
        <Menu.Item key="7" icon={<LogoutOutlined />} onClick={logoutHandler}>
          {app.menuLogout}
        </Menu.Item>
      </Menu>
    );
  return (
    <>
      <div className="App">
        {isLoggedIn ? (
          <Layout
            className={
              collapsed
                ? "side-menu-container toggle1"
                : "side-menu-container toggle2"
            }
            style={{ minHeight: "100vh" }}
          >
            <Sider
              collapsible
              collapsed={collapsed}
              // onClick={() => setShow(false)}
            >
              <Logo src={logo} className="type1" />
              <Logo src={logoSym} className="type2" />
              <SideMenu showAdmin={superAdmin} showDash={showDash} />
            </Sider>
            <Layout className="site-layout" style={{ minHeight: "100vh" }}>
              <Header className="header">
                <Row>
                  <Col md={1} className="triggerBtn">
                    {React.createElement(
                      collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                      {
                        className: "trigger",
                        onClick: toggle,
                      }
                    )}
                  </Col>
                  {(() => {
                    switch (heading) {
                      case "/projects":
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.headingForProject}</h1>
                          </Col>
                        );
                      case "/snapshotproject":
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.headingForSnapshotRepaort}</h1>
                          </Col>
                        );
                      case "/expenses":
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.headingForExpenses}</h1>
                          </Col>
                        );
                      case "/audits":
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.headingForAudits}</h1>
                          </Col>
                        );
                      case "/audits/weekly/" + auditId:
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.headingForAudits}</h1>
                          </Col>
                        );
                      case "/allocations":
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.headingForProjectAllocation} </h1>
                          </Col>
                        );
                      case "/TalentAllocation":
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.headingForTalentAllocation} </h1>
                          </Col>
                        );
                      case "/allocations/" + projectId:
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.headingForTalentAllocationDetails}</h1>
                          </Col>
                        );

                      case "/projects/" + projectId:
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.headingForProjectDetails}</h1>
                          </Col>
                        );
                      case "/customers":
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.headingForSummary}</h1>
                          </Col>
                        );
                      case "/customerClassification":
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.headingForClassification}</h1>
                          </Col>
                        );
                      case "/creditNote":
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.headingForCreditNote}</h1>
                          </Col>
                        );
                      case "/talent/" + talentJson?.Talent_id:
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.headingForTalentDetails}</h1>
                          </Col>
                        );
                      case "/snapshot":
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.headingForTalent}</h1>
                          </Col>
                        );
                      case "/snapshotReport":
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.headingForProjectRevenueRecognition}</h1>
                          </Col>
                        );
                      case "/purchaseorders":
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.headingForPurchaseOrder}</h1>
                          </Col>
                        );
                      case "/talent":
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.headingForTalent}</h1>
                          </Col>
                        );
                      case "/password":
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.headingForChangePass}</h1>
                          </Col>
                        );
                      case "/monthlyslots":
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.headingForMonthSlot}</h1>
                          </Col>
                        );
                      case "/auditreview":
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.hedingForAuditReview}</h1>
                          </Col>
                        );
                      case "/monthlysales":
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.headingForSales}</h1>
                          </Col>
                        );
                      case "/monthlyearnings":
                        return (
                          <Col md={8} xs={12}>
                            <h1
                              className="overflowEllipsis"
                              title="Revenue Recognition"
                            >
                              {app.headingForRevenueRecognition}
                            </h1>
                          </Col>
                        );
                      case "/users":
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.headingForUsers}</h1>
                          </Col>
                        );
                      case "/aiassistant":
                        return (
                          <Col md={8} xs={12}>
                            <h1>{"Traccar AI"}</h1>
                          </Col>
                        );
                      case "/buDashboardDelivery":
                        return (
                          <>
                            <Col md={8} xs={24} lg={6} xl={7}>
                              <Row>
                                <Col md={14} xs={24} lg={8} xl={15}>
                                  <h1>{app.headingForDeliveryDashboard}</h1>
                                </Col>
                                <Col md={1} xs={12} lg={6} xl={6}>
                                  {dashboardIndex.tabIndexDelivery != "1" ? (
                                    <div className="years">
                                      <Select
                                        defaultValue={`${currentYear}-${
                                          currentYear + 1
                                        }`}
                                        onChange={handleOnChange}
                                      >
                                        {fiscalYears.map((fy) => (
                                          <Option key={fy} value={fy}>
                                            {fy}
                                          </Option>
                                        ))}
                                      </Select>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </>
                        );
                      case "/buDashboard":
                        return (
                          <>
                            <Col md={8} xs={24} lg={6} xl={7}>
                              <Row>
                                <Col md={14} xs={24} lg={8} xl={15}>
                                  <h1>{app.headingForSalesDashboard}</h1>
                                </Col>
                                <Col md={1} xs={12} lg={6} xl={6}>
                                  {dashboardIndex.tabIndexSales !== "1" ? (
                                    <div className="years">
                                      <Select
                                        defaultValue={`${currentYear}-${
                                          currentYear + 1
                                        }`}
                                        onChange={handleChange}
                                      >
                                        {fiscalYears.map((fy) => (
                                          <Option key={fy} value={fy}>
                                            {fy}
                                          </Option>
                                        ))}
                                      </Select>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </>
                        );

                      case "/buDashboardInvoice":
                        return (
                          <>
                            <Col md={8} xs={24} lg={6} xl={7}>
                              <Row>
                                <Col md={14} xs={24} lg={8} xl={15}>
                                  <h1>{app.headingForInvoiceDashboard}</h1>
                                </Col>
                                <Col md={1} xs={12} lg={6} xl={6}>
                                  {dashboardIndex.tabIndexInvoice !== "1" ? (
                                    <div className="years">
                                      <Select
                                        defaultValue={`${currentYear}-${
                                          currentYear + 1
                                        }`}
                                        onChange={handleChange}
                                      >
                                        {fiscalYears.map((fy) => (
                                          <Option key={fy} value={fy}>
                                            {fy}
                                          </Option>
                                        ))}
                                      </Select>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </>
                        );
                      case "/DashboardInvoice":
                        return (
                          <Col md={8} xs={12}>
                            <h1>{app.headingForInvoiceDetail}</h1>
                          </Col>
                        );
                      default:
                        return <Col xs={12}></Col>;
                    }
                  })()}
                  <Col md={15} xs={16} lg={8} xl={10}>
                    <div className="dropDownOut">
                      <Dropdown
                        overlay={menu}
                        trigger={["click"]}
                        placement="bottomCenter"
                      >
                        <h6 className="ant-dropdown-link">
                          {app.hello} {firstName} <DownOutlined />
                        </h6>
                      </Dropdown>
                    </div>
                  </Col>
                </Row>
              </Header>
              <Content className="main-content-container">
                <Switch>
                  <PrivateRoute
                    path="/buDashboardDelivery"
                    component={buDashboardDelivery}
                    exact
                  />
                  {isRedirection && setRedirection(false) && (
                    <PrivateRoute
                      path="/buDashboardDelivery"
                      component={buDashboardDelivery}
                      exact
                    />
                  )}
                  <PrivateRoute
                    path="/buDashboard"
                    component={buDashboard}
                    exact
                  />
                  {isRedirect && setRedirect(false) && (
                    <PrivateRoute
                      path="/buDashboard"
                      component={buDashboard}
                      exact
                    />
                  )}
                  <PrivateRoute
                    path="/allocations"
                    component={allocation}
                    exact
                  />
                  <PrivateRoute
                    path="/allocations/:id"
                    component={AllocationDetails}
                    exact
                  />
                  <PrivateRoute
                    path="/projects/:id"
                    component={ProjectDetails}
                    exact
                  />
                  <PrivateRoute
                    path="/TalentAllocation"
                    component={TalentAllocation}
                    exact
                  />
                  <PrivateRoute
                    path="/snapshotReport"
                    component={SnapshotDetails}
                    exact
                  />
                  <PrivateRoute
                    path="/snapshotproject"
                    component={SnapshotProject}
                    exact
                  />
                  <PrivateRoute path="/expenses" component={Expenses} exact />
                  <PrivateRoute path="/audits" component={Audits} exact />
                  <PrivateRoute
                    path="/audits/weekly/:id"
                    component={AuditsWSR}
                    exact
                  />
                  <PrivateRoute
                    path="/audits/monthly/:id"
                    component={AuditsPSAT}
                    exact
                  />
                  <PrivateRoute
                    path="/purchaseorders"
                    component={PurchaseOrderList}
                    exact
                  />
                  <PrivateRoute path="/projects" component={Project} exact />
                  <PrivateRoute path="/password" component={ChangePass} exact />
                  <PrivateRoute
                    path="/monthlysales"
                    component={MonthlySales}
                    exact
                  />
                  <PrivateRoute
                    path="/monthlyearnings"
                    component={MonthlyEarnings}
                    exact
                  />
                  <PrivateRoute
                    path="/buDashboardInvoice"
                    component={buDashboardInvoice}
                    exact
                  />
                  <PrivateRoute
                    path="/dashboardInvoice"
                    component={dashboardInvoice}
                    exact
                  />
                  <PrivateRoute
                    path="/aiassistant"
                    component={AIAssistant}
                    exact
                  />

                  <PrivateRoute
                    path="/monthlyslots"
                    component={MonthlySlots}
                    exact
                  />
                  <PrivateRoute
                    path="/auditreview"
                    component={AuditsReview}
                    exact
                  />
                  <PrivateRoute
                    path="/projects/:id"
                    component={ProjectDetails}
                    exact
                  />
                  <PrivateRoute
                    path="/allocations/:id"
                    component={AllocationDetails}
                    exact
                  />
                  {(isAdmin() || isBuHead()) && (
                    <>
                      <PrivateRoute
                        path="/customers"
                        component={Customer}
                        exact
                      />
                      <PrivateRoute
                        path="/customerClassification"
                        component={CustomerClassification}
                        exact
                      />
                      {superAdmin && (
                        <PrivateRoute
                          path="/creditNote"
                          component={CreditNote}
                          exact
                        />
                      )}
                      <PrivateRoute path="/talent" component={Talent} exact />
                      <PrivateRoute
                        path="/snapshot"
                        component={Snapshot}
                        exact
                      />

                      <PrivateRoute
                        path="/monthlyslots"
                        component={MonthlySlots}
                        exact
                      />
                      <PrivateRoute
                        path="/auditreview"
                        component={AuditsReview}
                        exact
                      />
                      <PrivateRoute
                        path="/audits/weekly/:id"
                        component={AuditsWSR}
                        exact
                      />
                      <PrivateRoute path="/users" component={Users} exact />
                      <PrivateRoute
                        path="/talent/:id"
                        component={TalentDetails}
                        exact
                      />
                    </>
                  )}
                </Switch>
              </Content>
            </Layout>
          </Layout>
        ) : null}
        <Switch>
          <PublicRoute path="/" component={Login} restricted={false} exact />
          <PublicRoute
            path="/login"
            component={Login}
            restricted={false}
            exact
          />
        </Switch>
      </div>
      {isLoggedIn ? (
        <div className="mobileNavOut">
          <Menu mode="inline" className="toggleOut">
            <Menu.Item
              key="1"
              icon={<AppstoreOutlined />}
              onClick={() => onClickItems("dashboard")}
            >
              {app.menuDashboard}
              <Link to={"/buDashboard"}></Link>
            </Menu.Item>
            <Menu.Item
              key="1"
              icon={<AppstoreOutlined />}
              onClick={() => onClickItems("buDashboardDelivery")}
            >
              {app.menuDashboard}
              <Link to={"/buDashboardDelivery"}></Link>
            </Menu.Item>
            <Menu.Item
              key="3"
              icon={<TeamOutlined />}
              onClick={() => onClickItems("customers")}
            >
              {app.menuCustomer}
              <Link to={"/customers"}></Link>
            </Menu.Item>
            {/* <Menu.Item key="4" icon={<SolutionOutlined />}>
            Talent
            <Link to={"/talent"}></Link>
          </Menu.Item> */}
            <Menu.Item key="5" icon={<MoreOutlined />} onClick={toggleHandler}>
              {app.menuMore}
            </Menu.Item>
            {/* <Menu.Item key="6" icon={<ContactsOutlined />}>
            Users
            <Link to={"/users"}></Link>
          </Menu.Item>
          <Menu.Item key="7" icon={<LogoutOutlined />} onClick={logoutHandler}>
            Logout
          </Menu.Item> */}
            {showText ? <Text /> : null}
          </Menu>
        </div>
      ) : null}
    </>
  );
}

export default App;
