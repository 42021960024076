import { Tabs } from "antd";
import { useDispatch } from "react-redux";

import { SupersetEmbedComponent } from "../../../components";
import BuDashboardDeliveryDetailView from "./BuDashboardDeliveryDetailView";
import { getDeliveryTabsActions } from "../../../stores/actions/dashboardDeliveryAction";
const buDashboarddDelivery = () => {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();

  return (
    <Tabs className="BoldStyling"
      defaultActiveKey="1"
      onChange={(tabsKey: string) => dispatch(getDeliveryTabsActions(tabsKey))}
    >
      <TabPane tab="Summary" key="1">
        <SupersetEmbedComponent
          pageType="delivery"
          isDepartmentEnabled={true}
        />
      </TabPane>

      <TabPane tab="Detail View" key="2">
        <BuDashboardDeliveryDetailView />
      </TabPane>
    </Tabs>
  );
};

export default buDashboarddDelivery;
