import { useState } from "react";
import { Row, Col, Typography, Modal, Image, Button } from "antd";
import moment from "moment";
import ViewAttachment from "./ViewAttachment";
import ButtonReuse from "../../../components/Buttons/Button";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import { saveAs } from "file-saver";
import { renderMarkasSent } from "../../../utils/enums";
import UploadSentInvoiceAttachment from "./uploadSentInvoiceAttachment";
import pdfPlaceholderImage from "../../../assets/images/pdfPlaceHolder.png";
import docxPlaceholderImage from "../../../assets/images/docximage.png";
import "./RenderMarkAsSent.scss";
import xlsxPlaceholderImage from "../../../assets/images/xlsx_thumbnail.png";

const RenderMarkAsSent = (props: {
  invoiceId?: number;
  currencyName?: string;
  currencySymbol?: any;
  poDetails: any;
  Poid: number;
  finalInvoice: any;
}) => {
  const { finalInvoice, currencySymbol, poDetails } = props;
  const { Text } = Typography;
  const dateFormat = "YYYY/MM/DD";

  const images = finalInvoice.projectattachments.map((file, index: number) => {
    if (file.file !== "file corrupted") {
      const fileName = file?.attachment_id.split("^").pop().split(".")[0];
      const fileType = file?.attachment_id.split(".").pop();

      if (
        fileType === "pdf" ||
        fileType === "jpg" ||
        fileType === "jpeg" ||
        fileType === "png" ||
        fileType === "xlsx" ||
        fileType === "docx"
      ) {
        return (
          <div className="attachment" key={index}>
            {fileType === "pdf" ? (
              <div className="pdf-placeholder">
                <img
                  src={pdfPlaceholderImage}
                  alt="PDF Placeholder"
                  className="pdf-placeholder-image"
                />
              </div>
            ) : fileType === "xlsx" ? (
              <div className="xlsx-placeholder">
                <img
                  src={xlsxPlaceholderImage}
                  alt="XLSX Placeholder"
                  className="xlsx-placeholder-image"
                />
              </div>
            ) : fileType === "docx" ? (
              <div className="docx-placeholder">
                <img
                  src={docxPlaceholderImage}
                  alt="DOCX Placeholder"
                  className="docx-placeholder-image"
                />
              </div>
            ) : (
              <Image
                className="render-Image"
                width={62}
                height={62}
                src={`data:image/png;base64,${file.file}`}
                alt="Attachment"
              />
            )}
            <Button
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
              size="small"
              onClick={() =>
                handleDownload(
                  `data:image/png;base64,${file.file}`,
                  file.attachment_id
                )
              }
            />
            <text>{fileName}</text>
          </div>
        );
      } else {
        return (
          <div className="attachment boxStyle" key={index}>
            <Button
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
              size="small"
              onClick={() =>
                handleDownload(
                  `data:application/octet-stream;base64,${file.file}`,
                  file.attachment_id
                )
              }
            />
          </div>
        );
      }
    }
  });

  const [isModalVisibleImage, setIsModalVisibleImage] = useState(false);

  const showViewModalImage = () => {
    setIsModalVisibleImage(true);
  };
  const handleCancelModalImage = () => {
    setIsModalVisibleImage(false);
  };
  const handleDownload = (url: string, attachmentId: string) => {
    saveAs(url, attachmentId.split("^").pop());
  };

  return (
    <>
      <Row
        gutter={16}
        style={{ marginInlineStart: "0.2rem", marginBlock: "0.5rem" }}
      >
        <Col md={8} xs={24} style={{ display: "grid" }}>
          <label className="mt-0">
            {renderMarkasSent.labelForFinalInvoiceAmt}
          </label>
          <Text type="secondary">
            {currencySymbol[poDetails.currency_id]}
            {currencySymbol[poDetails.currency_id] === "₹"
              ? Intl.NumberFormat("en-IN").format(finalInvoice.final_amt)
              : Intl.NumberFormat("en-US").format(finalInvoice.final_amt)}
          </Text>
        </Col>
        <Col md={8} xs={24} style={{ display: "grid" }}>
          <label className="mt-0">{renderMarkasSent.labelForInvoiceDate}</label>
          <Text type="secondary">
            {moment(finalInvoice.recorded_date).format(dateFormat)}
          </Text>
        </Col>
        <Col md={8} xs={24} style={{ display: "grid" }}>
          <label className="mt-0">
            {renderMarkasSent.labelForInvoiceNumber}
          </label>
          <Text type="secondary">{finalInvoice.invoice_number}</Text>
        </Col>
      </Row>
      <Row
        gutter={16}
        style={{ marginInlineStart: "0.2rem", marginBlock: "0.5rem" }}
      >
        <Col md={8} xs={24} style={{ display: "grid" }}>
          <label>{renderMarkasSent.labelForAttachments}</label>
          <ButtonReuse
            value="Attachments"
            type="submit"
            className="edit-image-button"
            onClick={showViewModalImage}
          />
          <Modal
            title="Attachments"
            className="AttachmentsModal"
            visible={isModalVisibleImage}
            onCancel={handleCancelModalImage}
          >
            <ViewAttachment images={images} />
            <UploadSentInvoiceAttachment invoiceId={props.finalInvoice.id} />
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default RenderMarkAsSent;
