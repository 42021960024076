import "./EarnedSummary.scss";
import { Row, Col } from "antd";
import { convertNumber, formatCurrencyValue } from "../../../utils/util";
import { useSelector } from "react-redux";
import { earnedSummary } from "../../../utils/enums";

const EarnedSummary = (props: {
  parentRevenue?: any;
  parentExpended?: any;
  directExpense: number;
  totalExpense: number;
}) => {
  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );

  return (
    <div className="earnedSummary-Info">
      <Row className="earnedSummaryHeader">
        <Col span={24}>
          <h1>{earnedSummary.headingForEarnedSummary}</h1>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <p>{earnedSummary.labelForUtilizedStu}</p>
        </Col>
        <Col span={8} className="text-end">
          <p>{convertNumber(props.parentExpended, 2)}</p>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <p>{earnedSummary.labelForEarnedRevenue}</p>
        </Col>
        <Col span={8} className="text-end">
          <p>
            {formatCurrencyValue(
              currencySymb?.currency_code,
              props?.parentRevenue
            )}
          </p>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <p>{earnedSummary.labelForEarnedAvgbyStu}</p>
        </Col>
        <Col span={8} className="text-end">
          <p>
            {formatCurrencyValue(
              currencySymb?.currency_code,
              (props?.parentRevenue - props.totalExpense) / props?.parentExpended
            )}
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default EarnedSummary;
