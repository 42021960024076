import { AxiosConfig } from "../../../ApiConfig";
import { Row, Col, DatePicker, Input, notification } from "antd";
import * as yup from "yup";
import { useFormik } from "formik";
import ButtonReuse from "../../../components/Buttons/Button";
import Select from "react-select";
import { getToken } from "../../../auth";
import moment from "moment";
import { skillRecord } from "../../../utils/enums";
require("./RecordData.scss");

const SkillRecord = (props: {
  selectedRecord?: any;
  onCancelHandler?: any;
  skillOptions?: any;
}) => {
  const token = getToken();
  const { TextArea } = Input;
  const dateFormat = "YYYY/MM/DD";
  let talentData: any = localStorage.getItem("talentid");
  talentData = JSON.parse(talentData);
  const currentUserId = localStorage.getItem("userId");

  const initialValues = {
    old_value: talentData.Skill_set,
    new_value: "",
    new_skill_id: "",
    date_of_change: "",
    change_type_id: props.selectedRecord["key"],
    user_id: currentUserId,
    talent_id: talentData["Talent_id"],
    notes: "",
  };

  const validationSchema = yup.object({
    notes: yup.string().required("Please enter some notes"),
    date_of_change: yup
      .mixed()
      .required("Please enter the date of change")
      .nullable(false),
    new_value: yup.string().required("Please select the new skill"),
  });

  const handleSubmit = (values: any) => {
    values = {
      ...values,
      date_of_change: moment(values.date_of_change).format(dateFormat),
    };
    AxiosConfig.post("talents/changes/skills", values, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (response.status === 200) {
          talentData = { ...talentData, Skill_set: values.new_value };
          localStorage.setItem("talentid", JSON.stringify(talentData));
          const notify = () => {
            notification.open({
              message: response.data.status.message,
            });
          };
          notify();
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        }
      })
      .catch(function (error) {
        console.log(error.response.data);
        const notify = () => {
          notification.open({
            message: "",
            description: error.response.data.status.message,
            style: { color: "red" },
          });
        };
        notify();
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleSelectChange = (value: any) => {
    formik.setFieldValue("new_skill_id", value.key);
    formik.setFieldValue("new_value", value.label);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row>
        <Col span={24}>
          <label>{skillRecord.labelForExistingSkill}</label>
          <Input
            className="select--field"
            value={talentData.Skill_set}
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <label>{skillRecord.labelForNewSkills}</label>
          <Select
            options={props.skillOptions?.filter(
              (option) => option.value !== talentData.Skill_set
            )}
            name="new_skill_id"
            placeholder="Select the Skill"
            className="select--field"
            onChange={handleSelectChange}
          />
          <p className="display_error">{formik.errors.new_value}</p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <label style={{ display: "flex" }}>{skillRecord.labelForDate}</label>
          <DatePicker
            defaultValue={undefined}
            format={dateFormat}
            onChange={(val) => {
              formik.setFieldValue("date_of_change", val);
            }}
            placeholder="Select the Date"
            name="date_of_change"
            className="input--field"
          />
          <p className="display_error">{formik.errors.date_of_change}</p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <label>{skillRecord.labelForNotes}</label>
          <TextArea
            rows={4}
            placeholder="Enter the Notes"
            className="input--field textarea--field"
            value={formik.values.notes}
            name="notes"
            onChange={formik.handleChange}
          />
          <p className="display_error">{formik.errors.notes}</p>
        </Col>
      </Row>
      <div className="button-container">
        <ButtonReuse
          type="primary"
          className="primary-btn"
          htmlType="submit"
          value="Record"
        ></ButtonReuse>
        <ButtonReuse
          type="primary"
          className="primary-btn cancel--btn"
          value="Cancel"
          onClick={() => {
            formik.resetForm();
            props.onCancelHandler();
          }}
        ></ButtonReuse>
      </div>
    </form>
  );
};

export default SkillRecord;
