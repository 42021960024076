import "antd/dist/antd.css";
import {
  Drawer,
  Input,
  notification,
  DatePicker,
  Upload,
  Modal,
  AutoComplete,
} from "antd";
import Select from "react-select";
import { Formik, FormikProps } from "formik";
import { getToken } from "../../../auth";
import { InputField } from "../../../components";
import TextArea from "antd/lib/input/TextArea";
import ButtonReuse from "../../../components/Buttons/Button";
import { preventMinus } from "../../../utils/util";
import * as Yup from "yup";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { PlusOutlined } from "@ant-design/icons";
import { AxiosConfig } from "../../../ApiConfig";
import { enumForAddExpense } from "../../../utils/enums";

require("../Customer/AddCreditNote.scss");
const FormData = require("form-data");

const validationSchema = Yup.object().shape({
  reference_no: Yup.string().required("Reference number is required"),
  vendor_name: Yup.string().required("Vendor is required"),
  amount: Yup.number()
    .required("Amount is required")
    .positive("Amount must be a positive number"),
  bill_date: Yup.date().nullable().required("Bill date is required"),
  notes: Yup.string().required("Notes are required"),
  expense_type: Yup.string().required("Expense Type is required"),
});
const AddOverlay = (props: {
  visible?: boolean;
  onClose: () => void;
  onCancelButton: () => void;
}) => {
  type MyFormValues = {
    id: number;
    reference_no: string;
    vendor_name: string;
    amount: number;
    notes: string;
    bill_date: moment.Moment | null;
    expense_type: string;
    files: [];
  };
  const [selectedExpenseType, setSelectedExpenseType] = useState<string | null>(
    null
  );
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [previewImage, setPreviewImage] = useState<string | undefined>();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState<string | undefined>();
  const [vendorNames, setVendorNames] = useState<{ value: string }[]>([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [assignedVendor, setAssignedVendor] = useState<string>("");

  const token = getToken();

  const handleAttachmentChange = (info: UploadChangeParam<UploadFile<any>>) => {
    const { fileList } = info;
    setFiles(fileList);
  };

  useEffect(() => {
    const fetchVendorNames = async () => {
      try {
        const response = await AxiosConfig.get("expense", {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.status === 200) {
          const data = response.data.data.expenses;

          const tempVendors: any = [
            ...new Set(data.map((element) => element.vendor_name)),
          ].map((name) => ({
            value: name,
          }));
          setVendorNames(tempVendors);
        }
      } catch (error) {
        console.error("Error fetching vendor names:", error);
      }
    };

    fetchVendorNames();
  }, []);

  const formikRef = React.useRef<FormikProps<MyFormValues>>(null);

  const expenseTypes: Array<string> = [
    "Travel Expense",
    "Vendor Outsourcing Fees",
    "Contractor Fees",
    "Meals and Entertainment",
    "Other",
  ];

  const openNotification = () => {
    notification.open({
      message: "Expense Added Successfully",
    });
  };

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url || file.thumbUrl);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url?.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}> {"Upload"}</div>
    </div>
  );

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const onCancelHandler = (resetForm: () => void) => {
    resetForm();
    props.onCancelButton();
    window.location.reload();
  };

  const handleVendorNameChange = (value) => {
    setSelectedVendor(value);

    formikRef.current?.setFieldValue("vendor_name", value);
    setAssignedVendor(value);

    if (!vendorNames.some((item) => item.value === value)) {
      setVendorNames([...vendorNames, { value }]);
    }
  };
  const handleDrawerClose = () => {
    if (formikRef.current) {
      formikRef.current?.resetForm();
    }
    props.onClose();
    window.location.reload();
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const selectedDate = values.bill_date;
      const formattedDate = selectedDate
        ? moment(selectedDate).format("YYYY-MM-DD")
        : null;

      const formData = new FormData();
      formData.append("reference_no", values.reference_no);
      formData.append("vendor_name", assignedVendor);
      formData.append("expense_type", selectedExpenseType);
      formData.append("amount", values.amount.toString());
      formData.append("notes", values.notes);
      if (formattedDate) {
        formData.append("bill_date", formattedDate);
      }
      for (const file of files) {
        formData.append("files", file.originFileObj);
      }

      const token = getToken();
      const response = await AxiosConfig.post("expense", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        openNotification();
        props.onClose();
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      const notify = () => {
        notification.open({
          message: (
            <span style={{ color: "red" }}>
              Please provide a unique Reference No.
            </span>
          ),
        });
      };
      notify();
      setTimeout(function () {
        window.location.reload();
      }, 5000);
    }

    resetForm();
  };

  return (
    <>
      <Drawer
        title="Add Expenses"
        width={500}
        onClose={handleDrawerClose}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        className="add_creditNote"
      >
        <Formik
          initialValues={{
            vendor_name: "",
            credit_note: "",
            amount: 0,
            notes: "",
            reference_no: "",
            bill_date: null,
            expense_type: "",
            files: [],
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            // touched,
            resetForm,
            setFieldValue,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="input_container">
                  <label>{enumForAddExpense.labelForReferenceNo} </label>
                  <InputField
                    placeholder="Enter the Reference Number"
                    name="reference_no"
                    onChange={handleChange}
                    value={values.reference_no}
                  />
                  <p className="display_error">{errors.reference_no}</p>
                </div>

                <label>{enumForAddExpense.labelForVendorName}</label>
                <div className="vendor-input">
                  <AutoComplete
                    placeholder="Enter the Vendor"
                    onChange={(value) => {
                      setFieldValue("vendor_name", value);
                      handleVendorNameChange(value);
                    }}
                    value={selectedVendor}
                    dataSource={vendorNames.map((item) => item.value)}
                    filterOption={(inputValue, option) =>
                      option?.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </div>
                <p className="display_error">{errors.vendor_name}</p>

                <div className="input_container">
                  <label>{enumForAddExpense.labelForBillDate}</label>
                  <DatePicker
                    className="date-picker-input"
                    value={values.bill_date}
                    onChange={(date) => setFieldValue("bill_date", date)}
                  />
                  <p className="display_error">{errors.bill_date}</p>
                </div>

                <div className="input_container">
                  <label>{enumForAddExpense.labelForAmount}</label>
                  <Input
                    placeholder="Enter the Expense Amount"
                    name="amount"
                    onKeyPress={preventMinus}
                    min={0}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const twoDecimalRegex = /^\d+(\.\d{0,2})?$/;
                      if (
                        twoDecimalRegex.test(inputValue) ||
                        inputValue === ""
                      ) {
                        setFieldValue("amount", inputValue);
                      }
                    }}
                  />
                  <p className="display_error">{errors.amount}</p>
                </div>

                <div className="input_container">
                  <label>{enumForAddExpense.labelForTypesExpenses}</label>
                  <Select
                    placeholder="Select Expense Type"
                    value={
                      selectedExpenseType
                        ? {
                            value: selectedExpenseType,
                            label: selectedExpenseType,
                          }
                        : null
                    }
                    onChange={(selectedOption) => {
                      setSelectedExpenseType(selectedOption?.value || null);
                      setFieldValue(
                        "expense_type",
                        selectedOption?.value || null
                      );
                    }}
                    options={expenseTypes.map((type) => ({
                      value: type,
                      label: type,
                    }))}
                  />
                  <div className="display_error">{errors.expense_type}</div>
                </div>

                <div>
                  <label>{enumForAddExpense.labelForNotes}</label>
                  <TextArea
                    placeholder="Enter the Notes"
                    rows={5}
                    name="notes"
                    onChange={handleChange}
                    value={values.notes}
                    className="textarea-input"
                  />
                  <p className="display_error">{errors.notes}</p>
                </div>

                <Upload
                  className="PO-upload"
                  listType="picture-card"
                  fileList={files}
                  onPreview={handlePreview}
                  onChange={handleAttachmentChange}
                  beforeUpload={() => false}
                >
                  {files.length >= 8 ? null : uploadButton}
                </Upload>
                <Modal
                  visible={previewVisible}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>

                <div className="button-container">
                  <ButtonReuse
                    type="primary"
                    className="primary-btn"
                    htmlType="submit"
                    value="Add"
                  />
                  <ButtonReuse
                    type="primary"
                    className="primary-btn cancel--btn"
                    value="Cancel"
                    onClick={() => onCancelHandler(resetForm)}
                  />
                </div>
              </form>
            );
          }}
        </Formik>
      </Drawer>
    </>
  );
};

export default AddOverlay;
