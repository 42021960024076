require("./SnapShot.scss");
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { useRef, useState, useEffect } from "react";
import { Row, Col, Card } from "antd";
import { DatePicker } from "antd";
import moment from "moment";
import TeamSize from "./TeamSize";
import StackedAxisChart from "./StackedAxisChart";
import TeamSizeBySkill from "./TeamSizeBySkill";
import UtilizationGraph from "./UtilizationGraph";
import TopUtilised from "./TopUtlised";
import BottomUtilised from "./BottomUtilised";
import ButtonReuse from "../../../components/Buttons/Button";
import CircularProgressChart from "./Attrition";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { snapshot } from "../../../utils/enums";

const Snapshot = () => {
  let today = new Date();
  let day = today.getDate();
  let month = today.toLocaleString("default", { month: "long" });
  let year = today.getFullYear();
  const [go, setGo] = useState(false);
  const [skillOptions, setSkillOptions] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const dateFormat = "YYYY/MM/DD";
  const selectedSkills = useRef([]);
  const selectedBu = useRef([]);
  const [departmentsArray, setDepartmentsArray] = useState([]);

  var token = getToken();
  useEffect(() => {
    getSkills()
      .then(() => {
        getDepatments();
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, []);

  const getSkills = async () => {
    await AxiosConfig.get("skills", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const tempSkillArr: any = [];
        res.data.data.skills.map((skill: any) =>
          tempSkillArr.push({ label: skill.name, value: skill.id })
        );
        setSkillOptions(tempSkillArr);
        selectedSkills.current = tempSkillArr;
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const handleChangeSkill = (val: any) => {
    if (val.length == 0) {
      selectedSkills.current = skillOptions;
    } else {
      selectedSkills.current = val;
    }
  };
  const handleChangeBu = (val: any) => {
    if (val.length == 0) {
      selectedBu.current = departmentsArray;
    } else {
      selectedBu.current = val;
    }
  };
  const getDepatments = async () => {
    await AxiosConfig.get("departments", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const tempDept: any = [];
        res.data.data.departments.map(
          (row: { name: any; id: any; flag: any }) => {
            if (row.flag == 1)
              tempDept.push({
                label: row.name,
                value: row.id,
              });
          }
        );
        setDepartmentsArray(tempDept);
        selectedBu.current = tempDept;
        setFirstLoad(true);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const dateRef = useRef(day + " " + month + " " + year);
  const getCurrentDate = (value: any) => {
    dateRef.current =
      value.getDate() +
      " " +
      value.toLocaleString("default", { month: "long" }) +
      " " +
      value.getFullYear();
  };
  localStorage.setItem("Date", day + " " + month + " " + year);

  return (
    <>
      <Row gutter={16}>
        <Col className="displayDate" xs={24} sm={24} md={12} lg={12} xl={12}>
          <p>{dateRef.current}</p>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Row gutter={16}>
            <Col xs={6} sm={6} md={7} lg={7} xl={7}>
              <DatePicker
                defaultValue={moment()}
                format={dateFormat}
                placeholder="Enter the  Date"
                name="sale_date"
                onChange={(val: any) => {
                  let newDate = new Date(val);
                  dateRef.current =
                    newDate.getDate() +
                    " " +
                    newDate.toLocaleString("default", { month: "long" }) +
                    " " +
                    newDate.getFullYear();
                  getCurrentDate(newDate);
                  localStorage.setItem("Date", dateRef.current);
                }}
                className="TalentDatePicker input--field"
              />
            </Col>
            <Col xs={7} sm={7} md={6} lg={7} xl={7} className="skillsFilter">
              <ReactMultiSelectCheckboxes
                options={skillOptions}
                onChange={handleChangeSkill}
                placeholderButtonLabel="Skill Set Filter"
                style={{ width: "100%" }}
              />
            </Col>
            <Col
              xs={5}
              sm={5}
              md={6}
              lg={7}
              xl={7}
              className="departmentsFilter-snapshot"
            >
              <ReactMultiSelectCheckboxes
                options={departmentsArray}
                onChange={handleChangeBu}
                placeholderButtonLabel="Department"
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={6} sm={6} md={5} lg={3} xl={3}>
              <ButtonReuse
                type="primary"
                value="Go"
                className="primary-btn primary-btn-go"
                onClick={() => {
                  setGo(!go);
                  setFirstLoad(!firstLoad);
                }}
                size="small"
              ></ButtonReuse>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="teamSizeGraphCard"
        >
          <Card className="teamSizeGraph">
            {(firstLoad || go) && (
              <TeamSize
                selectedDate={dateRef.current}
                selectedSkills={selectedSkills.current}
                selectedBu={selectedBu.current}
              />
            )}
            <Row>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                style={{ display: "grid", placeItems: "center" }}
              >
                {(firstLoad || go) && (
                  <UtilizationGraph
                    daysGap={30}
                    selectedDate={dateRef.current}
                    selectedSkills={selectedSkills.current}
                    selectedBu={selectedBu.current}
                  />
                )}
                <p>{snapshot.labelFor30Utilization}</p>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                style={{ display: "grid", placeItems: "center" }}
              >
                {(firstLoad || go) && (
                  <UtilizationGraph
                    daysGap={90}
                    selectedDate={dateRef.current}
                    selectedSkills={selectedSkills.current}
                    selectedBu={selectedBu.current}
                  />
                )}
                <p>{snapshot.labelFor90Utilization}</p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="bulgeGraphCard">
          <Card className="bulgeGraph">
            {(firstLoad || go) && (
              <StackedAxisChart
                selectedDate={dateRef.current}
                selectedSkills={selectedSkills.current}
                selectedBu={selectedBu.current}
              />
            )}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Card className="skillBasedGraph">
            {(firstLoad || go) && (
              <TeamSizeBySkill
                selectedDate={dateRef.current}
                selectedSkills={selectedSkills.current}
                selectedBu={selectedBu.current}
              />
            )}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Card className="attritionProgress">
            {(firstLoad || go) && (
              <CircularProgressChart
                selectedDate={dateRef.current}
                selectedSkills={selectedSkills.current}
                selectedBu={selectedBu.current}
              />
            )}
          </Card>
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          {(firstLoad || go) && (
            <TopUtilised
              selectedDate={dateRef.current}
              selectedSkills={selectedSkills.current}
              selectedBu={selectedBu.current}
            />
          )}
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          {(firstLoad || go) && (
            <BottomUtilised
              selectedDate={dateRef.current}
              selectedSkills={selectedSkills.current}
              selectedBu={selectedBu.current}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Snapshot;
