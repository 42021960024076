import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { Table, Input, Row, Col, Select, Typography } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import "./MonthlySales.scss";
import moment from "moment";
import { CSVLink } from "react-csv";
import { ExportOutlined } from "@ant-design/icons";
import { formatCurrencyValue, formatCurrencyValueDecimal, replaceString } from "../../../utils/util";
import { useSelector } from "react-redux";

const MonthlySales = (props: { location: { state?: any } }) => {
  const token = getToken();
  const months = [
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ];
  let initialSalesInfo = {
    month: "",
    year: 2020,
    departments_id_array: [],
    po_type_id_array: [],
  };
  const po_id_map: any = {
    1: "Fixed Price",
    2: "Time & Material",
    3: "Retainer",
  };

  const [selectedYear, setSelectedYear] = useState(2020);
  const [selectedDeptName, setSeletedDeptName] = useState([]);
  const [selectedPoName, setSelectedPoName] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [nameSearch, setNameSearch] = useState("");
  const [totalSoldValue, setTotalSoldValue] = useState(0);
  const [totalDirectExpense, setDirectExpense] = useState(0);
  const [totalStusSold, setTotalStusSold] = useState(0);
  const [salesInfo, setSalesInfo] = useState(initialSalesInfo);
  const [projectNameList, setProjectNameList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [poNameList, setPoNameList] = useState([]);
  const [poTypeList, setPoTypeList] = useState([]);

  const { Option } = Select;
  const { Text, Title } = Typography;

  const history = useHistory();
  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );

  let symbol = currencySymb?.currency_symbol;

  const checkNumber = (number: any) => {
    if (number === null) {
      return "0";
    } else {
      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
      }).format(number);
    }
  };

  const getSalesInfo = () => {
    console.warn(props.location.state);
    initialSalesInfo = props.location.state;
    setSelectedYear(initialSalesInfo.year);
    setSalesInfo(initialSalesInfo);
  };

  const getSalesData = async () => {
    let data = { ...salesInfo, year: selectedYear };
    await AxiosConfig.post("/monthlysales", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const data = res.data.data.monthlySales;
        const tempArr: any = [];
        const tempProjectNameSet: any = new Set();
        const tempDepartmentNameSet: any = new Set();
        const tempPoNameSet: any = new Set();
        const tempPoTypeSet: any = new Set();
        const tempProjectNameList: any = [];
        const tempDepartmentNameList: any = [];
        const tempPoNameList: any = [];
        const tempPoTypeList: any = [];
        let totalDirectExpenser: any = 0;
        data.map((value: any) => {
          totalDirectExpenser = totalDirectExpenser + value.direct_expense;
          let tempData: any = {
            project_code: value.project.project_code,
            project_name: value.project.name,
            department_name: value.project.department.name,
            po_name: value.name,
            po_type: value.potype.type,
            direct_expense: value.direct_expense,
            sale_date: moment(value.sale_date).format("YYYY/MM/DD"),
            sold: formatCurrencyValueDecimal(
              currencySymb?.currency_code,
              value?.value_in_base
            ),
            sold_value: currencyFormat(value.value_in_base),
            stus_sold: checkNumber(value.stus_sold),
            pending_value: formatCurrencyValueDecimal(
              currencySymb?.currency_code,
              value?.value_in_base - value?.stus_sold
            ),
            pending: currencyFormat(value.value_in_base - value.stus_sold),
            delete_action: value.project.id,
          };
          tempArr.push(tempData);
          tempProjectNameSet.add(tempData.project_name);
          tempDepartmentNameSet.add(tempData.department_name);
          tempPoNameSet.add(tempData.po_name);
          tempPoTypeSet.add(tempData.po_type);
        });

        setDirectExpense(totalDirectExpenser);
        setSalesData(tempArr);
        setFilterData(tempArr);
        Array.from(tempProjectNameSet).forEach((projectName: any) => {
          tempProjectNameList.push({
            text: projectName,
            value: projectName,
          });
        });
        Array.from(tempDepartmentNameSet).forEach((departmentName: any) => {
          tempDepartmentNameList.push({
            text: departmentName,
            value: departmentName,
          });
        });
        Array.from(tempPoNameSet).forEach((poName: any) => {
          tempPoNameList.push({
            text: poName,
            value: poName,
          });
        });
        Array.from(tempPoTypeSet).forEach((poType: any) => {
          tempPoTypeList.push({
            text: poType,
            value: poType,
          });
        });
        setProjectNameList(tempProjectNameList);
        setDepartmentList(tempDepartmentNameList);
        setPoNameList(tempPoNameList);
        setPoTypeList(tempPoTypeList);
      })
      .catch((err) => console.log(err));
  };

  const handleOnChange = (event: any) => {
    setNameSearch(event.target.value);
  };

  const handleChange = (value: any) => {
    const [month, year] = value.split(",");
    setSelectedYear(parseInt(year));
    setSalesInfo({ ...salesInfo, month: month });
    props.location.state = salesInfo;
  };

  const onClickRow = (record: any) => {
    history.push("/projects/" + record);
    localStorage.setItem("projectsId", record);
  };

  const currencyFormat = (number: any) => {
    if (number === null) {
      return "$0.00";
    } else {
      return (
        "$" +
        new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(number)
      );
    }
  };
  const monthySalesHeaders = [
    { label: "PROJECT CODE", key: "project_code" },
    { label: "PROJECT NAME", key: "project_name" },
    { label: "DEPARTMENT NAME", key: "department_name" },
    { label: "PO NAME", key: "po_name" },
    { label: "PO TYPE", key: "po_type" },
    { label: "SALE DATE", key: "sale_date" },
    { label: "SOLD VALUE", key: "sold_value" },
    { label: "SOLD STUS", key: "stus_sold" },
    { label: "$ PENDING", key: "pending" },
  ];

  useEffect(() => {
    if (currencySymb?.currency_code) {
      getSalesInfo();
      getDeparments();
      getPo();
    }
  }, [currencySymb?.currency_code]);

  useEffect(() => {
    if (currencySymb?.currency_code) {
      getSalesData();
    }
  }, [salesInfo, currencySymb?.currency_code]);

  useEffect(() => {
    if (currencySymb?.currency_code) {
      if (salesData) {
        const results: any = salesData.filter(
          (item: any) =>
            item.project_name
              .toLowerCase()
              .includes(nameSearch.toLowerCase()) ||
            item.department_name
              .toLowerCase()
              .includes(nameSearch.toLowerCase()) ||
            item.po_name.toLowerCase().includes(nameSearch.toLowerCase()) ||
            item.po_type.toLowerCase().includes(nameSearch.toLowerCase)
        );
        setFilterData(results);
      }
    }
  }, [nameSearch, currencySymb?.currency_code]);

  useEffect(() => {
    if (currencySymb?.currency_code) {
      let totalValue = 0;
      let totalStus = 0;
      filterData.forEach((data: any) => {
        totalValue += parseFloat(
          data.sold_value.replace("$", "").replaceAll(",", "")
        );
        totalStus += parseFloat(data.stus_sold.replaceAll(",", ""));
      });
      setTotalSoldValue(totalValue);
      setTotalStusSold(totalStus);
    }
  }, [filterData, currencySymb?.currency_code]);

  const getDeparments = () => {
    const deptIdArr = initialSalesInfo.departments_id_array;
    const tempArr: any = [];
    let departments: any = localStorage.getItem("Departments");
    departments = JSON.parse(departments);
    deptIdArr.forEach((dept: any) => {
      let dept_name: any = undefined;
      departments.some((deparment: any) => {
        if (deparment.id === dept) {
          dept_name = deparment.name;
          return true;
        }
      });
      tempArr.push(dept_name);
    });
    setSeletedDeptName(tempArr);
  };

  const getPo = () => {
    const tempPoArr: any = [];
    initialSalesInfo.po_type_id_array.forEach((po: any) => {
      tempPoArr.push(po_id_map[po]);
    });
    setSelectedPoName(tempPoArr);
  };

  const columns: any = [
    {
      title: "PROJECT CODE",
      dataIndex: ["project_code", "delete_action"],
      key: "project_code",
      render: (text: any, record: any) => (
        <a
          onClick={() => {
            onClickRow(record["delete_action"]);
          }}
        >
          {record["project_code"]}
        </a>
      ),
      sorter: (a: any, b: any) => a.project_code.localeCompare(b.project_code),
    },
    {
      title: "PROJECT NAME",
      dataIndex: "project_name",
      key: "project_name",
      filters: projectNameList,
      onFilter: (value: any, record: any) => {
        console.log(record);
        return record.project_name.includes(value);
      },
      sorter: (a: any, b: any) => a.project_name.localeCompare(b.project_name),
    },
    {
      title: "DEPARTMENT NAME",
      dataIndex: "department_name",
      key: "department_name",
      filters: departmentList,
      onFilter: (value: any, record: any) =>
        record.department_name.includes(value),
      sorter: (a: any, b: any) =>
        a.department_name.localeCompare(b.department_name),
    },
    {
      title: "PO NAME",
      dataIndex: "po_name",
      key: "po_name",
      filters: poNameList,
      onFilter: (value: any, record: any) => record.po_name.includes(value),
      sorter: (a: any, b: any) => a.po_name.localeCompare(b.po_name),
    },
    {
      title: "PO TYPE",
      dataIndex: "po_type",
      key: "po_type",
      filters: poTypeList,
      onFilter: (value: any, record: any) => record.po_type.includes(value),
      sorter: (a: any, b: any) => a.po_type.localeCompare(b.po_type),
    },
    {
      title: "SALE DATE",
      dataIndex: "sale_date",
      key: "sale_date",
      sorter: (a: any, b: any) =>
        new Date(a.sale_date).getTime() - new Date(b.sale_date).getTime(),
    },
    {
      className: "textAlignCurrency",
      title: "SOLD VALUE",
      dataIndex: "sold",
      key: "sold_value",
      sorter: (a: any, b: any) => replaceString(a.sold) - replaceString(b.sold),
    },
    {
      className: "textAlignCurrency",
      title: "SOLD STUS",
      dataIndex: "stus_sold",
      key: "stus_sold",
      sorter: (a: any, b: any) =>
        replaceString(a.stus_sold) - replaceString(b.stus_sold),
    },
    {
      className: "textAlignCurrency",
      title: "PENDING",
      dataIndex: "pending_value",
      key: "pending",
      sorter: (a: any, b: any) =>
        replaceString(a.pending_value) - replaceString(b.pending_value),
    },
  ];

  return (
    <div className="table-card">
      <div className="mb20">
        <div className="tab-contents">
          <Row className="mb20">
            <Col md={4} xs={24}>
              <Title
                level={3}
                style={{ display: "inline", marginRight: "1rem" }}
              >
                {salesInfo.month} {selectedYear}
              </Title>
              <InfoCircleTwoTone
                className="showBuDetailSales"
                style={{ display: "inline-block" }}
              />
              <p id="buInfoSales" style={{ fontSize: "0.7rem" }}>
                <b>Associated BU: </b>
                <ul>
                  {selectedDeptName.map((dept_name: any, index: any) => (
                    <li key={index}>{dept_name}</li>
                  ))}
                </ul>
                <b>Associated PO</b>
                <ul>
                  {selectedPoName.map((po_name: any, index: any) => (
                    <li key={index}>{po_name}</li>
                  ))}
                </ul>
              </p>
            </Col>
            <Col md={4} xs={24}>
              <Select
                placeholder="Select the Month"
                style={{ width: "90%" }}
                onChange={handleChange}
              >
                {months.map((month: any, index: any) =>
                  index <= 8 ? (
                    <Option value={`${month}, ${salesInfo.year}`} key={index}>
                      {month}, {salesInfo.year}
                    </Option>
                  ) : (
                    <Option
                      value={`${month}, ${salesInfo.year + 1}`}
                      key={index}
                    >
                      {month}, {salesInfo.year + 1}
                    </Option>
                  )
                )}
              </Select>
            </Col>
            <Col md={13} xs={24}>
              <div className="searchForm">
                <form>
                  <Input.Search
                    allowClear
                    onChange={handleOnChange}
                    placeholder="Search by project name/department name/po name/ po type"
                  />
                </form>
              </div>
            </Col>
            <Col className="textAlignRight" md={3} xs={24}>
              <label className="totalLabel">Total #: {filterData.length}</label>
            </Col>
          </Row>
          <Row
            className="white-bg-widget mb20"
            style={{ paddingBlock: "1rem" }}
          >
            <Col md={8} xs={24}>
              <Text type="secondary" className="textHeading pr20">
                Sold
              </Text>{" "}
              <b className="textValue">
                {formatCurrencyValue(
                  currencySymb?.currency_code,
                  totalSoldValue
                )}
              </b>
            </Col>
            <Col md={8} xs={24}>
              <Text type="secondary" className="textHeading pr20">
                STU&apos;s Sold{" "}
              </Text>
              <b className="textValue">{checkNumber(totalStusSold)}</b>
            </Col>

            <Col md={8} xs={24}>
              <Text type="secondary" className="textHeading pr20">
                Avg {symbol} /STU
              </Text>{" "}
              <b className="textValue">
                {formatCurrencyValue(
                  currencySymb?.currency_code,
                  totalStusSold
                ) === "0" || totalSoldValue - totalDirectExpense === 0
                  ? `${symbol} 0`
                  : formatCurrencyValue(
                      currencySymb?.currency_code,
                      (totalSoldValue - totalDirectExpense) / totalStusSold
                    )}
              </b>
            </Col>
          </Row>
          <div className="tableOut noWordBreak scrollabarChange">
            <div className="text-end">
              <CSVLink
                data={filterData}
                target="_blank"
                filename={"monthly_sales.csv"}
                headers={monthySalesHeaders}
                className="exportTableButton mb-5"
                title="Export Table"
              >
                <ExportOutlined className="exportOultineIcon" />
              </CSVLink>
            </div>
            {salesData && (
              <Table
                columns={columns}
                dataSource={filterData}
                bordered
                className="talentTable"
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "30"],
                }}
                scroll={{ x: 1400, y: 500 }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthlySales;
