import React, { useState, useEffect } from "react";
import { Button, Table, Row, Col } from "antd";
import { EditTwoTone } from "@ant-design/icons";
import AddOverlay from "./AddExpenses";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import EditOverlay from "./EditExpenses";
import { ColumnType } from "antd/lib/table";
import { enumForExpenses } from "../../../utils/enums";
type ExpenseItem = {
  id: number;
  reference_no: string;
  vendor_name: string;
  amount: number;
  unaccounted_amount: number;
  notes: string;
  bill_date: string;
  pending_amount: number;
};
const Expenses = () => {
  const [editVisible, setEditVisible] = useState(false);
  const [expensesDetail, setExpenseDetail] = useState<ExpenseItem>();

  const [visible, setVisible] = useState<boolean>(false);
  const [expensesData, setExpensesData] = useState<ExpenseItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalUnaccountedAmount, setTotalUnaccountedAmount] = useState(0);
  const [ExepnseId, setExepnseId] = useState(0);

  var token = getToken();

  useEffect(() => {
    fetchExpensesData();
  }, []);

  useEffect(() => {
    const total = expensesData.reduce((acc, item) => acc + item.amount, 0);
    setTotalAmount(total);
  }, [expensesData]);
  useEffect(() => {
    const unaccountedTotal = expensesData.reduce(
      (acc, item) => acc + item.pending_amount,
      0
    );
    setTotalUnaccountedAmount(unaccountedTotal);
  }, [expensesData]);

  const getExpenseDetail = (expenses) => {
    setExpenseDetail(expenses);
  };



  const visibleHandler = (pageType?: string, expenseid?) => {
    if (pageType === "edit") {
      setEditVisible(true);
      setExepnseId(expenseid);
    } else {
      setVisible(true);
    }
  };

  const closeHandler = (pageType?: string) => {
    if (pageType === "edit") {
      setEditVisible(false);
    } else {
      setVisible(false);
    }
  };
  const fetchExpensesData = async () => {
    try {
      const response = await AxiosConfig.get("expense", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const expenseData = response.data.data.expenses;
      setExpensesData(
        expenseData.map(
          (row: {
            id: number;
            reference_no: string;
            vendor_name: string;
            amount: number;
            notes: string;
            bill_date: string;
            unaccounted_amount: number;
            pending_amount: number;
          }) => {
            const date = new Date(row.bill_date);
            const formattedDate = date.toISOString().split("T")[0];
            return {
              id: row.id,
              reference_no: row.reference_no,
              vendor_name: row.vendor_name,
              amount: row.amount,
              notes: row.notes,
              unaccounted_amount: row.unaccounted_amount,
              bill_date: formattedDate,
              pending_amount: (row.amount - row.unaccounted_amount),
            };
          }
        )
      );
    } catch (error) {
      console.error("Error fetching expenses data:", error);
    } finally {
      setLoading(false);
    }
  };

 const columns: ColumnType<ExpenseItem>[] = [
   {
     title: "Reference No.",
     dataIndex: "reference_no",
     key: "reference_no",
   },
   {
     title: "Vendor Name",
     dataIndex: "vendor_name",
     key: "vendor_name",
   },
   {
     title: "Bill Date",
     dataIndex: "bill_date",
     key: "bill_date",
     sorter: (a, b) => {
       const dateA = new Date(a.bill_date).getTime();
       const dateB = new Date(b.bill_date).getTime();
       return dateA - dateB;
     },
   },
   {
     title: "Amount",
     dataIndex: "amount",
     key: "amount",
     sorter: (a, b) => a.amount - b.amount,
     render: (text) => (
       <div style={{ textAlign: "right" }}>
         {new Intl.NumberFormat("en-IN", {
           style: "currency",
           currency: "INR",
         }).format(text)}
       </div>
     ),
   },
   {
     title: "Notes",
     dataIndex: "notes",
     key: "notes",
   },
   {
     title: "Unaccounted Amount",
     dataIndex: "pending_amount",
     key: "pending_amount",
     sorter: (a, b) => a.pending_amount - b.pending_amount,
     render: (text) => (
       <div style={{ textAlign: "right" }}>
         {new Intl.NumberFormat("en-IN", {
           style: "currency",
           currency: "INR",
         }).format(text)}
       </div>
     ),
   },
   {
     title: "Edit",
     key: "actions",
     dataIndex: "id",

     render: (record: any) => (
       <>
         <div className="actionBtns" style={{ cursor: "pointer" }}>
           <EditTwoTone
             onClick={() => {
               getExpenseDetail(record);
               visibleHandler("edit", record);
             }}
           />
         </div>
       </>
     ),
   },
 ];

  return (
    <div className="table-card">
      <Row>
        <Col
          className="AddExpensesButton"
          md={24}
          xs={24}
          style={{ textAlign: "right" }}
        >
          <Button
            type="primary"
            className="primary-btn"
            onClick={() => setVisible(true)}
            style={{ marginBottom: "10px" }}
          >
            {enumForExpenses.buttonForAddExpense}
          </Button>
        </Col>
      </Row>
      <AddOverlay
        visible={visible}
        onClose={() => setVisible(false)}
        onCancelButton={() => setVisible(false)}
      />
      {editVisible &&
          (
          <EditOverlay
            visible={editVisible}
            onClose={() => closeHandler("edit")}
            onCancelButton={() => closeHandler("edit")}
            expenseId={ExepnseId}
            expensesDetail={expensesDetail}
          ></EditOverlay>
        )}
      <div className="tableOut noWordBreak">
        <Table
          columns={columns}
          dataSource={expensesData}
          loading={loading}
          bordered
          scroll={{ x: 300, y: 680 }}
          footer={() => (
            <div>
              <strong>{enumForExpenses.labelForTotalAmount}</strong>
              {new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
              }).format(totalAmount)}
              <div>
                <strong>
                  {enumForExpenses.labelForTotalUnaccountedAmount}
                </strong>
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                }).format(totalUnaccountedAmount)}
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default Expenses;
