import { Tabs } from "antd";

import SnapshotProjectDetailView from "./snapshotProjectDetailView";
import { SupersetEmbedComponent } from "../../../components";

const SnapshotProject = () => {
  const { TabPane } = Tabs;

  return (
    <Tabs className="BoldStyling" defaultActiveKey="1">
      <TabPane tab="Project Management Dashboard" key="1">
        <SupersetEmbedComponent
          pageType="project_management"
          isDepartmentEnabled={false}
        />
      </TabPane>

      <TabPane tab="Detail View" key="2">
        <SnapshotProjectDetailView />
      </TabPane>
    </Tabs>
  );
};

export default SnapshotProject;
