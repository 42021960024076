import { useState, useEffect } from "react";
import Assistant from "../../assistantAI/assistant";
import "../../assistantAI/assistant.css";
import TableComponent from "../../assistantAI/tableComponent";
import { Row, Col, Space, Tooltip } from "antd";
import {
  DoubleLeftOutlined,
  PauseOutlined,
  // DoubleRightOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
const AIAssistant = () => {
  const defaultWidth = "700px";
  const defaultHeight = "700px";

  const defaultStyle = {
    width: defaultWidth,
    height: defaultHeight,
    backgroundColor: "white",
    borderRadius: "15px",
    boxShadow: "0 4px 8px rgba(12, 6, 43, 0.3)",
  };

  const [assistantStyle, setAssistantStyle] = useState(defaultStyle);
  const [tableStyle, setTableStyle] = useState(defaultStyle);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [infoContent, setInfoContent] = useState("");
  const [trainedDateTime, setTrainedDateTime] = useState("");

  useEffect(() => {
    fetchTrainingDetails();
  }, []);
  const fetchTrainingDetails = async () => {
    try {
      const response = await fetch(
        "https://traccar-dev-ai.spurtreetech.com/api/assistant/models",
        {
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjMsImlhdCI6MTY4Mzg3MjM1NywiZXhwIjoxNjg0NDc3MTU3fQ.orfZD4WB7uesMP_oROxel7wnHnnpglgRa44nhCEat44",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch assistant models");
      }
      const data = await response.json();
      const formattedInfo = data.data.responses.map((response) => {
        const updatedAt = new Date(response.updatedAt).toLocaleString();
        return ` ${response.type}, Last Trained on: ${updatedAt}`;
      });
      setInfoContent(formattedInfo.join("\n"));
      setTrainedDateTime(formattedInfo.join(", "));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching assistant models:", error);
      setLoading(false);
    }
  };

  const handleLeftButtonClick = () => {
    setAssistantStyle({
      ...defaultStyle,
      width: "100px",
      position: "relative",
    });
    setTableStyle({
      ...defaultStyle,
      width: "1500px",
      position: "relative",
    });
  };
  const handleCenterButtonClick = () => {
    setAssistantStyle({
      ...defaultStyle,
    });
    setTableStyle({
      ...defaultStyle,
    });
  };
  // const handleRightButtonClick = () => {
  //   setAssistantStyle({
  //     ...defaultStyle,
  //     width: "1500px",
  //     position: "relative",
  //   });
  //   setTableStyle({
  //     ...defaultStyle,
  //     width: "100px",
  //     position: "relative",
  //   });
  // };
  const clearTableData = () => {
    setTableData(null);
  };
  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <Row>
            <Col md={8} xs={24} lg={16} xl={16} offset={{ xl: 16 }} style={{marginLeft : "15px"}}>
              <Space>
                <DoubleLeftOutlined onClick={handleLeftButtonClick} />
                <PauseOutlined onClick={handleCenterButtonClick} />
                {/* <DoubleRightOutlined onClick={handleRightButtonClick} /> */}
              </Space>
            </Col>
          </Row>
          <div className="container">
            <div className="row1">
              <div
                className="col-md-6 "
                style={{ ...assistantStyle, overflow: "auto" }}
              >
                <Assistant
                  tableData={tableData}
                  setTableData={setTableData}
                  clearTableData={clearTableData}
                />
              </div>
            </div>
            <div className="row2">
              <div className="col-md-6" style={tableStyle}>
                <TableComponent Tabledata={tableData} />
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              margintop: "5px",
              textAlign: "center",
            }}
          >
            <div style={{ display: "inline-block" }}>
              <Row>
                <Col>
                  <Space>
                    <Tooltip title={infoContent} placement="top">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Space>
                </Col>
                <Col style={{ marginLeft: "10px" }}>
                  <p>{trainedDateTime}</p>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AIAssistant;
