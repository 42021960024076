import "antd/dist/antd.css";
import { Col, Divider, Drawer, Modal, Switch } from "antd";
import Select from "react-select";
import { EditProject } from "../../../utils/enums";
import { Formik } from "formik";
import * as yup from "yup";
import { AxiosConfig } from "../../../ApiConfig";
import { InputField } from "../../../components";
import ButtonReuse from "../../../components/Buttons/Button";
import { useState, useEffect, useRef } from "react";
import { getToken } from "../../../auth";
import { notification, Row } from "antd";
import { useParams } from "react-router-dom";
require("./EditProject.scss");
import EditProjectsAttachments from "./EditProjectAttachments";
import UploadProjectsAttachment from "./UploadProjectAttachment";
import { getLocation } from "../../../stores/actions/getLocationAction";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import { InfoCircleTwoTone } from "@ant-design/icons";

interface IProjectOwner {
  id: number;
  is_active: boolean;
  start_date: Date;
  end_date: Date;
  project_id: number;
  owner_id: number;
  user: {
    id: number;
    firstName: string;
    lastName: string;
  };
}

const AddOverlay = (props: {
  visible?: any;
  onClose?: any;
  onCancelButton?: any;
  attachmentIds?: any;
}) => {
  const validationSchema = yup.object({
    project_name: yup.string().trim().required("Please enter the project name"),
    geo_id: yup.object().required("please select the Geo Location"),
    project_owners: yup.array().of(
      yup.object({
        owner_id: yup
          .number()
          .notOneOf([0])
          .required("Please select an project owner"),
      })
    ),
  });

  let { id }: any = useParams();

  let projectAttachmentIds = props.attachmentIds;

  const onCancelHandler = () => {
    props.onCancelButton();
  };
  var token = getToken();

  const [customers, setCustomers] = useState([]);
  const [location, setLocation] = useState([]);
  const [locationId, setLocationId] = useState();
  const [users, setUsers] = useState([]);
  const [projectCode, setProjectCode] = useState();
  const [projectName, setProjectName] = useState();
  const [customerName, setCustomerName] = useState();
  const [billableEntityData, setBillableEntityData] = useState();
  const [billableEntityId, setBillableEntityId] = useState();
  const [customerId, setCustomerId] = useState();
  const [associatedBU, setAssociatedBU] = useState();
  const [associatedBUId, setAssociatedBUId] = useState();
  const [billableOptionDefault, setBillableOptionDefault] = useState({});
  const [manager, setManager] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [billableGeoOption, setBillableGeoOption] = useState([]);
  const [projectOwners, setProjectOwners] = useState<IProjectOwner[]>([]);
  const singleProjectOwnerActive = useRef(true);

  const monthFormat = "MM/yyyy";

  const dispatch: any = useDispatch();
  const geoLocationData = useSelector((store: any) => store.locations.location);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    dispatch(getLocation());
  }, [dispatch]);

  useEffect(() => {
    if (geoLocationData) {
      setLocation(geoLocationData);
    }
  }, [geoLocationData]);

  const getData = async () => {
    try {
      const res = await AxiosConfig.get(`projects/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      setProjectName(res.data.data.project.name);
      setCustomerName(res.data?.data?.project?.customer?.name);
      setBillableEntityData(
        res.data?.data?.project?.billableentity?.entity_name
      );
      setBillableEntityId(res.data?.data?.project?.billable_id);
      setCustomerId(res.data?.data?.project?.customer?.id);
      setLocationId(res.data?.data?.project?.Geography?.id);
      setAssociatedBU(res.data.data.project.department.name);
      setAssociatedBUId(res.data.data.project.department.id);
      setProjectCode(res.data.data.project.project_code);
      setManager(res.data.data.project.users);
      if (res.data.data.project.billable_option == true) {
        setBillableOptionDefault({ label: "Billable", value: 1 });
      } else if (res.data.data.project.billable_option == false) {
        setBillableOptionDefault({ label: "Non-Billable", value: 1 });
      } else {
        setBillableOptionDefault({});
      }
      setLocationData(res.data?.data?.project?.Geography?.location);
      setProjectOwners(res.data.data.project.ProjectOwners);
      if (!res.data.data.project.ProjectOwners.length) {
        singleProjectOwnerActive.current = false;
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    getBillableGeo();
  }, []);

  const getCustomers = async () => {
    await AxiosConfig.get("customers", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setCustomers(res.data.data.customers);
    });
  };

  let obj: any = localStorage.getItem("Departments");
  let departments = JSON.parse(obj);
  let filteredBu = [];
  if (departments === null) {
    filteredBu = [];
  } else {
    filteredBu = departments;
  }

  const getUsers = async () => {
    await AxiosConfig.get("users", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setUsers(res.data);
    });
  };

  const getBillableGeo = async () => {
    try {
      const response = await AxiosConfig.get("billableentity", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const billableOptions = response.data.data.billableEntity.map(
        (billable_option) => ({
          value: billable_option.entity_name,
          label: billable_option.entity_name,
          key: billable_option.id,
        })
      );

      setBillableGeoOption(billableOptions);
    } catch (error) {
      console.error("Error fetching billable options:", error);
    }
  };

  const customerOptions = customers.map(function (customer: {
    name: string;
    label: string;
    id: number;
  }) {
    return {
      value: customer.name,
      label: customer.name,
      key: customer.id,
    };
  });

  const locationOptions = location.map(function (Locations: {
    location: any;
    id: any;
  }) {
    return {
      value: Locations.id,
      label: Locations.location,
    };
  });

  const associatedBuOptions = filteredBu.map(function (associatedBus: {
    name: any;
    label: any;
    id: any;
  }) {
    return {
      value: associatedBus.name,
      label: associatedBus.name,
      key: associatedBus.id,
    };
  });

  const managerOptions = users.map(function (manager: {
    firstName: any;
    lastName: any;
    label: any;
    id: any;
  }) {
    return {
      label: manager.firstName + " " + manager.lastName,
      value: manager.id,
    };
  });
  const billableValues = [
    { key: 1, name: "Billable" },
    { key: 0, name: "Non-Billable" },
  ];
  const billableOptions = billableValues.map(function (billableBU: {
    name: any;
    key: any;
  }) {
    return {
      value: billableBU.name,
      label: billableBU.name,
      key: billableBU.key,
    };
  });

  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const showEditModal = () => {
    setIsModalVisibleEdit(true);
  };
  const handleCancelModalEdit = () => {
    setIsModalVisibleEdit(false);
  };
  const [editImage, setEditImage] = useState<any>([]);
  let image_arr: any = [];
  let edit_image_arr: any = [];

  useEffect(() => {
    projectAttachmentIds.forEach(function (row: any) {
      const attachment_id = row.attachment_id;
      getAttachments(attachment_id);
    });
  }, [projectAttachmentIds]);

  const getAttachments = (attachment_id: any) => {
    AxiosConfig.get("projects/attachments/" + attachment_id, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    }).then((res) => {
      let image_obj: any = {};
      const url = URL.createObjectURL(res.data);
      image_obj["id"] = attachment_id;
      image_obj["url"] = url;
      edit_image_arr.push(image_obj);
      image_arr.push(url);
      setEditImage(edit_image_arr);
    });
  };

  return (
    <>
      <Drawer
        title="Edit Project"
        width={600}
        onClose={props.onClose}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        className="edit_project_form"
      >
        <Formik
          initialValues={{
            customer_id: { label: customerName, value: customerId },
            geo_id: { label: locationData, value: locationId },
            project_name: projectName,
            billable_option: billableOptionDefault,
            billable_id: { lable: billableEntityData, value: billableEntityId },
            associated_bu_id: { label: associatedBU, value: associatedBUId },
            project_code: projectCode,
            managers_id_array: manager.map(
              (row: { firstName: any; id: any; lastName: any }) => {
                return {
                  label: row.firstName + "" + row.lastName,
                  value: row.id,
                };
              }
            ),
            project_owners: projectOwners.map(
              ({ owner_id, start_date, end_date, is_active }, index) => ({
                owner_id: owner_id,
                is_active,
                start_date: new Date(start_date),
                end_date: new Date(end_date),
                is_disable: !(index === projectOwners.length - 1),
              })
            ),
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            if (!singleProjectOwnerActive.current) {
              notification.open({
                message: "",
                description: "One project owner must be in active state",
                style: { color: "red" },
              });
            } else {
              const projectOwnerList = values.project_owners.map(
                ({ start_date, end_date, is_active, owner_id }, index) => ({
                  id: projectOwners[index]
                    ? projectOwners[index].id
                    : undefined,
                  owner_id,
                  start_date: start_date.toISOString(),
                  end_date: end_date.toISOString(),
                  is_active,
                })
              );
              let diffValues: any = {};
              let id_array: any = [];
              for (const element in values) {
                if (element === "project_owners") {
                  diffValues[element] = projectOwnerList;
                } else if (
                  values[element].value &&
                  !Array.isArray(values[element])
                ) {
                  diffValues[element] = values[element].value;
                } else if (Array.isArray(values[element])) {
                  values[element].forEach((item: any) => {
                    id_array.push(item.value);
                  });
                  diffValues[element] = id_array;
                } else {
                  diffValues[element] = values[element];
                }
              }
              AxiosConfig.put(`projects/${id}`, diffValues, {
                headers: { Authorization: `Bearer ${token}` },
              })
                .then((response) => {
                  if (response.status == 200) {
                    const notify = () => {
                      notification.open({
                        message: response.data.status.message,
                      });
                    };
                    {
                      notify();
                    }
                    setTimeout(function () {
                      window.location.reload();
                    }, 1000);
                  }
                })
                .catch(function (error) {
                  const notify = () => {
                    notification.open({
                      message: "",
                      description: error.response?.data?.status?.message
                        ? typeof error.response.data.status.message === "string"
                          ? error.response.data.status.message
                          : error.response.data.status.message.details[0].message.replaceAll(
                              '"',
                              ""
                            )
                        : "Unknown error occurred",
                      style: { color: "red" },
                    });
                  };
                  {
                    notify();
                  }
                });
            }
          }}
        >
          {({ handleSubmit, handleChange, errors, values, setFieldValue }) => (
            <form onSubmit={handleSubmit} className="add_project_form">
              <label>{EditProject.labelForCustomerName} </label>
              <Select
                options={customerOptions}
                onChange={(value: { key: any }) =>
                  setFieldValue("customer_id", {
                    label: "Selected Value",
                    value: value.key,
                  })
                }
                defaultValue={{ label: customerName, value: customerId }}
                name="customer_id"
                placeholder="Select customer name"
              />

              <p className="display_error">{errors.customer_id}</p>

              <label>{EditProject.labelForProjectName}</label>

              <InputField
                placeholder="Enter Project Name"
                name="project_name"
                onChange={handleChange}
                value={values.project_name}
                className="input--field--edit"
              ></InputField>
              <p className="display_error">{errors.project_name}</p>

              <label>{EditProject.labelForProjectCode}</label>

              <InputField
                name="project_code"
                value={projectCode}
                className="input--field--edit"
                disabled={true}
              ></InputField>
              <label>{EditProject.labelForAssociatedBU}</label>
              <Select
                placeholder="Select Associated BU"
                options={associatedBuOptions}
                onChange={(value: { key: any }) =>
                  setFieldValue("associated_bu_id", {
                    label: "Selected Value",
                    value: value.key,
                  })
                }
                name="associated_bu_id"
                defaultValue={{ label: associatedBU, value: associatedBUId }}
              />
              <p className="display_error">{errors.associated_bu_id}</p>

              <label>{EditProject.labelForProjectManagers}</label>
              <Select
                isMulti
                isClearable
                options={managerOptions}
                placeholder="Select Manager name"
                name="managers_id_array"
                defaultValue={manager.map(
                  (row: { firstName: any; id: any; lastName: any }) => {
                    return {
                      label: row.firstName + " " + row.lastName,
                      value: row.id,
                    };
                  }
                )}
                onChange={(selectedValue: any) =>
                  setFieldValue(
                    "managers_id_array",
                    selectedValue.map((row: any) => {
                      return {
                        label: row.label,
                        value: row.value,
                      };
                    })
                  )
                }
              />
              <p className="display_error">{errors.managers_id_array}</p>

              <label>{EditProject.labelForBillableorNonBillable}</label>
              <Select
                placeholder="Billable/Non-Billable"
                options={billableOptions}
                onChange={(value: { key: any }) =>
                  setFieldValue("billable_option", value.key)
                }
                name="billable_option"
                defaultValue={billableOptionDefault}
              />
              <p className="display_error">{errors.billable_option}</p>
              <label>{EditProject.labelForBillableOptions}</label>
              <Select
                placeholder="Billable Entity"
                options={billableGeoOption}
                onChange={(value: { key: number }) => {
                  setFieldValue("billable_id", value.key);
                }}
                name="billableGeoOption"
                defaultValue={{
                  label: billableEntityData,
                }}
              />
              <label>{EditProject.labelForGeoLocation}</label>
              <Select
                placeholder="Select location"
                options={locationOptions.filter(
                  (option) => option.value !== locationId
                )}
                onChange={(value: { value: number }) =>
                  setFieldValue("geo_id", {
                    label: "Selected Value",
                    value: value.value,
                  })
                }
                name="geo_id"
                defaultValue={{ label: locationData, value: locationId }}
              />
              <p className="display_error">{errors.geo_id}</p>

              <label>{EditProject.labelForAttachments}</label>
              <Row>
                <ButtonReuse
                  value="Edit"
                  type="submit"
                  className="edit-image-button"
                  onClick={showEditModal}
                />
                <Modal
                  title="Attachments"
                  className="AttachmentsModal"
                  visible={isModalVisibleEdit}
                  onCancel={handleCancelModalEdit}
                >
                  <UploadProjectsAttachment projectId={id} />
                  <EditProjectsAttachments images={editImage} />
                </Modal>
              </Row>
              <Row>
                <Col span={14}>
                  <label>{EditProject.labelForProjectOwner}</label>
                </Col>
                <Col span={10}>
                  <div
                    className={`${
                      values.project_owners.length &&
                      (values.project_owners[values.project_owners.length - 1]
                        .is_active ||
                        moment(
                          values.project_owners[
                            values.project_owners.length - 1
                          ].end_date
                        ).format(monthFormat) === "12/9999")
                        ? 'disabled-button'
                        : 'enabled-button'
                    } button-wrapper`}
                    title={
                      values.project_owners.length &&
                      (values.project_owners[values.project_owners.length - 1]
                        .is_active ||
                        moment(
                          values.project_owners[
                            values.project_owners.length - 1
                          ].end_date
                        ).format(monthFormat) === "12/9999")
                        ? "Cannot add owner when the last owner is active or has no end date"
                        : "Add Owner"
                    }
                    onClick={() => {
                      if (
                        values.project_owners.length &&
                        (values.project_owners[values.project_owners.length - 1]
                          .is_active ||
                          moment(
                            values.project_owners[
                              values.project_owners.length - 1
                            ].end_date
                          ).format(monthFormat) === "12/9999")
                      ) {
                        return;
                      }
                      singleProjectOwnerActive.current = true;
                      const newProjectOwners = [...values.project_owners];
                      const currentProjectOwnerCount = newProjectOwners.length;
                      if (currentProjectOwnerCount) {
                        newProjectOwners[
                          currentProjectOwnerCount - 1
                        ].is_disable = true;
                        newProjectOwners.push({
                          owner_id: 0,
                          start_date: new Date(
                            moment(
                              values.project_owners[
                                currentProjectOwnerCount - 1
                              ].end_date
                            )
                              .add(1, "day")
                              .format("YYYY-MM-DD")
                          ),
                          end_date: new Date("9999-12-31"),
                          is_active: true,
                          is_disable: false,
                        });
                      } else {
                        newProjectOwners.push({
                          owner_id: 0,
                          start_date: new Date(
                            moment(new Date())
                              .startOf("month")
                              .format("YYYY-MM-DD")
                          ),
                          end_date: new Date("9999/12/31"),
                          is_active: true,
                          is_disable: false,
                        });
                      }

                      setFieldValue("project_owners", newProjectOwners);
                    }}
                  >
                    <ButtonReuse
                      type="primary"
                      className={`primary-btn ${
                        values.project_owners.length &&
                        (values.project_owners[values.project_owners.length - 1]
                          .is_active ||
                          moment(
                            values.project_owners[
                              values.project_owners.length - 1
                            ].end_date
                          ).format(monthFormat) === "12/9999")
                          ? "edit-disabled-button"
                          : ""
                      }`}
                      value="Add Owner"
                      disabled={
                        !!(
                          values.project_owners.length &&
                          (values.project_owners[values.project_owners.length - 1].is_active ||
                            moment(
                              values.project_owners[values.project_owners.length - 1].end_date
                            ).format(monthFormat) === "12/9999")
                        )
                      }                      
                    />
                    {values.project_owners.length &&
                    (values.project_owners[values.project_owners.length - 1]
                      .is_active ||
                      moment(
                        values.project_owners[values.project_owners.length - 1]
                          .end_date
                      ).format(monthFormat) === "12/9999") ? (
                      <InfoCircleTwoTone
                        className="info-btn"
                        title="Cannot add owner when the last owner is active or has no end date"
                      />
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Divider />
              {values.project_owners.map(
                (
                  { is_active, is_disable, owner_id, start_date, end_date },
                  index: number
                ) => (
                  <div key={index} className="mb10">
                    <Select
                      isDisabled={is_disable}
                      options={managerOptions}
                      defaultValue={
                        projectOwners[index]
                          ? {
                              value: projectOwners[index].user.id,
                              label: `${projectOwners[index].user.firstName} ${projectOwners[index].user.lastName}`,
                            }
                          : undefined
                      }
                      placeholder="Select Project Owner name"
                      onChange={(value: { value: number }) =>
                        setFieldValue(
                          `project_owners[${index}].owner_id`,
                          value.value
                        )
                      }
                    />
                    <Row className="Spacing">
                      <Col span={4}>
                        {" "}
                        <Switch
                          defaultChecked={is_active}
                          onChange={(checked) => {
                            singleProjectOwnerActive.current = checked;
                            setFieldValue(
                              `project_owners[${index}].is_active`,
                              checked
                            );
                          }}
                          disabled={is_disable || !owner_id}
                        />
                      </Col>
                      <Col span={10}>
                        <DatePicker
                          disabled={is_disable}
                          showMonthYearPicker
                          showFullMonthYearPicker
                          showTwoColumnMonthYearPicker
                          minDate={
                            values.project_owners.length > 1
                              ? new Date(
                                  moment(
                                    values.project_owners[
                                      values.project_owners.length - 2
                                    ].end_date
                                  )
                                    .add(1, "day")
                                    .format("YYYY-MM-DD")
                                )
                              : undefined
                          }
                          maxDate={end_date}
                          selected={start_date}
                          onChange={(date) => {
                            if (date) {
                              setFieldValue(
                                `project_owners[${index}].start_date`,
                                date
                              );
                            }
                          }}
                          dateFormat={monthFormat}
                        />
                      </Col>
                      <Col span={10}>
                        <DatePicker
                          disabled={is_disable}
                          onChange={(date) => {
                            if (date) {
                              date = new Date(
                                moment(date).endOf("month").format("YYYY-MM-DD")
                              );
                              setFieldValue(
                                `project_owners[${index}].end_date`,
                                date
                              );
                            }
                          }}
                          minDate={start_date}
                          selected={end_date}
                          dateFormat={monthFormat}
                          showMonthYearPicker
                          showFullMonthYearPicker
                          showTwoColumnMonthYearPicker
                        />
                      </Col>
                      <p className="display_error">
                        {errors.project_owners
                          ? index === values.project_owners.length - 1
                            ? "Please select an project owner"
                            : undefined
                          : undefined}
                      </p>
                    </Row>
                  </div>
                )
              )}
              <div className="button-container">
                <ButtonReuse
                  type="primary"
                  className="primary-btn"
                  htmlType="submit"
                  value="Update"
                ></ButtonReuse>
                <ButtonReuse
                  type="primary"
                  className="primary-btn cancel--btn"
                  value="Cancel"
                  onClick={onCancelHandler}
                ></ButtonReuse>
              </div>
            </form>
          )}
        </Formik>
      </Drawer>
    </>
  );
};

export default AddOverlay;
