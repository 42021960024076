import React, { useState, useEffect } from "react";
import "./ExpensesDrawer.scss";
import { enumForExpensesDrawer } from "../../../utils/enums";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { Row, Col, Divider } from "antd";
import { FileExclamationOutlined } from "@ant-design/icons";
import Loader from "../../../components/Loader/Loader";
import dayjs from "dayjs";

const ExpensesInfo = (props: { poDetails?: any; activeKey?: string }) => {
  var token = getToken();

  const [expenseData, setExpensesData] = useState<any>({
    expenseData: {},
    totalAmt: null,
  });
  const [loading, isLoading] = useState(true);

  const groupAndSumExpenses = (expenses) => {
    return expenses.reduce((result, expense) => {
      const key = `${expense.expense.expense_type}_${expense.financialmonth.month}_${expense.financialmonth.year}`;
      if (result[key]) {
        result[key].amount += expense.amount;
      } else {
        result[key] = {
          ...expense,
        };
      }

      return result;
    }, {});
  };

  useEffect(() => {
    const fetchExpensesData = async () => {
      try {
        const response = await AxiosConfig.get(
          `purchaseorders/expense/${props.poDetails?.id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const poExpense: any = response.data.data;
        let newObj = {};

        for (let [key, value] of Object.entries(poExpense)) {
          const valObj = Object.entries(value as any).map(([, i]) => {
            const updatedObj = groupAndSumExpenses(i);
            return Object.keys(updatedObj).length ? updatedObj : null;
          });
          if (key != "total_amount") {
            newObj[key] = valObj;
          }
        }
        isLoading(false);
        setExpensesData({
          expenseData: newObj,
          totalAmt: poExpense.total_amount,
        });
      } catch (error) {
        console.error("Error fetching expenses data:", error);
      }
    };

    fetchExpensesData();
  }, [props.poDetails]);

  return (
    <div>
      <h2 className="expense-text">{enumForExpensesDrawer.labelForExpense}</h2>
      <div>
        {loading ? (
          <Loader />
        ) : (
          <div>
            {Object.keys(expenseData.expenseData).length === 0 ? (
              <div className="no-expense-div">
                <FileExclamationOutlined className="no-expense-image" />
                <p>{enumForExpensesDrawer.labelForNoExpenses}</p>
              </div>
            ) : (
              Object.entries(expenseData.expenseData)?.map(
                ([key, value]: any) => {
                  return (
                    <div key={key} className="expense-item">
                      <Row>
                        <Col span={24}>
                          <Divider type="horizontal" orientation="left">
                            <p>{key}</p>
                          </Divider>
                        </Col>
                      </Row>
                      {value.map((each, i) => {
                        const expense: any = Object.values(each)[0];
                        return (
                          <>
                            <div key={i} className="expense-item">
                              {expense.financialmonth && (
                                <div>
                                  <Row>
                                    <Col span={8}>
                                      <p className="heading-text">
                                        {
                                          enumForExpensesDrawer.labelForTypeOfExpense
                                        }
                                      </p>
                                      <p className="details-font">
                                        {expense.expense.expense_type}
                                      </p>
                                      <p className="heading-text">
                                        {
                                          enumForExpensesDrawer.labelForVendorName
                                        }
                                      </p>
                                      <p className="details-font">
                                        {expense.expense.vendor_name}
                                      </p>
                                    </Col>
                                    <Col span={8}>
                                      <p className="heading-text">
                                        {
                                          enumForExpensesDrawer.labelForAdjustedAmount
                                        }
                                      </p>
                                      <p className="details-font">
                                        {
                                          enumForExpensesDrawer.labelForCurrencySymbol
                                        }
                                        &nbsp;
                                        {expense.amount.toLocaleString("en-IN")}
                                      </p>
                                      <p className="heading-text">
                                        {enumForExpensesDrawer.labelForBillDate}
                                      </p>
                                      <p className="details-font">
                                        {dayjs(
                                          expense.expense.bill_date
                                        ).format("YYYY/MM/DD")}
                                      </p>
                                    </Col>
                                    <Col span={8}>
                                      <p className="heading-text">
                                        {
                                          enumForExpensesDrawer.labelForRefernceNo
                                        }
                                      </p>
                                      <p className="details-font">
                                        {expense.expense.reference_no}
                                      </p>
                                      <p className="heading-text">
                                        {enumForExpensesDrawer.labelForNotes}
                                      </p>
                                      <p className="details-font">
                                        {expense.expense.notes}
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              )}
                            </div>
                            {value.length > 1 && i < value.length - 1 && (
                              <hr />
                            )}
                          </>
                        );
                      })}
                    </div>
                  );
                }
              )
            )}
            <h2 className="total-adjusted-text">
              {enumForExpensesDrawer.labelForTotalAdjusted} &nbsp;
              {enumForExpensesDrawer.labelForCurrencySymbol} &nbsp;
              {expenseData?.totalAmt?.toLocaleString("en-IN")}
            </h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpensesInfo;
