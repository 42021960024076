import {
  Card,
  Col,
  Row,
  Typography,
  Button,
  Table,
  Dropdown,
  Menu,
  Space,
  Checkbox,
  notification,
} from "antd";
import BasicInfo from "../../ProjectDetails/BasicInfo";
import "./AllocationDetails.scss";
import AddOverlay from "../AllocationDetails/ManageTalent";
import arrow from "../../../../assets/images/arrow.png";
import { AxiosConfig } from "../../../../ApiConfig";
import { useState, useEffect, Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getToken } from "../../../../auth";
import ProjectStuSummary from "./ProjectStuSummary";
import editImage from "../../../../assets/images/edit.png";
import EditAllocation from "../../Project/AllocationDetails/Edit_Allocation";
import Loader from "../../../../components/Loader/Loader";
import {
  convertNumber,
  checkColor,
  convertNumberToAmounts,
  convertAmountToNumberForVariance,
  formatCurrencyValue,
} from "../../../../utils/util";
import { useSelector } from "react-redux";

import "../AllocationDetails/AllocationDetails.scss";
import { allocationDetails } from "../../../../utils/enums";
import { DownOutlined } from "@ant-design/icons";

export interface IProjectOwner {
  id: number;
  is_active: boolean;
  start_date: Date;
  end_date: Date;
  project_id: number;
  owner_id: number;
  user: {
    id: number;
    firstName: string;
    lastName: string;
  };
}

const AllocationDetails = () => {
  const history = useHistory();
  const [projectMonth, setProjectMonth] = useState({
    ThisMonth: "",
    NextMonth: "",
    FutureMonth: "",
    ThisMonthYear: String,
    NextMonthYear: String,
    FutureMonthYear: String,
    ThisMonthFinancialMonthId: Number,
    NextMonthFinancialMonthId: Number,
    FutureMonthFinancialMonthId: Number,
  });

  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );
  const [currmonth, setmonth] = useState();
  const [projectName, setProjectName] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [associatedBU, setDepartment] = useState("");
  const [isBillable, setIsBillable] = useState("");
  const [locationData, setLocationData] = useState([]);
  const [billabaleGeoOption, setBillableGeoOption] = useState();
  const [projectCode, setProjectCode] = useState("");
  const [managers, setManagers] = useState([]);
  const [stusSold, setStuSold] = useState();
  const [allocatedStus, setallocatedStus] = useState("");
  const [stusExecuted, setStusExecuted] = useState();
  const [getTalentData, setTalentData] = useState();
  const [attachmentIds, setAttachmentIds] = useState([]);
  const [visible, setVisible] = useState(false);
  const [talentAllocation, setTalentAllocation] = useState([]);
  const [getThismonthTotalStu, setThismonthTotalStu] = useState(0);
  const [getThismonthTotalHours, setThismonthTotalHours] = useState(0);
  const [getNextmonthTotalStu, setNextmonthTotalStu] = useState(0);
  const [getNextmonthTotalHours, setNextmonthTotalHours] = useState(0);
  const [getFuturemonthTotalStu, setFuturemonthTotalStu] = useState(0);
  const [getFuturemonthTotalHours, setFuturemonthTotalHours] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [projectOwners, setProjectOwners] = useState<IProjectOwner[]>([]);
  const [projectDeptId, setProjectDeptId] = useState(0);
  const [selectedMonths, setSelectedMonths] = useState<any[]>([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  let ThismonthTotalStu = 0;
  let ThismonthTotalHours = 0;
  let NextmonthTotalStu = 0;
  let NextmonthTotalHours = 0;
  let FuturemonthTotalStu = 0;
  let FuturemonthTotalHours = 0;

  const [dataSource, setDataSource] = useState([
    {
      Name: "Earned STU's",
      thisMonth: 0,
      nextMonth: 0,
      futureMonth: 0,
    },
  ]);
  const [visibleEdit, setVisibleEdit] = useState(false);

  useEffect(() => {
    getMonthsId();
  }, [currencySymb?.currency_code]);

  useEffect(() => {
    getAttachments();
  }, []);
  const token = getToken();

  const closeHandler = () => {
    setVisible(false);
  };
  const closeHandler1 = () => {
    setVisibleEdit(false);
  };
  const visibleHandler = () => {
    setVisible(true);
  };
  const onClickRow = () => {
    history.push("/projects/" + id);
  };
  const getMonthsId = async () => {
    await AxiosConfig.get("financialmonths", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      const currentMonth: any = res?.data?.data?.months.filter(
        (month: any) => month.status === "1"
      );
      setmonth(currentMonth[0].id);
      getTalentAllocation(currentMonth[0].id);
    });
  };

  const { id }: any = useParams();

  const getAttachments = async () => {
    try {
      const res = await AxiosConfig.get(`projects/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const projectValue =
        res?.data?.data?.project?.billableentity?.entity_name;
      if (projectValue) {
        setBillableGeoOption(projectValue);
        setAttachmentIds(
          res?.data?.data?.project?.projectattachments?.map(
            (row: { id: number; attachment_id: number }) => ({
              id: row?.id,
              attachment_id: row?.attachment_id,
            })
          ) || []
        );
      }
    } catch (error) {
      console.error("Error fetching attachments:", error);
    }
  };
  const { Text } = Typography;

  const getTalentAllocation = async (monthid: any) => {
    const res = await AxiosConfig.get(`allocations/${id}?month_id=` + monthid, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const projectData = res?.data?.data?.project[0];
    const MonthData = res?.data?.data?.months;
    if (projectData) {
      setProjectDeptId(projectData);
    }
    if (MonthData.length >= 0) {
      setIsLoading(false);
    }
    setProjectMonth({
      ThisMonth: res?.data?.data?.months[0]?.ThisMonth,
      NextMonth: res?.data?.data?.months[0]?.NextMonth,
      FutureMonth: res?.data?.data?.months[0]?.FutureMonth,
      ThisMonthYear: res?.data?.data?.months[0]?.ThisMonthYear,
      NextMonthYear: res?.data?.data?.months[0]?.NextMonthYear,
      FutureMonthYear: res?.data?.data?.months[0]?.FutureMonthYear,
      ThisMonthFinancialMonthId:
        res?.data?.data?.months[0]?.ThisMonthFinancialMonthId,
      NextMonthFinancialMonthId:
        res?.data?.data?.months[0]?.NextMonthFinancialMonthId,
      FutureMonthFinancialMonthId:
        res?.data?.data?.months[0]?.FutureMonthFinancialMonthId,
    });

    const temp = {};

    res?.data?.data?.TalentAllocationDetails.map((row: any) => {
      const {
        talent: {
          emp_id,
          firstName,
          lastName,
          current_band_id,
          band: { name },
        },

        financialmonth: { month },
        allocated_percentage,
        allocated_stus,
        hours,
      } = row;
      if (!temp[emp_id]) {
        temp[emp_id] = {
          Name: `${firstName} ${lastName}`,
          Employee_id: emp_id,
          Employee_band: current_band_id,
          Employee_bandName: name,
          TalentData: {
            First_Name: firstName,
            Last_Name: lastName,
            Talent_id: emp_id,
          },
          [month]: [
            `${convertNumber(allocated_percentage, 2)} %`,
            convertNumber(allocated_stus, 2),
            convertNumber(hours, 2),
          ],
        };

        if (res?.data?.data?.months[0]?.ThisMonth === [month][0]) {
          ThismonthTotalStu = ThismonthTotalStu + allocated_stus;
          ThismonthTotalHours = ThismonthTotalHours + hours;
        }
        if (res?.data?.data?.months[0]?.NextMonth === [month][0]) {
          NextmonthTotalStu = NextmonthTotalStu + allocated_stus;
          NextmonthTotalHours = NextmonthTotalHours + hours;
        }
        if (res?.data?.data?.months[0]?.FutureMonth === [month][0]) {
          FuturemonthTotalStu = FuturemonthTotalStu + allocated_stus;
          FuturemonthTotalHours = FuturemonthTotalHours + hours;
        }
      } else {
        temp[emp_id][month] = [
          `${convertNumber(allocated_percentage, 2)} %`,
          convertNumber(allocated_stus, 2),
          convertNumber(hours, 2),
        ];
        if (res?.data?.data?.months[0]?.ThisMonth === [month][0]) {
          ThismonthTotalStu = ThismonthTotalStu + allocated_stus;
          ThismonthTotalHours = ThismonthTotalHours + hours;
        }
        if (res?.data?.data?.months[0]?.NextMonth === [month][0]) {
          NextmonthTotalStu = NextmonthTotalStu + allocated_stus;
          NextmonthTotalHours = NextmonthTotalHours + hours;
        }
        if (res?.data?.data?.months[0]?.FutureMonth === [month][0]) {
          FuturemonthTotalStu = FuturemonthTotalStu + allocated_stus;
          FuturemonthTotalHours = FuturemonthTotalHours + hours;
        }
      }
    });
    setThismonthTotalStu(ThismonthTotalStu);
    setThismonthTotalHours(ThismonthTotalHours);
    setNextmonthTotalStu(NextmonthTotalStu);
    setNextmonthTotalHours(NextmonthTotalHours);
    setFuturemonthTotalStu(FuturemonthTotalStu);
    setFuturemonthTotalHours(FuturemonthTotalHours);

    Object.values(temp).forEach(() => {});
    setTalentAllocation(Object.values(temp));

    if (
      Array.isArray(res?.data?.data?.project) &&
      res?.data?.data?.project.length
    ) {
      setDataSource([
        {
          Name: "Revenue Earned",
          thisMonth: formatCurrencyValue(
            currencySymb?.currency_code,
            res?.data?.data?.project[0]?.PresentMonthRevenue
          ),
          nextMonth: formatCurrencyValue(
            currencySymb?.currency_code,
            res?.data?.data?.project[0]?.NextMonthRevenue
          ),
          futureMonth: formatCurrencyValue(
            currencySymb?.currency_code,
            res?.data?.data?.project[0]?.FutureMonthRevenue
          ),
        },
        {
          Name: "Earned STU's",
          thisMonth: convertNumberToAmounts(
            res?.data?.data?.project[0]?.ThisMonthstus_executed,
            2
          ),
          nextMonth: convertNumberToAmounts(
            res?.data?.data?.project[0]?.NextMonthstus_executed,
            2
          ),
          futureMonth: convertNumberToAmounts(
            res?.data?.data?.project[0]?.FutureMonthstus_executed,
            2
          ),
        },
        {
          Name: "Planned STU's",
          thisMonth: ThismonthTotalStu.toFixed(2),
          nextMonth: NextmonthTotalStu.toFixed(2),
          futureMonth: FuturemonthTotalStu.toFixed(2),
        },
      ]);
      setProjectName(res?.data?.data?.project[0]?.name);
      setCustomerName(res?.data?.data?.project[0]?.customer.name);
      setProjectCode(res?.data?.data?.project[0]?.project_code);
      setLocationData(res?.data?.data?.project[0]?.Geography?.location);
      setManagers(res?.data?.data?.project[0]?.users);
      setProjectOwners(res.data.data.project[0].ProjectOwners);

      if (res?.data?.data?.project[0]?.billable_option === true) {
        setIsBillable("True");
      } else if (res?.data?.data?.project[0]?.billable_option === false) {
        setIsBillable("False");
      } else {
        setIsBillable("Not Selected");
      }

      setDepartment(res?.data?.data?.project[0]?.department.name);
      setStuSold(
        res?.data?.data?.project[0]?.stus_sold != null
          ? res?.data?.data?.project[0]?.stus_sold.toFixed(2)
          : "0"
      );
      const totalAlloc =
        Number(ThismonthTotalStu) +
        Number(NextmonthTotalStu) +
        Number(FuturemonthTotalStu);
      setallocatedStus(totalAlloc.toFixed(2));
      setStusExecuted(
        convertNumber(res?.data?.data?.project[0]?.stus_executed, 2)
      );
    }
  };
  const handleView = async (record: any) => {
    setTalentData(record);
    setVisibleEdit(true);
  };

  const columns1 =
    dataSource.length === 3
      ? [
          {
            title: "STU's",
            dataIndex: "Name",
            className: "recognized",
          },
          {
            title: `${String([projectMonth.ThisMonth]).substring(0, 3)} ' ${[
              projectMonth.ThisMonthYear,
            ]}`,
            dataIndex: "thisMonth",
            maximumFractionDigits: 2,
            className: "thisMonthRecognized",
            render: (text: any, record: any) => {
              const color = checkColor(
                dataSource[2].thisMonth,
                dataSource[1].thisMonth,
                record["Name"]
              );
              const thisMonthValue = record["thisMonth"];
              let additionalInfo = "";

              if (color === "orange") {
                additionalInfo = `Under Allocated by ${convertAmountToNumberForVariance(
                  dataSource[2].thisMonth,
                  dataSource[1].thisMonth
                )}%`;
              } else if (color === "red") {
                additionalInfo = `Over Allocated by ${convertAmountToNumberForVariance(
                  dataSource[2].thisMonth,
                  dataSource[1].thisMonth
                )}%`;
              }

              return (
                <Row>
                  <Col md={24} xs={24} style={{ color }}>
                    <p className="showVariancedetailThisMonth">
                      {thisMonthValue}
                    </p>
                    {additionalInfo && <p id="showColor">{additionalInfo}</p>}
                  </Col>
                </Row>
              );
            },
          },
          {
            title: `${String([projectMonth.NextMonth]).substring(0, 3)} ' ${[
              projectMonth.NextMonthYear,
            ]}`,
            dataIndex: "nextMonth",
            maximumFractionDigits: 2,
            className: "nextMonthRecognized",
            render: (text: any, record: any) => {
              const color = checkColor(
                dataSource[2].nextMonth,
                dataSource[1].nextMonth,
                record["Name"]
              );
              let content = "";

              if (color === "orange") {
                content = `Under Allocated by ${convertAmountToNumberForVariance(
                  dataSource[2].nextMonth,
                  dataSource[1].nextMonth
                )}%`;
              } else if (color === "red") {
                content = `Over Allocated by ${convertAmountToNumberForVariance(
                  dataSource[2].nextMonth,
                  dataSource[1].nextMonth
                )}%`;
              }

              return (
                <Row>
                  <Col md={24} xs={24} style={{ color }}>
                    <p className="showVariancedetailNextMonth">
                      {record["nextMonth"]}
                    </p>
                    {content && <p id="showColor">{content}</p>}
                  </Col>
                </Row>
              );
            },
          },
          {
            title: `${String([projectMonth.FutureMonth]).substring(0, 3)} ' ${[
              projectMonth.FutureMonthYear,
            ]}`,
            dataIndex: "futureMonth",
            maximumFractionDigits: 2,
            className: "futureMonthRecognized",
            render: (text: any, record: any) => {
              const color = checkColor(
                dataSource[2].futureMonth,
                dataSource[1].futureMonth,
                record["Name"]
              );

              const variancePercentage = convertAmountToNumberForVariance(
                dataSource[2].futureMonth,
                dataSource[1].futureMonth
              );

              let varianceText = "";

              if (color === "orange") {
                varianceText = `Under Allocated by ${variancePercentage}%`;
              } else if (color === "red") {
                varianceText = `Over Allocated by ${variancePercentage}%`;
              }

              return (
                <Row>
                  <Col md={24} xs={24} style={{ color }}>
                    <p className="showVariancedetailFutureMonth">
                      {record["futureMonth"]}
                    </p>
                    {varianceText && <p id="showColor">{varianceText}</p>}
                  </Col>
                </Row>
              );
            },
          },
        ]
      : [];

  const columns = talentAllocation.length
    ? [
        {
          title: "Employee Id",
          dataIndex: ["Employee_id"],
          className: "titles",
        },
        {
          title: "Name",
          dataIndex: ["Name"],
          className: "titles",
        },
        {
          title: "Band",
          dataIndex: ["Employee_bandName"],
          className: "titles",
          sorter: (a, b) =>
            parseInt(a.Employee_bandName.replace("B", "")) -
            parseInt(b.Employee_bandName.replace("B", "")),
        },
        {
          title: [
            `${String(projectMonth.ThisMonth).substring(0, 3)} ' ${
              projectMonth.ThisMonthYear
            }`,
          ],
          className: "thisMonth",

          children: [
            {
              title: "% Age",
              dataIndex: [projectMonth.ThisMonth],
              key: `${projectMonth.ThisMonth}% Age`,
              sorter: (a, b) => {
                const v1 = Array.isArray(a[projectMonth.ThisMonth])
                  ? parseFloat(
                      a[projectMonth.ThisMonth][0].replace("%", "").trim()
                    )
                  : 0;
                const v2 = Array.isArray(b[projectMonth.ThisMonth])
                  ? parseFloat(
                      b[projectMonth.ThisMonth][0].replace("%", "").trim()
                    )
                  : 0;
                return v1 - v2;
              },
              className: "titleThisMonth",
              render: (text: any) => <p>{Array.isArray(text) ? text[0] : 0}</p>,
            },
            {
              title: "Hours",
              className: "titleThisMonth",
              key: `${projectMonth.ThisMonth}Hours`,
              dataIndex: [projectMonth.ThisMonth],
              render: (text: any) => <p>{Array.isArray(text) ? text[2] : 0}</p>,
              sorter: (a, b) => {
                const v1 = Array.isArray(a[projectMonth.ThisMonth])
                  ? parseFloat(a[projectMonth.ThisMonth][2])
                  : 0;
                const v2 = Array.isArray(b[projectMonth.ThisMonth])
                  ? parseFloat(b[projectMonth.ThisMonth][2])
                  : 0;
                return v1 - v2;
              },
              maximumFractionDigits: 2,
            },
            {
              title: "STU's",
              className: "titleThisMonth",
              key: `${projectMonth.ThisMonth}STUs`,
              dataIndex: [projectMonth.ThisMonth],
              sorter: (a, b) => {
                const v1 = Array.isArray(a[projectMonth.ThisMonth])
                  ? parseFloat(a[projectMonth.ThisMonth][1])
                  : 0;
                const v2 = Array.isArray(b[projectMonth.ThisMonth])
                  ? parseFloat(b[projectMonth.ThisMonth][1])
                  : 0;
                return v1 - v2;
              },
              render: (text: any) => <p>{Array.isArray(text) ? text[1] : 0}</p>,
              maximumFractionDigits: 2,
            },
          ],
        },
        {
          title: [
            `${String(projectMonth.NextMonth).substring(0, 3)} ' ${
              projectMonth.NextMonthYear
            }`,
          ],
          className: "nextMonth",

          children: [
            {
              title: "% Age",
              className: "titleNextMonth",
              key: `${projectMonth.NextMonth}% Age`,
              dataIndex: [projectMonth.NextMonth],
              render: (text: any) => <p>{Array.isArray(text) ? text[0] : 0}</p>,
              sorter: (a, b) => {
                const v1 = Array.isArray(a[projectMonth.NextMonth])
                  ? parseFloat(
                      a[projectMonth.NextMonth][0].replace("%", "").trim()
                    )
                  : 0;
                const v2 = Array.isArray(b[projectMonth.NextMonth])
                  ? parseFloat(
                      b[projectMonth.NextMonth][0].replace("%", "").trim()
                    )
                  : 0;
                return v1 - v2;
              },
            },
            {
              title: "Hours",
              className: "titleNextMonth",
              key: `${projectMonth.NextMonth}Hours`,
              dataIndex: [projectMonth.NextMonth],
              render: (text: any) => <p>{Array.isArray(text) ? text[2] : 0}</p>,
              sorter: (a, b) => {
                const v1 = Array.isArray(a[projectMonth.NextMonth])
                  ? parseFloat(a[projectMonth.NextMonth][2])
                  : 0;
                const v2 = Array.isArray(b[projectMonth.NextMonth])
                  ? parseFloat(b[projectMonth.NextMonth][2])
                  : 0;
                return v1 - v2;
              },
              maximumFractionDigits: 2,
            },
            {
              title: "STU's",
              className: "titleNextMonth",
              key: `${projectMonth.NextMonth}STUs`,
              dataIndex: [projectMonth.NextMonth],
              render: (text: any) => <p>{Array.isArray(text) ? text[1] : 0}</p>,
              sorter: (a, b) => {
                const v1 = Array.isArray(a[projectMonth.NextMonth])
                  ? parseFloat(a[projectMonth.NextMonth][1])
                  : 0;
                const v2 = Array.isArray(b[projectMonth.NextMonth])
                  ? parseFloat(b[projectMonth.NextMonth][1])
                  : 0;
                return v1 - v2;
              },
              maximumFractionDigits: 2,
            },
          ],
        },
        {
          title: [
            `${String(projectMonth.FutureMonth).substring(0, 3)} ' ${
              projectMonth.FutureMonthYear
            }`,
          ],
          className: "futureMonth",

          children: [
            {
              title: "% Age",
              className: "titleFutureMonth",
              key: `${projectMonth.FutureMonth}% Age`,
              dataIndex: [projectMonth.FutureMonth],
              render: (text: any) => <p>{Array.isArray(text) ? text[0] : 0}</p>,
              sorter: (a, b) => {
                const v1 = Array.isArray(a[projectMonth.FutureMonth])
                  ? parseFloat(
                      a[projectMonth.FutureMonth][0].replace("%", "").trim()
                    )
                  : 0;
                const v2 = Array.isArray(b[projectMonth.FutureMonth])
                  ? parseFloat(
                      b[projectMonth.FutureMonth][0].replace("%", "").trim()
                    )
                  : 0;
                return v1 - v2;
              },
            },
            {
              title: "Hours",
              className: "titleFutureMonth",
              key: `${projectMonth.FutureMonth}Hours`,
              dataIndex: [projectMonth.FutureMonth],
              render: (text: any) => <p>{Array.isArray(text) ? text[2] : 0}</p>,
              sorter: (a, b) => {
                const v1 = Array.isArray(a[projectMonth.FutureMonth])
                  ? parseFloat(a[projectMonth.FutureMonth][2])
                  : 0;
                const v2 = Array.isArray(b[projectMonth.FutureMonth])
                  ? parseFloat(b[projectMonth.FutureMonth][2])
                  : 0;
                return v1 - v2;
              },
              maximumFractionDigits: 2,
            },
            {
              title: "STU's",
              className: "titleFutureMonth",
              key: `${projectMonth.FutureMonth}STUs`,
              dataIndex: [projectMonth.FutureMonth],
              render: (text: any) => <p>{Array.isArray(text) ? text[1] : 0}</p>,
              sorter: (a, b) => {
                const v1 = Array.isArray(a[projectMonth.FutureMonth])
                  ? parseFloat(a[projectMonth.FutureMonth][1])
                  : 0;
                const v2 = Array.isArray(b[projectMonth.FutureMonth])
                  ? parseFloat(b[projectMonth.FutureMonth][1])
                  : 0;
                return v1 - v2;
              },
              maximumFractionDigits: 2,
            },
          ],
        },
        {
          title: "Edit",
          dataIndex: ["TalentData"],
          render: (record: any) => (
            <>
              <div className="actionBtns">
                <span onClick={() => handleView(record)}>
                  <img className="Editimage" src={editImage} />
                </span>
              </div>
            </>
          ),
        },
      ]
    : [];

  const openNotificationForAllocation = () => {
    notification.open({
      message:
        "Resource Allocation cloned successfully for the financial month(s)",
    });
  };

  const handleConfirmClick = async () => {
    try {
      const projectOwner = projectOwners[0];
      if (projectOwner) {
        const { project_id } = projectOwner;

        const response = await AxiosConfig.post(
          `allocations/clone/${project_id}?from_month_id=${
            projectMonth.ThisMonthFinancialMonthId
          }&to_month_id=${selectedMonths.join(",")}`,
          null,

          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        
        if (response?.data &&response?.data?.data && response?.data?.data?.MissedAllocationDetails && response?.data?.data?.MissedAllocationDetails?.length > 0) {
          notification.open({
            message: "",
            description: "Allocation for the below resource(s) is not cloned as it is exceeding 100% for the month",
            style: { color: "red" },
          });
        }
  
        if (response.status === 200) {
          openNotificationForAllocation();
          window.setTimeout(function () {
            location.reload();
          }, 5000);
        } else {
          setIsSaving(false);
        }
  
        setSelectedMonths([]);
        setDropdownVisible(false);
      } else {
        console.error("No project found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelClick = () => {
    setSelectedMonths([]);
    setDropdownVisible(false);
  };

  const menu = (
    <Menu>
      <Menu.Item>
      <div className="cloneToText">{allocationDetails.buttonCloneTo}</div>
        <Checkbox.Group
          onChange={(checkedValues) => setSelectedMonths(checkedValues)}
          value={selectedMonths}
        >
          <Col className="monthOptions" span={12}>
            <Checkbox value={projectMonth.NextMonthFinancialMonthId}>
              {projectMonth.NextMonth}&nbsp;{projectMonth.NextMonthYear}
            </Checkbox>
          </Col>
          <Col span={12}>
            <Checkbox value={projectMonth.FutureMonthFinancialMonthId}>
              {projectMonth.FutureMonth}&nbsp;{projectMonth.FutureMonthYear}
            </Checkbox>
          </Col>
        </Checkbox.Group>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <div className="cardClone">
          <Button
            className="buttonStyle"
            type="primary"
            onClick={handleCancelClick}
          >
            {allocationDetails.buttonCancel}
          </Button>
          <Button
            className="buttonStyle"
            type="primary"
            onClick={handleConfirmClick}
            disabled={isSaving}
          >
            {allocationDetails.buttonConfirmClone}
          </Button>
        </div>
      </Menu.Item>
    </Menu>
  );

  const isTableEmpty = talentAllocation.length === 0;

  return (
    <Fragment>
      <Row gutter={24}>
        <Col xl={6} xs={22}>
          <div style={{ marginBottom: 15 }}>
            <span
              className="back-button"
              style={{
                fontSize: 16,
              }}
              onClick={() => history.push("/allocations")}
            >
              <img src={arrow} />
              {allocationDetails.backButton}
            </span>
          </div>
        </Col>
        <Col xl={24} xs={2}>
          <Button id="button1" onClick={onClickRow}>
            {allocationDetails.buttonForProjectDetails}
          </Button>
          <Button
            style={{
              float: "right",
              borderRadius: "4px",
              color: "#fff",
              border: "none",
              padding: "7px 24px",
              fontSize: "16px",
              height: "40px",
              background: "#5c67e5",
              marginBottom: "20px",
            }}
            onClick={visibleHandler}
          >
            {allocationDetails.buttonForManageTalent}
          </Button>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xl={6} xs={22}>
          <Card
            className="basic-info"
            bordered={false}
            style={{ width: "341px" }}
          >
            <BasicInfo
              projectNameParent={projectName}
              billablaeGeoOption={billabaleGeoOption}
              customerNameParent={customerName}
              associatedBUParent={associatedBU}
              projectCodeParent={projectCode}
              managersParent={managers}
              attachmentIds={attachmentIds}
              isBillable={isBillable}
              locationNameParent={locationData}
              editreq={false}
              flag={true}
              projectId={id}
              projectOwners={projectOwners}
            ></BasicInfo>
          </Card>
          <Row gutter={24}>
            <Col xl={8} xs={24}>
              <Card
                className="ProjectStuSummary-Info"
                bordered={false}
                style={{ width: "341px" }}
              >
                <ProjectStuSummary
                  parentStus={stusSold}
                  parentSales={allocatedStus}
                  exuctedSales={stusExecuted}
                ></ProjectStuSummary>
              </Card>
            </Col>
          </Row>
        </Col>
        {/* Loader implemention */}
        {isLoading === true ? (
          <Loader />
        ) : (
          <Col xl={18} xs={2}>
            <div>
              <Card>
                <Table
                  columns={columns1}
                  dataSource={dataSource}
                  bordered
                  className="TalentDetails"
                  pagination={false}
                />
              </Card>
            </div>
            <br />

            <Card>
              <div className="cardClone">
                <h2>{allocationDetails.headingForTalentAllocation}</h2>
                <div>
                  {isTableEmpty ? (
                    <></>
                  ) : (
                    <Space>
                      <Dropdown
                        overlay={menu}
                        trigger={["click"]}
                        visible={dropdownVisible}
                        onVisibleChange={(visible) =>
                          setDropdownVisible(visible)
                        }
                        placement="bottomRight"
                      >
                        <Button
                          className="cloneButton"
                          onClick={() => setDropdownVisible(!dropdownVisible)}
                        >
                          {allocationDetails.buttonCloneFrom}{" "}
                          {`${String([projectMonth.ThisMonth]).substring(
                            0,
                            3
                          )} ' ${[projectMonth.ThisMonthYear]}`}{" "}
                          <DownOutlined />
                        </Button>
                      </Dropdown>
                    </Space>
                  )}
                </div>
              </div>

              <div>
                <Table
                  columns={columns}
                  dataSource={talentAllocation}
                  bordered
                  className="TalentDetails"
                  pagination={false}
                  summary={() => (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} className="total">
                        {allocationDetails.textForTotal}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={1}
                        className="Summary"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={2}
                        className="Summary"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={3}
                        className="Summary"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell index={4} className="Summary">
                        <Text>
                          {getThismonthTotalHours !== 0
                            ? getThismonthTotalHours.toFixed(2)
                            : ""}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5} className="Summary">
                        <Text className="Summary">
                          {getThismonthTotalStu !== 0
                            ? getThismonthTotalStu.toFixed(2)
                            : ""}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={6}
                        className="Summary"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell index={7} className="Summary">
                        <Text className="Summary">
                          {getNextmonthTotalHours !== 0
                            ? getNextmonthTotalHours.toFixed(2)
                            : ""}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={8} className="Summary">
                        <Text className="Summary">
                          {getNextmonthTotalStu !== 0
                            ? getNextmonthTotalStu.toFixed(2)
                            : ""}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={9}
                        className="Summary"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell index={10} className="Summary">
                        <Text>
                          {getFuturemonthTotalHours !== 0
                            ? getFuturemonthTotalHours.toFixed(2)
                            : ""}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={11} className="Summary">
                        <Text>
                          {getFuturemonthTotalStu !== 0
                            ? getFuturemonthTotalStu.toFixed(2)
                            : ""}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                />
              </div>
            </Card>
          </Col>
        )}
      </Row>
      {projectName && (
        <>
          <AddOverlay
            visible={visible}
            onClose={closeHandler}
            onCancelButton={closeHandler}
            projectName={projectName}
            projectCode={projectCode}
            emp_id={getTalentData}
            id={id}
            projectMonth={projectMonth}
          ></AddOverlay>
          {visibleEdit && (
            <EditAllocation
              projectDeptId={projectDeptId}
              projectOwners={projectOwners}
              managersParent={managers}
              visible={visibleEdit}
              onClose={closeHandler1}
              onCancelButton={closeHandler1}
              projectName={projectName}
              projectCode={projectCode}
              id={id}
              talentData={getTalentData}
              monthid={currmonth}
            ></EditAllocation>
          )}
        </>
      )}
    </Fragment>
  );
};
export default AllocationDetails;
