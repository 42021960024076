import { Popover, Table, Tabs } from "antd";
import { useState, useRef, memo, useCallback, useEffect } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { Row, Col, Typography, Button } from "antd";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import AddOverlay from "./AddPurchaseOrder";
import PoDetailsOverlay from "./PurchaseOrderDetails";
import deleteImage from "../../../assets/images/delete.png";
import warning from "../../../assets/images/WarningOutlined.png";
import { purchaseOrderTable } from "../../../utils/enums";
import DeletePoModal from "./DeletePurchaseOrderModal";
import {
  convertAmountToNumber,
  convertNumber,
  checkNumber,
  decimalCheck,
  formatCurrencyValue,
  checkBase,
  parseValue,
  formatCurrencyValueDecimal,
} from "../../../utils/util";
import { useSelector } from "react-redux";

require("./PurchaseOrderTable.scss");

const PurchaseOrderTable = (props: {
  archive_status: any;
  associatedBuId: any;
  ProjectManagers: any;
  ProjectName: any;
  ProjectOwnerId: any;
}) => {
  const { Text } = Typography;
  const [show, setShow] = useState(false);
  const [rowId, setRowId] = useState();
  const [poRevenueData, setRevenuePoData] = useState([]);
  const [poTypeList, setPoTypeList] = useState([]);
  const [months, setMonths] = useState([]);
  const currencyName: any = useRef({});
  const currencySymbol: any = useRef({});
  const [isLoad, setLoad] = useState(false);
  const [poRevenueLoading, setPoRevenueLoading] = useState(true);
  const newMonthId: any = useRef();
  const { TabPane } = Tabs;
  var token = getToken();
  let { id }: any = useParams();
  const currencyRate: any = useRef({});
  const isArchive = props.archive_status;
  const [currentMonth, setCurrentMonth] = useState();
  const [currentYear, setCurrentYear] = useState();
  const [masterCurrency, setMasterCurrency] = useState([]);
  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );

  useEffect(() => {
    if (currencySymb?.currency_code) {
      getMonthsId();
    }
  }, [currencySymb?.currency_code]);

  const getMonthsId = async () => {
    AxiosConfig.get("financialmonths", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      const currentMonth = res.data.data.months.filter(
        (month: any) => month.status === "1"
      );

      setCurrentMonth(currentMonth[0].month);
      getProjectAllocation(currentMonth[0].id);
    });
  };

  //Get current Year
  const getProjectAllocation = async (monthid: any) => {
    AxiosConfig.get("allocations?month_id=" + monthid, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setCurrentYear(res.data.data.months[0].ThisMonthYear);
    });
  };

  const getMonthsData = async () => {
    await AxiosConfig.get("financialmonths", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setMonths(res.data.data.months);
      const currentMonth = res.data.data.months.filter(
        (month: any) => month.status === "1"
      );
      newMonthId.current = currentMonth[0].id;
    });
  };

  const Variance = (total_sold_base: any, accounted: any) => {
    total_sold_base = total_sold_base ?? 0;
    accounted = accounted ?? 0;
    let variance = total_sold_base - accounted;
    return variance;
  };
  const VarianceVal = (
    Executed: any,
    Pending: any,
    Thismonth: any,
    totalSold: any,
    total_sold_base: any,
    accounted: any
  ) => {
    Executed = Executed ?? 0;
    Pending = Pending ?? 0;
    Thismonth = Thismonth ?? 0;
    totalSold = totalSold ?? 0;
    total_sold_base = total_sold_base ?? 0;
    accounted = accounted ?? 0;
    let variance = total_sold_base - accounted;
    return variance;
  };
  const unaccounted = (
    total_sold: number,
    gained: number,
    revenue_accounted: number
  ) => {
    total_sold = total_sold ?? 0;
    gained = gained ?? 0;
    revenue_accounted = revenue_accounted ?? 0;
    let unaccounted = total_sold + gained - revenue_accounted;
    return unaccounted;
  };
  const unaccountedVal = (
    total_sold: any,
    gained: any,
    revenue_accounted: any
  ) => {
    total_sold = total_sold ?? 0;
    gained = gained ?? 0;
    revenue_accounted = revenue_accounted ?? 0;
    let unaccountedVal = total_sold + gained - revenue_accounted;
    return unaccountedVal;
  };

  const pending = (value: any, executed_revenue) => {
    value = value ?? 0;
    executed_revenue = executed_revenue ?? 0;
    const pending = value - executed_revenue;
    return pending;
  };
  const leadLagval = (Invoiced: number, Accounted_tilldate: number) => {
    const Recognized_tilldate = Accounted_tilldate ?? 0;
    const Value = Invoiced ?? 0;
    return Value - Recognized_tilldate;
  };

  const invoiceunaccounted = (
    value_in_base: number,
    gained: number,
    dropped: number,
    invoiced_base: number,
    planned_invoice_base: number
  ) => {
    const Value_In_Base = value_in_base ?? 0;
    const Planned_invoice_base = planned_invoice_base ?? 0;
    const Invoiced_base = invoiced_base ?? 0;
    const Dropped = dropped ?? 0;
    const Gained = gained ?? 0;

    return (
      Value_In_Base + Gained - Dropped - Invoiced_base - Planned_invoice_base
    );
  };
  const invoiceunaccountedval = (
    value: number,
    gained: number,
    dropped: number,
    invoiced: number,
    planned_invoice: number
  ) => {
    const Value = value ?? 0;
    const Planned_invoice = planned_invoice ?? 0;
    const Invoiced = invoiced ?? 0;
    const Dropped = dropped ?? 0;
    const Gained = gained ?? 0;

    return Value + Gained - Dropped - Invoiced - Planned_invoice;
  };

  const stu = (value_in_base: any, stus_sold: any) => {
    value_in_base = value_in_base ?? 0;
    stus_sold = stus_sold ?? 0;
    let stu = value_in_base / stus_sold;
    return stu;
  };
  const filteredMonths = months.filter((month: any) => {
    return month.status === "1" || month.status === "0";
  });
  var editableMonth = months.filter(function (row: {
    status: any;

    month: any;
  }) {
    if (row.status === "1") {
      return row.month;
    }
  });
  var activeYear = months.filter(function (row: {
    status: any;

    year: any;
  }) {
    if (row.status === "1") {
      return row.year;
    }
  });
  var monthName = editableMonth.map(function (months: { month: any }) {
    return months.month;
  });
  var year = activeYear.map(function (actYear: { year: any }) {
    return actYear.year;
  });
  let newMonth = monthName[0];
  let newYear = year[0];

  const getData = async () => {
    setPoRevenueLoading(true);
    await AxiosConfig.get("projects/poListing/" + id, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const tempPoTypeSet: any = new Set();
        const tempRevenuePoTypeSet: any = new Set();
        const tempPoTypeList: any = poTypeList;

        if (res && res?.data?.data?.PurchaseOrders) {
          res.data.data.PurchaseOrders = res?.data?.data?.PurchaseOrders.map(
            (obj) => {
              const parsedObj = {};
              for (let key in obj) {
                parsedObj[key] = parseValue(obj[key]);
              }
              return parsedObj;
            }
          );
        }
        setPoRevenueLoading(false);
        setRevenuePoData(
          res?.data?.data?.PurchaseOrders.map(
            (row: {
              currency_change_status: any;
              currency_id: any;
              currency_rate: any;
              desc: any;
              executed_revenue: number;
              executed_revenue_base: number;
              id: any;
              invoiced: number;
              invoiced_base: number;
              name: any;
              number: any;
              pending_revenue: any;
              pending_revenue_base: any;
              planned_invoice: any;
              planned_invoice_base: any;
              po_status: any;
              sale_date: any;
              status: any;
              stus_executed: any;
              stus_sold: any;
              this_month_revenue: any;
              this_month_base: any;
              per_month_revenue_base: any;
              per_month_revenue: any;
              type: any;
              value: any;
              value_in_base: number;
              total_sold_base: number;
              unaccounted: number;
              revenue_accounted_base: any;
              revenue_accounted: any;
              po_sold_value: any;
              accounted_base: number;
              accounted: number;
              dropped: number;
              dropped_base: number;
              gained: number;
              gained_base: number;
              RevForexValue: number;
              InvForexValue: number;
              RevForexValueThisMonth: number;
              RevForexPastMonth: number;
              RevForexThisMonth: number;
              Accounted_tilldate_base: number;
              Accounted_tilldate: number;
              RevForexTillDate: number;
              RevUnaccounted_base: number;
              InvUnaccounted_base: number;
              poServices: any;
              lead_lag: any;
              direct_expense: any;
              soldbystu: any;
              expense: any;
              PendingStu: any;
            }) => {
              tempRevenuePoTypeSet.add(row.type);

              return {
                PendingStu:
                  (row.stus_executed ?? 0) -
                  (row.value_in_base +
                    (row.gained_base ?? 0) -
                    (row.dropped_base ?? 0) -
                    row.expense) /
                    ((row.value_in_base - row.expense) / row.stus_sold) -
                  (row.RevForexTillDate ?? 0) /
                    ((row.value_in_base - row.expense) / row.stus_sold),

                expense: row.expense,
                Currency_change_status: row.currency_change_status,
                Accounted_tilldate_base: row.Accounted_tilldate_base,
                Accounted_tilldate: row.Accounted_tilldate,
                direct_expense: row.direct_expense,
                Currency_Code: currencyName.current[row.currency_id],
                currency_symb: currencySymbol.current[row.currency_id],
                Currency_rate: row.currency_rate,
                Desc: row.desc,
                InvForexValue: decimalCheck(
                  row.InvForexValue,
                  2,
                  currencySymb?.currency_symbol
                ),
                RevForexTillDate: row.RevForexTillDate,
                Executed_revenue: convertNumber(row.executed_revenue, 2),
                Executed_revenue_base: checkBase(
                  currencySymb?.currency_code,
                  row?.executed_revenue_base + row?.RevForexPastMonth
                ),
                poServices: row.poServices ? row.poServices.length : 0,
                id: row.id,
                Invoice: convertNumber(row.invoiced, 2),
                Invoice_base: checkBase(
                  currencySymb?.currency_code,
                  row.invoiced_base
                ),
                Po_number: row.number,
                Po_name: row.name,
                Pending_revenue: convertNumber(row.pending_revenue, 2),
                Pending_revenue_base: decimalCheck(
                  row.pending_revenue_base,
                  2,
                  currencySymb?.currency_symbol
                ),
                Planned_invoice: convertNumber(row.planned_invoice, 2),
                Planned_invoice_base: checkBase(
                  currencySymb?.currency_code,
                  row.planned_invoice_base
                ),
                Po_status:
                  row.po_status == null || undefined
                    ? purchaseOrderTable.textForNotApplicable
                    : row.po_status,
                Sale_date: dayjs(row.sale_date).format("DD/MM/YYYY"),
                Status: row.status,
                Stus_executed: convertNumber(row.stus_executed, 2),
                Stus_sold: convertNumber(row.stus_sold, 2),
                This_month_revenue: row.this_month_revenue,
                This_month_base: decimalCheck(
                  row.this_month_base == null ? 0 : row.this_month_base,
                  2,
                  currencySymb?.currency_symbol
                ),
                per_month_revenue_base: checkBase(
                  currencySymb?.currency_code,
                  row?.per_month_revenue_base
                ),
                per_month_revenue: convertNumber(row.per_month_revenue, 2),
                Po_type: row.type,
                Value: convertNumber(row.value, 2),
                value_in_base: checkBase(
                  currencySymb?.currency_code,
                  row.value_in_base
                ),

                Variance: decimalCheck(
                  Variance(row.total_sold_base, row.accounted),
                  2,
                  currencySymb?.currency_symbol
                ),

                Variance_Val: convertNumber(
                  VarianceVal(
                    row.pending_revenue,
                    row.this_month_revenue,
                    row.executed_revenue,
                    row.value,
                    row.total_sold_base,
                    row.accounted
                  ),
                  2
                ),
                unaccounted: decimalCheck(
                  unaccounted(
                    row.value_in_base,
                    row.gained_base,
                    row.revenue_accounted_base
                  ),
                  2,
                  currencySymb?.currency_symbol
                ),
                unaccountedVal: convertNumber(
                  unaccountedVal(row.value, row.gained, row.revenue_accounted),
                  2
                ),
                unaccounted_base: checkBase(
                  currencySymb?.currency_code,
                  row.RevUnaccounted_base
                ),
                Variance_base: decimalCheck(
                  Variance(row.total_sold_base, row.accounted),
                  2,
                  currencySymb?.currency_symbol
                ),
                Pending: convertNumber(
                  pending(row.value, row.executed_revenue),
                  2
                ),

                Lead_Lag: decimalCheck(
                  row.lead_lag,
                  2,
                  currencySymb?.currency_symbol
                ),
                Lead_Lag_base: checkBase(
                  currencySymb?.currency_code,
                  row.lead_lag
                ),
                Lead_Lag_val: convertNumber(
                  leadLagval(row.invoiced, row.Accounted_tilldate),
                  2
                ),

                Invoiceunaccounted: convertNumber(
                  invoiceunaccounted(
                    row.value_in_base,
                    row.gained_base,
                    row.dropped_base,
                    row.invoiced_base,
                    row.planned_invoice_base
                  ),
                  0
                ),
                Invoiceunaccounted_base: convertNumber(
                  row.InvUnaccounted_base,
                  2
                ),
                STU: convertNumber(row.value / row.stus_sold, 2),
                STU_base: checkNumber(stu(row.value_in_base, row.stus_sold), 2),
                Stu_base: (row.value_in_base - row.expense) / row.stus_sold,
                Invoiceunaccounted_Val: convertNumber(
                  invoiceunaccountedval(
                    row.value,
                    row.gained,
                    row.dropped,
                    row.invoiced,
                    row.planned_invoice
                  ),
                  2
                ),
                Gain_Dropped: convertNumber(row.gained - row.dropped, 2),
                Gain_Dropped_base: checkBase(
                  currencySymb?.currency_code,
                  row.gained_base - row.dropped_base
                ),
                RevForexVal: formatCurrencyValue(
                  currencySymb?.currency_code,
                  row.RevForexValue
                ),
              };
            }
          )
        );

        if (
          tempPoTypeSet &&
          Array.isArray(tempPoTypeSet) &&
          tempPoTypeSet.length > 0
        ) {
          tempPoTypeSet.forEach((poType: any) => {
            tempPoTypeList.push({
              text: poType,
              value: poType,
            });
          });
          setPoTypeList(tempPoTypeList);
        }
      })
      .catch((err) => {
        console.log(err);
        setPoRevenueLoading(false);
      });
  };

  const getCurrency = async () => {
    try {
      const res = await AxiosConfig.get(`rates/${newMonthId.current}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      let mastercurrencies = res?.data?.monthRates?.mastercurrencies;
      setMasterCurrency(res.data?.monthRates?.mastercurrencies);
      const tempCurrencyName = {};
      const tempCurrencySymb = {};
      mastercurrencies.map((currency) => {
        tempCurrencyName[currency?.currencyrates?.currency_id] =
          currency?.currency_code;
        tempCurrencySymb[currency?.currencyrates?.currency_id] =
          currency?.currency_symbol;
        currencyRate.current[currency?.currencyrates?.currency_id] =
          currency?.currencyrates?.rate;
      });

      currencyName.current = tempCurrencyName;
      currencySymbol.current = tempCurrencySymb;
      setLoad(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (currencySymb?.currency_code) {
      getMonthsData().then(() => getCurrency().then(() => getData()));
    }
  }, [currencySymb?.currency_code]);

  const handleDelete = (id: any) => {
    setRowId(id);
    setShow(true);
  };
  const [povisible, setpoVisible] = useState(false);
  const [poDetails, setPoDetails] = useState<any>({});
  const [poattachmentIds, setPoAttachmentIds] = useState([]);

  useEffect(() => {
    if (poDetails?.id) {
      setpoVisible(true);
    }
  }, [poDetails, currencySymb?.currency_code]);

  const povisibleHandler = useCallback((id: any) => {
    AxiosConfig.get("purchaseorders/" + id, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setPoDetails(res.data.data.PO);

      setPoAttachmentIds(
        res.data.data.PO.poattachments.map(
          (row: { id: any; attachment_id: any }) => ({
            id: row.id,
            attachment_id: row.attachment_id,
          })
        )
      );
    });
  }, []);
  const pocloseHandler = () => {
    getData();
    setpoVisible(false);
  };

  const effortsColumns = [
    {
      title: "SALE DATE",
      dataIndex: "Sale_date",
      key: "sale_date",
      defaultSortOrder: "descend" as any,
      sorter: (a: any, b: any) => {
        const dateA = new Date(a.Sale_date.split("/").reverse().join("-"));
        const dateB = new Date(b.Sale_date.split("/").reverse().join("-"));
        return dateA.getTime() - dateB.getTime();
      },
    },
    {
      title: "PURCHASE ORDER",
      dataIndex: ["Po_name", "Po_number", "Po_type"],
      key: "Po_type",
      // sorter: (a: any, b: any) => a.Po_type.localeCompare(b.Po_type),
      // filters: poTypeList,
      onFilter: (value: any, record: any) => record.Po_type.includes(value),
      width: "13%",
      render: (text: any, record: any) => (
        <Row>
          <Col
            md={24}
            xs={24}
            style={{ color: "#5c67e5", cursor: "pointer" }}
            onClick={() => povisibleHandler(record["id"])}
          >
            {record["Po_number"]}
          </Col>
          <Col md={24} xs={24}>
            {record["Po_name"]}
          </Col>
          <Col md={24} xs={24}>
            {record["Po_type"]}
          </Col>
        </Row>
      ),
    },
    {
      title: "PO STATUS",
      dataIndex: "Po_status",
      key: "Po_status",
      sorter: (a: any, b: any) => a.Po_status.localeCompare(b.Po_status),
      // render: (text: any, record: any) =>
      //   record["Po_status"] ?? "Not Applicable",
    },
    {
      title: "SOLD",
      children: [
        {
          title: "Total",
          dataIndex: [
            "Value",
            "value_in_base",
            "Currency_Code",
            "currency_symb",
          ],
          key: "Value",
          className: "text-end",
          render: (text: any, record: any) => (
            <Row>
              <Col md={24} xs={24}>
                {record["currency_symb"]}
                {record["currency_symb"] != currencySymb?.currency_symbol
                  ? record["Value"]
                  : record["value_in_base"]?.replaceAll("$", "")}
              </Col>
              {record["currency_symb"] != currencySymb?.currency_symbol && (
                <Col md={24} xs={24}>
                  <Text type="secondary">{`${currencySymb?.currency_symbol} ${record["value_in_base"]}`}</Text>
                </Col>
              )}
            </Row>
          ),
        },
        {
          title: "SOLD/STU",
          dataIndex: "Stu_base",
          key: "Stu_base",
          className: "text-end",
          render: (text: any, record: any) => {
            const valueofsoldbystu = record["Stu_base"];
            return (
              <Row>
                <Col md={24} xs={24}>
                  {formatCurrencyValueDecimal(
                    currencySymb?.currency_code,
                    valueofsoldbystu.toFixed(2)
                  )}
                </Col>
              </Row>
            );
          },
        },
      ],
    },

    {
      title: "EARNED",
      children: [
        {
          title: "EARNED STU's",
          dataIndex: "Stus_executed",
          key: "Stus_executed",
          className: "text-end",
          render: (text: any, record: any) => record["Stus_executed"],
        },
        {
          title: "PENDING STU's",
          dataIndex: "PendingStu",
          key: "PendingStu",
          className: "text-end",
          sorter: (a: any, b: any) => {
            const aValue = a.PendingStu;
            const bValue = b.PendingStu;
            return bValue - aValue;
          },
          render: (text: any, record: any) => {
            let pendingStuValue = parseFloat(record["PendingStu"])
              .toFixed(2)
              .toString();

            if (pendingStuValue === "-0.00") {
              pendingStuValue = "0.00";
            } else if (pendingStuValue === "0.00") {
              pendingStuValue = "0.00";
            }

            let color = "black";

            if (pendingStuValue === "0.00") {
              color = "black";
            } else if (pendingStuValue < "0.00") {
              color = "orange";
            } else if (pendingStuValue > "0.00") {
              color = "red";
            }

            return <span style={{ color }}>{pendingStuValue}</span>;
          },
        },
      ],
    },
    ...(!isArchive || povisible
      ? [
          {
            dataIndex: "id",
            key: "id",
            render: (record: any) => (
              <div className="actionBtns">
                {!isArchive && (
                  <span onClick={() => handleDelete(record)}>
                    <img src={deleteImage} style={{ cursor: "pointer" }} />
                  </span>
                )}
              </div>
            ),
          },
        ]
      : []),
  ];
  //Revenue
  const RevenueColumns = [
    {
      title: "SALE DATE",
      dataIndex: "Sale_date",
      key: "sale_date",
      defaultSortOrder: "descend" as any,
      sorter: (a: any, b: any) =>
        a.Sale_date.split("/")
          .reverse()
          .join()
          .localeCompare(b.Sale_date.split("/").reverse().join()),
    },
    {
      title: "PURCHASE ORDER",
      dataIndex: ["Po_name", "Po_number", "Po_type"],
      key: "Po_type",
      sorter: (a: any, b: any) => a.Po_type.localeCompare(b.Po_type),
      filters: poTypeList,
      onFilter: (value: any, record: any) => record.Po_type.includes(value),
      width: "13%",
      render: (text: any, record: any) => (
        <Row>
          <Col
            md={24}
            xs={24}
            style={{ color: "#5c67e5", cursor: "pointer" }}
            onClick={() => povisibleHandler(record["id"])}
          >
            {record["Po_number"]}
          </Col>
          <Col md={24} xs={24}>
            {record["Po_name"]}
          </Col>
          <Col md={24} xs={24}>
            {record["Po_type"]}
          </Col>
        </Row>
      ),
    },
    {
      title: "PO STATUS",
      dataIndex: "Po_status",
      key: "Po_status",
      sorter: (a: any, b: any) => a.Po_status.localeCompare(b.Po_status),
      // render: (text: any, record: any) =>
      //   record["Po_status"] ?? "Not Applicable",
    },
    {
      title: "REVENUE",
      children: [
        {
          title: "Sold",
          dataIndex: [
            "Value",
            "value_in_base",
            "Currency_Code",
            "currency_symb",
          ],
          key: "value_in_base",
          className: "text-end",
          render: (text: any, record: any) => (
            <Row>
              <Col md={24} xs={24}>
                {record["currency_symb"]}
                {record["currency_symb"] != currencySymb?.currency_symbol
                  ? record["Value"]
                  : record["value_in_base"]?.replaceAll("$", "")}
              </Col>
              {record["currency_symb"] != currencySymb?.currency_symbol && (
                <Col md={24} xs={24}>
                  <Text type="secondary">{`${currencySymb?.currency_symbol} ${record["value_in_base"]}`}</Text>
                </Col>
              )}
            </Row>
          ),
        },
        {
          title: "Gain/(Dropped)",
          dataIndex: [
            "Gain_Dropped",
            "Gain_Dropped_base",
            "Currency_Code",
            "currency_symb",
          ],
          key: "Gain_Dropped_base",
          className: "text-end",
          render: (text: any, record: any) => (
            <Row>
              <Col md={24} xs={24}>
                {record["currency_symb"]}
                {record["currency_symb"] !== currencySymb?.currency_symbol
                  ? record["Gain_Dropped"]
                  : record["Gain_Dropped_base"]?.replaceAll("$", "")}
              </Col>
              {record["currency_symb"] !== currencySymb?.currency_symbol && (
                <Col md={24} xs={24}>
                  <Text type="secondary">{`${currencySymb?.currency_symbol} ${record["Gain_Dropped_base"]}`}</Text>
                </Col>
              )}
            </Row>
          ),
        },
        {
          title: "Earned (Past)",
          dataIndex: [
            "Executed_revenue",
            "Executed_revenue_base",
            "Currency_Code",
            "currency_symb",
          ],
          key: "Executed_revenue_base",
          className: "text-end",
          render: (text: any, record: any) => (
            <Row>
              <Col md={24} xs={24}>
                {record["currency_symb"]}
                {record["currency_symb"] !== currencySymb?.currency_symbol
                  ? record["Executed_revenue"]
                  : record["Executed_revenue_base"].replaceAll("$", "")}
              </Col>
              {record["currency_symb"] !== currencySymb?.currency_symbol && (
                <Col md={24} xs={24}>
                  <Text type="secondary">
                    {`${currencySymb?.currency_symbol} ${record["Executed_revenue_base"]}`}
                  </Text>
                </Col>
              )}
            </Row>
          ),
        },
        {
          title: `${String(currentMonth).substring(0, 3)}'${String(
            currentYear
          ).substring(2, 4)}`,
          dataIndex: [
            "Per_month_revenue",
            "per_month_revenue_base",
            "Currency_Code",
            "currency_symb",
          ],
          key: "Per_month_revenue",
          className: "text-end",
          render: (text: any, record: any) => (
            <Row>
              <Col md={24} xs={24}>
                {record["currency_symb"]}
                {record["currency_symb"] !== currencySymb?.currency_symbol
                  ? record["per_month_revenue"]
                  : record["per_month_revenue_base"]}
              </Col>
              {record["currency_symb"] !== currencySymb?.currency_symbol && (
                <Col md={24} xs={24}>
                  <Text type="secondary">
                    {`${currencySymb?.currency_symbol} ${record["per_month_revenue_base"]}`}
                  </Text>
                </Col>
              )}
            </Row>
          ),
        },

        {
          title: "Unaccounted",
          dataIndex: [
            "unaccounted",
            "unaccountedVal",
            "unaccounted_base",
            "Currency_Code",
            "currency_symb",
          ],
          key: "unaccounted",
          className: "text-end",
          render: (text: any, record: any) => (
            <Row>
              <Col
                md={24}
                xs={24}
                style={{
                  color: record["unaccountedVal"] >= 0 ? "green" : "red",
                }}
              >
                {record["currency_symb"]}
                {record["currency_symb"] !== currencySymb?.currency_symbol
                  ? record["unaccountedVal"]
                  : record["unaccounted_base"].replaceAll("$", "")}
              </Col>
              {record["currency_symb"] !== currencySymb?.currency_symbol && (
                <Col md={24} xs={24}>
                  <Text type="secondary">{`${currencySymb?.currency_symbol} ${record["unaccounted_base"]}`}</Text>
                </Col>
              )}
            </Row>
          ),
        },
      ],
    },
    {
      title: "FOREX ADJUSTMENT",
      dataIndex: ["RevForexVal"],
      key: "RevForexVal",
      className: "text-end",
      render: (record: any) => (
        <Row>
          <Col
            md={24}
            xs={24}
            style={{
              color: convertAmountToNumber(record) >= 0 ? "green" : "red",
            }}
          >
            {record}
          </Col>
        </Row>
      ),
    },

    ...(!isArchive || povisible
      ? [
          {
            key: "id",
            render: (record: any) => (
              <div className="actionBtns">
                <div className="warnBtns">
                  {record.poServices === 0 ? (
                    <div>
                      <Popover
                        content="PO Services has not been allocated"
                        trigger="hover"
                      >
                        <img
                          src={warning}
                          style={{ cursor: "pointer", marginLeft: 12 }}
                        />
                      </Popover>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <br></br>

                {!isArchive && (
                  <span onClick={() => handleDelete(record.id)}>
                    <img
                      src={deleteImage}
                      style={{ cursor: "pointer", marginLeft: 12 }}
                    />
                  </span>
                )}
              </div>
            ),
          },
        ]
      : [
          {
            key: "id",
            render: (record: any) => (
              <div className="actionBtns">
                <div className="warnBtns">
                  {record.poServices === 0 ? (
                    <div>
                      <Popover
                        content="PO Services has not been allocated"
                        trigger="hover"
                      >
                        <img
                          src={warning}
                          style={{ cursor: "pointer", marginLeft: 12 }}
                        />
                      </Popover>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <br></br>
              </div>
            ),
          },
        ]),
  ];
  //Invoice
  const invoiceColumns = [
    {
      title: "SALE DATE",
      dataIndex: "Sale_date",
      key: "sale_date",
      defaultSortOrder: "descend" as any,
      sorter: (a: any, b: any) =>
        a.Sale_date.split("/")
          .reverse()
          .join()
          .localeCompare(b.Sale_date.split("/").reverse().join()),
    },
    {
      title: "PURCHASE ORDER",
      dataIndex: ["Po_name", "Po_number", "Po_type"],
      key: "Po_type",
      sorter: (a: any, b: any) => a.Po_type.localeCompare(b.Po_type),
      filters: poTypeList,
      onFilter: (value: any, record: any) => record.Po_type.includes(value),
      width: "13%",
      render: (text: any, record: any) => (
        <Row>
          <Col
            md={24}
            xs={24}
            style={{ color: "#5c67e5", cursor: "pointer" }}
            onClick={() => povisibleHandler(record["id"])}
          >
            {record["Po_number"]}
          </Col>
          <Col md={24} xs={24}>
            {record["Po_name"]}
          </Col>
          <Col md={24} xs={24}>
            {record["Po_type"]}
          </Col>
        </Row>
      ),
    },
    {
      title: "PO STATUS",
      dataIndex: "Po_status",
      key: "Po_status",
      sorter: (a: any, b: any) => a.Po_status.localeCompare(b.Po_status),
      // render: (text: any, record: any) =>
      //   record["Po_status"] ?? "Not Applicable",
    },
    {
      title: "INVOICING",
      children: [
        {
          title: "Sold",
          dataIndex: [
            "Value",
            "value_in_base",
            "Currency_Code",
            "currency_symb",
          ],
          key: "Value",
          className: "text-end",
          render: (text: any, record: any) => (
            <Row>
              <Col md={24} xs={24}>
                {record["currency_symb"]}
                {record["currency_symb"] !== currencySymb?.currency_symbol
                  ? record["Value"]
                  : record["value_in_base"]?.replaceAll("$", "")}
              </Col>
              {record["currency_symb"] !== currencySymb?.currency_symbol && (
                <Col md={24} xs={24}>
                  <Text type="secondary">{`${currencySymb?.currency_symbol} ${record["value_in_base"]}`}</Text>
                </Col>
              )}
            </Row>
          ),
        },
        {
          title: "Gain/(Dropped)",
          dataIndex: [
            "Gain_Dropped",
            "Gain_Dropped_base",
            "Currency_Code",
            "currency_symb",
          ],
          key: "Gain_Dropped_base",
          className: "text-end",
          render: (text: any, record: any) => (
            <Row>
              <Col md={24} xs={24}>
                {record["currency_symb"]}
                {record["currency_symb"] !== currencySymb?.currency_symbol
                  ? record["Gain_Dropped"]
                  : record["Gain_Dropped_base"]?.replaceAll("$", "")}
              </Col>
              {record["currency_symb"] !== currencySymb?.currency_symbol && (
                <Col md={24} xs={24}>
                  <Text type="secondary">{`${currencySymb?.currency_symbol} ${record["Gain_Dropped_base"]}`}</Text>
                </Col>
              )}
            </Row>
          ),
        },
        {
          title: "Invoiced",
          dataIndex: [
            "Invoice",
            "Invoice_base",
            "Currency_Code",
            "currency_symb",
          ],
          key: "Invoice_base",
          className: "text-end",
          render: (text: any, record: any) => (
            <Row>
              <Col md={24} xs={24}>
                {record["currency_symb"]}
                {record["currency_symb"] !== currencySymb?.currency_symbol
                  ? record["Invoice"]
                  : record["Invoice_base"].replaceAll("$", "")}
              </Col>
              {record["currency_symb"] !== currencySymb?.currency_symbol && (
                <Col md={24} xs={24}>
                  <Text type="secondary">{`${currencySymb?.currency_symbol} ${record["Invoice_base"]}`}</Text>
                </Col>
              )}
            </Row>
          ),
        },
        {
          title: "Planned",
          dataIndex: [
            "Planned_invoice",
            "Planned_invoice_base",
            "Currency_Code",
            "currency_symb",
          ],
          key: "Invoice",
          className: "text-end",
          render: (text: any, record: any) => (
            <Row>
              <Col md={24} xs={24}>
                {record["currency_symb"]}
                {record["currency_symb"] !== currencySymb?.currency_symbol
                  ? record["Planned_invoice"]
                  : record["Planned_invoice_base"].replaceAll("$", "")}
              </Col>
              {record["currency_symb"] !== currencySymb?.currency_symbol && (
                <Col md={24} xs={24}>
                  <Text type="secondary">{`${currencySymb?.currency_symbol} ${record["Planned_invoice_base"]}`}</Text>
                </Col>
              )}
            </Row>
          ),
        },
        {
          title: "Unaccounted",
          dataIndex: [
            "Invoiceunaccounted",
            "Invoiceunaccounted_base",
            "Invoiceunaccounted_Val",
            "Currency_Code",
            "currency_symb",
          ],
          key: "Invoiceunaccounted",
          className: "text-end",
          render: (text: any, record: any) => (
            <Row>
              <Col
                md={24}
                xs={24}
                style={{
                  color:
                    record["Invoiceunaccounted_Val"] >= 0 ? "green" : "red",
                }}
              >
                {record["currency_symb"]}
                {record["currency_symb"] !== currencySymb?.currency_symbol
                  ? record["Invoiceunaccounted_Val"]
                  : record["Invoiceunaccounted_base"]?.replaceAll("$", "")}
              </Col>
              {record["currency_symb"] !== currencySymb?.currency_symbol && (
                <Col md={24} xs={24}>
                  <Text type="secondary">
                    {`${currencySymb?.currency_symbol} ${record["Invoiceunaccounted_base"]}`}
                  </Text>
                </Col>
              )}
            </Row>
          ),
        },
        {
          title: "Lead/Lag",
          dataIndex: [
            "Lead_Lag",
            "Lead_Lag_val",
            "Lead_Lag_base",
            "Currency_Code",
            "currency_symb",
          ],
          key: "Lead_Lag",
          className: "text-end",
          render: (text: any, record: any) => (
            <Row>
              <Col
                md={24}
                xs={24}
                style={{
                  color: record["Lead_Lag_val"] >= 0 ? "green" : "red",
                }}
              >
                {record["currency_symb"]}
                {record["currency_symb"] !== currencySymb?.currency_symbol
                  ? record["Lead_Lag_val"]
                  : record["Lead_Lag_base"]?.replaceAll("$", " ")}
              </Col>
              {record["currency_symb"] !== currencySymb?.currency_symbol && (
                <Col md={24} xs={24}>
                  <Text type="secondary">{`${currencySymb?.currency_symbol} ${record["Lead_Lag_base"]}`}</Text>
                </Col>
              )}
            </Row>
          ),
        },
      ],
    },
    {
      title: "FOREX ADJUSTMENT",
      dataIndex: ["InvForexValue"],
      key: "InvForexValue",
      className: "text-end",
      render: (record: any) => (
        <Row>
          <Col
            md={24}
            xs={24}
            style={{
              color: convertAmountToNumber(record) >= 0 ? "green" : "red",
            }}
          >
            {record}
          </Col>
        </Row>
      ),
    },
    ...(!isArchive || povisible
      ? [
          {
            dataIndex: "id",
            key: "id",
            render: (record: any) => (
              <div className="actionBtns">
                {!isArchive && (
                  <span onClick={() => handleDelete(record)}>
                    <img src={deleteImage} style={{ cursor: "pointer" }} />
                  </span>
                )}
              </div>
            ),
          },
        ]
      : []),
  ];
  const [visible, setVisible] = useState(false);
  const visibleHandler = () => {
    setVisible(true);
  };
  const closeHandler = () => {
    setVisible(false);
  };

  return (
    <>
      {isLoad && (
        <PoDetailsOverlay
          visible={povisible}
          onClose={pocloseHandler}
          onCancelButton={pocloseHandler}
          recordHandler={poRevenueData}
          poDetails={poDetails}
          poAttachmentIds={poattachmentIds}
          filteredMonths={filteredMonths}
          newMonth={newMonth}
          newMonthId={newMonthId.current}
          newYear={newYear}
          mastercurrencies={masterCurrency}
          currencyRate={currencyRate.current}
          currencyName={currencyName.current}
          currencySymbol={currencySymbol?.current}
          archive_status={isArchive}
        ></PoDetailsOverlay>
      )}
      <Row>
        <Col xl={6} xs={24}>
          <h1 className="purchaseOrderTitle">
            {purchaseOrderTable.labelForPurchaseOrder}
          </h1>
        </Col>
        <Col xl={18} xs={24} className="AddPoButton">
          {isArchive ? (
            <></>
          ) : (
            <Button
              type="primary"
              onClick={visibleHandler}
              className="primary-btn add-po"
            >
              {purchaseOrderTable.ButtonForAddPO}
            </Button>
          )}
        </Col>
      </Row>
      <div className="table-card">
        {isLoad && (
          <AddOverlay
            visible={visible}
            onClose={closeHandler}
            onCancelButton={closeHandler}
            currencyRate={currencyRate.current}
            mastercurrencies={masterCurrency}
            ProjectManagers={props.ProjectManagers}
            associatedBuId={props.associatedBuId}
            ProjectName={props.ProjectName}
            ProjectOwnerId={props.ProjectOwnerId}
          ></AddOverlay>
        )}
        <Row>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Revenue" key="1">
              <Col span={24}>
                <Table
                  loading={!poRevenueLoading ? false : true}
                  style={{ width: 1500 }}
                  columns={RevenueColumns}
                  dataSource={poRevenueData}
                  bordered
                  pagination={{ pageSize: 10 }}
                  className="projectsTable"
                  locale={{
                    emptyText: (
                      <div className="NoPurchanseOrder">
                        {purchaseOrderTable.textForNoPO}
                      </div>
                    ),
                  }}
                />
              </Col>
            </TabPane>
            <TabPane tab="Invoicing" key="2">
              <Col span={24}>
                <Table
                  style={{ width: 1500 }}
                  columns={invoiceColumns}
                  dataSource={poRevenueData}
                  bordered
                  pagination={{ pageSize: 10 }}
                  className="projectsTable"
                />
              </Col>
            </TabPane>
            <TabPane tab="Effort" key="3">
              <Col span={24}>
                <Table
                  style={{ width: 1500 }}
                  columns={effortsColumns}
                  dataSource={poRevenueData}
                  bordered
                  pagination={{ pageSize: 10 }}
                  className="projectsTable"
                />
              </Col>
            </TabPane>
          </Tabs>
        </Row>
      </div>

      <DeletePoModal
        onClose={() => setShow(false)}
        show={show}
        rowData={rowId}
      ></DeletePoModal>
    </>
  );
};

export default memo(PurchaseOrderTable);
