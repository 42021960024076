import React, { useEffect, useState } from "react";
import { AddCustomer } from "../../../utils/enums";
import "antd/dist/antd.css";
import { Drawer, Input, notification } from "antd";
import Switch from "react-switch";
import { Formik, FormikProps } from "formik";
import * as yup from "yup";
import { AxiosConfig } from "../../../ApiConfig";
import { InputField } from "../../../components";
import ButtonReuse from "../../../components/Buttons/Button";
import { getToken } from "../../../auth";
import Select from "react-select";
import { getCustomerClassificationTypes } from "../../../stores/actions/getCustomerClassificationTypesAction";
import { getCustomersCategory } from "../../../stores/actions/getCustomerCategoryAction";
import { useDispatch, useSelector } from "react-redux";
require("./AddCustomer.scss");

const { TextArea } = Input;

const AddOverlay = (props: {
  visible?: any;
  onClose?: any;
  onCancelButton?: any;
  customerDetails?: any;
}) => {
  const validationSchema = yup.object({
    customer_name: yup
      .string()
      .trim()
      .required("Please enter the Customer Name"),
    desc: yup.string().trim().required("Please enter the description"),
    category_id: yup.string().required("Please select the category"),
    classification_id: yup
      .string()
      .required("Please select the Customer Relation"),
  });

  type MyFormValues = {
    customer_name: "",
    desc: "",
    category_id: "",
    classification_id: "",
    MSA_reference: "",
    is_MSA_available: any,
  };

  const formikRef = React.useRef<FormikProps<MyFormValues>>(null);
  const onCancelHandler = (resetForm) => {
    resetForm();
    props.onCancelButton();
  };
  const token = getToken();

  const handleDrawerClose = () => {
    if (formikRef.current) {
      formikRef.current?.resetForm();
    }
    props.onClose();
  };

  const openNotification = () => {
    notification.open({
      message: "Customer Added Successfully",
    });
  };

  const dispatch: any = useDispatch();
  const customerCategoryData = useSelector(
    (store: any) => store.categories.customerTypes
  );
  const customerClassifcation = useSelector(
    (store: any) => store?.customerClassifcationTypes
  );

  const [customersCategory, setCustomersCategory] = useState([]);
  const [MSA, setMSA] = useState(false);

  useEffect(() => {
    dispatch(getCustomersCategory());
  }, [dispatch]);

  useEffect(() => {
    if (customerCategoryData) {
      setCustomersCategory(customerCategoryData);
    }
  }, [customerCategoryData]);

  useEffect(() => {
    dispatch(getCustomerClassificationTypes());
    dispatch(getCustomersCategory());
  }, []);

  const getCustomerClassification = () => {
    return customerClassifcation.customerClassifcationTypes.map(
      (classification) => ({
        value: classification.id,
        label: classification.title,
      })
    );
  };

  const categoryOptions = customersCategory.map(function (customer: {
    name: any;
    id: any;
  }) {
    return {
      value: customer.id,
      label: customer.name,
    };
  });

  const handleSwitchChange = () => {
    setMSA(!MSA);
  };

  return (
    <>
      <Drawer
        title="Add Customer"
        width={500}
        onClose={handleDrawerClose}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        className="add_project"
      >
        <Formik
        innerRef={formikRef} 
          initialValues={{
            customer_name: "",
            desc: "",
            category_id: "",
            classification_id: "",
            MSA_reference: "",
            is_MSA_available: yup.boolean,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            AxiosConfig.post(
              "customers",
              {
                ...values,
                customerRelationId: values.classification_id,
                customerTypeId: values.category_id,
                MSA_reference: values.MSA_reference,
                is_MSA_available: MSA,
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
              .then((response) => {
                if (response.status === 200) {
                  {
                    openNotification();
                    props.onClose();
                    setTimeout(function () {
                      window.location.reload();
                    }, 1000);
                  }
                }
              })
              .catch(function () {
                const notify = () => {
                  notification.open({
                    message: "Invalid Data",
                  });
                };
                {
                  notify();
                }
              });
          }}
        >
          {({
            handleSubmit,
            handleChange,
            errors,
            values,
            setFieldValue,
            resetForm,
          }) => {
            const selectedCategory = categoryOptions.find(
              (option) => option.value === values.category_id
            );

            const selectedClassification = getCustomerClassification().find(
              (option) => option.value === values.classification_id
            );

            return (
              <form onSubmit={handleSubmit} className="add_project_form">
                <label>{AddCustomer.labelForCustomerName}</label>
                <InputField
                  placeholder="Enter the Customer Name"
                  name="customer_name"
                  onChange={handleChange}
                  value={values.customer_name}
                  className="input--field"
                ></InputField>
                <p className="display_error">{errors.customer_name}</p>

                <label>{AddCustomer.labelForCustomerCategory}</label>
                <Select
                  value={
                    selectedCategory
                      ? {
                          value: selectedCategory.value,
                          label: selectedCategory.label,
                        }
                      : null
                  }
                  placeholder="Select Customer Category"
                  options={categoryOptions}
                  className="select--category"
                  onChange={(value) => {
                    setFieldValue("category_id", value ? value.value : "");
                  }}
                />

                <p className="display_error">{errors.category_id}</p>
                <label>{AddCustomer.labelForCustomerRelation}</label>
                <Select
                  value={
                    selectedClassification
                      ? {
                          value: selectedClassification.value,
                          label: selectedClassification.label,
                        }
                      : null
                  }
                  placeholder="Select Customer Relation"
                  options={getCustomerClassification()}
                  className="select--relation"
                  onChange={(value) => {
                    setFieldValue("classification_id", value ? value.value : "");
                  }}
                />
                <p className="display_error">{errors.classification_id}</p>

                <label>{AddCustomer.labelForDescription} </label>
                <TextArea
                  placeholder="Enter the Description"
                  rows={5}
                  name="desc"
                  onChange={handleChange}
                  value={values.desc}
                  className="textarea-input"
                />
                <p className="display_error">{errors.desc}</p>

                <label> {AddCustomer.labelForMSA}</label>
                <div>
                  <Switch
                    className="MSA"
                    onChange={(checked) => {
                      if (!checked) {
                        setFieldValue("MSA_reference", "");
                      }
                      handleSwitchChange();
                    }}
                    checked={
                      props.customerDetails?.is_MSA_available === true
                        ? !MSA
                        : MSA
                    }
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor="#5389F8"
                    offColor="#000000"
                  />
                </div>
                {MSA && (
                  <div>
                    <label> {AddCustomer.labelForMSA_Desc}</label>
                    <InputField
                      placeholder="Enter the MSA file references"
                      name="MSA_reference"
                      onChange={handleChange}
                      value={values?.MSA_reference ? values?.MSA_reference : ""}
                      className="textarea-input"
                    />
                  </div>
                )}
                {props.customerDetails?.is_MSA_available === true && (
                  <InputField
                    placeholder="Enter the MSA file references"
                    name="MSA_reference"
                    onChange={handleChange}
                    value={values?.MSA_reference}
                    className="textarea-input"
                  />
                )}
                {props.customerDetails?.is_MSA_available === false && null && (
                  <InputField
                    placeholder="Enter the MSA file references"
                    name="MSA_reference"
                    onChange={handleChange}
                    value={values?.MSA_reference}
                    className="textarea-input"
                  />
                )}

                <div className="button-container">
                  <ButtonReuse
                    type="primary"
                    className="primary-btn"
                    htmlType="submit"
                    value="Add"
                  ></ButtonReuse>
                  <ButtonReuse
                    type="primary"
                    className="primary-btn cancel--btn"
                    value="Cancel"
                    onClick={() => onCancelHandler(resetForm)}
                  ></ButtonReuse>
                </div>
              </form>
            );
          }}
        </Formik>
      </Drawer>
    </>
  );
};

export default AddOverlay;
