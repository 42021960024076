import "antd/dist/antd.css";
import { Drawer } from "antd";
import Select from "react-select";
import { Formik } from "formik";
import { AxiosConfig } from "../../../../ApiConfig";
import ButtonReuse from "../../../../components/Buttons/Button";
import { useState, useEffect, useRef } from "react";
import { getToken } from "../../../../auth";
import TableInfo from "./TableInfo";
import Switch from "react-switch";
import Loader from "../../../../components/Loader/Loader";
import { checkTalentStatus } from "../../../../utils/util";
import { manageTalent } from "../../../../utils/enums";

require("./ManageTalent.scss");

const data1 = new FormData();

const AddOverlay = (props: {
  visible?: any;
  onClose?: any;
  onCancelButton?: any;
  projectName?: any;
  projectCode?: any;
  emp_id?: any;
  id?: any;
  projectMonth?:any;
}) => {
  const onCancelHandler = () => {
    props.onCancelButton();
  };
  const token = getToken();
  const projectMonth = props.projectMonth;

  const [gettalent, settalent] = useState([]);
  const [month_id, getMonthid] = useState(0);
  const [getEmpId, setEmpId] = useState();
  const [getthisMonthtotalPercentage, setthisMonthtotalPercentage] =
    useState(0);
  const [getnextMonthtotalPercentage, setnextMonthtotalPercentage] =
    useState(0);
  const [getfutureMonthtotalPercentage, setfutureMonthtotalPercentage] =
    useState(0);

  const [getthisMonthtotalHour, setthisMonthtotalHour] = useState("");
  const [getnextMonthtotalHour, setnextMonthtotalHour] = useState("");
  const [getfutureMonthtotalHour, setfutureMonthtotalHour] = useState("");
  const [getThismonthlHour, setThismonthlHour] = useState(0);
  const [getnextMonthHour, setnextMonthHour] = useState(0);
  const [getfutureMonthHour, setfutureMonthHour] = useState(0);

  const [Hour, setHour] = useState(false);

  //Using to store month details
  const [thisMonthDetails, setthisMonthDetails] = useState([
    {
      project_name: "",
      allocated_percentage: "",
      project_id: "",
    },
  ]);
  const [nextMonthDetails, setnextMonthDetails] = useState([
    {
      project_name: "",
      allocated_percentage: "",
      project_id: "",
    },
  ]);
  const [futureMonthDetails, setfutureMonthDetails] = useState([
    {
      project_name: "",
      allocated_percentage: "",
      project_id: "",
    },
  ]);
  const [currentMonth, setCurrentMonth] = useState("");

  const thisMonthAllocatedPercent = useRef("");
  const nextMonthAllocatedPercent = useRef("");
  const futureMonthAllocatedPercent = useRef("");

  const handleSwitchChange = () => {
    setHour(!Hour);
  };

  useEffect(() => {
    getCustomers(currentMonth);
    getMonthsId();
  }, [currentMonth]);

  // const [projectMonth, setProjectMonth] = useState({
  //   ThisMonth: "",
  //   NextMonth: "",
  //   FutureMonth: "",
  //   ThisMonthYear: "",
  //   NextMonthYear: "",
  //   FutureMonthYear: "",
  // });
  const [tableData, setTableData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const getMonthsId = async () => {
    await AxiosConfig.get("financialmonths", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      const currentMonth: any = res.data.data.months.filter(
        (month: any) => month.status === "1"
      );
      setCurrentMonth(currentMonth[0].id);
      getMonthid(currentMonth[0].id);
      // getProjectAllocation(currentMonth[0].id);
    });
  };

  // const getProjectAllocation = async (monthid: any) => {
  //   getMonthid(monthid);
  //   await AxiosConfig.get("allocations?month_id=" + monthid, {
  //     headers: { Authorization: `Bearer ${token}` },
  //   }).then((res) => {
  //     setProjectMonth({
  //       ThisMonth: res.data.data.months[0].ThisMonth,
  //       ThisMonthYear: res.data.data.months[0].ThisMonthYear,
  //       NextMonth: res.data.data.months[0].NextMonth,
  //       NextMonthYear: res.data.data.months[0].NextMonthYear,
  //       FutureMonth: res.data.data.months[0].FutureMonth,
  //       FutureMonthYear: res.data.data.months[0].FutureMonthYear,
  //     });
  //   });
  // };

  const getCustomers = async (monthid: any) => {
    await AxiosConfig.get("allocations/alltalents?month_id=" + monthid, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      let result: any = [];

      res.data.data.talents.map(async (talent: any) => {
        if (
          checkTalentStatus(talent.date_of_seperation) != "Inactive" &&
          checkTalentStatus(talent.date_of_seperation) != "Resigned"
        ) {
          result.push(talent);
        }
      });
      settalent(result);
    });
  };

  const customerOptions = gettalent.map(function (customer: {
    firstName: any;
    lastName: any;
    emp_id: any;
    label: any;
    id: any;
  }) {
    return {
      value: customer.emp_id,
      label:
        customer.firstName +
        " " +
        customer.lastName +
        "  " +
        "(" +
        customer.emp_id +
        ")",
    };
  });

  function handleInputChange(params: any) {
    thisMonthAllocatedPercent.current = "";
    nextMonthAllocatedPercent.current = "";
    futureMonthAllocatedPercent.current = "";
    setIsLoading(true);

    setEmpId(params.value);
    let emp_id = params.value;
    AxiosConfig.get("allocations/talents", {
      headers: { Authorization: `Bearer ${token}` },
      params: { emp_id, month_id },
    }).then((res) => {
      let Projects = res?.data?.data?.TalentAllocations
        ? res?.data?.data?.TalentAllocations
        : [];
      if (Projects.length >= 0) {
        setTableData(false);
        setIsLoading(false);
      }
      const temparr1: any = [];
      let tempflag1 = false;
      const temparr2: any = [];
      let tempflag2 = false;
      const temparr3: any = [];
      let tempflag3 = false;
      if (Projects.length > 0) {
        Projects.map((row: any) => {
          if (row?.project?.name) {
            if (row?.financialmonth?.month == projectMonth.ThisMonth) {
              temparr1.push({
                project_name: row?.project?.name,
                allocated_percentage: row?.allocated_percentage,
                allocated_hours: row?.allocated_hours,
                project_id: row?.project_id,
              });
              if (row?.project?.name === props.projectName) {
                thisMonthAllocatedPercent.current = row?.allocated_percentage;
                setthisMonthtotalHour(row?.allocated_hours);
                tempflag1 = true;
              }
            } else if (row?.financialmonth.month == projectMonth.NextMonth) {
              temparr2.push({
                project_name: row?.project?.name,
                allocated_percentage: row?.allocated_percentage,
                allocated_hours: row?.allocated_hours,
                project_id: row?.project_id,
              });
              if (row?.project?.name === props.projectName) {
                nextMonthAllocatedPercent.current = row?.allocated_percentage;
                setnextMonthtotalHour(row?.allocated_hours);
                tempflag2 = true;
              }
            } else if (row?.financialmonth?.month == projectMonth.FutureMonth) {
              temparr3.push({
                project_name: row?.project?.name,
                allocated_percentage: row?.allocated_percentage,
                allocated_hours: row?.allocated_hours,
                project_id: row?.project_id,
              });
              if (row?.project?.name === props.projectName) {
                futureMonthAllocatedPercent.current = row?.allocated_percentage;
                setfutureMonthtotalHour(row?.allocated_hours);
                tempflag3 = true;
              }
            }
          }
        });
      }

      setthisMonthDetails(
        tempflag1
          ? temparr1
          : [
              {
                project_name: props.projectName,
                allocated_percentage: "",
                allocated_hours: "",
                project_id: props.id,
              },
              ...temparr1,
            ]
      );
      let thisMonthtotalPercentage = 0;
      for (let i = 0; i < temparr1.length; i++) {
        thisMonthtotalPercentage =
          thisMonthtotalPercentage + temparr1[i].allocated_percentage;
      }
      setthisMonthtotalPercentage(thisMonthtotalPercentage);
      setThismonthlHour(res.data.data.thisMonthHour);
      setnextMonthHour(res.data.data.nextMonthHour);
      setfutureMonthHour(res.data.data.futureMonthHour);

      setnextMonthDetails(
        tempflag2
          ? temparr2
          : [
              {
                project_name: props.projectName,
                allocated_percentage: "",
                allocated_hours: "",
                project_id: props.id,
              },
              ...temparr2,
            ]
      );
      let nextMonthtotalPercentage = 0;
      for (let i = 0; i < temparr2.length; i++) {
        nextMonthtotalPercentage =
          nextMonthtotalPercentage + temparr2[i].allocated_percentage;
      }

      setnextMonthtotalPercentage(nextMonthtotalPercentage);

      setfutureMonthDetails(
        tempflag3
          ? temparr3
          : [
              {
                project_name: props.projectName,
                allocated_percentage: "",
                allocated_hours: "",
                project_id: props.id,
              },
              ...temparr3,
            ]
      );
      let futureMonthtotalPercentage = 0;
      for (let i = 0; i < temparr3.length; i++) {
        futureMonthtotalPercentage =
          futureMonthtotalPercentage + temparr3[i].allocated_percentage;
      }
      setfutureMonthtotalPercentage(futureMonthtotalPercentage);
    });
  }

  return (
    <>
      <Drawer
        title="Manage Talent"
        width={525}
        onClose={props.onClose}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        className="manage_talent"
        closable={true}
        maskClosable={true}
      >
        <Formik
          initialValues={{
            talent_id: "",
          }}
          onSubmit={(values) => {
            data1.append("talent_id", values.talent_id);
          }}
        >
          {({
            handleSubmit,

            errors,

            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <div>
                  <label>{manageTalent.labelForTalentName} </label>
                </div>
                <div>
                  <label className="percent">{manageTalent.labelForHrs}</label>
                  <Switch
                    className="percent"
                    onChange={handleSwitchChange}
                    checked={Hour}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    width={36}
                    height={20}
                    handleDiameter={16}
                    onColor="#5389F8"
                    offColor="#BFCDDA"
                  />
                  <label className="percent">
                    {manageTalent.labelForpercent}
                  </label>
                </div>
              </div>

              <Select
                options={customerOptions}
                onChange={(event) => handleInputChange(event)}
                name="talent_id"
                placeholder="Select talent name"
                className="select--field"
              />
              <p className="display_error">{errors.talent_id}</p>
              {tableData === true ? (
                <>
                  <div className="edit_Loader" />
                  {isLoading === true ? <Loader className="edit_Loader" /> : ""}
                </>
              ) : (
                <>
                  <TableInfo
                    monthDetails={thisMonthDetails}
                    monthName={
                      String(projectMonth.ThisMonth).substring(0, 3) +
                      "'" +
                      projectMonth.ThisMonthYear
                    }
                    projectName={props.projectName}
                    monthId={month_id}
                    projectId={props.id}
                    empId={getEmpId}
                    allocatedPercent={thisMonthAllocatedPercent.current}
                    allocatedHour={getthisMonthtotalHour}
                    totalpercentage={getthisMonthtotalPercentage}
                    TotalHour={getThismonthlHour}
                    Hour={Hour}
                  />
                  <TableInfo
                    monthDetails={nextMonthDetails}
                    monthName={
                      String(projectMonth.NextMonth).substring(0, 3) +
                      "'" +
                      projectMonth.NextMonthYear
                    }
                    projectName={props.projectName}
                    monthId={month_id + 1}
                    projectId={props.id}
                    empId={getEmpId}
                    allocatedPercent={nextMonthAllocatedPercent.current}
                    allocatedHour={getnextMonthtotalHour}
                    totalpercentage={getnextMonthtotalPercentage}
                    TotalHour={getnextMonthHour}
                    Hour={Hour}
                  />
                  <TableInfo
                    monthDetails={futureMonthDetails}
                    monthName={
                      String(projectMonth.FutureMonth).substring(0, 3) +
                      "'" +
                      projectMonth.FutureMonthYear
                    }
                    projectName={props.projectName}
                    monthId={month_id + 2}
                    projectId={props.id}
                    empId={getEmpId}
                    allocatedPercent={futureMonthAllocatedPercent.current}
                    allocatedHour={getfutureMonthtotalHour}
                    totalpercentage={getfutureMonthtotalPercentage}
                    TotalHour={getfutureMonthHour}
                    Hour={Hour}
                  />
                  <div className="button-container">
                    <ButtonReuse
                      type="primary"
                      className="primary-btn cancel--btn"
                      value="Finish"
                      onClick={() => {
                        onCancelHandler();
                        resetForm();
                        window.location.reload();
                      }}
                    ></ButtonReuse>
                  </div>
                </>
              )}
            </form>
          )}
        </Formik>
      </Drawer>
    </>
  );
};

export default AddOverlay;
