import { Row, Col, Checkbox, Tabs } from "antd";
import { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import RevenueTable from "./RevenueTable";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { formatCurrencyValue } from "../../../utils/util";
import { enumForBuDashboardDeliveryDetailView } from "../../../utils/enums";
require("./buDashboard.scss");

const BuDashboardDeliveryDetailView = () => {
  const { TabPane } = Tabs;
  function callback(key: any) {
    console.log(key);
  }
  const [idArr, setIdArr] = useState<any>([]); //Initital Array
  const fin_year_start =
    localStorage.getItem("selectedFy")?.split("-")[0] ?? "2021";
  const fin_year_end =
    localStorage.getItem("selectedFy")?.split("-")[1] ?? "2022";
  const [associatedBu, setAssociatedBu] = useState([]);
  const [revenueYearlyValue, setRevenueYearlyValue] = useState(0);
  const [revenueStusSold, setRevenueStusSold] = useState("");
  const [poTypes, setPoTypes] = useState([]);
  const [poTypeOne, setPoTypeOne] = useState("");
  const [poTypeTwo, setPoTypeTwo] = useState("");
  const [poTypeThree, setPoTypeThree] = useState("");
  const [isLoad, setLoad] = useState(false);
  var token = getToken();
  let history = useHistory();
  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );
  const [msRevenuefixedPriceValues, setmsRevenuefixedPriceValues] = useState(
    []
  );
  const [msRevenueTimeMaterialValues, setRevenuemsTimeMaterialValues] =
    useState([]);
  const [msRevenueRetainerValues, setmsRevenueRetainerValues] = useState([]);

  const [msRevenuefixedPriceStusSold, setmsRevenuefixedPriceStusSold] =
    useState([]);
  const [msRevenueTimeMaterialStusSold, setmsRevenueTimeMaterialStusSold] =
    useState([]);
  const [msRevenueRetainerStusSold, setmsRevenueRetainerStusSold] = useState(
    []
  );
  const [revenueMonthlyAverage, setRevenueMonthlyAverage] = useState<any>([]);
  const [revenueTableData, setRevenueTableData]: any = useState([]);
  function onChangeBU(e: any) {
    let associatedBuList: any = localStorage.getItem("associatedBus");
    associatedBuList = JSON.parse(associatedBuList);
    const value = e.target.value;
    if (e.target.checked) {
      const selectedBu = associatedBu.filter((bu: any) => bu.id === value);
      associatedBuList.push(...selectedBu);
      localStorage.setItem("associatedBus", JSON.stringify(associatedBuList));
      setIdArr([...idArr, value]);
    } else {
      const index = idArr.indexOf(value);
      idArr.splice(index, 1);
      associatedBuList.splice(index, 1);
      localStorage.setItem("associatedBus", JSON.stringify(associatedBuList));
      setIdArr([...idArr]);
    }
  }

  const getProjectTypes = async () => {
    await AxiosConfig.get("potypes", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setPoTypes(res.data.data.poTypes);
        setPoTypeOne(res.data.data.poTypes[0].type);
        setPoTypeTwo(res.data.data.poTypes[1].type);
        setPoTypeThree(res.data.data.poTypes[2].type);
        setLoad(true);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setId();
  }, [associatedBu]);

  const setId = () => {
    const idArrList: any = [];
    setIdArr([]);
    let associatedBuList: any = localStorage.getItem("associatedBus");
    associatedBuList = JSON.parse(associatedBuList);
    associatedBuList?.map((row: { id: any }) => {
      idArrList.push(row.id);
      return {
        id: setIdArr((id_arr: any) => id_arr.concat(row.id)),
      };
    });
  };

  const isChecked = (id: any) => {
    let associatedBuList: any = localStorage.getItem("associatedBus");
    associatedBuList = JSON.parse(associatedBuList);
    if (associatedBuList) {
      for (const bu of associatedBuList) {
        if (bu.id === id) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  };
  const allValues = {
    departments_id_array: idArr,
    fin_year_start: parseInt(fin_year_start),
    fin_year_end: parseInt(fin_year_end),
  };
  localStorage.setItem("idArray", JSON.stringify(allValues));

  const months = [
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ];

  const getMonthlyEarnings = (monthIndex: any, po_type_id_array: any) => {
    const month = months[monthIndex];
    const departments_id_array: any = idArr;
    let fin_month_year: any = monthIndex >= 9 ? fin_year_end : fin_year_start;
    fin_month_year = parseInt(fin_month_year);
    const data: any = {
      departments_id_array,
      fin_month_year,
      po_type_id_array,
      month,
    };
    history.push({ pathname: "/monthlyearnings", state: data });
  };

  const revenueDataValue = {
    labels: months,
    datasets: [
      {
        label: poTypeOne,
        data: msRevenuefixedPriceValues,
        backgroundColor: "rgba(92, 103, 229, 0.6)",
        yAxisID: "y",
      },
      {
        label: poTypeTwo,
        data: msRevenueTimeMaterialValues,
        backgroundColor: "rgba(248, 192, 83, 0.6)",
        yAxisID: "y",
      },
      {
        label: poTypeThree,
        data: msRevenueRetainerValues,
        backgroundColor: "rgba(54, 204, 237, 0.6)",
        yAxisID: "y",
      },
      {
        type: "line",
        label: "Average Revenue",
        data: revenueMonthlyAverage,
        borderColor: "#FF7A37",
        backgroundColor: "#fff",
        yAxisID: "y1",
      },
    ],
  };

  const revenueDataStus = {
    labels: months,
    datasets: [
      {
        label: poTypeOne,
        data: msRevenuefixedPriceStusSold,
        backgroundColor: "#5C67E5",
      },
      {
        label: poTypeTwo,
        data: msRevenueTimeMaterialStusSold,
        backgroundColor: "#F8C053",
      },
      {
        label: poTypeThree,
        data: msRevenueRetainerStusSold,
        backgroundColor: "#36CCED",
      },
    ],
  };

  const revenueValueConfig = {
    type: "bar",
    data: revenueDataValue,
    options: {
      onClick: function (e: any, chartItem: any) {
        const po_type_id_array: any = [];
        const monthIndex = chartItem[0]?.index;
        chartItem.map((value: any) => {
          if (value.datasetIndex !== 3) {
            po_type_id_array.push(value.datasetIndex + 1);
          }
        });
        monthIndex !== undefined &&
          getMonthlyEarnings(monthIndex, po_type_id_array);
      },
      plugins: {
        title: {
          display: true,
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          title: {
            display: true,
          },
        },
        y: {
          stacked: true,
          title: {
            display: true,
            text: "Revenue",
          },
        },
        y1: {
          type: "linear",
          position: "right",
          title: {
            display: true,
            text: "Avg Revenue",
          },
        },
      },
    },
  };

  const revenueStusConfig = {
    type: "bar",
    data: revenueDataStus,
    options: {
      onClick: function (e: any, chartItem: any) {
        const po_type_id_array: any = [];
        const monthIndex = chartItem[0]?.index;
        chartItem.map((value: any) => {
          if (value.datasetIndex !== 3) {
            po_type_id_array.push(value.datasetIndex + 1);
          }
        });
        monthIndex !== undefined &&
          getMonthlyEarnings(monthIndex, po_type_id_array);
      },
      plugins: {
        title: {
          display: true,
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          title: {
            display: true,
          },
        },
        y: {
          stacked: true,
          title: {
            display: true,
            text: "Avg Revenue",
          },
        },
      },
    },
  };

  const convertStus = (number) => {
    if (number === null) {
      return "0";
    } else {
      return number
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d)(?=\d))/g, ",");
    }
  };

  useEffect(() => {
    getAssociatedBu();
    getProjectTypes();
  }, []);

  const getAssociatedBu = async () => {
    let associatedBuList: any = localStorage.getItem("fixedAssociatedBu");
    associatedBuList = JSON.parse(associatedBuList);
    setAssociatedBu(
      associatedBuList.filter(function (associatedBu: { flag: any }) {
        return associatedBu.flag === true;
      })
    );
  };

  const getRevenueDashData = (values: any) => {
    AxiosConfig.post(
      "/revenuedash",
      { ...values, departments_id_array: idArr },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((res) => setRevenueTableData(res.data.data.revenueTableData));
  };

  const getRevenueData = (values: any) => {
    AxiosConfig.post("dashboards/revenue", values, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (!res?.data?.data?.yearlyRevenue?.length) {
          setRevenueStusSold("0");
          setRevenueYearlyValue(0);
        }
        res.data.data.yearlyRevenue.map((item: any) => {
          var convNum = formatCurrencyValue(
            currencySymb?.currency_code,
            item.revenue_value
          );
          setRevenueYearlyValue(convNum);
          var convStus = convertStus(item.stus_expended);
          setRevenueStusSold(convStus);
        });

        const msFixedPriceVal = res.data.data.monthlyRevenue.filter(
          (eachItem: any) => eachItem.type === poTypeOne
        );

        const msTimeMaterialVal = res.data.data.monthlyRevenue.filter(
          (eachItem: any) => eachItem.type === poTypeTwo
        );

        const msRetainerVal = res.data.data.monthlyRevenue.filter(
          (eachItem: any) => eachItem.type === poTypeThree
        );

        const msFixedPriceValueData: any = [];
        const msTimeMaterialValueData: any = [];
        const msRetainerValueData: any = [];

        const msFixedPriceStusData: any = [];
        const msTimeMaterialStusData: any = [];
        const msRetainerStusData: any = [];

        const msRevenueTotalValueData: any = [];
        const msRevenueTotalStusData: any = [];
        const msRevenueAvgData: any = [];

        loop1: for (let i = 0; i < months.length; i++) {
          for (let j = 0; j < msFixedPriceVal.length; j++) {
            if (msFixedPriceVal[j].month == months[i]) {
              msFixedPriceValueData.push(msFixedPriceVal[j].Revenue_Value);
              msFixedPriceStusData.push(msFixedPriceVal[j].stus_expended);
              continue loop1;
            }
          }
          msFixedPriceValueData.push(null);
          msFixedPriceStusData.push(null);
        }
        loop2: for (let i = 0; i < months.length; i++) {
          for (let j = 0; j < msTimeMaterialVal.length; j++) {
            if (msTimeMaterialVal[j].month == months[i]) {
              msTimeMaterialValueData.push(msTimeMaterialVal[j].Revenue_Value);
              msTimeMaterialStusData.push(msTimeMaterialVal[j].stus_expended);
              continue loop2;
            }
          }
          msTimeMaterialValueData.push(null);
          msTimeMaterialStusData.push(null);
        }
        loop3: for (let i = 0; i < months.length; i++) {
          for (let j = 0; j < msRetainerVal.length; j++) {
            if (msRetainerVal[j].month == months[i]) {
              msRetainerValueData.push(msRetainerVal[j].Revenue_Value);
              msRetainerStusData.push(msRetainerVal[j].stus_expended);
              continue loop3;
            }
          }
          msRetainerValueData.push(null);
          msRetainerStusData.push(null);
        }
        loop4: for (let i = 0; i < months.length; i++) {
          for (let j = 0; j < revenueTableData.length; j++) {
            if (revenueTableData[j].month == months[i]) {
              msRevenueTotalStusData.push(revenueTableData[j].stus_allocated);
              continue loop4;
            }
          }
          console.warn(revenueTableData);
          msRevenueTotalStusData.push(null);
        }

        setmsRevenuefixedPriceValues(msFixedPriceValueData);
        setRevenuemsTimeMaterialValues(msTimeMaterialValueData);
        setmsRevenueRetainerValues(msRetainerValueData);

        setmsRevenuefixedPriceStusSold(msFixedPriceStusData);
        setmsRevenueTimeMaterialStusSold(msTimeMaterialStusData);
        setmsRevenueRetainerStusSold(msRetainerStusData);

        for (let i = 0; i < months.length; i++) {
          msRevenueTotalValueData.push(
            msFixedPriceValueData[i] +
              msTimeMaterialValueData[i] +
              msRetainerValueData[i]
          );
          if (
            msRevenueTotalStusData[i] !== 0 &&
            msRevenueTotalStusData[i] !== null
          ) {
            msRevenueAvgData.push(
              msRevenueTotalValueData[i] / msRevenueTotalStusData[i]
            );
          } else if (msRevenueTotalStusData[i] === 0) {
            msRevenueAvgData.push(null);
          }
        }
        setRevenueMonthlyAverage(msRevenueAvgData);
      })
      .catch((err) => console.log(err));
  };

  const selectedYear = localStorage.getItem("selectedFy");

  useEffect(() => {
    getRevenueDashData(allValues);
  }, [idArr, isLoad, selectedYear]);

  useEffect(() => {
    getRevenueData(allValues);
  }, [revenueTableData]);

  return (
    <>
      <div className="dasboardTabsOut">
        <div className="tabs-content tab-contenets-top">
          <Row style={{ paddingBlock: "0" }}>
            <Col md={24} xs={24}>
              <ul className="buList">
                {associatedBu.map((row: { name: any; id: any }) => {
                  return (
                    <li key={row.id}>
                      <Checkbox
                        onChange={onChangeBU}
                        name={row.id}
                        value={row.id}
                        defaultChecked={isChecked(row.id)}
                      >
                        {row.name}
                      </Checkbox>
                    </li>
                  );
                })}
              </ul>
            </Col>
          </Row>

          <Row>
            <Col md={24} xs={24}>
              <RevenueTable
                revenueTableData={revenueTableData}
                idArray={idArr}
                poTypes={poTypes}
              />
              <div className="tabsOut">
                <div className="white-bg-widget mb20">
                  <div className="tab-contents">
                    <h5>
                      {
                        enumForBuDashboardDeliveryDetailView.labelForRevenueReport
                      }
                    </h5>
                    <Row>
                      <Col md={12} xs={24}>
                        <div className="sold-amount">
                          <h1>
                            {revenueYearlyValue}
                            <small>
                              <b>{revenueStusSold}</b>{" "}
                              {
                                enumForBuDashboardDeliveryDetailView.labelForStusUtilized
                              }
                            </small>
                          </h1>
                        </div>
                      </Col>
                    </Row>

                    <div className="capacitySalesOut">
                      <Tabs defaultActiveKey="1" onChange={callback}>
                        <TabPane tab="Revenue" key="1">
                          <Bar
                            data={revenueDataValue}
                            options={revenueValueConfig.options}
                          />
                        </TabPane>
                        <TabPane tab="Capacity utilised" key="2">
                          <Bar
                            data={revenueDataStus}
                            options={revenueStusConfig.options}
                          />
                        </TabPane>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default BuDashboardDeliveryDetailView;
