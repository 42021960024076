import { Table, Row, Col, Input, Typography } from "antd";
import { useState, useEffect, useRef } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { Button } from "antd";
import AddOverlay from "./AddProject";
import deleteImage from "../../../assets/images/delete.png";
import UnarchiveImage_t from "../../../assets/images/unarchive_t.png";
import archiveImage_t from "../../../assets/images/archive_t.png";
import archive from "../../../assets/images/archive.png";
import unarchive from "../../../assets/images/unarchive.png";
import AddModal from "./Modal";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import { ExportOutlined } from "@ant-design/icons";
import Switch from "react-switch";
import type { ColumnsType } from "antd/es/table";
import {
  decimalCheck,
  formatCurrencyValue,
  replaceString,
} from "../../../utils/util";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { getLocation } from "../../../stores/actions/getLocationAction";
import { useDispatch, useSelector } from "react-redux";
import { getBaseCurrency } from "../../../stores/actions/getBaseCurrencyAction";
import Loader from "../../../components/Loader/Loader";
import { project } from "../../../utils/enums";
import moment from "moment";
require("./Project.scss");

const Project = () => {
  const [data, setData] = useState([]);
  const [projectName, setProjectName] = useState([]);
  const [show, setShow] = useState(false);
  const [rowId, setRowId] = useState();
  const [filterData, setFilterData] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [getAction, setAction] = useState("");
  const [pageLoaded, setpageLoaded] = useState(false);
  const [archiveStatus, setArchiveStatus] = useState();
  const [getTotalSold, setTotalSold] = useState(0);
  const [getTotalGain, setTotalGain] = useState(0);
  const [getTotalRecognized, setTotalRecognized] = useState(0);
  const [getTotalPlanned, setTotlPlanned] = useState(0);
  const [getTotalRevUnacc, setTotalRevUnacc] = useState(0);
  const [getTotalPlanInvoice, setTotalPlanInvoice] = useState(0);
  const [getTotalInvoiced, setTotalInvoiced] = useState(0);
  const [getTotalInvoiceUnacc, setTotalInvoiceUnacc] = useState(0);
  const [getTotalLeadTrail, setTotalLeadTrail] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [filterTotalCount, setFilterTotalCount] = useState(0);
  const [userid, getuserid] = useState();

  const [csvData, setCsvData] = useState([]);
  const [getMonthId, setMonthId] = useState(0);
  const currentTableData = useRef<any>([]);

  useEffect(() => {
    getCustomers();
    getMonthsId();
  }, []);

  const getMonthsId = async () => {
    await AxiosConfig.get("financialmonths", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      const currentMonth: any = res.data.data.months.filter(
        (month: any) => month.status === "1"
      );
      setMonthId(currentMonth[0].id);
    });
  };

  const getCustomers = async () => {
    await AxiosConfig.get("customers", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      let customers = res.data.data.customers;
      customers = customers.sort((a: any, b: any) => {
        var a1 = a.name.toLowerCase();
        var b1 = b.name.toLowerCase();
        return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
      });
      setCustomers(
        customers.map((row: { name: string; id: number }) => ({
          text: row?.name,
          value: row?.name,
        }))
      );
    });
  };

  const dispatch: any = useDispatch();
  const geoLocationData = useSelector((store: any) => store.locations.location);
  const [locationData, setLocationData] = useState([]);
  const baseCurrencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );
  const [currencySymb, setCurrencySymb] = useState<any>();

  useEffect(() => {
    dispatch(getLocation());
    dispatch(getBaseCurrency());
  }, [dispatch]);

  useEffect(() => {
    if (geoLocationData) {
      setLocationData(geoLocationData);
    }
  }, [geoLocationData]);

  useEffect(() => {
    if (baseCurrencySymb) {
      setCurrencySymb(baseCurrencySymb);
    }
  }, [baseCurrencySymb]);

  let obj: any = localStorage.getItem("Departments");
  let departments = JSON.parse(obj);
  let filteredBu = [];
  if (departments === null) {
    filteredBu = [];
  } else {
    filteredBu = departments;
  }

  const departmentOptions = filteredBu.map(function (associatedBus: {
    name: string;
    id: number;
  }) {
    return {
      text: associatedBus?.name,
      value: associatedBus?.name,
    };
  });

  const locationOptions = locationData.map(function (Locations: {
    location: any;
    id: any;
  }) {
    return {
      value: Locations.id,
      label: Locations.location,
    };
  });

  let history = useHistory();
  var token = getToken();

  const [nameSearch, setNameSearch] = useState("");
  const [isDepartment, setIsDepartment] = useState(false);
  const [isArchive, setIsArchive] = useState(false);
  const { Text } = Typography;

  var totalSold = 0;
  let totalGain = 0;
  let totalRecognized = 0;
  let totalPlanned = 0;
  let totalRevUnacc = 0;
  let totalPlanInvoice = 0;
  let totalInvoiced = 0;

  let totalInvoiceUnacc = 0;
  let totalLeadTrail = 0;

  useEffect(() => {
    const addProjectAccess = sessionStorage.getItem("isDepartment");
    if (addProjectAccess === "1") {
      setIsDepartment(true);
    }
  }, []);

  useEffect(() => {
    if (data) {
      const results: any = data.filter(
        (item: any) =>
          item.Project_name.toLowerCase().includes(nameSearch.toLowerCase()) ||
          item.Customer_name.toLowerCase().includes(nameSearch.toLowerCase()) ||
          item.Associated_bu.toLowerCase().includes(nameSearch.toLowerCase()) ||
          item.Project_code.toLowerCase().includes(nameSearch.toLowerCase())
      );

      setFilterData(results);
      setFilterTotalCount(results?.length);
      results.map((res) => {
        totalSold =
          Number(totalSold) +
          Number(res.Sold_value_base != null ? res.Sold_value_base : 0);

        totalGain =
          Number(totalGain) +
          (Number(res.Gained_base_forTotal) -
            Number(res.Dropped_base_forTotal));
        totalRecognized = Number(totalRecognized) + Number(res.Recognized_base);
        totalPlanned =
          Number(totalPlanned) + Number(res.Planned_recognition_base);
        totalRevUnacc = Number(totalRevUnacc) + Number(res.UnaccountedRevenue);
        totalPlanInvoice =
          Number(totalPlanInvoice) + Number(res.Planned_invoice_base);
        totalInvoiced = Number(totalInvoiced) + Number(res.FinalInvoice_base);
        totalInvoiceUnacc =
          Number(totalInvoiceUnacc) + Number(res.UnaccountedInvoice);
        totalLeadTrail = Number(totalLeadTrail) + Number(res.Lead_Lag);
      });
      setTotalSold(totalSold);
      setTotalGain(totalGain);
      setTotalRecognized(totalRecognized);
      setTotlPlanned(totalPlanned);
      setTotalRevUnacc(totalRevUnacc);
      setTotalPlanInvoice(totalPlanInvoice);
      setTotalInvoiced(totalInvoiced);
      setTotalInvoiceUnacc(totalInvoiceUnacc);
      setTotalLeadTrail(totalLeadTrail);
    }
  }, [nameSearch]);


  const getUser = async () => {
    try {
      const response = await AxiosConfig.get("users/current", {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data && response.data.data && response.data.data.user) {
        const user_id = response.data.data.user.id;
        getuserid(user_id);
      } else {
        console.error("User data not found in the API response");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    if (currencySymb?.currency_code) {
      getData();
      setpageLoaded(true);
    }
  }, [currencySymb?.currency_code]);

  useEffect(() => {
    if (pageLoaded) {
      getUser();
      getData();
    }
  }, [isArchive, pageLoaded]);

  useEffect(() => {
    getData();
  }, [userid]);

  const getData = async (geoIds?: number[]) => {
    await AxiosConfig.get(`projects`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        archive: isArchive,
        geoIds: geoIds,
        user_id: userid,
      },
    }).then((res) => {
      let proj = res.data.data.projects;
      if (proj.length >= 0) {
        setIsLoading(false);
      }
      setProjectName(
        proj.map((row: { name: any; id: any }) => ({
          text: row.name,
          value: row.name,
        }))
      );
      const tableData = res.data.data.projects.map(
        (row: {
          name: any;
          id: any;
          customer: any;
          department: any;
          stus_sold: any;
          project_code: any;
          $Value: any;
          revenue: any;
          stus_expended: any;
          future_offset: any; //unaccounted
          total_final_amt: any;
          archive_status: any;
          recognized_tilldate: any;
          Recognized: any;
          accounted: any;
          total_sold_base: any;
          lead_lag: any;
          final_invoice_base: any;
          unaccounted: any;
          invoice_unaccounted: any;
          planned_invoice_base: any;
          this_month_value: any;
          accounted_base: any;
          dropped: any;
          dropped_base: any;
          gained: any;
          gained_base: any;
          Planned_recognition: any;
          Po_type_id: any;
          RevForex: any;
          Rev_Forex: any;
          InvForex: any;
          Inv_Forex: any;
          RevForexTilldate: any;
          Sold_value_base: any;
          Geography: any;
          UnaccountedRevenue: any;
          UnaccountedInvoice: any;
          totalLeadLag: any;
          created_at: any;
        }) => ({
          created_at: row?.created_at,
          Project_code: row?.project_code,
          Project_name: row?.name,
          Customer_name: row?.customer.name,
          Associated_bu: row?.department.name,
          sold: formatCurrencyValue(
            currencySymb?.currency_code,
            row?.total_sold_base
          ),
          Sold_value_base: row?.total_sold_base,
          Stus_sold: decimalCheck(
            row?.stus_sold,
            2,
            currencySymb?.currency_symbol
          ),
          Sold_value: decimalCheck(
            row?.total_sold_base + row?.gained_base,
            2,
            currencySymb?.currency_symbol
          ),
          Sold_value_in_base: row?.total_sold_base + row?.gained_base,
          Delete_Action: row?.id,
          key: row?.id,
          Earned_value: decimalCheck(row?.revenue ? row?.revenue : null, 2),
          Stus_expended: decimalCheck(row?.stus_expended, 2),
          Pending: decimalCheck(row?.$Value - row?.future_offset, 2),
          final_invoice_base: formatCurrencyValue(
            currencySymb?.currency_symbol,
            row?.total_final_amt
          ),
          FinalInvoice_base: row?.total_final_amt,
          lead_lag: formatCurrencyValue(
            currencySymb?.currency_code,
            row?.lead_lag
          ),
          totalLeadLag: row?.lead_lag,
          recognized_tilldate: decimalCheck(
            row?.recognized_tilldate,
            2,
            currencySymb?.currency_symbol
          ),
          Recognized_tilldate_base: row?.recognized_tilldate,
          Recognized: formatCurrencyValue(
            currencySymb?.currency_code,
            row?.Recognized
          ),
          Recognized_base: row?.Recognized,
          this_month_value: decimalCheck(
            row?.this_month_value,
            2,
            currencySymb?.currency_symbol
          ),
          accounted: decimalCheck(row?.accounted, 2),
          accounted_base: decimalCheck(row?.accounted_base, 2),
          Accounted_base: row?.accounted_base,
          unaccounted: formatCurrencyValue(
            currencySymb?.currency_code,
            row?.UnaccountedRevenue
          ),
          planned_invoice_base: formatCurrencyValue(
            currencySymb?.currency_code,
            row?.planned_invoice_base
          ),
          Planned_invoice_base: row?.planned_invoice_base,
          invoice_unaccounted: formatCurrencyValue(
            currencySymb?.currency_code,
            row?.UnaccountedInvoice
          ),
          Unaccounted_Format: row?.UnaccountedRevenue,
          invoice_Uncc_format: row?.UnaccountedInvoice,
          Gained: decimalCheck(row?.gained, 2),
          Gained_base_forTotal: row?.gained_base,
          Dropped_base_forTotal: row?.dropped_base,
          Gained_base: decimalCheck(row?.gained_base, 2),
          Gain_Dropped: decimalCheck(
            row?.gained - row?.dropped,
            2,
            currencySymb?.currency_symbol
          ),
          Gain_Dropped_base: formatCurrencyValue(
            currencySymb?.currency_code,
            row?.gained_base - row?.dropped_base
          ),
          Planned_recognition: formatCurrencyValue(
            currencySymb?.currency_code,
            row?.Planned_recognition
          ),
          Planned_recognition_base: row?.Planned_recognition,
          archive_status: {
            id: row?.id,
            status: row?.archive_status,
          },
          RevForex: decimalCheck(
            row?.RevForex,
            2,
            currencySymb?.currency_symbol
          ),
          Rev_Forex: row?.RevForex,
          RevForexTilldate: row?.RevForexTilldate,
          InvForex: decimalCheck(
            row?.InvForex,
            2,
            currencySymb?.currency_symbol
          ),
          Inv_Forex: row?.InvForex,
          UnaccountedRevenue: row?.UnaccountedRevenue,
          UnaccountedInvoice: row?.UnaccountedInvoice,
          Lead_Lag: row?.lead_lag,
        })
      );

      setData(tableData);
      setFilterData(tableData);
      setFilterTotalCount(tableData?.length);
      res.data.data.projects.map((res) => {
        totalSold = totalSold + res.total_sold_base;
        totalGain = totalGain + (res.gained_base - res.dropped_base);
        totalRecognized = totalRecognized + res.Recognized;
        totalPlanned = totalPlanned + res.Planned_recognition;
        totalRevUnacc = totalRevUnacc + res.UnaccountedRevenue;
        totalPlanInvoice = totalPlanInvoice + res.planned_invoice_base;
        totalInvoiced = totalInvoiced + res.final_invoice_base;
        totalInvoiceUnacc = totalInvoiceUnacc + res.UnaccountedInvoice;
        totalLeadTrail = totalLeadTrail + res.lead_lag;
      });
      setTotalSold(totalSold);
      setTotalGain(totalGain);
      setTotalRecognized(totalRecognized);
      setTotlPlanned(totalPlanned);
      setTotalRevUnacc(totalRevUnacc);
      setTotalPlanInvoice(totalPlanInvoice);
      setTotalInvoiced(totalInvoiced);
      setTotalInvoiceUnacc(totalInvoiceUnacc);
      setTotalLeadTrail(totalLeadTrail);
    });
  };

  const handleDelete = (id: any) => {
    setRowId(id);
    setShow(true);
    setAction("delete");
  };

  const handleArchive = async (data: any) => {
    setRowId(data.id);
    setShow(true);
    setAction("archive");
    setArchiveStatus(data.status);
  };

  const onClickRow = (record: any) => {
    history.push("/projects/" + record);
    localStorage.setItem("projectsId", record);
  };

  const projectHeaders = [
    { label: "CODE", key: "Project_code" },
    { label: "NAME", key: "Project_name" },
    { label: "CUSTOMER", key: "Customer_name" },
    { label: "DEPARTMENT", key: "Associated_bu" },
    { label: "REVENUE - SOLD", key: "sold" },
    { label: "REVENUE - Gain/(Dropped)", key: "Gain_Dropped_base" },
    { label: "REVENUE - RECOGNIZED", key: "Recognized" },
    { label: "REVENUE - PLANNED", key: "Planned_recognition" },
    { label: "REVENUE - UNACCOUNTED", key: "unaccounted" },
    { label: "INVOICING - PLANNED", key: "planned_invoice_base" },
    { label: "INVOICING - INVOICED", key: "final_invoice_base" },
    { label: "INVOICING - UNACCOUNTED", key: "invoice_unaccounted" },
    { label: "INVOICING - Lead/Trail", key: "lead_lag" },
    { label: "RevForex", key: "RevForex" },
  ];

  interface DataType {}

  const columns: ColumnsType<DataType> = [
    {
      title: "CODE",
      fixed: "left",
      dataIndex: ["Project_code", "Delete_Action"],
      key: "key",
      render: (text: any, record: any) => (
        <a
          onClick={() => {
            onClickRow(record["Delete_Action"]);
          }}
        >
          {record["Project_code"]}
        </a>
      ),
      sorter: (a: any, b: any) =>
        a.Project_code.replace("STP_", "") - b.Project_code.replace("STP_", ""),
    },
    {
      title: "NAME",
      fixed: "left",
      dataIndex: "Project_name",
      key: "name",
      filters: projectName,
      defaultSortOrder: "ascend",
      onFilter: (value: any, record: any) =>
        record.Project_name.includes(value),
      sorter: (a: any, b: any) =>
        moment(b.created_at).diff(moment(a.created_at)),
    },
    {
      title: "BU",
      fixed: "left",
      dataIndex: "Associated_bu",
      key: "Associated_bu",
      filters: departmentOptions,
      onFilter: (value: any, record: any) => {
        return record.Associated_bu.includes(value);
      },
      sorter: (a: any, b: any) =>
        a.Associated_bu.localeCompare(b.Associated_bu),
    },
    {
      title: "CUSTOMER",
      fixed: "left",
      dataIndex: "Customer_name",
      key: "Customer_name",
      filters: customers,
      onFilter: (value: any, record: any) =>
        record.Customer_name.includes(value),
      sorter: (a: any, b: any) =>
        a.Customer_name.localeCompare(b.Customer_name),
    },
    {
      title: "REVENUE",
      className: "Revenue",
      children: [
        {
          title: "Sold",
          align: "right",
          dataIndex: "sold",
          key: "sold",
          className: "Revenue",
          width: "8%",
          sorter: (a: any, b: any) =>
            replaceString(a.sold) - replaceString(b.sold),
        },
        {
          title: "Gain/(Dropped)",
          align: "right",
          dataIndex: "Gain_Dropped_base",
          key: "Gain_Dropped_base",
          className: "Revenue",
          width: "8%",
          sorter: (a: any, b: any) =>
            replaceString(a.Gain_Dropped_base) -
            replaceString(b.Gain_Dropped_base),
        },
        {
          title: "Earned",
          align: "right",
          dataIndex: "Recognized",
          key: "Recognized",
          className: "Revenue",
          sorter: (a: any, b: any) =>
            replaceString(a.Recognized) - replaceString(b.Recognized),
        },
        {
          title: "Planned",
          align: "right",
          dataIndex: "Planned_recognition",
          key: "Planned_recognition",
          className: "Revenue",
          sorter: (a: any, b: any) =>
            replaceString(a.Planned_recognition) -
            replaceString(b.Planned_recognition),
        },
        {
          title: "Unaccounted",
          align: "right",
          dataIndex: "unaccounted", //unaccounted
          key: "unaccounted",
          className: "Revenue",
          // className: "text-end",
          sorter: (a: any, b: any) =>
            replaceString(a.unaccounted) - replaceString(b.unaccounted),
          render: (text: any, record: any) => (
            <Col
              style={{
                color: record["Unaccounted_Format"] > 0 ? "red" : "green",
              }}
            >
              <p className="showVarienceOfUnaccounted">
                {record["unaccounted"]}
              </p>
            </Col>
          ),
        },
      ],
    },
    {
      title: "INVOICING",
      className: "Invoice",
      children: [
        {
          title: "Planned",
          align: "right",
          dataIndex: "planned_invoice_base",
          key: "planned_invoice_base",
          className: "Invoice",
          sorter: (a: any, b: any) =>
            replaceString(a.planned_invoice_base) -
            replaceString(b.planned_invoice_base),
        },
        {
          title: "Invoiced",
          align: "right",
          dataIndex: "final_invoice_base",
          key: "final_invoice_base",
          className: "Invoice",
          sorter: (a: any, b: any) =>
            replaceString(a.final_invoice_base) -
            replaceString(b.final_invoice_base),
        },
        {
          title: "Unaccounted",
          align: "right",
          dataIndex: "invoice_unaccounted",
          key: "invoice_unaccounted",
          className: "Invoice",
          sorter: (a: any, b: any) =>
            replaceString(a.invoice_unaccounted) -
            replaceString(b.invoice_unaccounted),
          render: (text: any, record: any) => (
            <Col
              style={{
                color: record["invoice_Uncc_format"] > 0 ? "red" : "green",
              }}
            >
              <p className="showVarienceOfUnaccountedInvoicing">
                {record["invoice_unaccounted"]}
              </p>
            </Col>
          ),
        },
        {
          title: "Lead/Trail",
          align: "right",
          dataIndex: "lead_lag",
          key: "lead_lag",
          className: "Invoice",
          sorter: (a: any, b: any) =>
            replaceString(a.lead_lag) - replaceString(b.lead_lag),
        },
      ],
    },
    {
      title: "ACTION",
      dataIndex: "Delete_Action",
      key: "id",
      render: (record: any) => (
        <>
          <div className="actionBtns">
            <span onClick={() => handleDelete(record)}>
              <img src={deleteImage} />
            </span>
          </div>
        </>
      ),
    },
    {
      title: !isArchive ? "ARCHIVE" : "UNARCHIVE",
      dataIndex: "archive_status",
      key: "archive_status",
      render: (record: any) => (
        <>
          {isArchive ? (
            <div className="actionBtns">
              <span onClick={() => handleArchive(record)}>
                <img src={unarchive} style={{ height: 20, width: 20 }} />
              </span>
            </div>
          ) : (
            <div className="actionBtns">
              <span onClick={() => handleArchive(record)}>
                <img src={archive} style={{ height: 20, width: 20 }} />
              </span>
            </div>
          )}
        </>
      ),
    },
  ];

  const [visible, setVisible] = useState(false);

  const visibleHandler = () => {
    setVisible(true);
  };
  const closeHandler = () => {
    setVisible(false);
  };

  const handleOnChange = (event) => {
    setNameSearch(event.target.value);
  };

  const handleSwitchChange = () => {
    setIsArchive(!isArchive);
    // getData();
  };

  function CustomMultiSelectContainer({ children }) {
    return <div className="custom-container">{children}</div>;
  }

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectChange = (selected) => {
    const geoIds = selected?.map((val) => val.value);
    setSelectedOptions(selected);
    getData(geoIds);
  };

  return (
    <>
      <div className="table-card">
        <Row>
          <Col md={8} xs={24} lg={8} xl={5}>
            <div className="searchForm">
              <form>
                <Input.Search
                  allowClear
                  onChange={handleOnChange}
                  placeholder="Search by project / customer / BU"
                />
              </form>
            </div>
          </Col>
          <Col md={2} xs={24} lg={2} xl={2}>
            <label className="totalLabel">
              {project?.labelForTotal} {filterTotalCount}
            </label>
          </Col>
          <Col md={2} xs={24} lg={4} xl={3}>
            <label className="geography"> {project?.labelForGeography} </label>
          </Col>
          <Col md={3} xs={3} lg={3} xl={4} className="locationDropdown">
            <CustomMultiSelectContainer>
              <ReactMultiSelectCheckboxes
                options={locationOptions}
                placeholderButtonLabel="Select"
                value={selectedOptions}
                onChange={handleSelectChange}
              />
            </CustomMultiSelectContainer>
          </Col>

          <Col md={8} xs={24} lg={8} xl={9} className="column">
            <Switch
              onChange={handleSwitchChange}
              checked={isArchive}
              checkedIcon={
                <img
                  src={UnarchiveImage_t}
                  style={{ height: "15px", marginLeft: ".50rem" }}
                />
              }
              onColor="#d0021b"
              uncheckedIcon={
                <img
                  src={archiveImage_t}
                  style={{ height: "15px", marginLeft: ".35rem" }}
                />
              }
              offColor="#1b9558"
            />
            {isDepartment && (
              <div className="addProjectBtn">
                <Button
                  type="primary"
                  onClick={visibleHandler}
                  className="primary-btn"
                >
                  {project?.buttonForAddProject}
                </Button>
              </div>
            )}
            <CSVLink
              data={csvData}
              filename="projects.csv"
              className="exportTableButton"
              title="Export Table"
              headers={projectHeaders}
            >
              <ExportOutlined
                onClick={() => {
                  const arrayWithoutArchieve = currentTableData.current.map(
                    (obj) => ({
                      Project_code: obj.Project_code,
                      Project_name: obj.Project_name,
                      Customer_name: obj.Customer_name,
                      Associated_bu: obj.Associated_bu,
                      sold: obj.sold,
                      Gain_Dropped_base: obj.Gain_Dropped_base,
                      Recognized: obj.Recognized,
                      Planned_recognition: obj.Planned_recognition,
                      unaccounted: obj.unaccounted,
                      planned_invoice_base: obj.planned_invoice_base,
                      final_invoice_base: obj.final_invoice_base,
                      invoice_unaccounted: obj.invoice_unaccounted,
                      lead_lag: obj.lead_lag,
                      RevForex: obj.RevForex,
                    })
                  );
                  setCsvData(arrayWithoutArchieve);
                }}
                className="exportOultineIcon"
              />
            </CSVLink>
          </Col>
        </Row>

        <AddOverlay
          visible={visible}
          onClose={closeHandler}
          onCancelButton={closeHandler}
        ></AddOverlay>
        {isLoading === true ? (
          <Loader />
        ) : (
          <div className="tableOut noWordBreak">
            <Table
              columns={columns}
              dataSource={filterData}
              bordered
              className="projectsTable"
              scroll={{ x: 2400, y: 680 }}
              pagination={false}
              onChange={(pagination, filters, sorter, extra) => {
                const filteredData: any = extra.currentDataSource;
                filteredData.map((res) => {
                  totalSold =
                    Number(totalSold) +
                    Number(
                      res.Sold_value_base != null ? res.Sold_value_base : 0
                    );

                  totalGain =
                    Number(totalGain) +
                    (Number(res.Gained_base_forTotal) -
                      Number(res.Dropped_base_forTotal));
                  totalRecognized =
                    Number(totalRecognized) + Number(res.Recognized_base);
                  totalPlanned =
                    Number(totalPlanned) + Number(res.Planned_recognition_base);
                  totalRevUnacc =
                    Number(totalRevUnacc) + Number(res.UnaccountedRevenue);
                  totalPlanInvoice =
                    Number(totalPlanInvoice) + Number(res.Planned_invoice_base);
                  totalInvoiced =
                    Number(totalInvoiced) + Number(res.FinalInvoice_base);
                  totalInvoiceUnacc =
                    Number(totalInvoiceUnacc) + Number(res.UnaccountedInvoice);
                  totalLeadTrail =
                    Number(totalLeadTrail) + Number(res.Lead_Lag);
                });
                setTotalSold(totalSold);
                setTotalGain(totalGain);
                setTotalRecognized(totalRecognized);
                setTotlPlanned(totalPlanned);
                setTotalRevUnacc(totalRevUnacc);
                setTotalPlanInvoice(totalPlanInvoice);
                setTotalInvoiced(totalInvoiced);
                setTotalInvoiceUnacc(totalInvoiceUnacc);
                setTotalLeadTrail(totalLeadTrail);

                const filteredCount = filteredData.length;
                setFilterTotalCount(filteredCount);
              }}
              summary={(currentData) => {
                currentTableData.current = currentData;
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} className="totalProject">
                        {project?.textForTotal}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={1}
                        className="projSummary"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={2}
                        className="projSummary"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={3}
                        className="projSummary"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell index={4} className="projSummary1">
                        <Text>
                          {formatCurrencyValue(
                            currencySymb?.currency_code,
                            getTotalSold
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5} className="projSummary1">
                        <Text>
                          {formatCurrencyValue(
                            currencySymb?.currency_code,
                            getTotalGain
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6} className="projSummary1">
                        <Text>
                          {formatCurrencyValue(
                            currencySymb?.currency_code,
                            getTotalRecognized
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={7} className="projSummary1">
                        <Text>
                          {formatCurrencyValue(
                            currencySymb?.currency_code,
                            getTotalPlanned
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={8} className="projSummary1">
                        <Text>
                          {formatCurrencyValue(
                            currencySymb?.currency_code,
                            getTotalRevUnacc
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={9} className="projSummary2">
                        <Text>
                          {formatCurrencyValue(
                            currencySymb?.currency_code,
                            getTotalPlanInvoice
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={10} className="projSummary2">
                        <Text>
                          {formatCurrencyValue(
                            currencySymb?.currency_code,
                            getTotalInvoiced
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={11} className="projSummary2">
                        <Text>
                          {formatCurrencyValue(
                            currencySymb?.currency_code,
                            getTotalInvoiceUnacc
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={12} className="projSummary2">
                        <Text>
                          {formatCurrencyValue(
                            currencySymb?.currency_code,
                            getTotalLeadTrail
                          )}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </div>
        )}
      </div>
      <AddModal
        onClose={() => setShow(false)}
        show={show}
        rowData={rowId}
        action={getAction}
        archive_status={archiveStatus}
        monthid={getMonthId}
      ></AddModal>
    </>
  );
};

export default Project;
