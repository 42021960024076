import { useParams } from "react-router-dom";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { efficiencySummary } from "../../../utils/enums";

const EfficienctSummary = () => {
  var token = getToken();
  let { id }: any = useParams();
  console.log({ id });
  const [executedStu, setExecutedStu] = useState([]);
  const [expandedStu, setExpandedStu] = useState([]);
  const [effDetails, setEffDetails] = useState();

  const convertNumber = (number: any) => {
    if (number === null || number === undefined) {
      return "0";
    } else {
      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
      }).format(number);
    }
  };
  const option = {
    style: "percent",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  };
  const formatter = new Intl.NumberFormat("en-US", option);
  const effValue = (value: any) => {
    const formatter = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 2,
      // minimumFractionDigits: 2,
    });
    if (value == null) {
      return "0";
    } else {
      const salesValue = value ?? 0;
      return formatter.format(salesValue);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    AxiosConfig.get("projects/efficiency/lifetime/" + id, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setExecutedStu(res.data.data.stus_executed);
      setExpandedStu(res.data.data.stus_expended);
      setEffDetails(res.data.data.efficiency);
    });
  };
  return (
    <div className="earnedSummary-Info">
      <Row className="earnedSummaryHeader">
        <Col span={24}>
          <h1> {efficiencySummary.headingForDeliveryEfficiency}</h1>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <p>{efficiencySummary.labelForEarnedStus}</p>
        </Col>
        <Col span={8} className="text-end">
          <p>{effValue(executedStu)}</p>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <p>{efficiencySummary.labelForUtilizedStus}</p>
        </Col>
        <Col span={8} className="text-end">
          <p>{convertNumber(expandedStu)}</p>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <p>{efficiencySummary.labelForEfficiency}</p>
        </Col>
        {console.log(effDetails)}
        <Col span={8} className="text-end">
          <p>{formatter.format(effDetails ?? 0)}</p>
        </Col>
      </Row>
    </div>
  );
};

export default EfficienctSummary;
