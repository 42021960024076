import { Table, notification } from "antd";
import ButtonReuse from "../../../../components/Buttons/Button";
import { InputField } from "../../../../components";
import { useState } from "react";
import { AxiosConfig } from "../../../../ApiConfig";
import { getToken } from "../../../../auth";
import {
  allowOnlyNumbers,
  convertNumberToPer,
  convertPerToNumber,
} from "../../../../utils/util";
import { Buttons } from "../../../../utils/enums";

const TableInfo = (props: {
  monthDetails?: any;
  monthName?: string;
  projectName?: string;
  monthId?: number;
  projectId?: number;
  empId?: string;
  allocatedPercent?: string;
  allocatedHour?: any;
  monthtotalPercentage?: string;
  totalpercentage?: any;
  TotalHour?: any;
  Hour?: any;
}) => {
  const openNotification = () => {
    notification.open({
      message: "Updated Successfully",
    });
  };

  const token = getToken();
  const [allocatedPercentage, setAllocatedPercentage] = useState("");
  const [allocatedHour, setAllocatedHour] = useState("");

  const openErrNotification = () => {
    notification.error({
      message: "Invalid Allocation",
      description: "Please enter valid number",
      style: { color: "red" },
    });
  };
  const notify = () => {
    notification.error({
      message: "Notification",
      description: `Total allocation cannot be greater than 100%`,
      style: { color: "red" },
    });
  };
  const onChange = (e) => {
    const val = allowOnlyNumbers(e);
    if (val !== false) {
      setAllocatedPercentage(val);
      const totalHours = convertPerToNumber(val, props.TotalHour);
      setAllocatedHour(totalHours?.toString());
    }
  };
  const onChanges = (e) => {
    const val = allowOnlyNumbers(e);
    if (val !== false) {
      setAllocatedHour(val);
      const totalPercent = convertNumberToPer(val, props.TotalHour);
      setAllocatedPercentage(totalPercent?.toString());
    }
  };

  const addAllocattionToProject = async () => {
    const total = props.totalpercentage + Number(allocatedPercentage);

    if (props.empId != null && total <= 100) {
      let data: {
        month_id: any;
        project_id: any;
        emp_id: any;
        allocated_percentage: any;
        year: any;
      };
      data = {
        month_id: props.monthId,
        project_id: props.projectId,
        emp_id: props.empId,
        allocated_percentage: allocatedPercentage,
        year: 2023,
      };
      await AxiosConfig.post(`allocations/`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          console.log("/:id", res);
          openNotification();
        })
        .catch((res) => {
          console.log("/:id", res);
          openErrNotification();
        });
    } else {
      total >= 100 ? notify() : openErrNotification();
    }
  };
  const columnsMonth = [
    {
      title: "Project Name",
      dataIndex: ["project_name"],
      key: "project_name",
      width: "8%",
    },
    {
      title: "Hours",
      dataIndex: ["allocated_hours"],
      key: "allocated_hours",
      width: "8%",
      render: (text: any, record: any) =>
        record.project_name === props.projectName ? (
          <InputField
            disabled={props.Hour ? true : false}
            placeholder="Enter allocated Hour"
            name="allocated_Hours"
            onChange={onChanges}
            value={
              allocatedHour ? allocatedHour.toString() : props.allocatedHour
            }
            className="input--field"
          ></InputField>
        ) : (
          <>
            <p>{record.allocated_hours} hrs</p>
          </>
        ),
    },

    {
      title: "Percentage",
      dataIndex: ["allocated_percentage"],
      key: "allocated_percentage",
      width: "8%",
      render: (text: any, record: any) =>
        record.project_name === props.projectName ? (
          <InputField
            disabled={props.Hour ? false : true}
            placeholder="Enter allocated percentage"
            name="allocated_percentage"
            onChange={onChange}
            value={
              allocatedPercentage ? allocatedPercentage : props.allocatedPercent
            }
            className="input--field"
          ></InputField>
        ) : (
          <p>{record.allocated_percentage} %</p>
        ),
    },
  ];

  return (
    <div>
      <br />
      <h1
        style={{
          fontSize: "18px",
          color: "#4952B9",
          textTransform: "uppercase",
        }}
      >
        {props.monthName}
      </h1>
      <div style={{ marginTop: -35, textAlign: "center" }}>
        ({Buttons.textForTotalAvailableHrs} {props.TotalHour})
      </div>
      <div style={{ marginTop: -20, textAlign: "right" }}>
        {(props.totalpercentage).toFixed(2)}%/100%
      </div>
      <table>
        <Table
          columns={columnsMonth}
          dataSource={props.monthDetails}
          bordered
          className="ThisMonthTable"
          scroll={{ x: 500 }}
          pagination={false}
        />{" "}
      </table>
      <br />
      <ButtonReuse
        type="primary"
        className="primary-btn"
        htmlType="submit"
        value="Update"
        onClick={addAllocattionToProject}
      ></ButtonReuse>
    </div>
  );
};

export default TableInfo;
