import "./Basicinfo.scss";
import edit from "../../../assets/images/edit.png";
import { Row, Col, Modal, Button, Typography, Image } from "antd";
import EditOverlay from "../Project/EditProject";
import { useState, useEffect, useRef } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import ViewAttachment from "./ViewAttachment";
import ButtonReuse from "../../../components/Buttons/Button";
import { DownloadOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";
import moment from "moment";
import { basicInfo } from "../../../utils/enums";
import pdfPlaceholderImage from "../../../assets/images/pdfPlaceHolder.png";
import xlsxPlaceholderImage from "../../../assets/images/xlsx_thumbnail.png";
import docxPlaceholderImage from "../../../assets/images/docximage.png";

interface IProjectOwner {
  id: number;
  is_active: boolean;
  start_date: Date;
  end_date: Date;
  project_id: number;
  owner_id: number;
  user: {
    id: number;
    firstName: string;
    lastName: string;
  };
}

const BasicInfo = (props: {
  projectNameParent?: any;
  customerNameParent?: any;
  associatedBUParent?: any;
  projectCodeParent?: any;
  managersParent?: any;
  attachmentIds?: any;
  isBillable?: any;
  locationNameParent?: any;
  billablaeGeoOption?: string;
  editreq?: any;
  flag?: boolean;
  projectId?: number;
  projectOwners: IProjectOwner[];
}) => {
  const { Text } = Typography;
  const monthFormat = "MM/YYYY";
  let newedit = edit;
  const projectOwnersCount = props.projectOwners.length;

  if (props.editreq == false) {
    newedit = "";
  }

  let attachmentIds = props.attachmentIds;

  var token = getToken();
  let fileData: any = useRef({});

  const [visible, setVisible] = useState(false);

  const visibleHandler = () => {
    setVisible(true);
  };
  const closeHandler = () => {
    setVisible(false);
  };

  const [image, setImage] = useState<any>([]);
  const [isModalVisibleImage, setIsModalVisibleImage] = useState(false);

  let image_arr: any[] = [];

  const showViewModalImage = () => {
    setIsModalVisibleImage(true);
  };
  const handleCancelModalImage = () => {
    setIsModalVisibleImage(false);
  };

  const handleDownload = (file_id: any) => {
    saveAs(
      fileData["current"][file_id]["data"],
      fileData["current"][file_id]["attachment_id"].split("^").pop()
    );
  };

  useEffect(() => {
    attachmentIds.forEach(function (row: any) {
      const attachment_id = row.attachment_id;
      getAttachments(attachment_id);
    });
  }, [attachmentIds]);

  const getAttachments = async (attachment_id: any) => {
    AxiosConfig.get("projects/attachments/" + attachment_id, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    }).then((res) => {
      const url = URL.createObjectURL(res.data);
      fileData["current"][url] = { data: res.data, attachment_id };
      image_arr.push(url);
      setImage(image_arr);
    });
  };
  const images = image.map((img_url: any, index: any) => {
    const fileNameWithExtension = fileData["current"][img_url]["attachment_id"];
    const fileName = fileNameWithExtension.split("_").slice(0, -1).join("_");
   

    const fileType = fileNameWithExtension.split(".").pop();
    { 
      return (
        <div className="attachment" key={index}>
          {fileType.toLowerCase() === "pdf" ? (
            <div className="pdf-placeholder">
              <img
                src={pdfPlaceholderImage}
                alt="PDF Placeholder"
                className="pdf-placeholder-image"
              />
            </div>
          ) : fileType.toLowerCase() === "xlsx" ? (
            <div className="xlsx-placeholder">
              <img
                src={xlsxPlaceholderImage}
                alt="XLSX Placeholder"
                className="xlsx-placeholder-image"
              />
            </div>
          ) : fileType.toLowerCase() === "docx" ? (
            <div className="docx-placeholder">
              <img
                src={docxPlaceholderImage}
                alt="DOCX Placeholder"
                className="docx-placeholder-image"
              />
            </div>
          ) : (
            <Image
              className="attachment-img"
              width={62}
              height={62}
              src={img_url}
              alt="Attachment"
            />
          )}
          <Button
            type="primary"
            shape="round"
            icon={<DownloadOutlined />}
            size="small"
            onClick={() => handleDownload(img_url)}
          />
          <text>
            {fileName}
          </text>
        </div>
      );
    }
  });

  return (
    <div className="basic-info">
      <Row className="BasinInfoHeader">
        <Col span={20}>
          <h1>{props.projectNameParent}</h1>
        </Col>
        <Col span={4} className="imgColumn">
          <img
            src={newedit}
            onClick={visibleHandler}
            className="editProjectIcon"
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <p>{basicInfo.textForProjectCode}</p>
        </Col>
        <Col span={12}>
          <p>{props.projectCodeParent}</p>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <p>{basicInfo.textForCustomer}</p>
        </Col>
        <Col span={12}>
          <p>{props.customerNameParent}</p>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <p>{basicInfo.textForDepartment}</p>
        </Col>
        <Col span={12}>
          <p>{props.associatedBUParent}</p>
        </Col>
      </Row>
      {projectOwnersCount > 0 && (
        <Row>
          <Col span={12}>
            <p>{basicInfo.textForProjectOwner}</p>
          </Col>
          <Col span={12}>
            <p style={{ marginBlockEnd: "0.5rem" }}>{`${
              props.projectOwners[projectOwnersCount - 1]?.user?.firstName
            } ${
              props.projectOwners[projectOwnersCount - 1]?.user?.lastName
            }`}</p>
            <Text type="secondary">
              {`${moment(
                props.projectOwners[projectOwnersCount - 1]?.start_date
              ).format(monthFormat)} - ${moment(
                props.projectOwners[projectOwnersCount - 1]?.end_date
              ).format(monthFormat)}`}
            </Text>
          </Col>
        </Row>
      )}

      <Row>
        <Col span={12}>
          <p>{basicInfo.textForBillable}</p>
        </Col>
        <Col span={12}>
          <p>{props.isBillable}</p>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <p>{basicInfo.textForBillableOption} </p>
        </Col>
        <Col>
          <p>{props?.billablaeGeoOption}</p>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <p>{basicInfo.textForGeography}</p>
        </Col>
        <Col span={12}>
          <p>{props.locationNameParent}</p>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <p>{basicInfo.textForProjectManager}</p>
        </Col>
        <Col span={12}>
          {Array.isArray(props.managersParent) &&
          props.managersParent.length ? (
            props.managersParent.map((managerData: any) => {
              return (
                <p key={managerData.id}>{`${managerData.firstName ?? ""} ${
                  managerData.lastName ?? ""
                }`}</p>
              );
            })
          ) : (
            <> Not Assigned</>
          )}
        </Col>
      </Row>
      <Row>
        <label>{basicInfo.labelForAttachment}</label>
      </Row>
      <Row>
        <ButtonReuse
          value="View"
          type="submit"
          className="edit-image-button"
          onClick={showViewModalImage}
        />
        <div className="attachments-container">
          <Modal
            title="Attachments"
            className="AttachmentsModal"
            visible={isModalVisibleImage}
            onCancel={handleCancelModalImage}
          >
            <ViewAttachment images={images} />
          </Modal>
        </div>
      </Row>

      <EditOverlay
        visible={visible}
        onClose={closeHandler}
        onCancelButton={closeHandler}
        attachmentIds={attachmentIds}
      />
    </div>
  );
};
export default BasicInfo;
