import "antd/dist/antd.css";
import { Drawer } from "antd";
import Select from "react-select";
import { Formik } from "formik";
import * as yup from "yup";
import { AxiosConfig } from "../../../../ApiConfig";
import ButtonReuse from "../../../../components/Buttons/Button";
import { useState, useEffect, useRef } from "react";
import { getToken } from "../../../../auth";
import EditTalent from "./EditTalent";
import Switch from "react-switch";
import Loader from "../../../../components/Loader/Loader";
import { editAllocation } from "../../../../utils/enums";
import  {IProjectOwner}  from "../AllocationDetails/AllocationDetails"
import { Manager} from "./EditTalent";
import { checkTalentStatus } from "../../../../utils/util";
require("./Edit_Allocation.scss");

const data1 = new FormData();

const EditAllocation = (props: {
  visible?: boolean;
  onClose: () => void;
  onCancelButton: () => void;
  projectName?: string;
  projectCode?: string;
  id?: number;
  talentData?: any;
  monthid?: number;
  projectOwners: IProjectOwner[];
  managersParent: Manager[];
  projectDeptId: number;
}) => {
  const validationSchema = yup.object({
    talent_id: yup.string().required(" "),
  });
  const onCancelHandler = () => {
    props.onCancelButton();
  };
  const token = getToken();
  const [gettalent, settalent] = useState([]);
  const [month_id, setMonthId] = useState(0);
  const [getEmpId, setEmpId] = useState();
  const [getThisallocId, setThisallocId] = useState(0);
  const [getNextallocId, setNextallocId] = useState(0);
  const [getFutureallocId, setFutureallocId] = useState(0);
  const [getthisMonthtotalPercentage, setthisMonthtotalPercentage] =
    useState(0);
  const [getnextMonthtotalPercentage, setnextMonthtotalPercentage] =
    useState(0);
  const [getfutureMonthtotalPercentage, setfutureMonthtotalPercentage] =
    useState(0);

  const [Hour, setHour] = useState(false);

  const [thisMonthDetails, setthisMonthDetails] = useState([
    {
      project_name: "",
      allocated_percentage: "",
      project_id: "",
    },
  ]);
  const [nextMonthDetails, setnextMonthDetails] = useState([
    {
      project_name: "",
      allocated_percentage: "",
      project_id: "",
    },
  ]);
  const [futureMonthDetails, setfutureMonthDetails] = useState([
    {
      project_name: "",
      allocated_percentage: "",
      project_id: "",
    },
  ]);

  const [getThismonthlHour, setThismonthlHour] = useState(0);
  const [getnextMonthHour, setnextMonthHour] = useState(0);
  const [getfutureMonthHour, setfutureMonthHour] = useState(0);

  const thisMonthAllocatedPercent = useRef("");
  const nextMonthAllocatedPercent = useRef("");
  const futureMonthAllocatedPercent = useRef("");

  const [thisMonthAllocatedHour, setthisMonthAllocatedHour] = useState("");
  const [nextMonthAllocatedHour, setnextMonthAllocatedHour] = useState("");
  const [futureMonthAllocatedHour, setfutureMonthAllocatedHour] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const handleSwitchChange = () => {
    setHour(!Hour);
  };

  const [count, setCount] = useState(0);

  const [projectMonth, setProjectMonth] = useState({
    ThisMonth: "",
    NextMonth: "",
    FutureMonth: "",
    ThisMonthYear: "",
    NextMonthYear: "",
    FutureMonthYear: "",
  });
  useEffect(() => {
    getMonthsId().then(() => {
      handleInputChange(props.talentData);
    });
  }, [projectMonth.ThisMonth]);

  const getMonthsId = async () => {
    await AxiosConfig.get("financialmonths", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      const currentMonth: any = res.data.data.months.filter(
        (month: any) => month.status === "1"
      );
      getProjectAllocation(currentMonth[0].id).then(() => {
        getCustomers(currentMonth[0].id);
      });
      setMonthId(currentMonth[0].id);
    });
  };

  const getProjectAllocation = async (monthid: any) => {
    await AxiosConfig.get("allocations?month_id=" + monthid, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setProjectMonth({
        ThisMonth: res.data.data.months[0].ThisMonth,
        ThisMonthYear: res.data.data.months[0].ThisMonthYear,
        NextMonth: res.data.data.months[0].NextMonth,
        NextMonthYear: res.data.data.months[0].NextMonthYear,
        FutureMonth: res.data.data.months[0].FutureMonth,
        FutureMonthYear: res.data.data.months[0].FutureMonthYear,
      });
    });
  };

  useEffect(() => {
    props.talentData.month_id = props.monthid;
    setCount(count + 1);
  }, []);

  const getCustomers = async (monthid: any) => {
    await AxiosConfig.get("allocations/alltalents?month_id=" + monthid, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      let result: any = [];
      res?.data?.data?.talents?.map(async (talent) => {
        if (
          checkTalentStatus(talent?.date_of_seperation) != "Inactive" &&
          checkTalentStatus(talent?.date_of_seperation) != "Resigned"
        ) {
          result.push(talent);
        }
      });
      settalent(result);
      if (result.length >= 0) {
        setIsLoading(false);
      }
    });
  };
  const customerOptions = gettalent.map(function (customer: {
    firstName: any;
    lastName: any;
    emp_id: any;
    label: any;
    id: any;
  }) {
    return {
      value: customer.emp_id,
      label: customer.firstName + " " + customer.lastName,
    };
  });

  const handleInputChange = async (params: any) => {
    try {
      setEmpId(params.Talent_id);
      console.log("talentData------>", params);
      let emp_id = params.value || params.Talent_id;
      const response = await AxiosConfig.get("allocations/talents", {
        headers: { Authorization: `Bearer ${token}` },
        params: { emp_id, month_id },
      });
      const Projects = response?.data?.data?.TalentAllocations || [];

      const temparr1: any[] = [];
      let tempflag1 = false;
      const temparr2: any[] = [];
      let tempflag2 = false;
      const temparr3: any[] = [];
      let tempflag3 = false;
      Projects.forEach((row: any) => {
        if (
          row.project_id == props.id &&
          row.financialmonth.month == projectMonth.ThisMonth
        ) {
          setThisallocId(row.id);
        } else if (
          row.project_id == props.id &&
          row.financialmonth.month == projectMonth.NextMonth
        ) {
          setNextallocId(row.id);
        } else if (
          row.project_id == props.id &&
          row.financialmonth.month == projectMonth.FutureMonth
        ) {
          setFutureallocId(row.id);
        }
      });
      Projects.forEach((row: any) => {
        const tempProject = {
          project_name: row.project.name,
          allocated_percentage: row.allocated_percentage,
          allocated_hours: row.allocated_hours,
          project_id: row.project_id,
        };
        if (row.financialmonth.month == projectMonth.ThisMonth) {
          temparr1.push(tempProject);
          if (row.project.name === props.projectName) {
            thisMonthAllocatedPercent.current = row.allocated_percentage;
            setthisMonthAllocatedHour(row.allocated_hours);
            tempflag1 = true;
          }
        } else if (row.financialmonth.month == projectMonth.NextMonth) {
          temparr2.push(tempProject);
          if (row.project.name === props.projectName) {
            nextMonthAllocatedPercent.current = row.allocated_percentage;
            setnextMonthAllocatedHour(row.allocated_hours);
            tempflag2 = true;
          }
        } else if (row.financialmonth.month == projectMonth.FutureMonth) {
          temparr3.push(tempProject);
          if (row.project.name === props.projectName) {
            futureMonthAllocatedPercent.current = row.allocated_percentage;
            setfutureMonthAllocatedHour(row.allocated_hours);
            tempflag3 = true;
          }
        }
      });
  
      setthisMonthDetails(
        tempflag1
          ? temparr1
          : [
              {
                project_name: props.projectName,
                allocated_percentage: "",
                allocated_hours: "",
                project_id: props.id,
              },
              ...temparr1,
            ]
      );
  
      let thisMonthtotalPercentage = 0;
      temparr1.forEach((project) => {
        thisMonthtotalPercentage += project.allocated_percentage;
      });
      setthisMonthtotalPercentage(thisMonthtotalPercentage);
  
      setnextMonthDetails(
        tempflag2
          ? temparr2
          : [
              {
                project_name: props.projectName,
                allocated_percentage: "",
                allocated_hours: "",
                project_id: props.id,
              },
              ...temparr2,
            ]
      );
  
      let nextMonthtotalPercentage = 0;
      temparr2.forEach((project) => {
        nextMonthtotalPercentage += project.allocated_percentage;
      });
      setnextMonthtotalPercentage(nextMonthtotalPercentage);
  
      setfutureMonthDetails(
        tempflag3
          ? temparr3
          : [
              {
                project_name: props.projectName,
                allocated_percentage: "",
                allocated_hours: "",
                project_id: props.id,
              },
              ...temparr3,
            ]
      );
  
      let futureMonthtotalPercentage = 0;
      temparr3.forEach((project) => {
        futureMonthtotalPercentage += project.allocated_percentage;
      });
      setfutureMonthtotalPercentage(futureMonthtotalPercentage);
  
      setThismonthlHour(response.data.data?.thisMonthHour);
      setnextMonthHour(response.data.data?.nextMonthHour);
      setfutureMonthHour(response.data.data?.futureMonthHour);
    } catch (error) {
      // Handle errors if necessary
      console.error("Error fetching data:", error);
    }
  };  
  
  return (
    <>
      <Drawer
        title="Edit"
        width={500}
        onClose={props.onClose}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        className="manage_talent"
        closable={true}
        maskClosable={false}
      >
        {/* Loader Implemetation */}
        {isLoading === true ? (
          <Loader className="edit_Loader" />
        ) : (
          <Formik
            initialValues={{
              talent_id: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              data1.append("talent_id", values.talent_id);
            }}
          >
            {({
              handleSubmit,

              errors,

              resetForm,
            }) => (
              <form onSubmit={handleSubmit} className="add_project_form">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <div>
                    <label>{editAllocation.labelForTalentName} </label>
                  </div>
                  <div>
                  <label className="percent">
                    {editAllocation.labelForHrs}
                  </label>
                    <Switch
                      className="percent"
                      onChange={handleSwitchChange}
                      checked={Hour}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      width={36}
                      height={20}
                      handleDiameter={16}
                      onColor="#5389F8"
                      offColor="#BFCDDA"
                    />
                    <label className="percent">
                      {editAllocation.labelForpercent}
                    </label>
                  </div>
                </div>
                <Select
                  options={customerOptions}
                  defaultValue={{
                    label:
                      props.talentData.First_Name +
                      " " +
                      props.talentData.Last_Name,
                    value: props.talentData.Talent_Id,
                  }}
                  onChange={(event) => handleInputChange(event)}
                  name="talent_id"
                  placeholder="Select talent name"
                  className="select--field"
                  isDisabled={true}
                />

                <p className="display_error">{errors.talent_id}</p>

                <EditTalent
                  projectDeptId={props.projectDeptId}
                  projectOwners={props.projectOwners}
                  managersParent={props.managersParent}
                  monthDetails={thisMonthDetails}
                  monthName={
                    String(projectMonth.ThisMonth).substring(0, 3) +
                    "'" +
                    projectMonth.ThisMonthYear
                  }
                  projectName={props.projectName}
                  monthId={month_id}
                  projectId={props.id}
                  empId={getEmpId}
                  allocationId={getThisallocId}
                  allocatedPercent={thisMonthAllocatedPercent.current}
                  allocatedHour={thisMonthAllocatedHour}
                  totalpercentage={getthisMonthtotalPercentage}
                  TotalHour={getThismonthlHour}
                  Hour={Hour}
                />
                <EditTalent
                  projectDeptId={props.projectDeptId}
                  projectOwners={props.projectOwners}
                  managersParent={props.managersParent}
                  monthDetails={nextMonthDetails}
                  monthName={
                    String(projectMonth.NextMonth).substring(0, 3) +
                    "'" +
                    projectMonth.NextMonthYear
                  }
                  projectName={props.projectName}
                  monthId={month_id + 1}
                  projectId={props.id}
                  empId={getEmpId}
                  allocatedPercent={nextMonthAllocatedPercent.current}
                  allocatedHour={nextMonthAllocatedHour}
                  allocationId={getNextallocId}
                  totalpercentage={getnextMonthtotalPercentage}
                  TotalHour={getnextMonthHour}
                  Hour={Hour}
                />
                <EditTalent
                  projectDeptId={props.projectDeptId}
                  projectOwners={props.projectOwners}
                  managersParent={props.managersParent}
                  monthDetails={futureMonthDetails}
                  monthName={
                    String(projectMonth.FutureMonth).substring(0, 3) +
                    "'" +
                    projectMonth.FutureMonthYear
                  }
                  projectName={props.projectName}
                  monthId={month_id + 2}
                  projectId={props.id}
                  empId={getEmpId}
                  allocatedPercent={futureMonthAllocatedPercent.current}
                  allocatedHour={futureMonthAllocatedHour}
                  allocationId={getFutureallocId}
                  totalpercentage={getfutureMonthtotalPercentage}
                  TotalHour={getfutureMonthHour}
                  Hour={Hour}
                />

                {/* </Modal> */}
                <div className="button-container">
                  <ButtonReuse
                    type="primary"
                    className="primary-btn cancel--btn"
                    value="Finish"
                    onClick={() => {
                      onCancelHandler();
                      resetForm();
                      window.location.reload();
                    }}
                  ></ButtonReuse>
                </div>
              </form>
            )}
          </Formik>
        )}
      </Drawer>
    </>
  );
};

export default EditAllocation;
