import "./SoldSummary.scss";
import { Row, Col } from "antd";
import { convertNumber, formatCurrencyValue } from "../../../utils/util";
import { useSelector } from "react-redux";
import { soldSummary } from "../../../utils/enums";

const SoldSummary = (props: { parentStus?: any; parentSales?: any ; parentAvg?: any}) => {
  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );
  return (
    <div className="soldSummary-Info">
      <Row className="soldSummaryHeader">
        <Col span={24}>
          <h1>{soldSummary.labelForSoldSummary}</h1>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <p>{soldSummary.labelForLifetimeStus}</p>
        </Col>
        <Col span={8} className="text-end">
          <p>{convertNumber(props.parentStus, 2)}</p>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <p>{soldSummary.labelForLifetimeSales}</p>
        </Col>
        <Col span={8} className="text-end">
          <p>
            {formatCurrencyValue(
              currencySymb?.currency_code,
              props?.parentSales
            )}
          </p>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <p>{soldSummary.labelForAvgSoldbyStu}</p>
        </Col>
        <Col span={8} className="text-end">
          <p>
            {formatCurrencyValue(
              currencySymb?.currency_code,
              props?.parentAvg / props?.parentStus
            )}
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default SoldSummary;
