import React, { useRef, useEffect, useState } from "react";
import { Row, Col, Table, Input, Typography, Card } from "antd";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
require("./DashboardInvoice.scss");
import arrow from "../../../assets/images/arrow.png";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { getAssociatedBusIds } from "../../../utils/localStore";
import {
  convertNumber,
  formatCurrencyValue,
  replaceString,
} from "../../../utils/util";
import { useSelector } from "react-redux";

const DashboardInvoice = () => {
  const dateFormat = "YYYY/MM/DD";
  const selectedMonth: any = useRef();
  const selectedBu = useRef([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [invoiceTable, setInvoiceTable] = useState([]);
  const [nameSearch, setNameSearch] = useState("");
  const [totalPending, setTotalPending] = useState(0);
  const [totalRaised, setTotalRaised] = useState(0);
  const currenciesName = useRef({});
  const [projectNameList, setProjectNameList] = useState([]);
  const [deptMap, setDept] = useState({});
  let history = useHistory();
  const { Text } = Typography;

  var token = getToken();
  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );

  useEffect(() => {
    if (currencySymb?.currency_code) {
      getDepatments().then(() => getCurrency().then(() => getMonths()));

      const query = new URLSearchParams(window.location.search);
      const queryMonth = query.get("month");
      const queryYear = query.get("year");
      if (queryMonth && queryYear) {
        selectedMonth.current = `${queryMonth} ${queryYear}`;
      }
    }
  }, [currencySymb?.currency_code]);

  const getMonths = async () => {
    AxiosConfig.get("financialmonths", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const currentMonth: any = res.data.data.months.filter(
          (month) => month.status === "1"
        );
        const temp = `${currentMonth[0].month}${" "}${currentMonth[0].year}`;
        if (!selectedMonth.current) {
          selectedMonth.current = temp;
        }
        setFirstLoad(true);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const getCurrency = async () => {
    AxiosConfig.get("currency", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const tempCurrency: any = {};
        res.data.data.currencies.map((row: { id: any; currency_code: any }) => {
          tempCurrency[row.id] = row.currency_code;
        });
        currenciesName.current = tempCurrency;
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const getDepatments = async () => {
    AxiosConfig.get("departments", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const tempDept: any = [];
        const tempDeptObj: any = {};
        res.data.data.departments.map(
          (row: { name: any; id: any; flag: any }) => {
            if (row.flag == 1)
              tempDept.push({
                label: row.name,
                value: row.id,
              });
            tempDeptObj[row.id] = row.name;
          }
        );
        setDept(tempDeptObj);
        selectedBu.current = tempDept;
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (currencySymb?.currency_code) {
      if (selectedMonth.current) {
        getInvoice();
      }
    }
  }, [firstLoad, currencySymb?.currency_code]);

  const getInvoice = async () => {
    const [month, year] = selectedMonth.current.split(" ");

    const reqBody = {
      selected_month: month,
      selected_year: parseInt(year),
      dept_arr: getAssociatedBusIds(),
    };

    history.push({
      pathname: "/DashboardInvoice",
      search: `?month=${month}&year=${year}`,
    });

    AxiosConfig.post("invoice/invoicedata", reqBody, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const tempProjectNameSet: any = new Set();
        const tempProjectNameList: any = [];
        res.data.data.invoiceData.map((value: any) => {
          let proj: any = {
            project_Name: value.project_name,
          };
          tempProjectNameSet.add(proj.project_Name);
        });

        Array.from(tempProjectNameSet).forEach((project_Name: any) => {
          tempProjectNameList.push({
            text: project_Name,
            value: project_Name,
          });
        });
        setInvoiceTable(
          res.data.data.invoiceData.map(
            (row: {
              invoice_id: any;
              currency_id: any;
              invoice_number: any;
              invoice_value: any;
              base_invoice_value: any;
              department: any;
              project_id: any;
              po_name: any;
              po_status: any;
              project_name: any;
              invoice_date: any;
              status: any;
              title: any;
              project_managers: any;
            }) => ({
              Invoice_Date: moment(row.invoice_date).format(dateFormat),
              Invoice_Id: row.invoice_id,
              Currency_Code: currenciesName.current[row.currency_id],
              Invoice_Value: convertNumber(row?.invoice_value, 2) ?? "-",
              Invoice_base:
                formatCurrencyValue(
                  currencySymb?.currency_code,
                  row?.base_invoice_value
                ) ?? "-",
              Base_Invoice_Value:
                convertNumber(row.base_invoice_value, 2) ?? "-",
              Project_Id: row.project_id,
              Po_Name: row.po_name,
              Po_Status: row.po_status,
              Project_Name: row.project_name,
              Status: row.status ? "Pending" : "Sent",
              Invoice_Number: row.invoice_number ?? "-",
              Department: row.department,
              Title: row.title,
              project_managers: row.project_managers
                ? row.project_managers
                : "",
            })
          )
        );
        setProjectNameList(tempProjectNameList);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const handleOnChange = (event: any) => {
    setNameSearch(event.target.value);
  };

  useEffect(() => {
    if (currencySymb?.currency_code) {
      if (invoiceTable) {
        const results: any = invoiceTable.filter(
          (item: any) =>
            item.Po_Name.toLowerCase().includes(nameSearch.toLowerCase()) ||
            item.Project_Name.toLowerCase().includes(nameSearch.toLowerCase())
        );
        setInvoiceTable(results);
      }
    }
  }, [nameSearch, currencySymb?.currency_code]);

  useEffect(() => {
    if (currencySymb?.currency_code) {
      let totalRaised = 0;
      let totalPending = 0;
      invoiceTable.forEach((data: any) => {
        totalRaised += parseFloat(
          data.Base_Invoice_Value.replace("$", "").replaceAll(",", "")
        );
        if (data.Status === "Pending")
          totalPending += parseFloat(
            data.Base_Invoice_Value.replace("$", "").replaceAll(",", "")
          );
      });

      setTotalRaised(totalRaised);
      setTotalPending(totalPending);
    }
  }, [invoiceTable, currencySymb?.currency_code]);

  const onClickRow = (record: any) => {
    history.push("/projects/" + record);
    localStorage.setItem("projectsId", record);
  };
  const poStatusList = useRef([
    { value: "Pending", text: "Pending" },
    { value: "Sent", text: "Sent" },
  ]);

  const columns = [
    {
      title: "PROJECT NAME",
      dataIndex: ["Project_Name", "Project_Id", "Po_Name"],
      key: "project_name",
      sorter: (a: any, b: any) => a.Project_Name.localeCompare(b.Project_Name),
      filters: projectNameList,
      onFilter: (value: any, record: any) =>
        record.Project_Name.includes(value),
      render: (text: any, record: any) => (
        <Row>
          <Col md={24} xs={24}>
            <a
              style={{ color: "#5c67e5", cursor: "pointer" }}
              onClick={() => {
                onClickRow(record["Project_Id"]);
              }}
            >
              {record["Project_Name"]}
            </a>
          </Col>
          <Col md={24} xs={24}>
            {record["Po_Name"]}
          </Col>
        </Row>
      ),
    },
    {
      title: "INVOICE TITLE",
      dataIndex: "Title",
      key: "title",
      sorter: (a: any, b: any) => a.Title.localeCompare(b.Title),
    },
    {
      title: "PROJECT MANAGER",
      dataIndex: "project_managers",
      key: "project_managers",
      className: "projectManagers-invoice",
      sorter: (a: any, b: any) =>
        a?.project_managers.localeCompare(b?.project_managers),
      render: (text) => {
        let wrappedManagersString: any;
        if (text) {
          const managersArray = text.split(","); // Split the string into an array of values
          wrappedManagersString = managersArray.join("\n"); // Join the array with line breaks
        } else {
          wrappedManagersString = "-";
        }
        return (
          <div style={{ whiteSpace: "pre-wrap" }}>{wrappedManagersString}</div>
        );
      },
    },
    {
      title: "DEPARTMENTS",
      dataIndex: "Department",
      key: "department",
      sorter: (a: any, b: any) =>
        deptMap[a.Department].localeCompare(deptMap[b.Department]),
      render: (text: any, record: any) => (
        <div>{deptMap[record["Department"]]}</div>
      ),
    },
    {
      title: "INVOICE VALUE",

      dataIndex: ["Invoice_Value"],
      key: "invoice_value",

      sorter: (a: any, b: any) =>
        replaceString(a.Invoice_Value) - replaceString(b.Invoice_Value),

      render: (text: any, record: any) => (
        <Row>
          <Col md={24} xs={24}>
            {record["Currency_Code"]}
            {"  "}
            {record["Currency_Code"] != currencySymb?.currency_code
              ? record["Invoice_Value"]
              : record["Invoice_base"].replaceAll("₹", "")}
          </Col>
          {record["Currency_Code"] != currencySymb?.currency_code && (
            <Col md={24} xs={24}>
              <Text type="secondary">{record["Invoice_base"]}</Text>
            </Col>
          )}
        </Row>
      ),
    },
    {
      title: "INVOICE DATE",
      dataIndex: "Invoice_Date",
      key: "invoice_date",
      sorter: (a: any, b: any) => a.Invoice_Date.localeCompare(b.Invoice_Date),
    },
    {
      title: "INVOICE #",
      dataIndex: "Invoice_Number",
      key: "invoice_number",
      sorter: (a: any, b: any) =>
        a.Invoice_Number.localeCompare(b.Invoice_Number),
      render: (text) => {
        if (!text) {
          return "-";
        }
        return text;
      },
    },
    {
      title: "STATUS",
      dataIndex: "Status",
      key: "status",
      filters: poStatusList.current,
      onFilter: (value: any, record: any) => record.Status.includes(value),
      sorter: (a: any, b: any) => a.Status.localeCompare(b.Status),
      render: (text: any, record: any) => (
        <>
          <Row>
            <Col md={24} xs={24}>
              <div
                className={
                  record["Status"] === "Pending"
                    ? "pendingStatus"
                    : "sentStatus"
                }
              >
                {record["Status"]}
              </div>
            </Col>
            <Col md={24} xs={24}>
              {record["Po_Status"]}
            </Col>
          </Row>
        </>
      ),
    },
  ];
  return (
    <>
      <Row gutter={24}>
        <Col xl={6} xs={24}>
          <span
            className="back-button"
            onClick={() => history.push("/buDashboardInvoice")}
          >
            <img src={arrow} />
            back
          </span>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col className="displayDate" xs={24} sm={24} md={12} lg={12} xl={12}>
          <p>{selectedMonth.current}</p>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: "1rem" }}>
        <Col xs={12} sm={12} md={10} lg={8} xl={8}>
          <div className="searchForm">
            <form>
              <Input.Search
                allowClear
                onChange={handleOnChange}
                placeholder="Search by-  Po Name / Project Name "
              />
            </form>
          </div>
        </Col>
        <Col xs={12} sm={12} md={14} lg={16} xl={16}>
          <Card className="card_bg textValue" bordered={false}>
            <Row>
              <Col span={12}>
                <Text type="secondary">TOTAL:</Text>{" "}
                <b style={{ marginRight: "1rem", color: "rgb(39, 113, 173)" }}>
                  {formatCurrencyValue(
                    currencySymb?.currency_code,
                    totalRaised
                  )}
                </b>
              </Col>
              <Col span={12}>
                <Text type="secondary">PENDING:</Text>{" "}
                <b style={{ marginRight: "1rem", color: "red" }}>
                  {formatCurrencyValue(
                    currencySymb?.currency_code,
                    totalPending
                  )}
                </b>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={16}> </Row>
      <Table
        columns={columns}
        className="talentTable"
        dataSource={invoiceTable}
        bordered
        pagination={false}
      ></Table>
    </>
  );
};
export default DashboardInvoice;
