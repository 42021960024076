import React, { useState } from "react";
import { Table, Button, Space,Modal, Row, Col, Input } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { enumForAudit } from "../../../utils/enums";
import "antd/dist/antd.css";
require("./Audits.scss");

const initialWeek = 1;

const dataSource = {
  WSR: [
    {
      key: "1",
      projectCode: "ABC123",
      projectName: "Project ABC",
      customer: "keval",
      month1: "",
      month2: "",
      month3: "",
      month4: "",
    },
    {
      key: "2",
      projectCode: "ABC123",
      projectName: "Project ABC",
      customer: "Pramod",
      month1: "",
      month2: "",
      month3: "",
      month4: "",
    },
  ],
};

const AuditsPSAT = () => {
  const [currentWeek, setCurrentWeek] = useState(initialWeek);
  const [modalVisible, setModalVisible] = useState(false);
  const [, setNameSearch] = useState("");

  const columns = [
    {
      title: (
        <div className="project-code-column-header">
          <div style={{ fontSize: "16px"}}>{enumForAudit.labelForProjectCode}</div>
        </div>
      ),
      dataIndex: "projectCode",
      key: "projectCode",
      width: "100px",
      className: "project-code-column",
    },
    {
      title: (
        <div className="project-code-column-header">
          <div style={{ fontSize: "16px"}}>{enumForAudit.labelForProjectName}</div>
        </div>
      ),
      dataIndex: "projectName",
      key: "projectName",
      width: "100px",
      className: "project-code-column",
    },
    {
        title: (
          <div className="project-code-column-header">
            <div style={{ fontSize: "16px"}}>Customer</div>
          </div>
        ),
        dataIndex: "customer",
        key: "customer",
        width: "100px",
        className: "project-code-column",
      },
    {
      title: (
        <div className="header-div">
          <div className="header-div-child">Month 1</div>
          <div className="header-week-range">
            15 Nov&apos;23 - 21 Nov&apos;23
          </div>
        </div>
      ),
      dataIndex: "month1",
      key: "month1",
      width: "200px",
      onCell: () => ({
        onClick: () => handleCellClick("month1"),
      }),
    },
    {
      title: (
        <div className="header-div">
          <div className="header-div-child">Month 2</div>
          <div className="header-week-range">
            15 Nov&apos;23 - 21 Nov&apos;23
          </div>
        </div>
      ),
      dataIndex: "month2",
      key: "month2",
      width: "200px",
      onCell: () => ({
        onClick: () => handleCellClick("month2"),
      }),
    },
    {
      title: (
        <div className="header-div">
          <div className="header-div-child">Month 3</div>
          <div className="header-week-range">
            15 Nov&apos;23 - 21 Nov&apos;23
          </div>
        </div>
      ),
      dataIndex: "month3",
      key: "month3",
      width: "200px",
      onCell: () => ({
        onClick: () => handleCellClick("month3"),
      }),
    },

    {
      title: (
        <div className="header-div">
          <div className="header-div-child">Month 4</div>
          <div className="header-week-range">
            15 Nov&apos;23 - 21 Nov&apos;23
          </div>
        </div>
      ),
      dataIndex: "month4",
      key: "month4",
      width: "200px",
      onCell: () => ({
        onClick: () => handleCellClick("month4"),
      }),
    },
  ];

  const handlePrevWeek = () => {
    setCurrentWeek((prevWeek) => Math.max(prevWeek - 1, 1));
  };

  const handleNextWeek = () => {
    setCurrentWeek((prevWeek) => Math.min(prevWeek + 1, 4));
  };

  const handleCellClick = (week) => {
    console.log("week", week);
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleOnChange = (event) => {
    setNameSearch(event.target.value);
  };

  return (
    <div>
      <Row className="audit-wsr-search">
        <Col>
          <div className="searchForm">
            <form>
              <Input.Search
                allowClear
                onChange={handleOnChange}
                placeholder="Search by project name / project code / customer name"
              />
            </form>
          </div>
        </Col>
        <Space className="left-right-navigation-button">
          <Button icon={<LeftOutlined />} onClick={handlePrevWeek} />
          <Button icon={<RightOutlined />} onClick={handleNextWeek} />
        </Space>
      </Row>
      <Table
            className="custom-table"
            dataSource={dataSource.WSR.map((data) => ({
              ...data,
              key: data.key + currentWeek,
            }))}
            columns={columns}
            bordered
            pagination={false}
            style={{ tableLayout: "fixed" }}
          />
      <Modal
        title="Cell Clicked"
        visible={modalVisible}
        onCancel={handleModalCancel}
      >
        <p>You clicked on a cell</p>
      </Modal>
    </div>
  );
};
export default AuditsPSAT;
