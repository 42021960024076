import "antd/dist/antd.css";
import { Table, Card, Col, Row, Input, Select, Tabs } from "antd";
import BasicInfo from "./BasicInfo";
import PurchaseOrderTable from "./PurchaseOrderTable";
import "./ProjectDetails.scss";
import { useHistory, useParams } from "react-router-dom";
import { Fragment } from "react";
import arrow from "../../../assets/images/arrow.png";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { useState, useEffect } from "react";
import SoldSummary from "./SoldSummary";
import EarnedSummary from "./EarnedSummary";
import EfficiencySummary from "./EfficiencySummary";
import type { ColumnsType } from "antd/es/table";
import { Button } from "antd";
import { convertNumber, formatCurrencyValue } from "../../../utils/util";
import { useSelector } from "react-redux";
import { projectDetails } from "../../../utils/enums";
import Loader from "../../../components/Loader/Loader";
import { SupersetEmbedComponent } from "../../../components";

interface IProjectOwner {
  id: number;
  is_active: boolean;
  start_date: Date;
  end_date: Date;
  project_id: number;
  owner_id: number;
  user: {
    id: number;
    firstName: string;
    lastName: string;
  };
}

const ProjectDetails = () => {
  const { Option } = Select;
  let history = useHistory();
  const { TabPane } = Tabs;
  const [projectData, setProjectData] = useState([]);
  const [projectName, setProjectName] = useState();
  const [billabaleGeoOption, setBillableGeoOption] = useState();
  const [customerName, setCustomerName] = useState();
  const [associatedBU, setAssociatedBU] = useState();
  const [associatedBuId, setAssociatedBuId] = useState();
  const [isBillable, setIsBillable] = useState("");
  const [projectCode, setProjectCode] = useState();
  const [projectList, setProjectList] = useState([]);
  const [stus, setStus] = useState();
  const [total_sold_base, setTotalSoldBase] = useState(0);
  const [totalDirectExpense, setTotalDirectExpense] = useState(0);
  const [futureValue, setfutureValue] = useState(0);
  const [this_month_value, setThisMonthValuet] = useState(null);
  const [managers, setManagers] = useState("");
  const [attachmentIds, setAttachmentIds] = useState([]);
  const [revenue, setRevenue] = useState(null);
  const [expended, setExpended] = useState();
  const [archiveStatus, setArchiveStatus] = useState();
  const [locationData, setLocationData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState("");
  const [currentYear, setCurrentYear] = useState("");
  const [projectOwners, setProjectOwners] = useState<IProjectOwner[]>([]);
  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );
  const [isLoading, setIsLoading] = useState(true);
  const [directExpense, setDirectExpense] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [userid, getuserid] = useState();

  var token = getToken();
  const monthOrder = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  let { id }: any = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await Promise.all([tableData(), getData(), getMonthsId()]);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setIsLoading(false);
      }
    };

    fetchData();

    getUser();
    getProjectDetails(archiveStatus);
  }, [userid, archiveStatus]);

  const getUser = async () => {
    try {
      const response = await AxiosConfig.get("users/current", {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data && response.data.data && response.data.data.user) {
        const user_id = response.data.data.user.id;
        getuserid(user_id);
      } else {
        console.error("User data not found in the API response");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const getProjectDetails = async (archiveStatus: any) => {
    try {
      const res = await AxiosConfig.get("projects?archive=" + archiveStatus, {
        headers: { Authorization: `Bearer ${token}` },
        params: { user_id: userid },
      });
      let projects = res?.data?.data?.projects;
      projects = projects.sort((a: any, b: any) => {
        var a1 = a.project_code.replace("STP_", "");
        var b1 = b.project_code.replace("STP_", "");
        return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
      });
      setProjectList(projects);
    } catch (err) {
      console.log(err);
    }
  };
  const getMonthsId = async () => {
    try {
      const res = await AxiosConfig.get("financialmonths", {
        headers: { Authorization: `Bearer ${token}` },
      });

      const monthsData = res?.data?.data?.months;
      const currentMonth = monthsData.find(
        (month: any) => month.status === "1"
      );

      if (currentMonth) {
        await getProjectAllocation(currentMonth.id);
        setCurrentMonth(currentMonth.month);
      } else {
        console.error("Current month not found.");
      }
    } catch (error) {
      console.error("Error fetching months: ", error);
    }
  };

  const getProjectAllocation = async (monthid: any) => {
    try {
      const res = await AxiosConfig.get("allocations?month_id=" + monthid, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res?.data?.data?.months?.length >= 0) {
        setIsLoading(false);
      }
      setCurrentYear(res.data.data.months[0].ThisMonthYear);
    } catch (error) {
      console.error("Error fetching project allocation: ", error);
      setIsLoading(false);
    }
  };

  const tableData = async () => {
    try {
      const response = await AxiosConfig.get("/projects/projectSummary/" + id, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const summaries = response.data.data;

      const formattedData = summaries.map((summary) => {
        return {
          Month: summary.month,
          Year: summary.year,
          Month_Year1: `${summary.month.substr(0, 3)} ${summary.year}`,
          Revenue_Value1: formatCurrencyValue(
            currencySymb?.currency_code,
            summary?.Revenue_Value
          ),
          stus_executed1: convertNumber(summary.stus_executed, 2),
          Stus_allocated1: convertNumber(summary.allocated_stus, 2),
          stus_expended1: convertNumber(summary.stus_expended, 2),
        };
      });

      setProjectData(formattedData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (value: any) => {
    history.push("/projects/" + value);
    window.location.reload();
  };

  const onClickRow = () => {
    history.push("/allocations/" + id);
  };
  const getData = async () => {
    try {
      const res = await AxiosConfig.get(`projects/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (res.data?.data?.project) {
        setProjectName(res.data.data.project.name);
        setCustomerName(res.data.data.project.customer.name);
        setAssociatedBU(res.data.data.project.department.name);
        setAssociatedBuId(res.data.data.project.department.id);
        setProjectCode(res.data.data.project.project_code);
        setStus(res.data.data.project.stus_sold);
        setTotalSoldBase(res.data.data.project.total_sold_base);
        setThisMonthValuet(res.data.data.project.this_month_base);
        setRevenue(res.data.data.project.accounted_base);
        setExpended(res.data.data.project.stus_expended);
        setManagers(res.data.data.project?.users);
        setArchiveStatus(res.data.data.project.archive_status);
        setLocationData(res.data.data.project.Geography.location);
        setBillableGeoOption(res.data.data.project.billableentity.entity_name);
        setDirectExpense(res.data?.data?.directExpense);
        setTotalExpense(res.data?.data?.totalExpense);
        if (res.data.data.project.billable_option == true) {
          setIsBillable("True");
        } else if (res.data.data.project.billable_option == false) {
          setIsBillable("False");
        } else {
          setIsBillable("Not Selected");
        }
        let direct_expenses = 0;
        res.data.data.project.PurchaseOrders.forEach((exp: any) => {
          direct_expenses += exp.direct_expense;
        });
        setTotalDirectExpense(direct_expenses);
        setAttachmentIds(
          res.data.data.project.projectattachments.map(
            (row: { id: any; attachment_id: any }) => ({
              id: row.id,
              attachment_id: row.attachment_id,
            })
          )
        );
        setProjectOwners(res.data.data.project.ProjectOwners);
        await getProjectDetails(res.data.data.project.archive_status);
      }
      setfutureValue(res.data?.data?.futureValue);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  const archive_status = () => {
    const status = archiveStatus;
    if (status == false) {
      console.log("project is active");
    } else {
      return "Archived Project";
    }
  };

  const futureValueData = () => {
    const value = futureValue ?? 0;
    return formatCurrencyValue(currencySymb?.currency_code, value);
  };

  const currentMonthValue = () => {
    const currentMonth = this_month_value ?? 0;
    // const ThisMonthRevForex = thisMonthRevForex ?? 0;
    return formatCurrencyValue(currencySymb?.currency_code, currentMonth);
  };

  interface DataType {
    Month_Year: string;
    Month: string;
    Year: string;
    Month_Status: any;
    Revenue_Value: string;
    Revenue_base: string;
    Stus_allocated: string;
    Stus_earned: string;
    Stus_utilized: string;
    stus_executed: string;
    stus_expended: string;
  }
  const columns: ColumnsType<DataType> = [
    {
      title: "MONTH",
      dataIndex: "Month_Year1",
      key: "Month_Year1",

      sorter: (a, b) => {
        if (a.Month === "MAG" || b.Month === "MAG") {
          return -1;
        }
        return (
          parseInt(b.Year) - parseInt(a.Year) ||
          monthOrder[b.Month] - monthOrder[a.Month]
        );
      },
      sortOrder: "ascend",
      sortDirections: [],
    },
    {
      title: "EARNED",
      className: "earned_Data",
      dataIndex: "Revenue_Value1",
      key: "Revenue_Value1",
    },

    {
      title: "EARNED STU'S",
      className: "exec_Data",
      dataIndex: "stus_executed1",
      key: "stus_executed1",
    },

    {
      title: "PLANNED STU'S",
      className: "alloc_Data",
      dataIndex: "Stus_allocated1",
      key: "Stus_allocated1",
    },
    {
      title: "UTILIZED STU'S",
      className: "expended_Data",
      dataIndex: "stus_expended1",
      key: "stus_expended1",
    },
  ];

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Fragment>
          <Row gutter={24}>
            <Col xl={6} xs={24}>
              <span
                className="back-button"
                onClick={() => history.push("/projects")}
              >
                <img src={arrow} />
                {projectDetails.textForBack}
              </span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xl={12} xs={24}>
              <Input.Group className="project-select">
                <Select
                  showSearch
                  className="searchableDropdown"
                  onChange={handleChange}
                  placeholder={`${projectCode} : ${projectName}`}
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {projectList.map(
                    (row: { name: any; id: any; project_code: any }) => (
                      <Option key={row.id} value={row.id}>
                        {row.project_code + ": " + row.name}
                      </Option>
                    )
                  )}
                </Select>
              </Input.Group>
              <Button
                id="button1"
                onClick={onClickRow}
                className="archivDivStyle"
              >
                {projectDetails.ButtonForProjectAllocationDetail}
              </Button>
            </Col>
            <Col
              className="futureSales"
              xl={12}
              xs={24}
              style={{ display: "grid" }}
            >
              <div className="archivDivStyle">
                <h3 className="archive_status">{archive_status()}</h3>
              </div>

              <h3 className="archivDivStyle">
                {projectDetails.textForFutureValue} {futureValueData()}
              </h3>
              <h3 className="archivDivStyle">
                {String(currentMonth).substring(0, 3)}
                {String(currentYear).substring(2, 4)}{" "}
                {projectDetails.textForCurrentValue} {currentMonthValue()}
              </h3>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xl={6} xs={24}>
              <Card
                className="basic-info"
                bordered={false}
                style={{ height: "95%" }}
              >
                <BasicInfo
                  projectNameParent={projectName}
                  billablaeGeoOption={billabaleGeoOption}
                  customerNameParent={customerName}
                  associatedBUParent={associatedBU}
                  projectCodeParent={projectCode}
                  managersParent={managers}
                  attachmentIds={attachmentIds}
                  isBillable={isBillable}
                  locationNameParent={locationData}
                  flag={false}
                  projectOwners={projectOwners}
                ></BasicInfo>
              </Card>
            </Col>
            <Col xl={18} xs={24}>
              <div>
                <Card
                  title="Efficiency Summary"
                  bordered={false}
                  className="basic-info"
                  style={{ height: "95%" }}
                >
                  <Tabs defaultActiveKey="1">
                    <TabPane tab="Project view" key="1">
                      <SupersetEmbedComponent
                        pageType="project_view"
                        isDepartmentEnabled={false}
                      />
                    </TabPane>
                    <TabPane tab="Table" key="2">
                      <Table
                        columns={columns}
                        dataSource={projectData}
                        bordered
                        className="projectsTable"
                        pagination={{ pageSize: 10 }}
                      />
                    </TabPane>
                    <TabPane tab="Graph" key="3"></TabPane>
                  </Tabs>
                </Card>
              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xl={8} xs={24}>
              <Card bordered={false}>
                <SoldSummary
                  parentStus={stus}
                  parentSales={total_sold_base}
                  parentAvg={total_sold_base - totalDirectExpense}
                />{" "}
              </Card>
            </Col>
            <Col xl={8} xs={24}>
              <Card bordered={false}>
                <EarnedSummary
                  parentRevenue={revenue}
                  parentExpended={expended}
                  directExpense={directExpense}
                  totalExpense={totalExpense}
                />
              </Card>
            </Col>
            <Col xl={8} xs={24}>
              <Card bordered={false}>
                <EfficiencySummary />
              </Card>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xl={24} xs={24}>
              <Card className="PurchaseOrderTable" bordered={false}>
                <PurchaseOrderTable
                  archive_status={archiveStatus}
                  associatedBuId={associatedBuId}
                  ProjectManagers={managers}
                  ProjectName={projectName}
                  ProjectOwnerId={projectOwners}
                />
              </Card>
            </Col>
          </Row>
        </Fragment>
      )}
    </>
  );
};

export default ProjectDetails;
