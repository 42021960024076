import { Row, Col, Switch, message } from "antd";
import CapacityReport from "../CapacityReport";
import dayjs from "dayjs";
import { Dispatch, SetStateAction } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { talentDetailsCard } from "../../../utils/enums";
require("./TalentDetails.scss");

interface TalentProps {
  id: number;
  isSalesEnabled: boolean;
}

const TalentDetailsCard = (props: {
  parentProfileFirstName?: any;
  parentProfileLastName?: any;
  parentDesignation?: any;
  parentBand?: any;
  parentLifeTimeStus?: any;
  parentAssignedBu?: any;
  parentJoiningDate?: any;
  parentReleivingDate?: any;
  parentSkills?: any;
  parentEmail?: any;
  parentStatus?: any;
  talent: TalentProps;
  setTalent: Dispatch<SetStateAction<TalentProps>>;
}) => {
  const token = getToken();
  console.warn(token);
  const [messageApi, contextHolder] = message.useMessage();

  const handleSalesPersonEnable = (checked: boolean) => {
    const salesEnable = checked ? "1" : "0";
    const messageMap = {
      "0": "disable",
      "1": "enable",
    };
    AxiosConfig.put(
      `salesAllocation/${props.talent.id}?isSalesEnabled=${salesEnable}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then(() => {
        props.setTalent({
          ...props.talent,
          isSalesEnabled: checked,
        });
        messageApi.success(`Sales ${messageMap[salesEnable]} successfully`);
      })
      .catch(() => {
        messageApi.error(`Failed to ${messageMap[salesEnable]} Sales`);
      });
  };

  return (
    <>
      {contextHolder}
      <Row gutter={16}>
        <Col span={8}>
          <div className="white-bg-widget">
            <div className="profileOut">
              <h4>
                {props.parentProfileFirstName +
                  " " +
                  props.parentProfileLastName}
              </h4>
              <h5>{props.parentEmail}</h5>
              <p>{props.parentDesignation}</p>
            </div>
            <div className="profileDetails">
              <Row gutter={16}>
                <Col span={12}>
                  <label>{talentDetailsCard.labelForBand}</label>
                </Col>
                <Col span={12}>
                  <p>{props.parentBand}</p>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <label>{talentDetailsCard.labelForLifetimeSTUs}</label>
                </Col>
                <Col span={12}>
                  <p>{props.parentLifeTimeStus}</p>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <label>{talentDetailsCard.labelForAssignedBu}</label>
                </Col>
                <Col span={12}>
                  <p>{props.parentAssignedBu}</p>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <label>{talentDetailsCard.labelForJoiningDate}</label>
                </Col>
                <Col span={12}>
                  <p>{dayjs(props.parentJoiningDate).format("YYYY/MM/DD")}</p>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <label>{talentDetailsCard.labelForRelievingDate}</label>
                </Col>
                <Col span={12}>
                  <p>{props.parentReleivingDate}</p>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <label>{talentDetailsCard.labelForSkill}</label>
                </Col>
                <Col span={12}>
                  <p>{props.parentSkills}</p>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <label>{talentDetailsCard.labelForStatus}</label>
                </Col>
                <Col span={12}>
                  <p>{props.parentStatus}</p>
                </Col>
              </Row>
              {props.parentStatus === "Active" && (
                <Row gutter={16}>
                  <Col span={12}>
                    <label>{talentDetailsCard.labelForSalesPerson}</label>
                  </Col>
                  <Col span={12}>
                    <Switch
                      checked={props.talent.isSalesEnabled}
                      onChange={handleSalesPersonEnable}
                    />
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </Col>
        <Col span={16}>
          <div className="white-bg-widget capcityReport">
            <h5>{talentDetailsCard.labelForCapacityReport}</h5>
            <CapacityReport />
          </div>
        </Col>
      </Row>
    </>
  );
};
export default TalentDetailsCard;
