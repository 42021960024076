import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { Table, Input, Row, Col, Select, Typography, Card } from "antd";
import { InfoCircleTwoTone, EyeTwoTone } from "@ant-design/icons";
import "./MonthlyEarnings.scss";
import { CSVLink } from "react-csv";
import { ExportOutlined } from "@ant-design/icons";
import StuDetail from "./StuDetails";
import { useSelector } from "react-redux";
import {
  convertNumber,
  formatCurrencyValue,
  infinityCheck,
  replaceString,
} from "../../../utils/util";
const Utlization_value = process.env.REACT_APP_DEFAULT_UTLIZATION as any;
const Execution_value = process.env.REACT_APP_DEFAULT_EXECUTION as any;
const Effeciency_value = process.env.REACT_APP_DEFAULT_EFFICIENCY as any;

const MonthlyEarnings = (props: { location: { state?: any } }) => {
  const token = getToken();
  const months = [
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ];
  const po_id_map: any = {
    1: "Fixed Price",
    2: "Time & Material",
    3: "Retainer",
  };
  let initialEarningsInfo = {
    month: "",
    fin_month_year: 2020,
    departments_id_array: [],
    po_type_id_array: [],
  };
  const [selectedYear, setSelectedYear] = useState(2020);
  const [selectedDeptName, setSeletedDeptName] = useState([]);
  const [selectedPoName, setSelectedPoName] = useState([]);
  const [totalAvailable, setTotalAvailable] = useState(0);
  const [totalExecuted, setTotalExecuted] = useState(0);
  const [totalMyTeamExpended, setTotalMyTeamExpended] = useState(0);
  const [earningsData, setEarningsData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [nameSearch, setNameSearch] = useState("");
  const [totalAddOnRevenue, setTotalAddOnRevenue] = useState(0);
  const [totalEarnedValue, setTotalEarnedValue] = useState(0);
  const [earningsInfo, setEarningsInfo] = useState(initialEarningsInfo);
  const [departmentList, setDepartmentList] = useState([]);
  const [isVisibleExpendedStu, setVisibleExpendedStu] = useState(false);
  const [isVisibleAvailableStu, setVisibleAvailableStu] = useState(false);
  const [stusBorrowed, setstusBorrowed] = useState(0);
  const [stusLentOut, setStusLentOut] = useState(0);
  const [stuTillDate, setStuTillDate] = useState(0);
  const [stuLentTillDate, setStuLentTillDate] = useState(0);
  const [stuBorrowedTillDate, setStuBorrowedTillDate] = useState(0);
  const costPerStu = useRef(0);
  const [unavailableStu, setUnavailableStu] = useState(0);
  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );

  let symbol = currencySymb?.currency_symbol;

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const { Option } = Select;
  const { Text } = Typography;
  const month = new Date();

  const currentMonth = monthNames[month.getMonth()];

  const history = useHistory();

  const percentFormatter = new Intl.NumberFormat("en-IN", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const currencyFormat = (number: any) => {
    if (number === null || number === undefined) {
      return 0;
    } else {
      return number;
    }
  };

  const formatAddOnRevenue = (gain: any, drop: any) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    return !gain ? "0" : !drop ? "0" : formatter.format(gain - drop);
  };

  const getEarningsInfo = () => {
    initialEarningsInfo = props.location.state;
    setSelectedYear(initialEarningsInfo.fin_month_year);
    setEarningsInfo(initialEarningsInfo);
  };

  const getDeparments = () => {
    const deptIdArr = initialEarningsInfo.departments_id_array;
    const tempArr: any = [];
    let departments: any = localStorage.getItem("Departments");
    departments = JSON.parse(departments);
    deptIdArr.forEach((dept: any) => {
      let dept_name: any = undefined;
      departments.some((deparment: any) => {
        if (deparment.id === dept) {
          dept_name = deparment.name;
          return true;
        }
      });
      tempArr.push(dept_name);
    });
    setSeletedDeptName(tempArr);
  };

  const getPo = () => {
    const tempPoArr: any = [];
    initialEarningsInfo.po_type_id_array.forEach((po: any) => {
      tempPoArr.push(po_id_map[po]);
    });
    setSelectedPoName(tempPoArr);
  };

  const showDrawerExpendedStu = () => {
    setVisibleExpendedStu(true);
  };

  const closeDrawerExpendedStu = () => {
    setVisibleExpendedStu(false);
  };
  const showDrawerAvailableStu = () => {
    setVisibleAvailableStu(true);
  };

  const closeDrawerAvailableStu = () => {
    setVisibleAvailableStu(false);
  };
  let executionStus =
    totalAvailable - Number(unavailableStu) + stusBorrowed - stusLentOut;

  const getEarningsData = async () => {
    let data = { ...earningsInfo, fin_month_year: selectedYear };
    await AxiosConfig.post("/monthlyearnings", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        costPerStu.current = res.data.data.financialMonth.cost_per_stu;
        const data = res.data.data.monthlyEarnings;
        const tempArr: any = [];
        const tempDepartmentSet: any = new Set();
        const tempDepartmentList: any = [];
        data.map((value: any, index: any) => {
          let totalEarned = 0;
          let earned = 0;
          let totalStu = 0;
          let flag = 0;
          let tempData: any = {
            key: index,
            project_code: value.project_code,
            project_name: value.name,
            department_name: value.department.name,
            delete_action: value.id,
            purchaseOrders: { key: index, orderList: [] },
            stusExpended: convertNumber(value.stus_expended, 2),
          };
          let filteredPos = value.PurchaseOrders.filter((earnings: any) => {
            return earnings.po_month_earnings.length > 0;
          });
          filteredPos.map((order: any) => {
            if (order.po_month_earnings.length) flag = 1;
            let tempObj: any = {
              poNumber: order.number,
              poName: order.name,
              poType: order.potype.type,
              earned_value: formatCurrencyValue(
                currencySymb.currency_code,
                order?.po_month_earnings[0]?.earned_value_in_base
              ),

              earnedValue: currencyFormat(
                order.po_month_earnings[0]?.earned_value_in_base
              ),
              Revenue: formatCurrencyValue(
                currencySymb.currency_code,
                order?.po_month_earnings[0]?.gained_value_in_base -
                  order?.po_month_earnings[0]?.dropped_value_in_base
              ),
              addOnRevenue: formatAddOnRevenue(
                order.po_month_earnings[0]?.gained_value_in_base,
                order.po_month_earnings[0]?.dropped_value_in_base
              ),
              stuRecognised: convertNumber(
                order.po_month_earnings[0]?.stus_executed,
                2
              ),
            };
            totalEarned +=
              order.po_month_earnings[0]?.earned_value_in_base ?? 0;
            earned += order.po_month_earnings[0]?.earned_value_in_base ?? 0;
            totalStu += order.po_month_earnings[0]?.stus_executed ?? 0;
            tempData.purchaseOrders.orderList.push(tempObj);
          });
          tempData["totalEarned"] = currencyFormat(totalEarned);
          tempData["earned"] = formatCurrencyValue(
            currencySymb?.currency_code,
            earned
          );
          tempData["totalStu"] = convertNumber(totalStu, 2);
          tempData["executionEfficiency"] = value.stus_expended
            ? percentFormatter.format(totalStu / value.stus_expended)
            : "0%";
          tempData["margin"] = formatCurrencyValue(
            currencySymb?.currency_code,
            totalEarned - value.stus_expended * costPerStu.current
          );
          tempData["earningPerStu"] = formatCurrencyValue(
            currencySymb?.currency_code,
            totalEarned / totalStu
          );
          if (
            (flag === 1 && value.stus_expended === null) ||
            (flag === 0 && value.stus_expended != null) ||
            (flag === 1 && value.stus_expended != null)
          )
            tempArr.push(tempData);

          tempDepartmentSet.add(tempData.department_name);
        });
        setEarningsData(tempArr);
        setFilterData(tempArr);
        tempDepartmentSet.forEach((department: any) => {
          tempDepartmentList.push({
            text: department,
            value: department,
          });
        });
        setDepartmentList(tempDepartmentList);

        setTotalAvailable(res?.data?.data?.stusCapacity[0]?.stusAllocated);
        setTotalExecuted(res?.data?.data?.stusExecuted[0]?.stus_executed);
        setstusBorrowed(res?.data?.data?.stusBorrowed[0]?.stus_borrowed);
        setStusLentOut(res?.data?.data?.stusLentOut[0]?.stus_lent_out);
        setTotalMyTeamExpended(
          res?.data?.data?.myTeamStusExpended[0]?.stus_expended
        );
        setStuTillDate(res?.data?.data?.stusCapacityTillDate[0]?.stusAllocated);
        setStuLentTillDate(
          res?.data?.data?.stusLentOutTillDate[0]?.stus_lent_out
        );
        setStuBorrowedTillDate(
          res?.data?.data?.stusBorrowedTillDate[0]?.stus_borrowed
        );
        setUnavailableStu(
          res?.data?.data?.totalUnavailableStu.length > 0
            ? res?.data?.data?.totalUnavailableStu[0]?.stuUnavailable
            : 0
        );
      })
      .catch((err) => console.log(err));
  };
  let selectedMonth = localStorage.getItem("selectedMonth");
  var divisor;

  if (selectedMonth === currentMonth) {
    divisor =
      Number(stuTillDate) +
      Number(stuBorrowedTillDate) -
      Number(stuLentTillDate);
  } else {
    divisor =
      Number(totalAvailable) -
      Number(unavailableStu) +
      Number(stusBorrowed) -
      Number(stusLentOut);
  }

  const handleOnChange = (event: any) => {
    setNameSearch(event.target.value);
  };

  const handleChange = (value: any) => {
    const [month, year] = value.split(",");
    setSelectedYear(parseInt(year));
    setEarningsInfo({
      ...earningsInfo,
      month: month,
    });
    props.location.state = earningsInfo;
  };

  const onClickRow = (record: any) => {
    history.push("/projects/" + record);
  };

  const monthlyEarningHeaders = [
    { label: "PROJECT CODE", key: "project_code" },
    { label: "PROJECT NAME", key: "project_name" },
    { label: "DEPARTMENT NAME", key: "department_name" },
    { label: "EXECUTION EFFICIENCY", key: "executionEfficiency" },
    { label: "MARGIN", key: "margin" },
    { label: "EARNING/STU", key: "earningPerStu" },
    { label: "EARNED VALUE", key: "totalEarned" },
    { label: "STU'S RECOGNIZED", key: "totalStu" },
    { label: "STU'S EXPENDED", key: "stusExpended" },
  ];
  useEffect(() => {
    if (currencySymb?.currency_code) {
      getEarningsInfo();
      getDeparments();
      getPo();
    }
  }, [currencySymb?.currency_code]);

  useEffect(() => {
    if (currencySymb?.currency_code) {
      getEarningsData();
    }
  }, [earningsInfo, currencySymb?.currency_code]);

  useEffect(() => {
    if (earningsData && currencySymb?.currency_code) {
      const results: any = earningsData.filter(
        (item: any) =>
          item.project_code.toLowerCase().includes(nameSearch.toLowerCase()) ||
          item.project_name.toLowerCase().includes(nameSearch.toLowerCase()) ||
          item.department_name.toLowerCase().includes(nameSearch.toLowerCase())
      );
      setFilterData(results);
    }
  }, [nameSearch, currencySymb?.currency_code]);

  useEffect(() => {
    if (currencySymb?.currency_code) {
      let totalEarned = 0;
      let totalRevenue = 0;
      filterData.forEach((data: any) => {
        data?.purchaseOrders.orderList.map((order: any) => {
          totalEarned += parseInt(order.earnedValue);
          totalRevenue += parseFloat(
            order.addOnRevenue.replace("$", "").replaceAll(",", "")
          );
        });
      });
      setTotalEarnedValue(totalEarned);
      setTotalAddOnRevenue(totalRevenue);
    }
  }, [filterData, currencySymb?.currency_code]);

  const columns: any = [
    {
      title: "PROJECT",
      dataIndex: [
        "project_code",
        "delete_action",
        "project_name",
        "department_name",
      ],
      key: "project_code",
      filters: departmentList,
      onFilter: (value: any, record: any) =>
        record.department_name.includes(value),
      sorter: (a: any, b: any) => a.project_code.localeCompare(b.project_code),
      render: (text: any, record: any) => (
        <Row>
          <Col md={24} xs={24}>
            <a
              onClick={() => {
                onClickRow(record["delete_action"]);
              }}
            >
              {record["project_code"]}
            </a>
          </Col>
          <Col md={24} xs={24}>
            {record["project_name"]}
          </Col>
          <Col md={24} xs={24}>
            {record["department_name"]}
          </Col>
        </Row>
      ),
    },
    {
      title: "EXECUTION EFFICIENCY",
      key: "executionEfficiency",
      dataIndex: "executionEfficiency",
      sorter: (a: any, b: any) =>
        a.executionEfficiency.replace(/[%]/g, "") -
        b.executionEfficiency.replace(/[%]/g, ""),
      align: "right",
    },
    {
      title: "MARGIN",
      key: "margin",
      dataIndex: "margin",
      sorter: (a: any, b: any) =>
        replaceString(a.margin) - replaceString(b.margin),
      align: "right",
    },
    {
      title: "EARNED/STU",
      key: "earningPerStu",
      dataIndex: "earningPerStu",
      sorter: (a: any, b: any) =>
        replaceString(a.earningPerStu) - replaceString(b.earningPerStu),
      align: "right",
    },
    {
      title: "EARNED VALUE",
      key: "totalEarned",
      dataIndex: "earned",
      sorter: (a: any, b: any) =>
        replaceString(a.earned) - replaceString(b.earned),
      align: "right",
    },
    {
      title: "STU'S EARNED",
      key: "totalStu",
      dataIndex: "totalStu",
      sorter: (a: any, b: any) =>
        replaceString(a.totalStu) - replaceString(b.totalStu),
      align: "right",
    },

    {
      title: "STU'S UTILIZED",
      key: "stusExpended",
      dataIndex: "stusExpended",
      sorter: (a: any, b: any) =>
        replaceString(a.stusExpended) - replaceString(b.stusExpended),
      align: "right",
    },
  ];

  const expandedColumns: any = [
    {
      title: "PURCHASE ORDER",
      dataIndex: "poName",
      sorter: (a: any, b: any) => a.poName.localeCompare(b.poName),
    },
    {
      title: "PO TYPE",
      dataIndex: "poType",
      sorter: (a: any, b: any) => a.poType.localeCompare(b.poType),
    },
    {
      title: "EARNED VALUE",
      align: "right",
      dataIndex: ["earned_value"],
      sorter: (a: any, b: any) =>
      replaceString(a.earned_value) -
      replaceString(b.earned_value),
      render: (text: any, record: any) => (
        <Row>
          <Col md={24} xs={24}>
            {record["earned_value"]}
          </Col>
        </Row>
      ),
    },
    {
      title: "STU'S RECOGNIZED",
      dataIndex: "stuRecognised",
      sorter: (a: any, b: any) =>
      replaceString(a.stuRecognised) -
      replaceString(b.stuRecognised),
      align: "right",
    },
  ];

  const cardTitle = (
    <>
      {`${earningsInfo.month} ${selectedYear} `}
      <InfoCircleTwoTone className="showBuDetail" />
      <p id="buInfo" style={{ fontSize: "0.7rem" }}>
        <b>Associated BU: </b>
        <ul>
          {selectedDeptName.map((dept_name: any, index: any) => (
            <li key={index}>{dept_name}</li>
          ))}
        </ul>
        <b>Associated PO</b>
        <ul>
          {selectedPoName.map((po_name: any, index: any) => (
            <li key={index}>{po_name}</li>
          ))}
        </ul>
      </p>
    </>
  );

  const expandedRow = (row: any) => {
    let dataSource = undefined;
    console.warn(row);
    if (row.key === row.purchaseOrders.key) {
      dataSource = row.purchaseOrders.orderList;
    }
    return (
      <Table
        columns={expandedColumns}
        dataSource={dataSource}
        pagination={false}
      />
    );
  };

  return (
    <div>
      <div className="table-card">
        <div className="mb20">
          <div className="tab-contents">
            <Row>
              <Col md={11} xs={18}>
                <div className="searchForm">
                  <form>
                    <Input.Search
                      allowClear
                      onChange={handleOnChange}
                      placeholder="Search by Project Code/Project Name/Department Name"
                    />
                  </form>
                </div>
              </Col>
              <Col md={3} xs={6}>
                <label className="totalLabel">
                  Total #: {filterData.length}
                </label>
              </Col>
              <Col md={4} xs={0}></Col>
              <Col md={4} xs={18}>
                <Select
                  placeholder="Select the Month"
                  style={{ width: "100%" }}
                  onChange={handleChange}
                >
                  {months.map((month: any, index: any) =>
                    index <= 8 ? (
                      <Option
                        value={`${month}, ${earningsInfo.fin_month_year}`}
                        key={index}
                      >
                        {month}, {earningsInfo.fin_month_year}
                      </Option>
                    ) : (
                      <Option
                        value={`${month}, ${earningsInfo.fin_month_year + 1}`}
                        key={index}
                      >
                        {month}, {earningsInfo.fin_month_year + 1}
                      </Option>
                    )
                  )}
                </Select>
              </Col>
              <Col className="textAlignRight" md={2} xs={6}>
                <CSVLink
                  data={filterData}
                  target="_blank"
                  filename={"monthly_earnings.csv"}
                  headers={monthlyEarningHeaders}
                  className="exportTableButton mb-5"
                  title="Export Table"
                >
                  <ExportOutlined className="exportOultineIcon" />
                </CSVLink>
              </Col>
            </Row>
            <Row className="mb20" gutter={16}>
              <Col className="site-card-border-less-wrapper " md={8} xs={24}>
                <Card className="cardHeight" title={cardTitle} bordered={false}>
                  <Row style={{ display: "flex", alignItems: "center" }}>
                    <Col md={12} xs={12}>
                      <Text
                        title="Recognized"
                        type="secondary"
                        className="textHeadingRevenue pr4 overflowEllipsis"
                      >
                        Recognized
                      </Text>
                      <InfoCircleTwoTone title="The revenue earned/executed during this period. Monthly revenue recognition is captured for each active purchase order." />
                    </Col>
                    <Col md={12} xs={12} className="textAlignRight">
                      <b
                        className="textValueRevenue overflowEllipsisValue"
                        style={{ textAlign: "right" }}
                        title={currencyFormat(totalEarnedValue)}
                      >
                        {formatCurrencyValue(
                          currencySymb?.currency_code,
                          totalEarnedValue
                        )}
                      </b>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="" md={13} xs={12}>
                      <Text
                        type="secondary"
                        className="textHeadingRevenue pr4 overflowEllipsis"
                        title=" Add-on Revenue"
                      >
                        Add-on Revenue
                      </Text>
                      <InfoCircleTwoTone title="The additional revenue gained/lost during this period. Applicable mostly for a retainer or T&M projects" />
                    </Col>
                    <Col md={11} xs={12} className="textAlignRight">
                      <b
                        className={`textValueRevenue overflowEllipsisValue ${
                          totalAddOnRevenue >= 0 ? "textGreen" : "textRed"
                        }`}
                        title={currencyFormat(totalAddOnRevenue)}
                      >
                        {formatCurrencyValue(
                          currencySymb?.currency_code,
                          totalAddOnRevenue
                        )}
                      </b>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xs={12}>
                      <Text
                        type="secondary"
                        className="textHeadingRevenue pr4 overflowEllipsis"
                        title="Avg /STU"
                      >
                        Avg /STU
                      </Text>
                    </Col>
                    <Col md={12} xs={12} className="textAlignRight">
                      <b
                        className="textValueRevenue overflowEllipsisValue"
                        title={
                          convertNumber(totalAvailable, 2) != "0"
                            ? currencyFormat(
                                totalEarnedValue /
                                  (totalAvailable - Number(unavailableStu))
                              )
                            : `${symbol} 0.00`
                        }
                      >
                        {formatCurrencyValue(
                          currencySymb?.currency_code,
                          totalEarnedValue /
                            (totalAvailable - Number(unavailableStu))
                        )}
                      </b>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col className="site-card-border-less-wrapper " md={8} xs={24}>
                <Card
                  className="cardHeight"
                  title="Execution Data"
                  bordered={false}
                >
                  <Row style={{ display: "flex", alignItems: "center" }}>
                    <Col md={14} xs={14}>
                      <Text
                        type="secondary"
                        className="textHeadingRevenue pr4 overflowEllipsis"
                        title="Available STUs"
                      >
                        Available STUs
                      </Text>
                      <EyeTwoTone onClick={showDrawerAvailableStu} />
                    </Col>
                    <Col md={10} xs={10} className="textAlignRight">
                      <b
                        className="textValueRevenue overflowEllipsisValue"
                        title={convertNumber(
                          Number(totalAvailable) -
                            Number(unavailableStu) +
                            Number(stusBorrowed) -
                            Number(stusLentOut),
                          2
                        )}
                      >
                        {convertNumber(
                          Number(totalAvailable) -
                            Number(unavailableStu) +
                            Number(stusBorrowed) -
                            Number(stusLentOut),
                          2
                        )}
                      </b>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={14} xs={14}>
                      <Text
                        type="secondary"
                        className="textHeadingRevenue pr4 overflowEllipsis"
                        title="Recognized STUs"
                      >
                        Earned STUs
                      </Text>
                      <InfoCircleTwoTone title="Earned STUs include the number of STUs earned as value delivered. Information is calculated for all fixed prices and entered monthly for all T&M projects" />
                    </Col>
                    <Col md={10} xs={10} className="textAlignRight">
                      <b
                        className="textValueRevenue overflowEllipsisValue"
                        title={convertNumber(totalExecuted, 2)}
                      >
                        {convertNumber(totalExecuted, 2)}
                      </b>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={14} xs={14}>
                      <Text
                        type="secondary"
                        className="textHeadingRevenue pr4 overflowEllipsis"
                        title="Expended STUs"
                      >
                        Utilized STUs
                      </Text>
                      <EyeTwoTone onClick={showDrawerExpendedStu} />
                    </Col>
                    <Col md={10} xs={10} className="textAlignRight">
                      <b
                        className="textValueRevenue overflowEllipsisValue"
                        title={convertNumber(
                          Number(totalMyTeamExpended) +
                            Number(stusBorrowed) -
                            Number(stusLentOut) -
                            Number(unavailableStu),
                          2
                        )}
                      >
                        {convertNumber(
                          Number(totalMyTeamExpended) +
                            Number(stusBorrowed) -
                            Number(stusLentOut) -
                            Number(unavailableStu),
                          2
                        )}
                      </b>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col className="site-card-border-less-wrapper" md={8} xs={24}>
                <Card className="cardHeight" bordered={false}>
                  <Row style={{ display: "flex", alignItems: "center" }}>
                    <Col md={17} xs={14}>
                      <Text
                        type="secondary"
                        className="textHeadingEfficiency pr4 overflowEllipsis"
                        title="Utilization"
                      >
                        Utilization
                      </Text>
                      <InfoCircleTwoTone title="Utilization % indicates how many STUs of the available team were utilized in approved projects. Formula = utilized STUs / Available STUs" />
                    </Col>
                    <Col md={7} xs={10} className="textAlignRight">
                      <b
                        className={`textValueEfficiency overflowEllipsisValue ${
                          totalMyTeamExpended === null ||
                          divisor === null ||
                          divisor !== 0 || // <-- Add this check
                          Number(
                            percentFormatter
                              .format(
                                (totalMyTeamExpended +
                                  stusBorrowed -
                                  stusLentOut -
                                  Number(unavailableStu)) /
                                  divisor
                              )
                              .replace("%", "")
                          ) < 90
                            ? "textRed"
                            : "textGreen"
                        }`}
                        title={
                          totalMyTeamExpended === null ||
                          divisor === null ||
                          divisor === 0 // <-- Add this check
                            ? "0%"
                            : percentFormatter.format(
                                (totalMyTeamExpended +
                                  stusBorrowed -
                                  stusLentOut -
                                  Number(unavailableStu)) /
                                  divisor
                              )
                        }
                      >
                        {totalMyTeamExpended === null ||
                        divisor === null ||
                        divisor === 0 || // <-- Add this check
                        Number(
                          percentFormatter
                            .format(
                              (totalMyTeamExpended +
                                stusBorrowed -
                                stusLentOut -
                                Number(unavailableStu)) /
                                divisor
                            )
                            .replace("%", "")
                        ) < Utlization_value
                          ? 0
                          : percentFormatter.format(
                              (totalMyTeamExpended +
                                stusBorrowed -
                                stusLentOut -
                                Number(unavailableStu)) /
                                divisor
                            )}
                      </b>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={17} xs={14}>
                      <Text
                        type="secondary"
                        className="textHeadingEfficiency pr4 overflowEllipsis"
                        title="Execution"
                      >
                        Execution
                      </Text>
                      <InfoCircleTwoTone title="Execution % indicates how many STUs out of the available STUs, were Earned as value delivered against. Formula = Earned STUs / Available STUs" />
                    </Col>
                    <Col md={7} xs={10} className="textAlignRight">
                      <b
                        className={`textValueEfficiency overflowEllipsisValue ${
                          totalMyTeamExpended === null ||
                          totalAvailable === null ||
                          Number(
                            percentFormatter
                              .format(totalExecuted / executionStus)
                              .replace("%", "")
                          ) < 80
                            ? "textRed"
                            : "textGreen"
                        }`}
                        title={
                          totalMyTeamExpended === null ||
                          totalAvailable === null ||
                          Number(
                            percentFormatter
                              .format(totalExecuted / executionStus)
                              .replace("%", "")
                          ) < 80
                            ? "0%"
                            : percentFormatter.format(
                                totalExecuted / executionStus
                              )
                        }
                      >
                        {totalMyTeamExpended === null ||
                        totalAvailable === null ||
                        Number(
                          percentFormatter
                            .format(totalExecuted / executionStus)
                            .replace("%", "")
                        ) < Execution_value
                          ? 0
                          : percentFormatter.format(
                              totalExecuted / executionStus
                            )}
                      </b>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={17} xs={14}>
                      <Text
                        type="secondary"
                        className="textHeadingEfficiency pr4 overflowEllipsis"
                        title="Efficiency"
                      >
                        Efficiency
                      </Text>
                      <InfoCircleTwoTone title="Efficiency % indicates for every STU that is Earned, how many STUs of effort is being utilized. Formula = Earned STUs / Available STUs" />
                    </Col>
                    <Col md={7} xs={10} className="textAlignRight">
                      <b
                        className={`textValueEfficiency overflowEllipsisValue ${
                          totalMyTeamExpended === 0 ||
                          totalMyTeamExpended === null ||
                          totalAvailable === null ||
                          Number(
                            percentFormatter
                              .format(totalExecuted / totalMyTeamExpended)
                              .replace("%", "")
                          ) < 90
                            ? "textRed"
                            : "textGreen"
                        }`}
                        title={
                          totalMyTeamExpended === 0 ||
                          totalMyTeamExpended === null ||
                          totalAvailable === null
                            ? "0%"
                            : percentFormatter.format(
                                totalExecuted / totalMyTeamExpended
                              )
                        }
                      >
                        {totalMyTeamExpended === 0 ||
                        totalMyTeamExpended === null ||
                        totalAvailable === null ||
                        Number(
                          infinityCheck(
                            totalExecuted / infinityCheck(totalMyTeamExpended)
                          )
                        ) < Effeciency_value
                          ? 0
                          : percentFormatter.format(
                              infinityCheck(
                                totalExecuted /
                                  infinityCheck(totalMyTeamExpended)
                              )
                            )}
                      </b>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <div className="tableOut noWordBreak">
              <Table
                columns={columns}
                expandedRowRender={expandedRow}
                dataSource={filterData}
                bordered
                className="talentTable"
                scroll={{ x: 1000, y: 500 }}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "30", "40", "50"],
                }}
              />
            </div>
            {/* <Table /> */}
          </div>
        </div>
      </div>
      {isVisibleExpendedStu && (
        <StuDetail
          drawerTitle="Utilized STUs"
          drawerDesc="Utilized STUs include STUs recorded into approved timesheets against the projects mentioned."
          closeDrawer={closeDrawerExpendedStu}
          visible={isVisibleExpendedStu}
          earningsInfo={earningsInfo}
        />
      )}
      {isVisibleAvailableStu && (
        <StuDetail
          drawerTitle="Available STUs"
          drawerDesc="Available STUs include STUs planned + STUs Utilized by external team members for accounted projects - STUs utilized by this  team for external projects"
          closeDrawer={closeDrawerAvailableStu}
          visible={isVisibleAvailableStu}
          earningsInfo={earningsInfo}
        />
      )}
    </div>
  );
};

export default MonthlyEarnings;
