import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import {
  Drawer,
  Select as AntSelect,
  Input,
  Upload,
  Modal,
  notification,
  DatePicker,
} from "antd";
import Select from "react-select";
import { Formik, FormikProps } from "formik";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { InputField } from "../../../components";
import TextArea from "antd/lib/input/TextArea";
import ButtonReuse from "../../../components/Buttons/Button";
import { getAllCustomers } from "../../../stores/actions/getAllCustomerAction";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { preventMinus } from "../../../utils/util";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { enumForAddCreditNote } from "../../../utils/enums";
import * as Yup from "yup";
import moment from "moment";
require("./AddCreditNote.scss");

const FormData = require("form-data");

const validationSchema = Yup.object().shape({
  customer_id: Yup.number().required("Customer name is required"),
  credit_note: Yup.string().required("Credit note number is required"),
  credit_amount: Yup.number()
    .required("Credit amount is required")
    .moreThan(
      0,
      "Please select currency & enter the credit amount greater than 0"
    ),
  description: Yup.string().required("Description is required"),
  currency_id: Yup.number().required("Currency is required"),
  issue_date: Yup.date().nullable().required("Issue date is required"), // Validation for Issue Date field
});
const AddOverlay = (props: {
  visible?: boolean;
  onClose: () => void;
  onCancelButton: () => void;
  currencyRate?: any;
  mastercurrencies?: any;
}) => {
  const { Option } = AntSelect;
  const dispatch: any = useDispatch();
  const [files, setFiles] = useState<any>([]);
  const [previewImage, setPreviewImage] = useState<string>();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState<string>();
  const [isDisable, setDisable] = useState(true);

  const allCustomers = useSelector(
    (store: any) => store?.allCustomers.allCustomers
  );

  type MyFormValues = {
    customer_id: number;
    credit_note: string;
    credit_amount: number;
    description: string;
    currency_id: number;
    issue_date: moment.Moment | null;
    files: [];
  };

  const formikRef = React.useRef<FormikProps<MyFormValues>>(null);

  useEffect(() => {
    dispatch(getAllCustomers());
  }, [dispatch]);

  const handleAttachmentChange = (info: UploadChangeParam<UploadFile<any>>) => {
    const { fileList } = info;
    setFiles(fileList);
  };
  const openNotification = () => {
    notification.open({
      message: "Credit Note Added Successfully",
    });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}> {"Upload"}</div>
    </div>
  );

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const getCurrencyDropdown = (setFieldValue) => {
    return (
      <AntSelect
        className="select-before"
        onChange={(value) => {
          setDisable(false);
          setFieldValue("currency_id", value);
        }}
      >
        {props.mastercurrencies.map((currency: any, index: number) => (
          <Option key={index} value={currency?.id}>
            {currency?.currency_code}
          </Option>
        ))}
      </AntSelect>
    );
  };

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url || file.thumbUrl);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url?.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const onCancelHandler = (resetForm: () => void) => {
    resetForm();
    props.onCancelButton();
  };
  const token = getToken();
  const handleDrawerClose = () => {
    if (formikRef.current) {
      formikRef.current?.resetForm();
    }
    props.onClose();
  };

  return (
    <>
      <Drawer
        title="Add Credit Note"
        width={500}
        onClose={handleDrawerClose}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        className="add_creditNote"
      >
        <Formik
          initialValues={{
            customer_id: "",
            credit_note: "",
            credit_amount: 0,
            description: "",
            currency_id: 0,
            issue_date: null,
            files: [],
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            try {
              const customer = allCustomers.find(
                (c) => c.id === parseInt(values.customer_id)
              );

              const data = new FormData();
              const selectedDate = values.issue_date;
              const formattedDate = selectedDate
                ? moment(selectedDate).format("YYYY-MM-DD")
                : null;
              if (customer) {
                data.append("customer_id", customer.id);
              }
              data.append("description", values.description);
              data.append("credit_note", values.credit_note);
              data.append("credit_amount", values.credit_amount);
              data.append("currency_id", values.currency_id);
              data.append("issue_date", formattedDate);
              for (const file of files) {
                data.append("files", file.originFileObj);
              }              

              const response = await AxiosConfig.post("creditnote", data, {
                headers: { Authorization: `Bearer ${token}` },
              });
              
              if (response.status === 200) {
                openNotification();
                props.onClose();
                setTimeout(function () {
                  window.location.reload();
                }, 1000);
              }

            } catch (error:any) {
              if (error.response) {
                if (error.response.status === 409) {
                  notification.open({
                    message: "",
                    description:"This Customer with this Currency already exists, please do edit in the previously existing Customer",
                    style: { color: "red" },
                  });
                  resetForm();
                } else if(error.response.status === 400){              
                  notification.open({
                    message: "",
                    description: "Credit note already exists with the same Credit Note Number",
                    style: { color: "red" },
                  });
                  resetForm();
                }
              } 
            }

            resetForm();
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            resetForm,
            setFieldValue,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="input_container">
                  <label>{enumForAddCreditNote.labelForCustomerName} </label>
                  <Select
                    className="select--category"
                    placeholder="Select Customer Name"
                    onChange={(value) => {
                      setFieldValue("customer_id", value?.value?.toString());
                    }}
                    options={allCustomers.map((customer) => ({
                      value: customer.id,
                      label: customer.name,
                    }))}
                    isSearchable={true}
                  />
                  {errors.customer_id && touched.customer_id && (
                    <div className="display_error">{errors.customer_id}</div>
                  )}
                </div>

                <div className="input_container">
                  <label>{enumForAddCreditNote.labelForCreditNoteNumber}</label>
                  <InputField
                    placeholder="Enter the Credit Note Number"
                    name="credit_note"
                    onChange={handleChange}
                    value={values.credit_note}
                  />
                  <p className="display_error">{errors.credit_note}</p>
                </div>

                <div className="input_container">
                  <label>{enumForAddCreditNote.labelForCreditAmount}</label>
                  <Input
                    placeholder="Enter the Credit Amount"
                    name="value"
                    onKeyPress={preventMinus}
                    min={0}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const twoDecimalRegex = /^\d+(\.\d{0,2})?$/;
                      if (
                        twoDecimalRegex.test(inputValue) ||
                        inputValue === ""
                      ) {
                        setFieldValue("credit_amount", inputValue);
                      }
                    }}
                    addonBefore={getCurrencyDropdown(setFieldValue)}
                    disabled={isDisable}
                  />
                  {errors.credit_amount && touched.credit_amount && (
                    <div className="display_error">{errors.credit_amount}</div>
                  )}
                </div>

                <div className="input_container">
                  <label>{enumForAddCreditNote.labelForIssueDate}</label>
                </div>
                <DatePicker
                  className="date-picker-input"
                  value={values.issue_date}
                  onChange={(date) => setFieldValue("issue_date", date)}
                />
                <p className="display_error">{errors.issue_date}</p>

                <div>
                  <label>{enumForAddCreditNote.labelForDescription}</label>
                  <TextArea
                    placeholder="Enter the Description"
                    rows={5}
                    name="description"
                    onChange={handleChange}
                    value={values.description}
                    className="textarea-input"
                  />
                  {errors.description && touched.description && (
                    <div className="display_error">{errors.description}</div>
                  )}
                </div>

                <Upload
                  className="PO-upload"
                  listType="picture-card"
                  fileList={files}
                  onPreview={handlePreview}
                  onChange={handleAttachmentChange}
                  beforeUpload={() => false}
                >
                  {files.length >= 8 ? null : uploadButton}
                </Upload>
                <Modal
                  visible={previewVisible}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>

                <div className="button-container">
                  <ButtonReuse
                    type="primary"
                    className="primary-btn"
                    htmlType="submit"
                    value="Add"
                  />
                  <ButtonReuse
                    type="primary"
                    className="primary-btn cancel--btn"
                    value="Cancel"
                    onClick={() => onCancelHandler(resetForm)}
                  />
                </div>
              </form>
            );
          }}
        </Formik>
      </Drawer>
    </>
  );
};

export default AddOverlay;
