import { Table, Input, Row, Col, Button } from "antd";
import { useState, useEffect, useRef } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { useHistory } from "react-router-dom";
import CsvDownloader from "react-csv-downloader";
import { ExportOutlined } from "@ant-design/icons";
import AddTalent from "./AddTalent";
import { checkTalentStatus } from "../../../utils/util";
import Loader from "../../../components/Loader/Loader";

require("./Talent.scss");

const Talent = () => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [nameSearch, setNameSearch] = useState("");
  const [bands, setBands] = useState([]);
  const [department, setDepartments] = useState([]);
  const [skills, setSkills] = useState([]);
  const [isVisible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const currentTableData = useRef<any>([]);
  const [filterTotalCount, setFilterTotalCount] = useState(0);
  const token = getToken();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getBands();
  }, []);

  useEffect(() => {
    getDepatments();
  }, []);

  useEffect(() => {
    getSkillSet();
  }, []);

  useEffect(() => {
    if (data) {
      const results: any = data.filter(
        (item: any) =>
          item.Emp_id.toLowerCase().includes(nameSearch.toLowerCase()) ||
          item.First_name.toLowerCase().includes(nameSearch.toLowerCase()) ||
          item.Last_name.toLowerCase().includes(nameSearch.toLowerCase()) ||
          item.Band.toLowerCase().includes(nameSearch.toLowerCase()) ||
          item.Skill_set.toLowerCase().includes(nameSearch.toLowerCase()) ||
          item.Department.toLowerCase().includes(nameSearch.toLowerCase()) ||
          item.Active_status.toLowerCase().startsWith(nameSearch.toLowerCase())
      );
      setFilterData(results);
      setFilterTotalCount(results.length);
    }
  }, [nameSearch]);

  const getBands = async () => {
    await AxiosConfig.get("bands", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      let band = res.data.data.bands;
      band = band.sort((a: any, b: any) => {
        var a1 = a.name.toLowerCase();
        var b1 = b.name.toLowerCase();
        return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
      });
      setBands(
        band.map((row: { name: any; id: any }) => ({
          text: row.name,
          value: row.name,
        }))
      );
    });
  };

  const getDepatments = async () => {
    await AxiosConfig.get("departments", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      let dept = res.data.data.departments;
      dept = dept.sort((a: any, b: any) => {
        var a1 = a.name.toLowerCase();
        var b1 = b.name.toLowerCase();
        return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
      });
      setDepartments(
        dept.map((row: { name: any; id: any }) => ({
          text: row.name,
          value: row.name,
        }))
      );
    });
  };

  const getSkillSet = async () => {
    await AxiosConfig.get("skills", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      if (res.data.data.skills.length >= 0) {
        setIsLoading(false);
      }
      let skill = res.data.data.skills;
      skill = skill.sort((a: any, b: any) => {
        var a1 = a.name.toLowerCase();
        var b1 = b.name.toLowerCase();
        return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
      });
      setSkills(
        skill.map((row: { name: any; id: any }) => ({
          text: row.name,
          value: row.name,
        }))
      );
    });
  };
  const getData = async () => {
    await AxiosConfig.get("/talents", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setData(
        res.data.data.talents.map(
          (row: {
            emp_id: any;
            firstName: any;
            lastName: any;
            id: any;
            date_of_seperation: any;
            department: any;
            band: any;
            skill: any;
          }) => ({
            Emp_id: row?.emp_id,
            First_name: row?.firstName,
            Last_name: row?.lastName,
            Talent_id: row.id,
            Active_status: checkTalentStatus(row?.date_of_seperation),
            Name: `${row?.firstName} ${row?.lastName}`,
            Department: row?.department?.name,
            Band: row?.band?.name,
            Skill_set: row?.skill?.name,
            key: row?.id,
            Full_name: `${row.firstName} ${row.lastName}`,
          })
        )
      );

      const tableData =  res.data.data.talents.map(
          (row: {
            emp_id: any;
            firstName: any;
            lastName: any;
            id: any;
            date_of_seperation: any;
            department: any;
            band: any;
            skill: any;
          }) => ({
            Emp_id: row?.emp_id,
            First_name: row?.firstName,
            Last_name: row?.lastName,
            Talent_id: row?.id,
            Active_status: checkTalentStatus(row?.date_of_seperation),
            Department: row?.department?.name,
            Band: row?.band?.name,
            Skill_set: row?.skill?.name,
            key: row?.id,
            Full_name: `${row?.firstName} ${row?.lastName}`,
          })
        );
      setFilterData(tableData);
      setFilterTotalCount(tableData?.length);
    });
  };

  const visibleHandler = () => {
    setVisible(true);
  };
  const closeHandler = () => {
    setVisible(false);
  };

  let history = useHistory();

  const handleView = (record: any) => {
    history.push("/talent/" + record.Talent_id);
    localStorage.setItem("talentid", JSON.stringify(record));
  };

  const columns = [
    {
      title: "EMPLOYEE ID",
      dataIndex: "Emp_id",
      key: "Emp_id",
      sorter: (a: any, b: any) => a.Emp_id.localeCompare(b.Emp_id),
      render: (text: any, record: any) => (
        <div
          onClick={() => handleView(record)}
          style={{ color: "#5c67e5", cursor: "pointer" }}
        >
          {record["Emp_id"]}
        </div>
      ),
    },
    {
      title: "NAME",
      dataIndex: "[First_name, Last_name]",
      key: "name",
      width: "20%",
      render: (text: any, record: any) => (
        <div>
          <p className="nameTable">
            {record["First_name"] + " " + record["Last_name"]}
          </p>
        </div>
      ),
      sorter: (a: any, b: any) => a.First_name.localeCompare(b.First_name),
    },
    {
      title: "BU",
      dataIndex: "Department",
      key: "bu",
      filters: department,
      onFilter: (value: any, record: any) => record.Department.includes(value),
      sorter: (a: any, b: any) => a.Department.localeCompare(b.Department),
    },
    {
      title: "BAND",
      dataIndex: "Band",
      key: "band",
      filters: bands,
      onFilter: (value: any, record: any) => record.Band.includes(value),
      sorter: (a: any, b: any) =>
        a.Band.replace("B", "") - b.Band.replace("B", ""),
    },
    {
      title: "SKILL SET",
      dataIndex: "Skill_set",
      key: "skill_set",
      filters: skills,
      onFilter: (value: any, record: any) => record.Skill_set.includes(value),
      sorter: (a: any, b: any) => a.Skill_set.localeCompare(b.Skill_set),
    },
    {
      title: "STATUS",
      dataIndex: "Active_status",
      key: "status",
      className: "Active",
      sorter: (a: any, b: any) =>
        a.Active_status.length - b.Active_status.length,
      render(text: any) {
        return {
          props: {
            style: {
              color:
                text === "Active"
                  ? "green"
                  : text === "Resigned"
                  ? "red"
                  : text === "Serving Notice period"
                  ? "orange"
                  : "red",
            },
          },
          children: <div>{text}</div>,
        };
      },
      filters: [
        { text: "Active", value: "Active" },
        { text: "Inactive", value: "Inactive" },
        { text: "Resigned", value: "Resigned" },
        { text: "Serving Notice period", value: "Serving Notice period" },
      ],
      onFilter: (value: any, record: any) =>
        record.Active_status.includes(value),
    },
  ];

  const talentHeaders = [
    { displayName: "EMPLOYEE ID", id: "Emp_id" },
    { displayName: "NAME", id: "Full_name" },
    { displayName: "BU", id: "Department" },
    { displayName: "BAND", id: "Band" },
    { displayName: "SKILL SET", id: "Skill_set" },
    { displayName: "STATUS", id: "Active_status" },
  ];

  const handleOnChange = (event: any) => {
    setNameSearch(event.target.value);
  };

  const fetchCSVData = () => {
    return Promise.resolve(currentTableData.current);
  };

  return (
    <div className="table-card">
      <Row>
        <Col md={8} xs={24}>
          <div className="searchForm">
            <form>
              <Input.Search
                allowClear
                onChange={handleOnChange}
                placeholder="Search by name / band / skillset / department/ status"
              />
            </form>
          </div>
        </Col>
        <Col md={8} xs={24}>
          <label className="totalLabel">Total #: {filterTotalCount}</label>
        </Col>
        <Col md={8} xs={24} className="text-end">
          <div className="addTalentBtn">
            <Button
              type="primary"
              onClick={visibleHandler}
              className="primary-btn"
            >
              Add Talent
            </Button>
          </div>
          <CsvDownloader
            datas={fetchCSVData}
            filename="talents.csv"
            columns={talentHeaders}
            className="exportTableButton"
            title="Export Table"
          >
            <ExportOutlined className="exportOultineIcon" />
          </CsvDownloader>
        </Col>
      </Row>
      <AddTalent
        visible={isVisible}
        onClose={closeHandler}
        onCancelButton={closeHandler}
      />
      {isLoading === true ? (
        <Loader />
      ) : (
        <div className="tableOut noWordBreak">
          <Table
            columns={columns}
            dataSource={filterData}
            bordered
            className="talentTable"
            onChange={(pagination, filters, sorter, extra) => {
              const filteredData: any = extra.currentDataSource;
              const filteredCount = filteredData.length;
              setFilterTotalCount(filteredCount);
            }}
            summary={(currentData) => {
              currentTableData.current = currentData;
              return <></>;
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Talent;
